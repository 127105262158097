import React, { useEffect, useState } from 'react';
import './UsersReport.scss';
import { RegisterPatientList } from '../RegisterPatientList/RegisterPatientList';
import { RegisterProviderList } from '../RegisterProviderList/RegisterProviderList';
import { useNavigationService } from '../../fx-core';

const UserReport = (props: any) => {
    const { pageTitle } = useNavigationService();
    const [selectedTab, setSelectedTab] = useState("patients");

    const loadBySelectedTab = (type: any) => {
        setSelectedTab(type);
    }

    /*useEffect(() => {
        showPatientList();
    }, []);*/

    return (
        <div className="content-container">
            <div className="title-font">
                <span>{pageTitle}</span>
            </div>
            <div className="px-3">
                <div className="d-flex align-items-center justify-content-center py-3">
                    <div>
                        <span onClick={() => loadBySelectedTab('patients')}>
                            {
                                <span className={selectedTab === 'patients' ? "selectedStyle pointer" : "pointer"}>Patients</span>
                            }
                        </span>
                        <span className="pl-4" onClick={() => loadBySelectedTab('providers')}>
                            {
                                <span className={selectedTab === 'providers' ? "selectedStyle pointer" : "pointer"}>Providers</span>
                            }
                        </span>
                    </div>
                </div>
                {
                    selectedTab === 'patients' &&
                    <RegisterPatientList />
                }
                {
                    selectedTab === 'providers' &&
                    <RegisterProviderList />
                }
            </div>
        </div>
    );
};

export default UserReport;
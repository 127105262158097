import validator from 'validator';
import moment from "moment";
import 'moment/min/locales';


export const FormValidator = {

    bulkValidate(items) {
        let result = {};
        let hasError = false;
        for (let item of items) {
            const validationResult = FormValidator.validate(item); // { name: 'name', value: '', validations: "["required"]"}
            result[item.name] = validationResult;
            if (!hasError) {
                hasError = Object.keys(validationResult).some(val => validationResult[val]);
            }
        }
        return { isValid: !hasError, errors: result };
    },

    validate(options) {
        const value = options.value || '';
        const name = options.name;

        if (!name) throw new Error('FormValidator.Validate - Name must not be empty.');

        const param = options.param;
        const validations = options.validations;

        let result = [];
        if (validations && validations.length) {
            /*  Result of each validation must be true if the input is invalid
                and false if valid. */
            validations.forEach(m => {
                switch (m) {
                    case 'required':
                        result[m] = validator.isEmpty(value)
                        break;
                    case 'email':
                        result[m] = value ? !validator.isEmail(value) : false
                        break;
                    case 'number':
                        result[m] = value ? !validator.isNumeric(String(value)) : false
                        break;
                    case 'integer':
                        result[m] = !validator.isInt(value)
                        break;
                    case 'alphanum':
                        result[m] = value ? !validator.isAlphanumeric(value) : false
                        break;
                    case 'url':
                        result[m] = !validator.isURL(value)
                        break;
                    case 'minlen':
                        result[m] = !validator.isLength(value, { min: param })
                        break;
                    case 'maxlen':
                        result[m] = !validator.isLength(value, { max: param })
                        break;
                    case 'len':
                        const [min, max] = JSON.parse(param)
                        result[m] = !validator.isLength(value, { min, max })
                        break;
                    case 'min':
                        result[m] = !validator.isInt(value, { min: validator.toInt(param) })
                        break;
                    case 'max':
                        result[m] = !validator.isInt(value, { max: validator.toInt(param) })
                        break;
                    case 'list':
                        const list = JSON.parse(param)
                        result[m] = !validator.isIn(value, list)
                        break;
                    case 'mobileno':
                        const mobileRegex = /^[0-9+]*$/;
                        result[m] = value ? !validator.matches(value, mobileRegex) : false;
                        break;
                    case 'alphabets':
                        const alphaRegex = /^[aA-zZ\s]+$/;
                        result[m] = value ? !validator.matches(value, alphaRegex) : false;
                        break;
                    case 'pastdate':
                        const dateVal = moment(value).format("YYYY-MM-DD");
                        const currentDate = moment().format("YYYY-MM-DD");
                        if (moment(dateVal).isBefore(currentDate)) {
                            result[m] = true;
                        } else {
                            result[m] = false;
                        }
                        break;
                    case 'futuredate':
                        const valueFromDatePicker = moment(value).format("YYYY-MM-DD");
                        const todayDate = moment().format("YYYY-MM-DD");
                        if (moment(valueFromDatePicker).isAfter(todayDate)) {
                            result[m] = true;
                        } else {
                            result[m] = false;
                        }
                        break;
                    case 'alphanumspcl':
                        const alphaNumSpclRegex = /^[A-Za-z0-9!@#%^&*)\s]+$/;
                        result[m] = value ? !validator.matches(value, alphaNumSpclRegex) : false;
                        break;
                    case 'alphanumeric':
                        const alphaNumRegex = /^[A-Za-z0-9\s]+$/;
                        result[m] = value ? !validator.matches(value, alphaNumRegex) : false;
                        break;
                    case 'emptystring':
                        result[m] = value && value.trim().length == 0 ? true : false;
                        break;
                    case 'emailvalid':
                        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                        result[m] = value ? !validator.matches(value, emailRegex) : false;
                        break;
                    case 'pwdvalid':
                        const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                        result[m] = value ? !validator.matches(value, pwdRegex) : false;
                        break;
                    default:
                        throw new Error('Unrecognized validator.');
                }

            })
        }
        return result;
    }
}
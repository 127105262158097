import React, { useEffect, useState } from 'react';
import './DocumentForm.scss';
import { FxInput, http, AlertService, FxSelect, FxDateDisplay } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import dummyImage from "./../../../assets/images/dummy.png";
import CloseIcon from '@material-ui/icons/Close';
import UploadDocument from '../UploadDocument/UploadDocument';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';

const DocumentForm = (props: any) => {
  let defaultForm = {
    documentName: "",
    documentTypeId: -1,
    customerId: props.custId,
    otherDocumentType: "",
    imageUrl: "",
    documentList: [],
    documents: [],
    id: 0,
    appointmentId: props.apptId
  };
  const [item, setItem] = useState(defaultForm);
  let defaultItem: any = {
    appointmentIdentifier: 0,
    appointmentDate: null,
    slotTime: null,
    customerName: null
  };
  const [appointment, setAppointment] = useState(defaultItem);
  const [documentType, setDocumentType] = useState([]);
  const [familyMember, setFamilyMember] = useState([]);
  const [error, setError] = useState(null);
  const [uploadConfig, setUploadConfig] = useState({
    fileUploadConfig: { url: 'patientdocument/savefileimage' },
    id: item.customerId
  });

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
  }

  const handleFilterChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        DocumentType: { default: true }
      },
      filters: {
        customerId: props.custId
      }
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setDocumentType(response.data.DocumentType);
      handleFilterChange({ "documentTypeId": -1 });
    });
    getAppointmentById();
  }

  const getFamilyMember = () => {
    let inputData = {
      filters: {
        customerId: props.custId
      }
    }
    let apiOptions: any = {
      url: `customerfamilymap/member`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      let familyMemberList: any = [{ "id": props.custId, "text": "Self" }];
      for (let item of result) {
        familyMemberList.push(item);
      }
      setFamilyMember(familyMemberList);
      handleFilterChange({ "customerId": props.custId });
    })
  }

  const getAppointmentById = () => {
    let apiOptions: any = {
      url: `stores/service/appointment/${props.apptId}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      var splitTime = result.startTime.split(":");
      result.slotTime = splitTime[0].trim() + ":" + splitTime[1].trim();
      setAppointment(result);
    })
  }

  const saveDocumentForm = () => {
    setError(null);
    if (!item.id && item.documentList && item.documentList.length == 0) {
      AlertService.showErrorMsg("Please upload document");
      return;
    }
    if (!item.documentName || (item.documentName && item.documentName.trim().length == 0) ||
      (item.documentTypeId === 9999 && !item.otherDocumentType)) {
      setError("This field is required");
      return;
    }
    if (item.documentTypeId !== 9999 && item.otherDocumentType) {
      handleFilterChange({ "otherDocumentType": "" });
    }
    item.documentName = item.documentName.trim();
    let apiOptions: any = {
      url: 'patientdocument/add',
      data: item
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      props.handleDocumentFormClose();
    })
  }

  const handleClose = () => {
    props.handleDocumentFormClose();
  };

  const onCallBack = (docList) => {
    console.log(docList);
    handleFilterChange({ "documentList": docList });
  }

  useEffect(() => {
    initLookup();
  }, []);


  const body = (
    <div className="docform-ui modalalign">
      <div className="title-font pl-4">
        <span>Document Form</span>
        <span className="col-1 float-right pointer mr-3" onClick={handleClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </span>
      </div>

      <div className="row m-0 px-3 pt-3">
        <div className="col-12 my-2 pb-2 d-flex align-items-center justify-content-center">
          <span className="col-4">
            #{appointment.appointmentIdentifier}
          </span>

          <span className="col-4">
            <EventIcon fontSize="small" color="action" />
            <FxDateDisplay value={appointment.appointmentDate} />
            <span className="pl-1">
              <ScheduleIcon fontSize="small" color="action" />
              {appointment.slotTime}
            </span>
          </span>

          <span className="col-4">
            <span className="float-right">
              <PersonIcon fontSize="small" color="action" />
              {appointment.customerName}
            </span>
          </span>
        </div>

        <div className="col-6">
          <UploadDocument uploadConfig={uploadConfig} onCallBack={onCallBack} />
        </div>

        <div className="col-6">
          <div className="">
            <FxInput name="documentName" variant="outlined" label="Document Name" size="small"
              value={item.documentName} onValueChange={handleInputChange} />
            {
              error && (!item.documentName || (item.documentName && item.documentName.trim().length == 0)) &&
              <div className="validation-msg">{error}</div>
            }
          </div>
          <div className="pt-3">
            <FxSelect name="documentTypeId" variant="outlined" label="Document Type"
              options={documentType}
              selectedValue={item.documentTypeId}
              valueField="id"
              displayField="text"
              onValueChange={handleInputChange} />
          </div>
          {
            item.documentTypeId == 9999 &&
            <div className="pt-3">
              <FxInput name="otherDocumentType" variant="outlined" label="Other Document Type" size="small"
                value={item.otherDocumentType} onValueChange={handleInputChange} />
              {
                error && !item.otherDocumentType &&
                <div className="validation-msg">{error}</div>
              }
            </div>
          }

          {/* <div className="pt-3">
              <FxSelect name="customerId" variant="outlined" label="Member"
                options={familyMember}
                selectedValue={item.customerId}
                valueField="id"
                displayField="text"
                onValueChange={handleInputChange} />
            </div> */}
        </div>
        <div className="col-12 py-3 text-right">
          <Button variant="contained" size="small" color="primary"
            onClick={saveDocumentForm}>Save</Button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default DocumentForm;
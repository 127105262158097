import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatFileSize } from './service/helpers';
import CancellationToken from './service/CancellationToken';
import dicomUploader from './service/DicomUploadService';
import './DicomUploader.css';
import { getActiveServer } from './service/getServers';
import { http, session } from '../fx-core';
import * as parser from 'fast-xml-parser';
import CloseIcon from '@material-ui/icons/Close';

export default class DicomUploader extends Component {
  state = {
    status: 'Upload',
    isCancelled: false,
    errorsCount: 0,
    files: null,
    uploadedVolume: null,
    wholeVolumeStr: null,
    isFilesListHidden: true,
    timeLeft: null,
    uploadedList: null,
    totalCount: 0,
    successfullyUploadedCount: 0,
    lastFile: '',
    uploadContext: null, // this is probably not needed, but we use this variable to distinguish between different downloads
  };

  static propTypes = {
    id: PropTypes.string,
    event: PropTypes.string,
    url: PropTypes.string,
    retrieveAuthHeaderFunction: PropTypes.func,
    onClose: PropTypes.any,
    onUploadSuccess: PropTypes.func
  };

  filesLeft() {
    return (
      this.state.uploadedList.length + ' of ' + this.state.totalCount + ' files'
    );
  }

  volumeLeft() {
    let left = formatFileSize(this.state.uploadedVolume);
    return left + ' of ' + this.state.wholeVolumeStr;
  }

  percents() {
    let result:any = (100 * this.state.uploadedList.length) / Object.keys(this.state.files).length;
    return parseInt(result);
  }

  isFinished() {
    return (
      this.state.isCancelled ||
      Object.keys(this.state.files).length === this.state.uploadedList.length
    );
  }

  errorsMessage() {
    const errors = this.state.errorsCount === 1 ? ' error' : ' errors';
    return (
      this.state.errorsCount + errors + ' while uploading, click for more info'
    );
  }

  uploadFiles = files => {
    const filesArray:any = Array.from(files.target.files);
    const filesDict = {};
    filesArray.forEach((file:any, i:any) => {
      const fileDesc = {
        id: i,
        name: file.name,
        path: file.webkitRelativePath || file.name,
        size: file.size,
        error: null,
        processed: false,
        processedInUI: false,
      };
      filesDict[i] = fileDesc;
      file.fileId = i;
    });
    const wholeVolume = filesArray.map((f:any) => f.size).reduce((a, b) => a + b);
    const uploadContext = Math.random();
    this.setState({
      status: 'Uploading...',
      files: filesDict,
      uploadedList: [],
      uploadedVolume: 0,
      lastFile: filesArray[0].name,
      totalCount: filesArray.length,
      wholeVolumeStr: formatFileSize(wholeVolume),
      uploadContext: uploadContext,
      cancellationToken: new CancellationToken(),
    });
    const cancellationToken = new CancellationToken();
    const uploadCallback = (fileId, error) =>
      uploadContext === this.state.uploadContext &&
      this.uploadCallback.call(this, fileId, error);

      let activeServer:any = getActiveServer();
      let url = activeServer && activeServer.qidoRoot;
      dicomUploader.smartUpload(
        files.target.files,
        url,
        uploadCallback,
        cancellationToken
      );
  };

  async uploadCallback(fileId:any, result:any, error:any) {
    const file = this.state.files[fileId];
    file.processed = true;
    var jsonObj = parser.parse(result, { parseAttributeValue : true, ignoreAttributes: false});
    var studyId = '';
    if(jsonObj && jsonObj.NativeDicomModel && jsonObj.NativeDicomModel.DicomAttribute) {
      var retrieveURL = jsonObj.NativeDicomModel.DicomAttribute.find(i => i['@_keyword'] == 'RetrieveURL');
      if(retrieveURL && retrieveURL.Value) {
        let link = retrieveURL.Value['#text'];
        studyId = link.substr(link.lastIndexOf('/')+1)
      }
    }
    file.studyId = studyId;
    console.log('studyId=', studyId);
    if (!error) {
      let uploadedVolume = this.state.uploadedVolume + file.size;
      this.setState({ uploadedVolume });
    } else {
      file.error = error;
      this.setState({ errorsCount: this.state.errorsCount + 1 });
    }
    this.setState({ lastFile: file.name });
    let uploadedList = this.state.uploadedList;
    uploadedList.push(file);
    //console.log('uploadedList=', uploadedList);
    this.setState({ uploadedList });
    this.props.onUploadSuccess( { uploadedList })
  }

  renderTableRow = (file:any) => {
    let error = null;
    if (file.error !== null) {
      error = <p style={{ color: 'red' }}>{file.error}</p>;
    }
    return (
      <tr key={file.id}>
        <td className="project">
          {file.name} {error}
        </td>
      </tr>
    );
  };

  componentDidMount() {
    this.getAccessToken();
  }

  async getAccessToken() {
    let apiOptions: any = {
      url: `dicom/getToken`
    };
    var result: any = await http.get(apiOptions);
    var accessToken = result.data.accessToken;
    //console.log('accessToken=',accessToken);
    session.setItem('dicomAccessToken', accessToken);
  }

  closeMe = () => {
    this.props.onClose({ uploadedList : this.state.uploadedList });
  }

  render() {
    let body;
    let dirUploadProps: any =  { webkitdirectory : "true", mozdirectory: "true" };
    if (this.state.files === null) {
     body = <div className="dicom-uploader">
           {/* <div className="button">
           <div className="mt-2">
             <h5>Upload Files</h5></div>
            <label htmlFor="file">
              <i className="far fa-file-image fa-2x mr-2 mt-2"></i>
            </label>
            <input
              onChange={this.uploadFiles}
              type="file"
              id="file"
              multiple
            />
          </div> */}

          <div className="button mt-4">
            <div><h5>Upload Folder</h5></div>
            <label htmlFor="folder">
             <i className="far fa-folder fa-2x mr-2 mt-2"></i>
            </label>
            <input
              type="file"
              onChange={this.uploadFiles}
              id="folder"
              multiple
              {...dirUploadProps}
            />
          </div> 
        </div>;
    } else {
      body = <table id="tblProjectList" className="table noselect">
          <thead>
            <tr>
              <th className="table-header">
                {this.percents()}% {this.filesLeft()}
              </th>
            </tr>
          </thead>
          <tbody id="ProjectList">
            {this.state.uploadedList.map(this.renderTableRow)}
          </tbody>
        </table>;
    }

    return (<div className="dicomUploaderContainer">
            <div className="mt-4 ml-3">
              {body}
            </div>
          </div>);
  }
}

import React, { useState, useEffect } from 'react';
import './RegisterProvider.scss';
import { FxInput, FxTextarea, FxSelect, AlertService, http, formatService, FormValidator, FxMobile, FxMultiSelect } from '../fx-core';
import { Button, Link, Dialog } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Banner from "./../../assets/banner.jpg";
import * as _ from 'lodash';
import Checkbox from "@material-ui/core/Checkbox";
import TermsAndConditions from '../general/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../general/PrivacyPolicy/PrivacyPolicy';
import BusinessAgreement from '../general/BusinessAgreement/BusinessAgreement';
import Popover from "@material-ui/core/Popover";
import InfoIcon from '@material-ui/icons/Info';

var bgStyle = {
    width: "100%",
    height: "100vh",
    backgroundImage: "url(" + Banner + ")",
};

const RegisterProvider = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;

    const defaultItem = {
        prefix: "Dr.",
        userName: "",
        password: "",
        firstName: "",
        lastName: "",
        experience: "",
        email: "",
        mobile: "",
        id: 0,
        providerUserName: "",
        currency: "USD",
        referralCode: propsObj.referralCode ? propsObj.referralCode : "",
        registrationDate: "",
        isSelfSignUp: true,
        dateFormat: "MM/DD/YYYY",
        cancellationFee: 0,
        cancellationHours: 24,
        countryCode: "+1",
        showReviews: "Yes",
        country: "us",
        accept: "",
        isBusinessAccept: ""
    };
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});
    const [countryCode, setCountryCode] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [termsOpen, setTermsOpen] = useState(false);
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const [businessAgreementOpen, setBusinessAgreementOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const popoverOpen = Boolean(anchorEl);

    const validationMap = {
        userName: ["required", "alphanum"],
        password: ["required", "pwdvalid"],
        firstName: ["required", "alphabets", "emptystring"],
        lastName: ["required", "alphabets", "emptystring"],
        email: ["required", "email", "emailvalid"],
        mobile: ["required", "mobileno", "maxlen"],
        country: ["required"]
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            if (key == "mobile") {
                let itemVal = { name: key, value: item[key], validations: validationMap[key], param: 10 }
                items.push(itemVal);
            }
            else {
                let itemVal = { name: key, value: item[key], validations: validationMap[key] }
                items.push(itemVal);
            }
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                Currency: { default: false },
                DateFormat: { default: false },
                CountryCode: { default: false },
                Country: { default: false }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'Options/GetLookupOptions',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setCountryCode(response.data.CountryCode);
            setCountryList(response.data.Country);
        });
    }

    const registerProvider = () => {
        const isValid = bulkValidate();
        if (isValid) {
            if (!item.accept) {
                AlertService.showErrorMsg("Please accept terms of service and privacy policy");
                return;
            }
            if (!item.isBusinessAccept) {
                AlertService.showErrorMsg("Please accept Business Associate Agreement");
                return;
            }
            item.registrationDate = formatService.getCurrentDateForServer();
            let apiOptions: any = {
                url: `provider/register`,
                data: item
            };
            http.post(apiOptions).then(res => {
                saveProviderCallBack(res);
            });
        }
    };

    const saveProviderCallBack = (res) => {
        AlertService.showSuccessMsg();
        navigateBack();
    };

    const handleInputChange = (item) => {
        handleProviderObjChange({ [item.name]: item.value });
    };

    const handleCheckbox = (event) => {
        handleProviderObjChange({ [event.target.name]: event.target.checked });
    };

    const handleCountryChange = (item) => {
        handleProviderObjChange({ [item.name]: item.value });
        if (item.value == "us") {
            handleProviderObjChange({ ["currency"]: "USD" });
            handleProviderObjChange({ ["dateFormat"]: "MM/DD/YYYY" });
        }
        else if (item.value == "india") {
            handleProviderObjChange({ ["currency"]: "INR" });
            handleProviderObjChange({ ["dateFormat"]: "DD/MM/YYYY" });
        }
    };

    const handleProviderObjChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const navigateBack = () => {
        if (propsObj.isLogin) {
            history.push('/');
        }
        else {
            history.push({
                pathname: '/registerlanding',
                state: { code: item.referralCode ? item.referralCode : null }
            });
        }
    }
    const openGooglePlaystore = (url) => {
        window.open('https://play.google.com/store/apps/details?id=app.mymedifam', '_blank');
    }
    const openAppStore = (url) => {
        window.open('https://apps.apple.com/us/app/mymedifam/id1598963734', '_blank');
    }

    const openTermsAndConditions = () => {
        setTermsOpen(true);
    };

    const handleTermsClose = () => {
        setTermsOpen(false);
    };

    const openPrivacyPolicy = () => {
        setPrivacyPolicyOpen(true);
    };

    const handlePrivacyPolicyClose = () => {
        setPrivacyPolicyOpen(false);
    };

    const openBusinessAgreement = () => {
        setBusinessAgreementOpen(true);
    };

    const handleBusinessAgreementClose = () => {
        setBusinessAgreementOpen(false);
    };


    useEffect(() => {
        initLookup();
    }, []);

    return (
        <div className="reg-appt reg-prov reg-appt-margin" style={bgStyle}>
            <div className="title-font pl-4">
                <span>Register Provider</span>
            </div>
            <div className="row pt-5 pt-lg-0 m-0">
                <div className="col-12 col-md-0 col-lg-1"></div>
                <div className="col-12 row m-0 col-lg-5 my-auto px-3">
                    <div className='col-12 reg-content text-center mb-5'>We are HIPPA compliant, and end to end encrypted</div>
                    <div className="col-12 labelHeading text-center">
                        Get More New Users
                    </div>
                    <div className="col-12 text-center font-16 pt-3 labeldesc">
                        MYMEDIFAM helps your users find you faster and easier when they need your services.
                    </div>
                    <div className="col-12 text-center pt-3 p-0">
                        <span className="pr-3">
                            <Button variant="contained" size="large" color="primary" startIcon={<i className="fab fa-google-play"></i>}
                                onClick={openGooglePlaystore}>Google Play</Button>
                        </span>
                        <span>
                            <Button variant="contained" size="large" color="primary" startIcon={<i className="fab fa-apple"></i>}
                                onClick={openAppStore}>App Store</Button>
                        </span>
                    </div>
                </div>
                <div className="col-12 row m-0 col-lg-5 pt-3 mt-4 formstyle px-3">
                    <div className="labelHeading col-12 text-center">
                        Register Provider
                    </div>
                    <div className=" col-12 col-md-6 col-lg-12 mt-3">
                        <FxInput name="userName" variant="outlined" label="Username*" size="small" fullWidth
                            value={item.userName}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("userName", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("userName", "alphanum") &&
                            <div className="validation-msg">Username should contain alphabets and numbers</div>
                        }
                    </div>

                    <div className=" col-12 col-md-6 col-lg-12 mt-3">
                        <FxInput type="password" name="password" variant="outlined" label="Password*" size="small" fullWidth
                            value={item.password}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("password", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("password", "pwdvalid") &&
                            <div className="validation-msg">Password should contain atleast one uppercase,one lowercase,one number and one special character(@$!%*?&) with minimum 8 characters</div>
                        }
                    </div>

                    <div className=" col-12 col-md-6 col-lg-12 mt-3">
                        <FxInput name="firstName" variant="outlined" label="First name*" size="small" fullWidth
                            value={item.firstName}
                            onValueChange={handleInputChange}
                        />
                        {
                            (hasError("firstName", "required") || hasError("firstName", "emptystring")) &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("firstName", "alphabets") &&
                            <div className="validation-msg">First name should contain only alphabets</div>
                        }
                    </div>

                    <div className=" col-12 col-md-6 col-lg-12 mt-3">
                        <FxInput name="lastName" variant="outlined" label="Last name*" size="small" fullWidth
                            value={item.lastName}
                            onValueChange={handleInputChange}
                        />
                        {
                            (hasError("lastName", "required") || hasError("lastName", "emptystring")) &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("lastName", "alphabets") &&
                            <div className="validation-msg">Last name should contain only alphabets</div>
                        }
                    </div>

                    <div className=" col-12 col-md-6 col-lg-12 mt-3">
                        <FxMobile value={item.mobile} onValueChange={handleInputChange} countryCode={countryCode}
                            validation={true} />

                        {
                            hasError("mobile", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("mobile", "mobileno") &&
                            <div className="validation-msg">Mobile should contain only numbers</div>
                        }
                        {
                            hasError("mobile", "maxlen") &&
                            <div className="validation-msg">Mobile should accept 10 digits</div>
                        }
                    </div>
                    <div className=" col-12 col-md-6 col-lg-12 mt-3">
                        <FxInput name="email" variant="outlined" label="Email*" size="small" fullWidth
                            value={item.email}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("email", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            (hasError("email", "email") || hasError("email", "emailvalid")) &&
                            <div className="validation-msg">Please enter valid email id</div>
                        }
                    </div>

                    <div className='col-12 col-md-6 col-lg-12 mt-3'>
                        <div className='row px-2'>
                            <span className='col-11'>
                                <FxInput name="referralCode" variant="outlined" label="Referral Code" size="small" fullWidth
                                    value={item.referralCode} onValueChange={handleInputChange} />
                            </span>
                            <span className='col-1 float-right alignicon' onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                            }}>
                                <InfoIcon />
                            </span>
                        </div>
                        <Popover
                            anchorEl={anchorEl}
                            open={popoverOpen}
                            id={popoverOpen ? "simple-popover" : undefined}
                            onClose={() => { setAnchorEl(null); }}
                            transformOrigin={{ horizontal: "center", vertical: "top", }}
                            anchorOrigin={{ horizontal: "center", vertical: "bottom", }}>
                            <span className='px-2'>Please enter the 10 character code received if this application was referred
                                to you by someone</span>
                        </Popover>
                    </div>

                    <div className=" col-12 col-md-6 col-lg-12 mt-3">
                        <FxSelect name="country" variant="outlined" label="Address*" fullWidth
                            options={countryList}
                            selectedValue={item.country} valueField="code" displayField="text"
                            onValueChange={handleCountryChange} />
                        {
                            hasError("country", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                    </div>

                    <div className="mt-3 font-13 col-12">
                        <Checkbox name="accept" color="primary" value={item.accept} onChange={handleCheckbox} />
                        I have read and agreed to the
                        <Link className="pointer" onClick={openTermsAndConditions}> Terms of Service </Link>
                        and the
                        <Link className="pointer" onClick={openPrivacyPolicy}> Privacy Policy </Link>
                    </div>
                    <div className="font-13 col-12">
                        <Checkbox name="isBusinessAccept" color="primary" value={item.isBusinessAccept} onChange={handleCheckbox} />
                        I have read and agreed to the
                        <Link className="pointer" onClick={openBusinessAgreement}> Business Associate Agreement</Link>
                    </div>
                    <div className="row col-12 mt-4 pb-3 pr-3 justify-content-end">
                        <span className="pl-2">
                            <Button variant="contained" size="medium" onClick={navigateBack}>Cancel</Button>
                        </span>
                        <span className="pl-2">
                            <Button variant="contained" size="medium" color="primary" onClick={registerProvider}>Register</Button>
                        </span>
                    </div>
                </div>
                <div className="col-12 col-md-0 col-lg-1"></div>
            </div>
            <Dialog open={termsOpen}
                maxWidth="lg" onClose={handleTermsClose}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <TermsAndConditions handleTermsClose={handleTermsClose} />
            </Dialog>
            <Dialog open={privacyPolicyOpen}
                maxWidth="lg" onClose={handlePrivacyPolicyClose}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <PrivacyPolicy handlePrivacyPolicyClose={handlePrivacyPolicyClose} />
            </Dialog>
            <Dialog open={businessAgreementOpen}
                maxWidth="lg" onClose={handleBusinessAgreementClose}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <BusinessAgreement handleBusinessAgreementClose={handleBusinessAgreementClose} />
            </Dialog>
        </div>
    )
};

export default RegisterProvider;
import React, { useEffect, useState } from 'react';
import './NotificationList.scss';
import { http, FxDateTimeDisplay, FxInput, FxDate, formatService } from '../../fx-core';
import { session, useNavigationService } from "../../fx-core";
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from "react-router-dom";

const NotificationList = (props: any) => {
  const history = useHistory();
  const { pageTitle } = useNavigationService();
  const initialList = [];
  let defaultItem: any = {
    startDate: null,
    endDate: null,
    message: ''
  };
  const [item, setItem] = useState(defaultItem);
  const [notificationList, setNotificationList] = useState(initialList);
  const [userId, setUserId] = React.useState(parseInt(session.getItem('userId')));
  const [valError, setValError] = useState(null);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    let options = { item: { [item.name]: item.value } };
    getNotifications(options);
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getNotifications = (options: any = {}) => {
    let selectedStartDate: any = item.startDate;
    let selectedEndDate: any = item.endDate;
    let msg: any = item.message;
    if (options && options.item) {
      selectedStartDate = options.item.startDate || options.item.startDate == "" ? options.item.startDate : item.startDate;
      selectedEndDate = options.item.endDate || options.item.endDate == "" ? options.item.endDate : item.endDate;
      msg = options.item.message;
    }
    setValError(null);
    if (formatService.isBefore(selectedStartDate, selectedEndDate)) {
      setValError("End date should be greater than or equal to Start date");
      return;
    }
    let inputData = {
      userId: userId,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      message: msg
    }
    let apiOptions: any = {
      url: `notification/provider`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setNotificationList(response.data);
    });
  }

  const openNotificationPreferences = () => {
    history.push({
      pathname: '/notificationpreferences',
      state: {}
    });
  }

  useEffect(() => {
    let options = { item: item };
    getNotifications(options);
  }, []);


  const body = (
    <div className="content-container">
      <div className="title-font">
        <span>Notifications</span>
        <span onClick={openNotificationPreferences} className="pointer mr-3"><SettingsIcon /></span>
      </div>

      <div className='px-3'>
        <div className="row col-12 my-4 appointment-filter m-0 p-0">
          <div className="col-2">
            <FxDate name="startDate" label="Start Date" variant="outlined"
              value={item.startDate} onValueChange={handleInputChange} />
          </div>

          <div className="col-2">
            <FxDate name="endDate" label="End Date" variant="outlined"
              value={item.endDate} onValueChange={handleInputChange} />
              {valError &&
            <div className="error"> {valError} </div>}
          </div>

          <div className="col-3">
            <FxInput name="message" label="Notification message search" variant="outlined" 
              value={item.message} onValueChange={handleInputChange} />
          </div>
        </div>
        {
          notificationList.map((item) => (
            <div className="row m-0 pt-1 col-12 alignbox">
              <div className="col-8">{item.message}</div>

              <div className="col-4">
                <span className="float-right">
                  <FxDateTimeDisplay value={item.notificationDate} /></span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default NotificationList;
import React, { useEffect, useState } from 'react';
import './ProviderRegistration.scss';
import { FxInput, FormValidator, http, AlertService } from '../../fx-core';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';

const ProviderRegistration = (props: any) => {
  let defaultItem: any = {
    details: null,
    providerId: props.providerId
  };
  const initialList = [];
  const [item, setItem] = useState(defaultItem);
  const [providerRegistrations, setProviderRegistrations] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [errors, setErrors] = useState({});

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const validationMap_Item = {
    details: ["required", "alphanumeric", "emptystring"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = FormValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends


  const saveProviderRegistration = () => {
    const isValid = bulkValidate();
    if (isValid) {
      let apiOptions: any = {
        url: 'providerregistration/add',
        data: item
      };
      http.post(apiOptions).then(response => {
        saveRegistrationCallBack(response);
      });
    }
  }

  const saveRegistrationCallBack = (res) => {
    setItem({ details: '' });
    AlertService.showSuccessMsg();
    getProviderRegistrations();
  };

  const getProviderRegistrations = () => {
    let inputData = {
      providerId: props.providerId
    }
    let apiOptions: any = {
      url: 'providerregistration/query', data: inputData
    };
    http.post(apiOptions).then(response => {
      setProviderRegistrations(response.data);
    });
  }

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setMessage("Do you want to delete " + item.details + "?");
    setOpen(true);
  };

  const confirmMessage = () => {
    setOpen(false);
    deleteRegistration(selectedItem);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const deleteRegistration = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `providerregistration/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderRegistrations();
    })
  }

  useEffect(() => {
    getProviderRegistrations();
  }, []);


  const body = (
    <div className="content-container1 card provider-registration">
      <div className="title-font">
        <span>Registration/NPI Number</span>
      </div>
      <div className="row m-0 px-3">
        <div className="col-6 p-0">
          <FxInput name="details" variant="outlined" label="Details*" size="small"
            value={item.details}
            onValueChange={handleInputChange}
          />
          {
            (hasError("details", "required") || hasError("details", "emptystring")) &&
            <div className="error">This field is required</div>
          }
          {
            hasError("details", "alphanumeric") &&
            <div className="error">Details should contain only alphabets and numbers</div>
          }
        </div>

        <div className="col-2 mt-3">
          <span>
            <Button variant="contained" size="small" color="primary"
              onClick={() => saveProviderRegistration()}>Add</Button>
          </span>
        </div>
      </div>

      <div>
        <div className="row m-0 col-12 p-0 px-3 justify-content-between">
          {
            providerRegistrations.map((item) => (
              <div className="row m-0 col-prov-edu-5 border-bottom mt-2">
                <div className="col-10" title={item.details}>{item.details}</div>
                <div className="col-2">
                  <span onClick={() => confirmDelete(item)}>
                    <DeleteIcon color="primary" />
                    {/* <span className="ml-1">Delete</span> */}
                  </span>
                </div>
              </div>
            ))
          }

        </div>

        <div className="row m-0 col-12 p-0 pl-3 pt-5 color-aaa">
          <span className='color-aaa'>(not displayed to patients; for internal verification only)</span>
        </div>
      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderRegistration;
import React, { useState, useEffect } from 'react';
import { http,FxDateTimeDisplay } from '../../fx-core';
import './PrintHealthHistory.css';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const PrintHealthHistory = (props) => {
    const history = useHistory();
    const [customerId, setCustomerId] = useState(props.location.state ? props.location.state.custId : 0);
    const [healthHistory, setHealthHistory] = useState([]);
    const [filledDate, setFilledDate] = useState(null);

    const getHealthHistoryForms = (custId) => {
        let inputData = {
            formCode: "healthhistory",
            customerId: custId
        };
        let apiOptions: any = {
            url: `patientform/query`, data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            patientFormsCallBack(result, custId);
        });
    }

    const patientFormsCallBack = (res, custId) => {
        let sectionDataList = [];
        if (res.length > 0) {
            for (let item of res) {
                item.sectionData = JSON.parse(item.sectionData);
                sectionDataList.push(item.sectionData);
            }
            setHealthHistory(sectionDataList);
            console.log(sectionDataList);
        }
    }

    const getLatestFilledDate = (custId) => {
        let apiOptions: any = {
            url: `patientfilledform/latest/${custId}`, data: {}
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            setFilledDate(result.generatedDate);
        });
    }

    useEffect(() => {
        getLatestFilledDate(customerId);
        getHealthHistoryForms(customerId);
    }, []);

    return (
        <div className="printhealthhistory w-90 m-y-2">
            <div className="text-align-center font-28">HEALTH HISTORY</div>
            <div className="text-align-center sub-title outlined">Welcome to our practice. As a new patient, please fill out the information found below to the best of your ability</div>
            <div className="text-align-right m-t-2">Date: <span className="col border-bottom-line">
            <FxDateTimeDisplay value={filledDate} />
                </span></div>
            <div>
                {healthHistory.map((row, idx) => (
                    <>
                        <div>
                            {row.sectionDisplay &&
                                <div className="mt-2 col"><b>{row.sectionDisplay}</b></div>
                            }
                            <div className="p-0 row m-0">
                                {row.Items && row.Items.length > 0 && row.Items.map((item, b) => (
                                    <div key={b} className={row.col ? "col-" + (12 / row.col) : "col-12 p-0"}>
                                        <div>
                                            {item.type == 'text' &&
                                                <div className="row m-0 m-t-2">{item.display}:<span className="col border-bottom-line">{item.value}</span></div>
                                            }
                                            {item.type == 'date' &&
                                                <div className="row m-0 m-t-2">{item.display}:<span className="col border-bottom-line">{item.value}</span></div>
                                            }
                                            {item.type == 'yesno' &&
                                                <div className={item.col ? "col-" + (12 / item.col) : ""}>
                                                    <div className="row m-0">{item.display}:
                                                        <span className="col border-bottom-line">{item.value == 'yes' && 'Yes'} {item.value == 'no' && 'No'} {!item.value && 'NA'}</span>
                                                    </div>
                                                    <div>
                                                        {(item.key == 'anyOtherDisease' && item.value == "yes") &&
                                                            <span>
                                                                <div><b>Other Diseases</b></div>
                                                                <span>
                                                                    {item.listedValues.map((row, i) => (
                                                                        <div className="p-0 row m-0">
                                                                            <div className="col-12 border-bottom-line">{row[i]}
                                                                                {row["anyOtherDisease"]}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </span>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {item.type == 'list' && item.header && item.header.length > 0 &&
                                                <div className="p-0 row m-0">
                                                    {
                                                        item.header.map((header, c) => (
                                                            <div key={c} className={"mt-2 col-" + (header.col)}><b>{header.display}</b></div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            {item.type == 'list' && item.header && item.value && item.value.length > 0 &&
                                                <div>
                                                    {
                                                        item.value.map((valueObj, d) => (
                                                            <div key={d} className="p-0 row m-0">
                                                                {
                                                                    item.header.map((header, e) => (
                                                                        <>
                                                                            <div key={e} className={"col-" + (header.col)}>
                                                                                {header.type == 'combo' &&
                                                                                    <div className="col-12 border-bottom-line">{valueObj[header.key]}</div>
                                                                                }
                                                                                {header.type == 'text' &&
                                                                                    <div className="col-12 border-bottom-line">{valueObj[header.key]}</div>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            {!item.sectionDisplay && item.helpText &&
                                                <div className="font-12">{item.helpText}</div>
                                            }
                                            {item.type == 'radiogroup' && item.Options && item.Options.length > 0 &&
                                                <>
                                                    <div className={item.axis == "x" ? "display-flex flex-row align-items-center" : ""}>
                                                        {item.key &&
                                                            <div><b><i>{item.display}</i></b></div>
                                                        }
                                                        {item.helpText &&
                                                            <div className="font-12">{item.helpText}</div>
                                                        }
                                                        <div className="row m-0 align-items-center">
                                                            <span className="col border-bottom-line">{item.value}</span>
                                                            {item.text && item.text.type == 'text' && item.text.value &&
                                                                <span className="col border-bottom-line">{item.text.value}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {item.type == 'section' &&
                                                <div className={item.axis == "x" ? "display-flex flex-row align-items-center" : ""}>
                                                    <div>
                                                        {item && item.sectionDisplay &&
                                                            <div className="mt-2 col pl-0"><b>{item.sectionDisplay}</b></div>
                                                        }
                                                        <div className="p-0 row m-0">
                                                            {item && item.Items && item.Items.map((obj, b) => (
                                                                <div key={b} className={obj.col ? "col-" + (12 / obj.col) : "col-12 p-0"}>
                                                                    <div>
                                                                        {obj.type == 'text' &&
                                                                            <div className="row m-0 m-t-2">{obj.display}:<span className="col border-bottom-line">{obj.value}</span></div>
                                                                        }
                                                                        {obj.type == 'date' &&
                                                                            <div className="row m-0 m-t-2">{obj.display}:<span className="col border-bottom-line">{obj.value}</span></div>
                                                                        }
                                                                        {obj.type == 'yesno' &&

                                                                            <div className={obj.col ? "col-" + (12 / obj.col) : ""}>
                                                                                <div className="row m-0">{obj.display}:
                                                                                    <span className="col border-bottom-line">{obj.value == 'yes' && 'Yes'} {obj.value == 'no' && 'No'} {!obj.value && 'NA'}</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {item.text && item.text.length > 0 &&
                                                <div>
                                                    {item.text.map((textControl, g) => (
                                                        <div key={g}>
                                                            <div className="row m-0">{textControl.display}:
                                                                <span className="col border-bottom-line">{textControl.value}</span>
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>))
                                }
                            </div>
                        </div>

                    </>
                ))
                }
            </div>
            <div className="border-tow-top">
                To the best of my knowledge, the questions on this form have been accurately answered. I understand that providing incorrect information can be dangerous to my health. It is my responsibility to inform the doctor's office of any changes in my medical status. I also authorize the healthcare staff to perform the necessary services I may need.
            </div>
        </div>
    )
};

export default PrintHealthHistory;
import React, { useEffect, useState } from 'react';
import './RegisterLanding.scss';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { http } from '../fx-core';
import mymedifamLogo1 from "./../../assets/mymedifamLogo1.svg";

const RegisterLanding = (props) => {
    const history = useHistory();
    const [referralCode, setReferralCode] = useState(props.location.state ? props.location.state.code : null);

    const resolveReferralCode = (token) => {
        let apiOptions: any = {
            url: `user/resolvereferralcode/${token}`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            setReferralCode(result.referralCode);
        });
    }

    const navigateToRegister = (type: any) => {
        if (type == 'patient') {
            history.push({
                pathname: '/registerpatient',
                state: { referralCode: referralCode }
            });
        } else if (type == 'provider') {
            history.push({
                pathname: '/registerprovider',
                state: { referralCode: referralCode }
            });
        }

    }

    const navigateBack = () => {
        history.push('/');
    }


    useEffect(() => {
        console.log(props);
        let token = props.match.params.tk ? props.match.params.tk : "";
        if (token) {
            resolveReferralCode(token);
        }
    }, []);

    return (
        <div className="reg-appt reg-appt-margin">
            <div className="title-font pl-4">
                <span>Register</span>
            </div>
            <div className="text-center">
                <img src={mymedifamLogo1} alt="MYMEDIFAM Logo" className='register-landing-logo' />
            </div>
            <div className="mt-4 text-center">
                <span className="register-as-user">
                    <Button variant="contained" size="small" color="primary" onClick={() => navigateToRegister
                        ('patient')}>Register as User</Button>
                </span>
                <span className=" register-as-provider">
                    <Button variant="contained" size="small" color="primary" onClick={() => navigateToRegister
                        ('provider')}>Register as Provider</Button>
                </span>
                <span className=" register-back">
                    <Button variant="contained" size="small" onClick={navigateBack}>Back</Button>
                </span>
            </div>
        </div>
    )
};

export default RegisterLanding;
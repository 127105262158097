import React, { useEffect, useState } from 'react';
import './ConfirmDialog.css';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import { FxInput, AlertService } from '../../fx-core';

const ConfirmDialog = (props: any) => {

  const [comments, setComments] = useState(null);
  const [error, setError] = useState(null);

  const handleInputChange = (item) => {
    setComments(item.value);
  };

  const handleClose = () => {
    props.handleClose();
  }

  const confirmDialog = () => {
    if (props.action && (props.action == 'cancel' || props.action == 'resolve')) {
      if (!comments) {
        setError("This field is required");
        return;
      }
      props.onConfirmCallback(props.action, comments);
    }
    else {
      props.onConfirmCallback(props.action);
    }
  }


  useEffect(() => {
  }, []);

  const body = (
    <div className="confirmContainer">
      <div className="row title-font mx-auto">
        <div className="col-10 pl-0">
          <span>Confirm</span>
        </div>
        <div className="col-2 float-right pointer" onClick={handleClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </div>
      </div>
      <div className="row m-2">
        <div className="col-12">
          {props.message}
        </div>
      </div>

      { (props.action == 'cancel' || props.action == 'resolve') &&
        <div className="row m-2">
          <div className="col-12">
            <FxInput name="comments" variant="outlined" label="Comments" size="small"
              value={comments} onValueChange={handleInputChange}
            />
          </div>
          {error &&
            <div className="error"> {error} </div>}
        </div>
      }


      <div className="row m-2">
        <div className="col-12 alignright">
          <span className="mr-2">
            <Button variant="contained" size="small" color="primary" onClick={handleClose}>
              No
                  </Button>
          </span>
          <span>
            <Button variant="contained" size="small" color="primary" onClick={confirmDialog}>
              Yes
            </Button>
          </span>
        </div>
      </div>
    </div>
  );


  return (
    <div>
      {body}
    </div>
  );

}

export default ConfirmDialog;
import React, { useEffect, useState } from 'react';
import './ProviderClinic.scss';
import { http, AlertService, FxCurrency, general } from '../../fx-core';
import { Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import dummyImage from "./../../../assets/images/dummy.png";
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RoomIcon from '@material-ui/icons/Room';
import DescriptionIcon from '@material-ui/icons/Description';
import ProviderClinicForm from '../ProviderClinicForm/ProviderClinicForm';

const ProviderClinic = (props: any) => {

  const initialList = [];
  const [providerClinics, setProviderClinics] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [imageConfig, setImageConfig] = useState({});
  const [provider, setProvider] = useState({ currency: '' });
  const [clinicFormOpen, setClinicFormOpen] = useState(false);
  const [providerClinicId, setProviderClinicId] = useState(0);
  const [providerId, setProviderId] = useState(props.providerId);


  const getProviderById = () => {
    let apiOptions: any = {
      url: `provider/${props.providerId}`
    };
    http.get(apiOptions).then(res => {
      setProvider(res.data);
    });
    getProviderClinics();
  };

  const getProviderClinics = () => {
    let inputData = {
      providerId: props.providerId
    }
    let apiOptions: any = {
      url: 'providerclinic/query', data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      for (let item of result) {
        item.imageUrl = general.getImageUrl(item.profileImagePath);
      }
      setProviderClinics(result);
    });
  }

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setMessage("Do you want to delete " + item.clinicName + "?");
    setOpen(true);
  };

  const confirmMessage = () => {
    setOpen(false);
    deleteSpecialization(selectedItem);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const deleteSpecialization = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `providerclinic/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderClinics();
    })
  }

  const openPhotoCapture = (item: any) => {
    let photoCaptureConfig = {
      id: item.id,
      cameraConfig: { url: 'providerclinic/savecameraimage' },
      fileUploadConfig: { url: 'providerclinic/savefileimage' }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
    getProviderClinics();
  }

  const openImages = (item: any) => {
    let config = {
      api: `providerclinic/${item.id}/images`,
      title: item.clinicName,
      updateDefaultImageApi: `providerclinic/updateDefaultImage`,
      deleteImageApi: `providerclinic/deleteImage`,
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
    getProviderClinics();
  }

  const openProviderClinicForm = (id) => {
    setProviderClinicId(id);
    setClinicFormOpen(true);
  }

  const handleClinicFormClose = () => {
    setProviderClinicId(0);
    setClinicFormOpen(false);
    getProviderClinics();
  }

  useEffect(() => {
    getProviderById();
  }, []);


  const body = (
    <div className="content-container2 card provider-clinic">
      <div className="title-font pl-4">
        <span>Clinics</span>
        <span className="float-right pointer" onClick={() => openProviderClinicForm(0)}>
          <AddCircleIcon />
        </span>
      </div>
      <div>
        {
          providerClinics.map((item) => (
            <div className="row m-0 px-3 py-2 even">
              <div className="col-9 font-14">
                <div className='row m-0 color-333'>
                  <div className='col-9 p-0 text-truncate text-bold' title={item.clinicName}>{item.clinicName}</div>
                  <div className='col-3 p-0 text-right'>
                    {item.price > 0 &&
                      <FxCurrency value={item.price} currency={provider.currency} />
                    }
                  </div>
                </div>

                <div>
                  <RoomIcon color="action" style={{ width: '18px' }} />{item.address} {item.zipCode && <>-</>} {item.zipCode}
                </div>
                {item.clinicDescription &&
                  <div>
                    <DescriptionIcon color="action" style={{ width: '18px' }} />{item.clinicDescription}
                  </div>
                }
              </div>
              <div className="col-1 text-center">
                <span className="pointer" onClick={() => openProviderClinicForm(item.id)}>
                  <EditIcon color="primary" />
                </span>
                <div className="mt-2">
                  <DeleteIcon color="primary" className="pointer" onClick={() => confirmDelete(item)} />
                </div>
              </div>

              <div className="col-2">
                <div className="image-photo">
                  <Link className="pointer" onClick={() => openImages(item)}>
                    {!item.imageUrl && <img src={dummyImage} alt="dummy" className="image-photo"></img>}
                    {item.imageUrl && <img src={item.imageUrl} alt="prod" className="image-photo"></img>}
                  </Link>
                  <div className="text-center camera-align" onClick={() => openPhotoCapture(item)}>
                    <CameraAltIcon className="pointer" />
                  </div>
                </div>
              </div>

            </div>
          ))
        }

      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
        <Dialog
          open={photoCaptureOpen}
          onClose={handlePhotoCaptureClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <PhotoCapture uploadConfig={uploadConfig} handlePhotoCaptureClose={handlePhotoCaptureClose} />
        </Dialog>
        <Dialog
          open={imageSlideOpen}
          onClose={handleImageSlideClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
        </Dialog>
        <Dialog
          open={clinicFormOpen}
          onClose={handleClinicFormClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ProviderClinicForm providerClinicId={providerClinicId} providerId={providerId} handleClinicFormClose={handleClinicFormClose} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderClinic;
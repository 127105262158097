import React, { useEffect, useState } from 'react';
import './ReportReviewForm.scss';
import { http, AlertService, FxTextarea, FxDateTimeDisplay, session } from '../../fx-core';
import Rating from '@material-ui/lab/Rating';
import { Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';

const ReportReviewForm = (props: any) => {

  let defaultItem: any = {
    reviewReportTypeId: '',
    reporterComments: ''
  };
  const [item, setItem] = useState(defaultItem);
  const initialList = [];
  const [reviewReportType, setReviewReportType] = useState(initialList);
  const [valError, setValError] = React.useState(null);
  const [valCommentsError, setValCommentsError] = React.useState(null);
  const [review, setReview] = React.useState({ rating: 0, ratingDate: '', userName: '', reviewComment: '' });

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  };

  const handleRadioChange = (event) => {
    handleItemChange({ [event.target.name]: event.target.value });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };



  const initLookup = () => {
    let inputData = {
      lookups: {
        ReviewReportType: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      console.log(response.data);
      setReviewReportType(response.data.ReviewReportType);
    })
  }

  const getReviewById = () => {
    let apiOptions: any = {
      url: `review/${props.reviewId}`
    };
    http.get(apiOptions).then(response => {
      setReview(response.data);
    });
  }

  const handleReportFormClose = () => {
    props.handleReportFormClose();
  }

  const saveReviewReportForm = () => {
    setValError(null);
    setValCommentsError(null);
    if (!item.reviewReportTypeId || item.reviewReportTypeId == -1) {
      setValError("Please select any reason to report this review");
      return;
    }
    if (item.reviewReportTypeId == 9999 && !item.reporterComments) {
      setValCommentsError("Reporter comments is required");
      return;
    }
    let inputData = {
      reviewReportTypeId: item.reviewReportTypeId,
      reporterComments: item.reporterComments,
      reportedById: session.getItem('userId'),
      reviewId: props.reviewId
    }
    let apiOptions: any = {
      url: 'reviewreport/add', data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      props.handleReportFormClose();
    })
  }


  useEffect(() => {
    initLookup();
    getReviewById();
  }, []);


  const body = (
    <div>
      <div className="row m-0 title-font mx-auto">
        <div className="col-11 pl-0">
          <span>Report Review Form</span>
        </div>
        <div className="col-1 text-right pointer" onClick={props.handleReportFormClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </div>
      </div>

      <div className="row m-0 col-12 mt-2">
        <div className="col-8">
          <div className='d-flex'>
            <PersonIcon className='mr-2' fontSize="small" color="action" />
            {review.userName}
          </div>
          <div className='font-12'>
            <FxDateTimeDisplay value={review.ratingDate} />
          </div>
        </div>
        <div className="col-4">
          <Rating name="read-only" value={review.rating} readOnly />
        </div>

        {review.reviewComment &&
          <div className="col-12 font-12">
            {review.reviewComment}
          </div>
        }
      </div>

      <div className="col-12 px-3 mt-4">
        <div className="mt-2 mb-2 border-bottom">Reason to report this review</div>
        {
          reviewReportType.length > 0 && reviewReportType.map((item, i) => (
            <div key={i}>
              <input type="radio" id={item.reviewReportTypeId} value={item.id}
                name="reviewReportTypeId" onChange={handleRadioChange}></input>
              <span className="ml-1">{item.text}</span>
            </div>
          ))
        }
      </div>
      {
        valError && !item.reviewReportTypeId &&
        <div className="error">{valError} </div>
      }
      <div className="col-12 px-3">
        <FxTextarea name="reporterComments" variant="outlined" label="Reporter Comments" className="textareawidth"
          value={item.reporterComments} maxRows={3} onValueChange={handleInputChange} />
        {
          valCommentsError && !item.reporterComments &&
          <div className="error"> {valCommentsError}</div>
        }
      </div>
      <div className="m-2 mt-2 px-3 text-right">
        <Button variant="contained" size="small" color="primary" onClick={saveReviewReportForm}>
          Save</Button>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ReportReviewForm;
import React, { useState } from 'react';
import './UploadDocument.scss';
import { AlertService, general } from '../../fx-core';
import { Dialog } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import dummyImage from "./../../../assets/images/dummy.png";
import { FxUploader, http } from '../../fx-core';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import * as _ from 'lodash';

const UploadDocument = (props: any) => {

  const [documentList, setDocumentList] = useState([]);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [message, setMessage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [acceptType, setAcceptType] = React.useState('image/jpeg,image/jpg, image/png,application/pdf');

  const onUploadImage = (files: any) => {
    console.log('Photo capture files=', files);
    let id = props.uploadConfig.id;
    if (files && files.length > 0) {
      const formData = new FormData()
      formData.append('file', files[0]);
      if (id > 0) {
        formData.append('id', id);
      }
      let apiOptions = { url: props.uploadConfig.fileUploadConfig.url, data: formData };
      http.post(apiOptions).then((response: any) => {
        console.log('Upload image success');
        uploadFileCallback(response.data);
      }).catch(error => {
        console.log('Upload error=', error);
      });
    }
  }

  const uploadFileCallback = (res) => {
    AlertService.showSuccessMsg();
    getFileDetails(res);
  }

  const getFileDetails = (data) => {
    documentList.push({
      filePath: data.filePath.toLowerCase(), fileExtension: data.fileExtension.toLowerCase(),
      fileName: data.fileName.toLowerCase(), fileUrl: general.getImageUrl(data.filePath.toLowerCase())
    });
    setDocumentList(documentList);
    refreshDocumentImage();
    props.onCallBack(documentList);
  }

  const refreshDocumentImage = () => {
    for (let item of documentList) {
      if (isImage(item.fileExtension)) {
        item.imageUrl = general.getImageUrl(item.filePath);
      }
    }
  }

  function isImage(fileExtn: any = "") {
    let result = false;
    if (!fileExtn) {
      result = true;
    } else {
      result = (fileExtn == '.jpeg' || fileExtn == '.jpg' || fileExtn == '.png');
    }
    return result;
  }

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setMessage("Do you want to delete " + item.fileName + "?");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const confirmMessage = () => {
    setOpen(false);
    deleteImage(selectedItem);
  };


  const deleteImage = (item: any) => {
    let itemIdx = _.findIndex(documentList, { fileName: item.fileName });
    documentList.splice(itemIdx, 1);
    refreshDocumentImage();
    AlertService.showSuccessMsg();
  }

  const body = (
    <div className="uploaddoc-ui">
      <div className="row m-0">
        <div className="col-12">
          <FxUploader onUpload={onUploadImage} acceptType={acceptType} />
        </div>
      </div>
      {
        documentList.map((item) => (
          <div className='row m-0 align-items-center'>
            <a className="pointer col-2 p-0">
              {!item.imageUrl && <img src={dummyImage} alt="dummy" className="profile-pic"></img>}
              {item.imageUrl && <img src={item.imageUrl} alt="prod" className="profile-pic"></img>}
              {!isImage() &&
                <span className="form_fileextn">{item.fileExtension}</span>
              }
            </a>
            <span className="col-8 text-truncate pl-1 font-12" title={item.fileName}>{item.fileName}</span>
            <span className='col-2 pointer text-danger' onClick={() => confirmDelete(item)}><DeleteOutlineIcon /> </span>
          </div>
        ))
      }
      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
      </div>
    </div>
  );


  return (
    <div>
      {body}
    </div>
  );

}
export default UploadDocument;
import React, { useState, useEffect } from 'react';
import './SpecializationForm.scss';
import { AlertService, http, FxInput } from '../../fx-core';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';

const SpecializationForm = (props) => {

    const defaultItem = {
        referenceValueCode: props.valueCode ? props.valueCode : null,
        referenceValueText: "",
        id:0
    };
    const [item, setItem] = useState(defaultItem);
    const [error, setError] = useState(null);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };


    const saveSpecialization = () => {
        if (!item.id && !item.referenceValueCode || !item.referenceValueText) {
            setError("This field is required");
            return;
        }
        let apiOptions: any = {
            url: `referencevalue/specializationtype/save`,
            data: item
        };
        http.post(apiOptions).then(res => {
            saveSpecializationCallBack(res);
        });
    };

    const saveSpecializationCallBack = (res) => {
        AlertService.showSuccessMsg();
        props.handleFormClose();
    };

    const getSpecializationByCode = () => {
        let apiOptions: any = {
            url: `referencevalue/specializationtype/${item.referenceValueCode}`
        };
        http.get(apiOptions).then(res => {
            setItem(res.data);
        });
    };

    const handleClose = () => {
        props.handleFormClose();
    }

    useEffect(() => {
        if (props.valueCode) {
            getSpecializationByCode();
        }
    }, []);

    const body = (
        <div className="specform-ui">
            <div className="row title-font mx-auto">
                <div className="col-10 pl-0">
                    <span>Specialization Form</span>
                </div>
                <div className="col-2 float-right pointer" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="mx-auto row mt-2">
                {!item.id &&
                    <div className="col-12">
                        <FxInput name="referenceValueCode" variant="outlined" label="Code" size="small"
                            value={item.referenceValueCode} className="txtstyle"
                            onValueChange={handleInputChange}
                        />

                        {error && !item.referenceValueCode &&
                            <div className="error"> {error} </div>}
                    </div>
                }

                <div className="col-12">
                    <FxInput name="referenceValueText" variant="outlined" label="Name" size="small"
                        value={item.referenceValueText} className="txtstyle"
                        onValueChange={handleInputChange}
                    />
                    {error && !item.referenceValueText &&
                        <div className="error"> {error} </div>}
                </div>
            </div>
            <div className="row mt-4 pr-4 mr-0 pb-4 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={saveSpecialization}>Save</Button>
                </span>
            </div>
        </div>
    );

    return (
        <div>
            {body}
        </div>
    );

}

export default SpecializationForm;
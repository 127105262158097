import React from 'react';
import { FxDate, FxInput, FxTextarea } from '../../../fx-core';
import './SignaturePage.scss';
import signature from '../../../../assets/signature.png';

const SignaturePage = (props: any) => {
  const handleInputChange = () => {
  }
  return (
    <div>
			<div className='text-center'>
				<b>[SIGNATURE PAGE]</b>
			</div>
			<div className='pl-3 pt-3'>
				<p>
					The Parties hereto have had the opportunity to review this Agreement
					with their individual legal counsel, and hereby attests by affixing
					its signature hereto that it understands, accepts, and agrees to its
					terms.
				</p>
			</div>
			<div className='pl-3'>
				<p>ACCEPTED AND AGREED TO:</p>
			</div>
			<div className='my-4 row ml-4'>
				<div className='col-6 mr-3'>
					<div>
						<b>COVERED ENTITY: </b>
					</div>
					<div>
						<FxInput
							name='entityName'
							variant='standard'
							size='small'
							value=''
							onValueChange={handleInputChange}
						/>
					</div>
					<div className='pt-4'>
						<b>BY.</b>
					</div>
					<div className='pt-3'>
						<FxInput
							name='signature'
							variant='standard'
							size='small'
							value=''
							onValueChange={handleInputChange}
						/>
					</div>
					<div>
						<b>Signature</b>
					</div>
					<div>
						<FxInput
							name='userName'
							variant='standard'
							size='small'
							value=''
							onValueChange={handleInputChange}
						/>
					</div>
					<div>
						<b>Name</b>
					</div>
					<div>
						<FxInput
							name='title'
							variant='standard'
							size='small'
							value=''
							onValueChange={handleInputChange}
						/>
					</div>
					<div>
						<b>Title</b>
					</div>
					<div className='pt-4'>
						<b>Address</b>
					</div>
					<div>
						<FxTextarea
							name='address'
							variant='outlined'
							label='Address'
							size='small'
							fullWidth
							value=''
							maxRows={5}
							onValueChange={handleInputChange}
						/>
					</div>
					<div className='row pt-3 pr-2 pl-2'>
						<div className='pt-2 col-1'>
							<b>Tel:</b>
						</div>
						<div className='col-11 pr-1'>
							<FxInput
								name='mobile'
								variant='standard'
								size='small'
								value=''
								onValueChange={handleInputChange}
								fullWidth
							/>
						</div>
					</div>
					<div className='row pt-3 pr-2 pl-2'>
						<div className='pt-2 col-1'>
							<b>Fax:</b>
						</div>
						<div className='col-11 pr-1'>
							<FxInput
								name='fax'
								variant='standard'
								size='small'
								value=''
								onValueChange={handleInputChange}
								fullWidth
							/>
						</div>
					</div>
					<div className='row pt-3 pr-2 pl-2'>
						<div className='pt-2 col-1'>
							<b>Email:</b>
						</div>
						<div className='col-11 pr-1'>
							<FxInput
								name='email'
								variant='standard'
								size='small'
								value=''
								onValueChange={handleInputChange}
								fullWidth
							/>
						</div>
					</div>
					<div className='row pt-3 pr-2 pl-2'>
						<div className='pt-2 col-1'>
							<b>Date:</b>
						</div>
						<div className='col-11 pr-1'>
							<FxInput
								name='date'
								variant='standard'
								size='small'
								value=''
								onValueChange={handleInputChange}
								fullWidth
							/>
						</div>
					</div>
				</div>
				<div className='col-5'>
					<div>
						<b>BUSINESS ASSOCIATE:</b>
					</div>
					<div className='pt-3'>
						<b>MYMEDIFAM INC.</b>
					</div>
					{/* <div className='pt-3'><b>BY.</b></div>
          <div className='row px-2'>
            <div className='col-5'>
              <img src="/images/Signature-img.png" />
            </div>
            <div className='col-7 pt-3'>
              <FxInput name="owner" variant="standard" size="small" value="" onValueChange={handleInputChange} fullWidth />
            </div>
          </div>
          <div><b>Signature</b></div>
          <div className='row pt-3 pr-2 pl-2'>
            <div className='col-1'><b>Name:</b></div>
            <div className='col-11 pr-1 pl-3'>Rachel William Anantha</div>
          </div>
          <div className='row pr-2 pl-2'>
            <div className='col-1'><b>Title:</b></div>
            <div className='col-11 pr-1 pl-3'>CEO</div>
          </div>
          <div className='row pt-3 pr-2 pl-2'>
            <div className='col-12'><b>Address:</b></div>
            <div className='col-12'>
              <div><b>339 Sunset Road</b></div>
              <div><b>Skillman, NJ 08558</b></div>
              <div><b>USA</b></div>
            </div>
          </div> */}
					<div className='row pt-3 pr-2 pl-2'>
						<div className='col-1'>
							<b>Tel:</b>
						</div>
						<div className='col-11 pr-1 pl-3'>+1 609-540-9062</div>
					</div>
					<div className='row pt-3 pr-2 pl-2'>
						<div className='pt-2 col-1'>
							<b>Fax:</b>
						</div>
						<div className='col-11 pr-1 pl-3'>
							<FxInput
								name='ownerfax'
								variant='standard'
								size='small'
								value=''
								onValueChange={handleInputChange}
								fullWidth
							/>
						</div>
					</div>
					<div className='row pt-3 pr-2 pl-2'>
						<div className='col-1'>
							<b>Email:</b>
						</div>
						<div className='col-11 pr-1 pl-3'>support@mymedifam.com</div>
					</div>
					<div className='row pt-3 pr-2 pl-2'>
						<div className='pt-2 col-1'>
							<b>Date:</b>
						</div>
						<div className='col-11 pr-1 pl-3'>
							<FxInput
								name='signdate'
								variant='standard'
								size='small'
								value=''
								onValueChange={handleInputChange}
								fullWidth
							/>
							{/* <FxDate name="signdate" variant="standard" value="" onValueChange="" fullWidth /> */}
						</div>
					</div>
				</div>
			</div>
		</div>
  );
}
export default SignaturePage;


import React, { useState, useEffect } from 'react';
import './InsuranceForm.scss';
import { AlertService, http, FxInput } from '../../fx-core';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';

const InsuranceForm = (props) => {

    const defaultItem = {
        insuranceName: "",
        id: props.insuranceId > 0 ? props.insuranceId : 0
    };
    const [item, setItem] = useState(defaultItem);
    const [error, setError] = useState(null);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };


    const saveInsurance = () => {
        if(!item.insuranceName)
        {
            setError("This field is required");
            return;
        }
        let apiOptions: any = {
            url: `insurance/save`,
            data: item
        };
        http.post(apiOptions).then(res => {
            saveInsuranceCallBack(res);
        });
    };

    const saveInsuranceCallBack = (res) => {
        AlertService.showSuccessMsg();
        props.handleFormClose();
    };

    const getInsuranceById = () => {
        let apiOptions: any = {
            url: `insurance/${item.id}`
        };
        http.get(apiOptions).then(res => {
            setItem(res.data);
        });
    };

    const handleClose = () => {
        props.handleFormClose();
    }

    useEffect(() => {
        if (props.insuranceId > 0) {
            getInsuranceById();
        }
    }, []);

    const body = (
        <div className="insuranceform-ui">
            <div className="row title-font mx-auto">
                <div className="col-10 pl-0">
                    <span>Insurance Form</span>
                </div>
                <div className="col-2 float-right pointer" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="mx-auto row">
                <div className="col-6">
                    <FxInput name="insuranceName" variant="outlined" label="Insurance Name" size="small"
                        value={item.insuranceName} className="txtstyle"
                        onValueChange={handleInputChange}
                    />
                    {error && !item.insuranceName &&
                        <div className="error"> {error} </div>}
                </div>


            </div>
            <div className="row mt-4 pr-4 mr-0 pb-4 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={saveInsurance}>Save</Button>
                </span>
            </div>
        </div>
    );

    return (
        <div>
            {body}
        </div>
    );

}

export default InsuranceForm;
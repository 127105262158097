import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

export const LocationSearchInput = (props:any) => {
 
  let defaultAddress = props.address || "";
  const [address, setAddress] = useState(defaultAddress);
 
  const handleChange = (address:any) => {
    setAddress(address);
  };

  const handleSelect = (address:any, placeId:any) => {

    const div = document.createElement('div')
    if((window as any).google && (window as any).google.maps && (window as any).google.maps.places) {
      let service = new (window as any).google.maps.places.PlacesService(div);
      service.getDetails({placeId: placeId}, (results:any, status:any) => {
        if (status === (window as any).google.maps.places.PlacesServiceStatus.OK) {
          if(results && results.address_components) {
           
            let lscountry = results.address_components.filter((i: { types: string | string[]; })=> i.types && i.types.indexOf("country") > -1);
            let countryName = "";
            if(lscountry && lscountry.length > 0) {
              countryName = lscountry[0].long_name;
            }

            let lspincode = results.address_components.filter((i: { types: string | string[]; })=> i.types && i.types.indexOf("postal_code") > -1);
            let pincode = "";
            if(lspincode && lspincode.length > 0) {
              pincode = lspincode[0].long_name;
            }
            if(countryName || pincode) {
              if(props.onReceiveOtherDetails) {
                props.onReceiveOtherDetails({ country: countryName, pinCode: pincode || "" });
              }
            }
          }
          console.log("place details-",results);
        } else {
          console.log('place details not found!');
        }
      })
  }

    setAddress(address);
    console.log('address=', address);
    geocodeByAddress(address)
      .then((results: any[]) => getLatLng(results[0]))
      .then((latLng: { lat: any; lng: any; }) => {
        console.log('Success', latLng);
        let data = { address: address, lat: latLng.lat, lng: latLng.lng };
        props.locationSelected(data);
      })
      .catch((error: any) => console.error('Error', error));
  };

  if (props.visibility === false) {
    return <></>;
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
           {/* <TextField 
          {...getInputProps({
            placeholder: props.placeholder,
            className: 'location-search-input'                     
          })}
          multiline
          rows={3}
          fullWidth          
           /> */}
          <input
            {...getInputProps({
              placeholder: props.placeholder,
              className: 'location-search-input',
              style:{ width: '100%', height: 58 },
              disabled:props.disabled
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion:any, index:any) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div 
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
import React, { useEffect, useState } from 'react';
import './ViewReviewReports.scss';
import { FxDate, FxSelect, http, AlertService, FxDateTimeDisplay, formatService, useNavigationService } from '../../fx-core';
import { Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import moment from 'moment';
import CommentIcon from '@material-ui/icons/Comment';
import Rating from '@material-ui/lab/Rating';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { useSelector } from 'react-redux';
import { Constants } from '../../fx-core/helpers/constants';

const ViewReviewReports = (props: any) => {
  const { pageTitle } = useNavigationService();
  const preferences = useSelector((store: any) => store.preferences);
  const dateFormat = Constants.ServerDateFormat;

  let defaultItem: any = {
    fromDate: formatService.substractDaysInDisplayFormat(formatService.getCurrentDate(), 7, dateFormat),
    toDate: formatService.getCurrentDate(dateFormat),
    reviewReportStatusId: 1
  };

  const [item, setItem] = useState(defaultItem);
  const initialList = [];
  const [reviewReportStatus, setReviewReportStatus] = useState(initialList);
  const [reportedReviewDetails, setReportedReviewDetails] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState(defaultItem);
  const [valError, setValError] = useState(null);

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let options = { item: { [item.name]: item.value } };
    getReviewReports(options);
  }

  const handleFilterChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };


  const initLookup = () => {
    let inputData = {
      lookups: {
        ReviewReportStatus: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setReviewReportStatus(response.data.ReviewReportStatus);
      if (response.data.ReviewReportStatus.length > 0) {
        handleFilterChange({ reviewReportStatusId: response.data.ReviewReportStatus[0].id });
      }
    });
  }

  const getReviewReports = (options: any = {}) => {

    let selectedStatusId = item.reviewReportStatusId;
    let selectedFromDate: any = item.fromDate;
    let selectedToDate: any = item.toDate;
    if (options && options.item) {
      selectedStatusId = options.item.reviewReportStatusId ? options.item.reviewReportStatusId : item.reviewReportStatusId;
      selectedFromDate = options.item.fromDate ? options.item.fromDate : item.fromDate;
      selectedToDate = options.item.toDate ? options.item.toDate : item.toDate;
    }
    setValError(null);
    if (moment(selectedToDate).isBefore(selectedFromDate)) {
      setValError("To date should be greater than or equal to From date");
      return;
    }
    let inputData = {
      fromDate: formatService.getDateStringForServer(selectedFromDate, dateFormat),
      toDate: formatService.getDateStringForServer(selectedToDate, dateFormat),
      reviewReportStatusId: selectedStatusId
    }
    let apiOptions: any = {
      url: 'reviewreport/allreportedreviews', data: inputData
    };
    http.post(apiOptions).then(response => {
      setReportedReviewDetails(response.data);
    });
  }

  const confirmReject = (item: any) => {
    setSelectedItem(item);
    setAction('reject');
    setMessage("Do you want to reject this review ?");
    setOpen(true);
  };

  const confirmResolve = (item: any) => {
    setSelectedItem(item);
    setAction('resolve');
    setMessage("Do you want to resolve this review ?");
    setOpen(true);
  };


  const confirmMessage = (action: any, comments: any) => {
    setOpen(false);
    if (action == 'reject') {
      markReviewAsRejected(selectedItem);
    }
    else if (action == 'resolve') {
      selectedItem.resolverComments = comments;
      markReviewAsResolved(selectedItem);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }


  const markReviewAsRejected = (item: any) => {
    let inputData = {
      id: item.id
    }
    let apiOptions: any = {
      url: 'reviewreport/reject', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getReviewReports({});
    })
  }

  const markReviewAsResolved = (item: any) => {
    let inputData = {
      id: item.id,
      resolverComments: item.resolverComments
    }
    let apiOptions: any = {
      url: 'reviewreport/resolve', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getReviewReports({});
    })
  }

  useEffect(() => {
    initLookup();
    let options = { item: item };
    getReviewReports(options);
  }, []);


  const body = (
    <div className="content-container">
      <div className="title-font">
        <span>{pageTitle}</span>
      </div>
      <div className=" px-3">
        <div className="row col-12 m-0 py-4 p-0">
          <div className="col-3">
            <FxDate name="fromDate" label="From Date" variant="outlined"
              value={item.fromDate} onValueChange={handleInputChange} />
          </div>

          <div className="col-3">
            <FxDate name="toDate" label="To Date" variant="outlined"
              value={item.toDate} onValueChange={handleInputChange} />
          </div>
          {valError &&
            <div className="error"> {valError} </div>}

          <div className="col-4">
            <FxSelect name="reviewReportStatusId" variant="outlined" label="Review Report Status"
              options={reviewReportStatus}
              selectedValue={item.reviewReportStatusId} valueField="id"
              displayField="text" className="selectStyle" onValueChange={handleInputChange} />
          </div>
        </div>
        <div>
          {
            reportedReviewDetails.map((item) => (
              <div className="row col-12 even m-0 py-3">
                <div className="col-8">
                  <span><PersonIcon /> {item.review.userName}</span>
                  <div className='col-12 p-0 font-12 d-flex align-items-center'>
                    <FxDateTimeDisplay value={item.review.ratingDate} />
                    <Rating className="ml-2" name="read-only" value={item.review.rating} readOnly />
                  </div>
                </div>
                <div className="col-2 status ">

                  {item.reportedReview.reviewReportStatus == 'Rejected' && <span className='text-danger'>{item.reportedReview.reviewReportStatus}</span>}
                  {item.reportedReview.reviewReportStatus != 'Rejected' && <span>{item.reportedReview.reviewReportStatus}</span>}
                </div>
                <div className="col-2 text-right">
                  {
                    item.reportedReview.reviewReportStatusId === 1 &&
                    <>
                      <Button variant="contained" size="small" color="primary" onClick={() => confirmReject(item.reportedReview)}>
                        Reject</Button>
                      <span className="ml-2">
                        <Button variant="contained" size="small" color="primary" onClick={() => confirmResolve(item.reportedReview)}>
                          Resolve</Button>
                      </span>
                    </>
                  }
                </div>
                <div className="col-12 font-13 ">
                  <RateReviewIcon style={{ width: '20px', marginRight: '5px' }} />
                  {item.review.reviewComment}
                </div>
                <div className="col-9 font-13">
                  {item.reportedReview.reviewReportType}
                </div>

                <div className="col-3 font-13 text-right">
                  <span className="mr-1">Reported on : </span>
                  <FxDateTimeDisplay value={item.reportedReview.reportedDateTime} />
                </div>

                {item.reportedReview.reporterComments &&
                  <div className="col-12 font-13">
                    <CommentIcon style={{ width: '20px', marginRight: '5px' }} />
                    {item.reportedReview.reporterComments}
                  </div>
                }
                {item.reportedReview.resolverId > 0 &&
                  <div className="divider row col-12 px-0 font-13 m-0">
                    {
                      item.reportedReview.resolverComments &&
                      <div className="col-9">
                        <span><b>Resolver Comments:</b>
                          <span> {item.reportedReview.resolverComments}</span>
                        </span>
                      </div>
                    }

                    {
                      item.reportedReview.resolvedDateTime &&
                      <div className="col-3 text-right">
                        <span className="mr-1">Resolved on : </span>
                        <FxDateTimeDisplay value={item.reportedReview.resolvedDateTime} />
                      </div>
                    }
                  </div>
                }
              </div>
            ))
          }

        </div>

        <div>
          <Dialog
            open={open}
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <ConfirmDialog message={message} action={action} handleClose={handleClose} onConfirmCallback={confirmMessage} />
          </Dialog>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ViewReviewReports;
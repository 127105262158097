import React, { useEffect, useState } from 'react';
import './InsuranceList.scss';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Dialog, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { http, FxInput, useNavigationService } from '../../fx-core';
import InsuranceForm from '../InsuranceForm/InsuranceForm';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';

const InsuranceList = () => {
    const { pageTitle } = useNavigationService();
    let defaultFilter: any = {
        insuranceName: ''
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [insurances, setInsurances] = useState([]);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);
    const [insuranceId, setInsuranceId] = useState(0);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getInsuranceList({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getInsuranceList(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getInsuranceList = (options: any = {}) => {
        let startIndex = 0;
        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        let filters = {
            insuranceName: filter.insuranceName
        };
        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `insurance/query`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getInsuranceCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getInsuranceCallBack = (res, pagerOptions) => {
        const result = res.data;
        setInsurances(result.items);
        computePagerData(result, pagerOptions);
    };

    const openInsuranceForm = (id) => {
        setInsuranceId(id);
        setOpen(true);
    };

    const handleFormClose = () => {
        setOpen(false);
        let options = { pager: { pageNo: 1 }, filter: filter };
        getInsuranceList(options);
    };

    useEffect(() => {
        let options = { pager: { pageNo: 1 }, filter: filter };
        getInsuranceList(options);
    }, []);

    return (
        <div className="content-container">
            <div className="title-font">
                <span>{pageTitle}</span>
                <span onClick={() => openInsuranceForm(0)} className="pointer"><AddCircleOutlineIcon /></span>
            </div>
            <div className="px-3 ">
                <div className="col-4 py-3">
                    <FxInput name="insuranceName" variant="outlined" label="Search Insurance"
                        size="small"
                        value={filter.insuranceName}
                        onValueChange={handleInputChange}
                    />
                </div>
                {/* <div className="row col-12 mt-1">
                <div className="col-8"></div>
                <div className="col-4">
                    <span className="float-right pt-2 pr-2">
                        <Button variant="contained" size="small" color="primary" onClick={() => openInsuranceForm(0)}>Add</Button>
                    </span>
                </div>
            </div> */}
                <div className="row col-12 m-0">
                    <TableContainer component={Paper}>
                        <Table stickyHeader className="table table-striped">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="90%" className="table-head table-font">Insurance Name</TableCell>
                                    <TableCell className="table-head table-font">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {insurances && insurances.length > 0 &&
                                    insurances.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <div className="table-font">
                                                    <span>{row.insuranceName}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="btn-style">
                                                    <span className="table-font">
                                                        <EditIcon
                                                            color="primary"
                                                            className='pointer'
                                                            onClick={() => openInsuranceForm(row.id)} />
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="row m-0 col-12 mb-5 justify-content-between">
                    <div className="bottom-info pt-3">{pager.pagerInfo}</div>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                        padding={1}
                        paddingRight={1}
                    >
                        <Pagination
                            page={pager.pageNo}
                            count={pager.pageCount}
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                            onChange={pageChanged}
                        />
                    </Box>
                </div>
            </div>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={handleFormClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <InsuranceForm insuranceId={insuranceId} handleFormClose={handleFormClose} />
            </Dialog>
        </div>
    )
};

export default InsuranceList;
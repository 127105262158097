import React, { useEffect, useState } from 'react';
import './PdfViewer.scss';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';

export const PdfViewer = (props: any) => {

  const [numPages, setNumPages] = useState(null);
  let defaultScale: any = 1.0;
  const [scale, setScale] = useState(defaultScale);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  console.log('props.pdfData=', props.pdfData);

  const zoomIn = () => {
    let newScale = scale + 0.5;
    setScale(newScale);
  }

  const zoomOut = () => {
    if (parseInt(scale) != 1) {
      let newScale = scale - 0.5;
      setScale(newScale);
    }
  }

  const body = (
    <div className="pdfviewer-ui">
      <div className='confirmContainer'>
        <div className='custom-head'>
          <div className="row title-font mx-auto">
            <div className="col-11 pl-0">
              <span>{props.docName}</span>
            </div>
            <div className="col-1 text-right pointer" onClick={props.handleClose}>
              <CloseIcon fontSize="small" color="secondary" />
            </div>
          </div>
        </div>
        <div>
          <Document
            file={props.docData}
            onLoadSuccess={onDocumentLoadSuccess}>
            {
              Array.from(
                new Array(numPages),
                (el, index) => (
                  <>
                    <Page scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
                    <div className="pdf-pagefooter">Page {index + 1} of {numPages}</div>
                  </>
                ),
              )
            }
          </Document>
        </div>
        <div className='custom-foot py-2'>
          <span className='pr-3'><i className="fas fa-search-minus pointer font-25" onClick={zoomOut}></i></span>
          <span className='pl-3'><i className="fas fa-search-plus pointer font-25" onClick={zoomIn}></i></span>         
        </div>
      </div>
    </div>
  );


  return (
    <div>
      {body}
    </div>
  );

}
import React, { useEffect, useState } from 'react';
import './ProviderPaymentOptions.scss';
import { AlertService, FxInput, http } from "../../fx-core";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, Link } from '@material-ui/core';

const ProviderPaymentOptions = (props: any) => {

  let defaultItem = {
    cod: false,
    paypal: false,
    paypalKey: "",
    paypalSecret: ""
    //stripe: false, - Stripe commented as part of #217
  };

  const [item, setItem] = useState(defaultItem);
  const [paypalError, setPaypalError] = useState('');
  const [payOptionRequired, setPayOptionRequired] = useState('');

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  }

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getProviderPaymentOptions = () => {
    let apiOptions: any = {
      url: `paymentoption/detail/${props.providerId}`
    };
    http.get(apiOptions).then(response => {
      let payments = response.data.paymentOptions;
      let paypalCreds = response.data.paypalCredentials;

      let paymentOptions: any = {};
      for (let item of payments) {
        paymentOptions[item.paymentOptionCode] = item.isActive;
        if (item.paymentOptionCode == 'paypal') {
          paymentOptions.paypalKey = paypalCreds.providerKey;
          paymentOptions.paypalSecret = paypalCreds.secret;
        }
      }

      handleItemChange(paymentOptions);
    })
  }

  const savePaymentOptions = () => {
    setPayOptionRequired('');
    setPaypalError('');
    if (!item.cod && !item.paypal) {
      setPayOptionRequired("Please select any of the payment options");
      return;
    }
    if (item.paypal && (!item.paypalKey || !item.paypalSecret)) {
      setPaypalError("Please enter paypal key and paypal secret");
      return;
    }
    let inputData = { providerId: props.providerId, ...item };
    let apiOptions = { url: 'provider/paymentoptions', data: inputData };
    http.post(apiOptions).then(response => {
      savePaymentCallback();
    })
  }

  const savePaymentCallback = () => {
    AlertService.showSuccessMsg("Payment options updated successfully");
    getProviderPaymentOptions();
  }

  const getStripeAuthUrl = () => {
    let providerId = props.providerId;
    let apiOptions = {
      url: 'provider/stripe/authurl', data: {
        id: providerId
      }
    };
    http.post(apiOptions).then(response => {
      if (response.data) {
        let url = response.data.authUrl;
        window.open(url, '_blank');
      }
    })
  }

  const redirectDevPaypal = () => {
    window.open("https://www.paypal.com/signin?returnUri=https%3A%2f%2fwww.paypal.com%2fbusinessprofile&state=%2fsettings%2femail", '_blank');
  }

  useEffect(() => {
    getProviderPaymentOptions();
  }, []);


  const body = (
    <div className="content-container1 card">
      <div className="title-font">
        <span>Payment Options</span>
      </div>
      <div className="px-3 m-2">
        <span>Payment Options*</span>
      </div>
      <div className="px-3">
        <div>
          <Checkbox name="cod" color="primary" checked={item.cod}
            value={item.cod} onChange={handleCheckbox} />Pay at Clinic
        </div>

        <div>
          <Checkbox name="paypal" color="primary" checked={item.paypal}
            value={item.paypal} onChange={handleCheckbox} />Paypal
          <Link className="pointer ml-4" onClick={redirectDevPaypal}>Click here</Link>
        </div>

        {item.paypal &&
          <div className="m-2">
            <FxInput name="paypalKey" variant="outlined" label="Paypal Key"
              value={item.paypalKey} onValueChange={handleInputChange} className="mr-2"
            />

            <FxInput name="paypalSecret" variant="outlined" label="Paypal Secret"
              value={item.paypalSecret} onValueChange={handleInputChange}
            />
            {paypalError &&
              <div className="error"> {paypalError} </div>}
          </div>
        }


        {/* <div>
        <Checkbox name="stripe" color="primary" checked={item.stripe}
          value={item.stripe} onChange={handleCheckbox} />Stripe(Credit/Debit Cards)
        <Link className="pointer ml-4" onClick={getStripeAuthUrl} style={{ marginLeft: 2 }}>Click here</Link>
      </div> */}
        {payOptionRequired &&
          <div className="error"> {payOptionRequired} </div>}

        <div className="text-right">
            <Button variant="contained" size="small" color="primary" onClick={savePaymentOptions}>Save</Button>
        </div>

      </div>


    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderPaymentOptions;
import { Action, combineReducers, createStore } from 'redux';
import { cart } from './cart.reducer';
import { externallogin } from './externallogin.reducer';
import { navData } from './nav.reducer';
import { preferences } from './preferences.reducer';

export interface IAppAction extends Action<string> {
  payload?: any;
}
const rootReducer = combineReducers({
  cart,
  externallogin,
  preferences,
  navData,
});

function configureStore(rootReducer?: any) {
  const store = createStore(rootReducer);
  return store;
}

export const store = configureStore(rootReducer);
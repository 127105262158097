import React, { useEffect, useState } from 'react';
import './ProviderCertificateForm.scss';
import { FxInput, http, AlertService, session } from '../../fx-core';
import { Button } from '@material-ui/core';
import UploadDocument from '../UploadDocument/UploadDocument';
import CloseIcon from '@material-ui/icons/Close';

const ProviderCertificateForm = (props: any) => {
  let defaultForm = {
    certificateName: null,
    providerId: props.providerId,
    uploadBy: session.getItem("userId"),
    documentList: [],
    id: 0
  };
  const initialList = [];
  const [item, setItem] = useState(defaultForm);
  const [error, setError] = useState(null);
  const [uploadConfig, setUploadConfig] = useState({
    fileUploadConfig: { url: 'providercertificate/savefileimage' },
    id: props.providerId
  });


  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
  }

  const handleFilterChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const saveCertificate = () => {
    setError(null);
    if (!item.id && item.documentList && item.documentList.length == 0) {
      AlertService.showErrorMsg("Please upload document");
      return;
    }
    if (!item.certificateName || (item.certificateName && item.certificateName.trim().length == 0)) {
      setError("This field is required");
      return;
    }
    item.certificateName = item.certificateName.trim();
    let apiOptions: any = {
      url: 'providercertificate/add',
      data: item
    };
    http.post(apiOptions).then(response => {
      saveCertificateCallBack(response);
    })
  }

  const saveCertificateCallBack = (res) => {
    AlertService.showSuccessMsg();
    props.handleCertificateFormClose();
  };

  const onCallBack = (docList) => {
    console.log(docList);
    handleFilterChange({ "documentList": docList });
  }

  const handleClose = () => {
    props.handleCertificateFormClose();
  };

  useEffect(() => {
  }, []);


  const body = (
    <div className="modalalign provcertform-ui">
      <div className="title-font pl-4">
        <span>Certificates</span>
        <span className="col-1 float-right pointer mr-3" onClick={handleClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </span>
      </div>
      <div className="row m-2 pl-3">
        <div className="col-6">
          <UploadDocument uploadConfig={uploadConfig} onCallBack={onCallBack} />
        </div>
        <div className="col-6">
          <div className="pt-2">
            <FxInput name="certificateName" variant="outlined" label="Certificate Name" size="small"
              value={item.certificateName} onValueChange={handleInputChange} />
            {
              error && (!item.certificateName || (item.certificateName && item.certificateName.trim().length == 0)) &&
              <div className="validation-msg">{error}</div>
            }
          </div>
          <div className="mt-3 col-12">
            <span className="pl-3 float-right">
              <Button variant="contained" size="small" color="primary"
                onClick={() => saveCertificate()}>Add</Button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderCertificateForm;
import React, { useEffect, useState } from 'react';
import './VitalList.scss';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Dialog, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom";
import { http, AlertService, FxInput, FxSelect, useNavigationService } from '../../fx-core';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const VitalList = () => {
    const history = useHistory();
    const { pageTitle } = useNavigationService();
    let defaultFilter: any = {
        codeorname: '',
        vitalUOM: '-1',
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [vitals, setVitals] = useState([]);
    const [vitalUOM, setVitalUOM] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);

    const [open, setOpen] = useState(false);
    const [selectedVital, setSelectedVital] = useState({});
    const [message, setMessage] = useState(null);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getVitals({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getVitals(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getVitals = (options: any = {}) => {
        let startIndex = 0;
        let filters = {
            codeorname: filter.codeorname,
            vitalUOM: filter.vitalUOM,
        };

        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            filters.codeorname = options.filter.codeorname;
            filters.vitalUOM = options.filter.vitalUOM;
        }

        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `vital/getvitallist`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getVitalsCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getVitalsCallBack = (res, pagerOptions) => {
        const result = res.data;
        setVitals(result.items);
        computePagerData(result, pagerOptions);
    };

    const showVitalForm = (item) => {
        let stateObj: any = { id: 0 };
        if (item && item.id) {
            stateObj = { ...stateObj, ...item }
        }
        history.push({
            pathname: '/vitalform',
            state: stateObj
        });
    };

    const handleSelectedVital = (item) => {
        setSelectedVital(item);
    };

    const openDeleteConfirmDialog = (item) => {
        setMessage("Do you want to delete " + item.vitalName + "?");
        handleSelectedVital(item);
        setOpen(true);
    };

    const closeDeleteConfirmDialog = () => {
        handleSelectedVital({});
        setOpen(false);
    };

    const confirmMessage = () => {
        setOpen(false);
        deleteVital(selectedVital);
    };

    const deleteVital = (item: any) => {
        let inputData = {
            id: item.id,
        };
        let apiOptions: any = {
            url: `vital/${inputData.id}`
        };
        http.delete(apiOptions).then(res => {
            deleteVitalCallBack(res);
        })
    };

    const deleteVitalCallBack = (res) => {
        AlertService.showSuccessMsg();
        handleSelectedVital({});
        let options = { pager, filter };
        getVitals(options);
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                VitalUOMs: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'Options/GetLookupOptions',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.VitalUOMs;
            for (let item of result) {
                if (item.id == -1) {
                    item.code = "-1";
                }
            }
            setVitalUOM(response.data.VitalUOMs);
        });
    };

    useEffect(() => {
        initLookup();
        let options = { pager: { pageNo: 1 }, filter: filter };
        getVitals(options);
    }, []);

    return (
        <div className="content-container vitallist-ui">
            <div className="title-font">
                <span>{pageTitle}</span>
                <span onClick={showVitalForm} className="pointer"><AddCircleOutlineIcon /></span>
            </div>
            <div className="px-3 ">
                <div className="row m-0 col-12 py-3 justify-content-between">
                    <div className="col-4">
                        <FxInput name="codeorname" variant="outlined" label="Search Code / Name"
                            size="small"
                            value={filter.codeorname}
                            onValueChange={handleInputChange}
                        />
                    </div>
                    <div className="col-4">
                        <FxSelect name="vitalUOM" variant="outlined" label="Vital UOM"
                            options={vitalUOM}
                            selectedValue={filter.vitalUOM}
                            valueField="code"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                </div>
                {/* <div className="row col-12 mt-1">
                <div className="col-8"></div>
                <div className="col-4">
                    <span className="float-right pt-2 pr-2">
                        <Button variant="contained" size="small" color="primary" onClick={showVitalForm}>Add</Button>
                    </span>
                </div>
            </div> */}
                <div className="row m-0 col-12">
                    <TableContainer component={Paper}>
                        <Table stickyHeader className="table table-striped">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-head table-font">Code</TableCell>
                                    <TableCell className="table-head table-font">Name</TableCell>
                                    <TableCell className="table-head table-font">Description</TableCell>
                                    <TableCell className="table-head table-font">UOM</TableCell>
                                    <TableCell className="table-head table-font">Is Default</TableCell>
                                    <TableCell className="table-head table-font">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    vitals.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <div>
                                                    <span className="table-font">{row.vitalCode}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <span className="table-font">{row.vitalName}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <span className="table-font">{row.description}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <span className="table-font">{row.vitalUOM}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <span className="table-font">{row.isDefault ? "Yes" : "No"}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <span className="table-font">
                                                    <EditIcon
                                                        color="primary"
                                                        className='pointer'
                                                        onClick={() => showVitalForm(row)} />
                                                </span>
                                                <span className="table-font ml-3">
                                                    <DeleteOutlineIcon
                                                        color="primary"
                                                        className='pointer'
                                                        onClick={() => openDeleteConfirmDialog(row)} />
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="row m-0 col-12 justify-content-between">
                    <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                        padding={1}
                        paddingRight={1}
                    >
                        <Pagination
                            page={pager.pageNo}
                            count={pager.pageCount}
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                            onChange={pageChanged}
                        />
                    </Box>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={closeDeleteConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleClose={closeDeleteConfirmDialog} onConfirmCallback={confirmMessage} />
            </Dialog>
        </div>
    )
};

export default VitalList;
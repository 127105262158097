import React, { useEffect, useState } from 'react';
import './ProviderList.scss';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Link, Dialog, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import IconPhone from '@material-ui/icons/Phone';
import { useHistory } from "react-router-dom";
import { http, AlertService, FxInput, FxSelect, useNavigationService } from '../../fx-core';
import { ReasonDialog } from '../ReasonDialog/ReasonDialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const ProviderList = () => {
    const history = useHistory();
    const { pageTitle } = useNavigationService();

    let defaultFilter: any = {
        registrationStatusId: 1,
        firstName: ''
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [proveiders, setProviders] = useState([]);
    const [approveOpen, setApproveOpen] = useState(false);
    const [rejectOpen, setrejectOpen] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState({ id: 0 });
    const [registrationStatus, setRegistrationStatus] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getProviders({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getProviders(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getProviders = (options: any = {}) => {
        let startIndex = 0;
        let filters = {
            registrationStatusId: filter.registrationStatusId,
            name: filter.firstName
        };

        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            if (options.filter.registrationStatusId) {
                filters.registrationStatusId = options.filter.registrationStatusId;
            }
            if (options.filter.firstName) {
                filters.name = options.filter.firstName;
            }
        }

        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `provider/getadminproviderlist`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getProvidersCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getProvidersCallBack = (res, pagerOptions) => {
        const result = res.data;
        setProviders(result.items);
        computePagerData(result, pagerOptions);
    };

    const showProviderForm = (item) => {
        let stateObj: any = { id: 0 };
        if (item && item.id) {
            stateObj = { ...stateObj, ...item }
        }
        history.push({
            pathname: '/providerform',
            state: stateObj
        });
    };

    const handleSelectedProvider = (item) => {
        setSelectedProvider(item);
    };

    const openApproveReasonDialog = (item) => {
        handleSelectedProvider(item);
        setApproveOpen(true);
    };

    const closeApproveReasonDialog = () => {
        handleSelectedProvider({ id: 0 });
        setApproveOpen(false);
    }

    const updateApproveStatus = (reason) => {
        let inputData: any = {
            id: selectedProvider.id,
            registrationStatusId: 2, //Approve
            comments: reason
        };

        let apiOptions: any = {
            url: `provider/updatestatus`,
            data: inputData
        };
        http.put(apiOptions).then(res => {
            updateApproveStatusCallBack(res);
        });
    };

    const updateApproveStatusCallBack = (res) => {
        AlertService.showSuccessMsg();
        closeApproveReasonDialog();
        getProviders();
    };

    const openRejectReasonDialog = (item) => {
        handleSelectedProvider(item);
        setrejectOpen(true);
    };

    const closeRejectReasonDialog = () => {
        handleSelectedProvider({ id: 0 });
        setrejectOpen(false);
    };

    const updateRejectStatus = (reason) => {
        let inputData: any = {
            id: selectedProvider.id,
            registrationStatusId: 3, //Reject
            comments: reason
        };

        let apiOptions: any = {
            url: `provider/updatestatus`,
            data: inputData
        };
        http.put(apiOptions).then(res => {
            updateRejectStatusCallBack(res);
        });
    };

    const updateRejectStatusCallBack = (res) => {
        AlertService.showSuccessMsg();
        closeRejectReasonDialog();
        getProviders();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                RegistrationStatus: { default: false }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'Options/GetLookupOptions',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setRegistrationStatus(response.data.RegistrationStatus);
        });
    }

    const updateIsActive = (row) => {
        let apiOptions: any = {
            url: `provider/updateisactive/${row.id}`,
            data: {}
        };
        http.put(apiOptions).then(res => {
            updateIsActiveCallBack(res);
        });
    };

    const updateIsActiveCallBack = (res) => {
        AlertService.showSuccessMsg();
        let options = { filter: filter };
        getProviders(options);
    };

    const getExternalLink = (item) => {
        let inputData: any = {
            id: item.id
        };

        let apiOptions: any = {
            url: `provider/${inputData.id}/mailgetlink`,
            data: inputData
        };
        http.get(apiOptions).then(res => {
            AlertService.showSuccessMsg("Mailed link successfully");
        });
    }

    useEffect(() => {
        initLookup();
        let options = { pager: { pageNo: 1 }, filter: filter };
        getProviders(options);
    }, []);

    return (
        <div className="content-container providerlist-ui">
            <div className="title-font">
                <span>{pageTitle}</span>
                <span onClick={showProviderForm} className="pointer"><AddCircleOutlineIcon /></span>
            </div>
            <div className="container-fluid">
                <div className="row m-0 col-12 my-3 justify-content-between p-0">
                    <div className="col-4">
                        <FxInput name="firstName" variant="outlined" label="Search Name"
                            size="small"
                            value={filter.firstName}
                            onValueChange={handleInputChange}
                        />
                    </div>
                    <div className="col-4">
                        <FxSelect name="registrationStatusId" variant="outlined" label="Registration Status"
                            options={registrationStatus}
                            selectedValue={filter.registrationStatusId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                </div>
                {/* <div className="row col-12 mt-1">
                <div className="col-8"></div>
                <div className="col-4">
                    <span className="float-right pt-2 pr-2">
                        <Button variant="contained" size="small" color="primary" onClick={showProviderForm}>Add</Button>
                    </span>
                </div>
            </div> */}
                <div className="row m-0 col-12">
                    <Container maxWidth="xl" className='p-0 '>
                        <TableContainer component={Paper}>
                            <Table stickyHeader className="table table-striped">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-head">Name</TableCell>
                                        <TableCell width="5%" align="right" className="table-head">Experience</TableCell>
                                        <TableCell className="table-head">Mobile</TableCell>
                                        <TableCell className="table-head">Email</TableCell>
                                        <TableCell className="table-head">Reg. Status</TableCell>
                                        <TableCell width="30%" className="table-head">About</TableCell>
                                        <TableCell width="14%" className="table-head">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        proveiders.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <div>
                                                        <span>{row.prefix} {row.firstName}</span> &nbsp;
                                                        <span>{row.lastName}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className="">
                                                        {row.experience}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="mobileno-color">
                                                        <Link href="tel:{{row.mobile}}">
                                                            <IconPhone fontSize="small" />
                                                            <span>{row.mobile}</span>
                                                        </Link>
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <div> <span>{row.email}</span></div>
                                                </TableCell>
                                                <TableCell>
                                                    <div> <span>{row.registrationStatus}</span></div>
                                                </TableCell>
                                                <TableCell>
                                                    <div> <span>{row.about}</span></div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="btn-style">
                                                        <span title='Edit'>
                                                            <EditIcon className='pointer' color="primary" onClick={() => showProviderForm(row)} />
                                                            {/* <Button variant="contained" size="small" 
                                                                > Edit
                                                            </Button> */}
                                                        </span>
                                                        {
                                                            row.registrationStatusId == 1 &&
                                                            <span className=" ml-2" title='Approve'>
                                                                <ThumbUpIcon className='pointer' color="primary" onClick={() => openApproveReasonDialog(row)} />
                                                                {/* <Button variant="contained" size="small" color="primary"
                                                                    onClick={() => (row)}> Approve
                                                                </Button> */}
                                                            </span>
                                                        }
                                                        {
                                                            row.registrationStatusId == 2 &&
                                                            <span className=" ml-2" title='Reject'>
                                                                <ThumbDownIcon className='pointer' color="primary" onClick={() => openRejectReasonDialog(row)} />

                                                                {/* <Button variant="contained" size="small" color="primary"
                                                                    onClick={() => (row)}>Reject
                                                                </Button> */}
                                                            </span>
                                                        }
                                                        <span className=" ml-2" title={row.isActive ? "InActive" : "Active"}>
                                                            {!row.isActive &&
                                                                <DoneIcon className='pointer' color="primary" onClick={() => updateIsActive(row)} />
                                                            }
                                                            {row.isActive &&
                                                                <CloseIcon className='pointer' color="primary" onClick={() => updateIsActive(row)} />
                                                            }

                                                            {/* <Button variant="contained" size="small" color="primary"
                                                                onClick={() => (row)}> {row.isActive ? "InActive" : "Active"}
                                                            </Button> */}
                                                        </span>
                                                        <span className=" ml-2">
                                                            <Link className="pointer text-primary" onClick={() => getExternalLink(row)}>Get Link</Link>
                                                            {/* <Button variant="contained" size="small" color="primary"
                                                                onClick={}>
                                                            </Button> */}
                                                        </span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </div>
                <div className="container pt-2 row col-12 justify-content-between">
                    <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                        padding={1}
                        paddingRight={1}
                    >
                        <Pagination
                            page={pager.pageNo}
                            count={pager.pageCount}
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                            onChange={pageChanged}
                        />
                    </Box>
                </div>
            </div>
            <Dialog
                open={approveOpen}
                onClose={closeApproveReasonDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ReasonDialog handleReason={updateApproveStatus} closeReasonDialog={closeApproveReasonDialog} />
            </Dialog>
            <Dialog
                open={rejectOpen}
                onClose={closeRejectReasonDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ReasonDialog handleReason={updateRejectStatus} closeReasonDialog={closeApproveReasonDialog} />
            </Dialog>
        </div>
    )
};

export default ProviderList;
import React, { useState } from 'react';
import './ProviderChangePassword.scss';
import { FxInput, http, AlertService, FormValidator } from '../../fx-core';
import { Button } from '@material-ui/core';

const ProviderChangePassword = (props: any) => {
    const defaultItem = {
        newPassword: "",
        confirmPassword: ""
    };
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});

    const validationMap = {
        newPassword: ["required", "pwdvalid"],
        confirmPassword: ["required", "pwdvalid"]
    };

    const bulkValidate = () => {
        let valItems = [];
        for (var key in validationMap) {
            let valItem = { name: key, value: item[key], validations: validationMap[key] }
            valItems.push(valItem);
        }
        let validationResult = FormValidator.bulkValidate(valItems);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    };

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    };

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });

        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const changePassword = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `user/ChangePassword`,
                data: item
            };
            http.post(apiOptions).then(res => {
                changePasswordCallBack(res);
            });
        }
    };

    const changePasswordCallBack = (res) => {
        setItem(prevState => {
            return { ...prevState, ...defaultItem }
        });
        AlertService.showSuccessMsg("Password changed!");
    };

    return (
        <div>
            <div className="content-container1 card provider-chg-pwd">
                <div className="title-font">
                    <span>Changed Password</span>
                </div>
                <div className="row m-2 pl-3">
                    <div className="col-6 mt-2">
                        <FxInput name="newPassword" variant="outlined" label="New Password*" size="small"
                            value={item.newPassword} type="password"
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("newPassword", "required") &&
                            <div className="validation-msg">New Password is required</div>
                        }
                        {
                            hasError("newPassword", "pwdvalid") &&
                            <div className="validation-msg"> New Password should contain atleast one uppercase,one lowercase,one number and one special character(@$!%*?&) with minimum 8 characters</div>
                        }
                    </div>
                    <div className="col-6 mt-2">
                        <FxInput name="confirmPassword" variant="outlined" label="Confirm Password*" size="small"
                            value={item.confirmPassword} type="password"
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("confirmPassword", "required") &&
                            <div className="validation-msg">Confirm Password is required</div>
                        }
                        {
                            hasError("confirmPassword", "pwdvalid") &&
                            <div className="validation-msg"> Confirm Password should contain atleast one uppercase,one lowercase,one number and one special character(@$!%*?&) with minimum 8 characters</div>
                        }
                    </div>
                    <div className="col-12 text-right mt-2">
                        <span>
                            <Button variant="contained" size="small" color="primary" onClick={() => changePassword()}>Save</Button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderChangePassword;
import React, { useState, useEffect } from 'react';
import './ChangeParentForm.scss';
import { AlertService, http, FxInput, FxSelect } from '../../fx-core';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Link } from '@material-ui/core';

const ChangeParentForm = (props) => {

    const defaultItem = {
        roleId: props.roleId,
        controlId: props.controlId,
        display: "",
        moduleCode: props.moduleCode,
        sRef: "",
        parentControlId: 0,
        parentControlCode: null,
        controlTargetType: props.controlTargetType,
        iconRef: ""
    };
    const [item, setItem] = useState(defaultItem);
    const [error, setError] = useState(null);
    const [modules, setModules] = useState([]);
    const [parentScreens, setParentScreens] = useState([]);
    const [showText, setShowText] = useState(false);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    // const initLookup = () => {
    //     let inputData = {
    //         lookups: {
    //             Modules: { default: false }
    //         },
    //         filters: {}
    //     };
    //     let apiOptions = {
    //         url: 'Options/GetLookupOptions',
    //         data: inputData
    //     };
    //     http.post(apiOptions).then(response => {
    //         setModules(response.data.Modules);
    //         let defaultModuleCd;
    //         if (response.data.Modules.length > 0) {
    //             defaultModuleCd = response.data.Modules[0].code;
    //             handleItemChange({ moduleCode: response.data.Modules[0].code });
    //         }
    //         getParentScreens(defaultModuleCd);
    //     });
    // }

    const getParentScreens = () => {
        let inputData = {
            moduleCode: item.moduleCode,
            controlTargetType: item.controlTargetType
        }
        let apiOptions: any = {
            url: `control/parentcontrols`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            setParentScreens(res.data);
        })
    };

    const saveParentControl = () => {
        if (!item.parentControlId && !item.display && !item.iconRef) {
            setError("This field is required");
            return;
        }
        let inputData = {
            id: item.controlId,
            roleId: item.roleId,
            display: item.display,
            moduleCode: item.moduleCode,
            parentControlId: item.parentControlId,
            controlTargetType: item.controlTargetType,
            iconRef: item.iconRef,
            controlCode: item.display ? item.display.replace(" ", "").toLowerCase() : ""
        }
        let apiOptions: any = {
            url: `control/parentcontrol/save`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveParentControlCallBack(res);
        });
    };

    const saveParentControlCallBack = (res) => {
        AlertService.showSuccessMsg();
        props.handleChangeParentFormClose();
    };

    const handleClose = () => {
        props.handleChangeParentFormClose();
    }

    const addParent = () => {
        setShowText(true);
    }

    const openLink = () => {
        window.open("https://fontawesome.com/v5.15/icons?d=gallery&p=2", '_blank');
    }

    useEffect(() => {
        getParentScreens();
    }, []);

    const body = (
        <div className="changedisplayform-ui">
            <div className="row title-font mx-auto">
                <div className="col-10 pl-0">
                    <span>Change Parent Form</span>
                </div>
                <div className="col-2 float-right pointer" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </div>
            </div>
            {/* <div className="p-2">
                <FxSelect name="moduleCode" variant="outlined" label="Module"
                    options={modules} className="selectStyle"
                    selectedValue={item.moduleCode} valueField="code" displayField="text"
                    onValueChange={handleInputChange} style={{ minWidth: 305 }} />
            </div> */}

            <div>
                {!showText &&
                    <div className="row m-0 pt-3">
                        <div className="col-10">
                            <FxSelect name="parentControlId" variant="outlined" label="Parent Screen"
                                options={parentScreens} className="selectStyle"
                                selectedValue={item.parentControlId} valueField="id" displayField="display"
                                onValueChange={handleInputChange} />
                            {error && !item.parentControlId &&
                                <div className="error"> {error} </div>}
                        </div>
                        <div className="col-2 float-right pointer" onClick={addParent}>
                            <i className="fa-lg mt-2 fas fa-plus-circle"></i>
                        </div>

                    </div>
                }
                {showText &&
                    <div>
                        <div className="col-12">
                            <FxInput name="display" variant="outlined" label="Display" size="small"
                                value={item.display} className="txtstyle"
                                onValueChange={handleInputChange}
                            />
                            {error && !item.display &&
                                <div className="error"> {error} </div>}
                        </div>
                        <div className="col-12">
                            <FxInput name="iconRef" variant="outlined" label="Icon(fas fa-list)" size="small"
                                value={item.iconRef} className="txtstyle"
                                onValueChange={handleInputChange}
                            />
                            {error && !item.iconRef &&
                                <div className="error"> {error} </div>}
                        </div>
                        <div className="col-12">
                            <span className="pl-2">
                                <Link className="pointer" onClick={openLink}>Select icon from font-awesome</Link>
                            </span>
                        </div>
                    </div>
                }
            </div>

            <div className="text-right p-3 mt-2">
                <Button variant="contained" size="small" color="primary" onClick={saveParentControl}>Save</Button>
            </div>
        </div>
    );

    return (
        <div>
            {body}
        </div>
    );

}

export default ChangeParentForm;
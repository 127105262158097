import React, { useEffect, useState } from 'react';
import './DoctorNotesForm.scss';
import { FxTextarea, http, AlertService, FxDateDisplay } from '../../fx-core';
import { Button } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';
import NotesIcon from '@material-ui/icons/Notes';
import { useHistory } from "react-router-dom";
import HospitalIcon from '@material-ui/icons/LocalHospital';
import EditIcon from '@material-ui/icons/Edit';

const DoctorNotesForm = (props: any) => {
  const history = useHistory();

  let defaultForm = {
    doctorNotes: "",
    id: props.match && props.match.params.apptId ? parseInt(props.match.params.apptId) : 0
  };
  const [item, setItem] = useState(defaultForm);
  let defaultItem: any = {
    appointmentIdentifier: 0,
    appointmentDate: null,
    slotTime: null,
    customerName: null,
    customerId: 0,
    providerId: 0,
    clinicName: null
  };
  const strTitle = props.match && props.match.params ? props.match.params.title : "Doctor Notes Form";
  const [title, setTitle] = useState(strTitle);
  const [appointment, setAppointment] = useState(defaultItem);
  const [appointmentHistory, setAppointmentHistory] = useState([]);
  const [error, setError] = useState(null);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getAppointmentById = () => {
    let apiOptions: any = {
      url: `stores/service/appointment/${item.id}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      var splitTime = result.startTime.split(":");
      result.slotTime = splitTime[0].trim() + ":" + splitTime[1].trim();
      setAppointment(result);
      handleItemChange({ "doctorNotes": result.doctorNotes });
      let custId = 0;
      if (result.appointmentForId && result.appointmentForId == 3) {
        custId = result.memberCustomerId;
      }
      else {
        custId = result.customerId;
      }
      getAppointmentHistory(custId, result.providerId);
    })
  }

  const getAppointmentHistory = (custId, provId) => {
    let inputData = {
      customerId: custId,
      providerId: provId,
      id: item.id
    };
    let apiOptions: any = {
      url: `appointment/history`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      for (let appt of result) {
        var splitTime = appt.startTime.split(":");
        appt.slotTime = splitTime[0].trim() + ":" + splitTime[1].trim();
      }
      setAppointmentHistory(result);
    })
  }

  const saveDoctorNotes = () => {
    setError(null);
    if (!item.doctorNotes || (item.doctorNotes && item.doctorNotes.trim().length == 0)) {
      setError("This field is required");
      return;
    }
    let apiOptions: any = {
      url: 'appointment/updatedoctornotes',
      data: item
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      navigateBack();
    })
  }

  const navigateBack = () => {
    history.push({
      pathname: '/appointments', state: {}
    });
  }

  useEffect(() => {
    getAppointmentById();
  }, []);


  const body = (
    <div className="content-container">
      <div className="title-font">
        <span>{title}</span>
      </div>

      <div className="row m-0 px-3 pt-3">
        <div className="col-12 my-2 pb-2 d-flex align-items-center justify-content-center">
          <span className="col-4">
            <PersonIcon fontSize="small" color="action" />
            {appointment.customerName}
          </span>

          <span className="col-4">
            <HospitalIcon fontSize="small" color="action" />
            <span className='text-truncate'>{appointment.clinicName}</span>
          </span>

          <span className="col-4">
            <span className="float-right">
              <EventIcon fontSize="small" color="action" />
              <FxDateDisplay value={appointment.appointmentDate} />
              <span className="pl-1">
                <ScheduleIcon fontSize="small" color="action" />
                {appointment.slotTime}
              </span>
            </span>
          </span>
        </div>

        <div className="col-12">
          <FxTextarea name="doctorNotes" variant="outlined" label="Provider/Doctor Notes" size="small"
            value={item.doctorNotes} maxRows={5}
            onValueChange={handleInputChange} className="txtareastyle"
          />

          {error &&
            <div className="error"> {error} </div>}
        </div>

        <div className="col-12 py-2 text-right">
          <span>
            <Button variant="contained" size="small" onClick={navigateBack}>Back</Button>
          </span>
          <span className="col-3">
            <Button variant="contained" size="small" color="primary"
              onClick={saveDoctorNotes}>Save</Button>
          </span>
        </div>

        <div className='m-1 font-16'><b>Appointment History</b></div>
        {
          appointmentHistory.map((apptHistory) => (
            <div className="row m-0 pt-2 col-12 alignbox">
              <span className="col-6">
                #{apptHistory.appointmentIdentifier}
              </span>

              <span className="col-6">
                <span className="float-right">
                  <EventIcon fontSize="small" color="action" />
                  <FxDateDisplay value={apptHistory.appointmentDate} />
                  <span className="pl-1">
                    <ScheduleIcon fontSize="small" color="action" />
                    {apptHistory.slotTime}
                  </span>
                </span>
              </span>

              {apptHistory.doctorNotes &&
                <span className='col-12'>
                  <NotesIcon fontSize="small" color="action" />
                  {
                    apptHistory.doctorNotes.split("\n").map(function (item, idx) {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      )
                    })
                  }
                </span>
              }
            </div>
          ))
        }
        {appointmentHistory && appointmentHistory.length == 0 &&
          <div className="row col-12 p-3">
            <span className='pl-1'>No appointment history</span>
          </div>
        }

      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default DoctorNotesForm;
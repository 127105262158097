import React, { useEffect, useState } from 'react';
import './AppointmentQuestions.css';
import CloseIcon from '@material-ui/icons/Close';
import { http, FxDateTimeDisplay } from '../../fx-core';

const AppointmentQuestions = (props: any) => {
  const [questionList, setQuestionList] = useState([]);

  const getAppointmentQuestions = () => {
    let inputData = {
      id: props.apptId
    }
    let apiOptions: any = {
      url: `patientappointmentquestionanswer/appointment/${inputData.id}`
    };
    http.get(apiOptions).then(response => {
      setQuestionList(response.data);
    })
  }

  const handleClose = () => {
    props.handleQuestionsClose()
  }

  useEffect(() => {
    getAppointmentQuestions();
  }, []);


  const body = (
    <div className="documentContainer scroll-ui">
      <div className="custom-head">
        <div className="row title-font mx-auto">
          <div className="col-11 pl-2">
            <span>Appointment Questions</span>
          </div>
          <div className="col-1 text-right pr-0 pointer" onClick={handleClose}>
            <CloseIcon fontSize="small" color="secondary" />
          </div>
        </div>

        <div>
          {
            questionList && questionList.map((item) => (
              <div className="m-2 pt-4 row questionsgridbox">
                <div className="col-10">{item.questionDescription}</div>
                <div className="col-2 float-right">
                  {item.questionAnswer == "true" ? "Yes" : "No"}
                </div>
                <div className="col-12">
                  <span className="font-11">
                    <span className="mr-1">Answered on </span>
                    <FxDateTimeDisplay value={item.answerDate} /></span>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default AppointmentQuestions;
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import SaveIcon from '@material-ui/icons/Save';
import { Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone'
import './PhotoCapture.scss';
import { FxUploader, http } from '../../fx-core';

const PhotoCapture = (props: any) => {

    const [acceptType, setAcceptType] = React.useState('image/jpeg,image/jpg, image/png,image/svg+xml');
 
    const openCamera = () => {

    }
    const saveCameraContent = () => {

    }

    const onUploadImage = (files: any) => {
        console.log('Photo capture files=', files);
        let id = props.uploadConfig.id;
        if (files && files.length > 0) {
            const formData = new FormData()
            formData.append('file', files[0]);
            if (id > 0) {
                formData.append('id', id);
            }
            let apiOptions = { url: props.uploadConfig.fileUploadConfig.url, data: formData };
            http.post(apiOptions).then((response: any) => {
                console.log('Upload image success');
                props.handlePhotoCaptureClose(response.data);
            }).catch(error => {
                console.log('Upload error=', error);
            });
        }
    }

    const handleClose = () => {
        props.handlePhotoCaptureClose();
    }


    const body = (
        <div className="photocapture-style">
            <div className="row title-font mx-auto">
                <div className="col-10 pl-0">
                    <span>Photo Capture</span>
                </div>
                <div className="col-2 float-right pointer" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </div>
            </div>
            {/* <div className="row m-2">
                <div className="col-12">
                    Capture Image
                </div>
                <div className="col-12">
                    <span>
                        <Button variant="contained" size="small" color="primary" onClick={openCamera}>
                            Open <span className="ml-1"><CameraAltIcon /></span>
                        </Button>
                    </span>
                    <span className="float-right">
                        <Button variant="contained" size="small" color="primary" onClick={saveCameraContent}>
                            Save <span className="ml-1"><SaveIcon /></span>
                        </Button>
                    </span>
                </div>
            </div> */}


            <div className="row m-2">

                <div className="col-12 m-1">
                    Select Image
                </div>
                <div className="col-12">
                    <FxUploader onUpload={onUploadImage} acceptType={acceptType} />
                </div>
            </div>
        </div>
    );


    return (
        <div>
            {body}
        </div>
    );

}
export default PhotoCapture;

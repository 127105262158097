import React, { useEffect, useState } from 'react';
import './ProviderClinicForm.scss';
import { FxInput, http, AlertService, LocationSearchInput, FxTextarea, general } from '../../fx-core';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ProviderClinicForm = (props: any) => {
  let defaultItem: any = {
    clinicName: '',
    clinicDescription: '',
    address: '',
    lat: '',
    lng: '',
    price: '',
    zipCode: '',
    providerId: props.providerId,
    id: props.providerClinicId ? props.providerClinicId : 0
  };
  const [item, setItem] = useState(defaultItem);
  const [error, setError] = React.useState(null);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const addressSelected = (data) => {
    let inputData = { address: data.address, lat: data.lat, lng: data.lng };
    console.log(data);
    handleItemChange(inputData);
  };

  const onReceiveOtherDetails = (data) => {
    console.log(data);
    let inputData = { country: data.country, zipCode: data.pinCode };
    handleItemChange(inputData);
  }

  const saveProviderClinic = () => {
    setError(null);
    if (!item.clinicName || !item.address || !item.zipCode) {
      setError("This field is required");
      return;
    }
    item.price = item.price ? item.price : 0;
    let apiOptions: any = {
      url: 'providerclinic/save',
      data: item
    };
    http.post(apiOptions).then(response => {
      saveClinicCallBack(response);
    });
  }

  const saveClinicCallBack = (res) => {
    AlertService.showSuccessMsg();
    props.handleClinicFormClose();
  };

  const getProviderClinicById = () => {
    let apiOptions: any = {
      url: `providerclinic/clinic/${item.id}`
    };
    http.get(apiOptions).then(res => {
      setItem(res.data);
    });
  };

  const handleClose = () => {
    props.handleClinicFormClose();
  };

  useEffect(() => {
    if (props.providerClinicId > 0) {
      getProviderClinicById();
    }
  }, []);


  const body = (
    <div className="modalalign provider-clinic-form">
      <div className="title-font pl-4">
        <span>Provider Clinic Form</span>
        <span className="col-1 float-right pointer mr-3" onClick={handleClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </span>
      </div>
      <div className="row m-0 px-3 border-bottom pb-2 mb-3">
        <div className="col-10 mt-2">
          <FxInput name="clinicName" variant="outlined" label="Clinic name*" size="small"
            value={item.clinicName}
            onValueChange={handleInputChange}
          />
          {
            error && !item.clinicName &&
            <div className="error"> {error} </div>
          }
        </div>

        <div className="col-10 mt-2">

          {(item.id > 0 && item.address) && <LocationSearchInput address={item.address} name="address"
            locationSelected={addressSelected} onReceiveOtherDetails={onReceiveOtherDetails} placeholder="Address*" />}
          {(item.id == 0 || !item.address) && <LocationSearchInput address={item.address} name="address"
            locationSelected={addressSelected} onReceiveOtherDetails={onReceiveOtherDetails} placeholder="Address*" />}
          {
            error && !item.address &&
            <div className="error"> {error} </div>
          }
        </div>

        <div className="col-10 mt-2">
          <FxTextarea name="clinicDescription" variant="outlined" label="Description" size="small"
            value={item.clinicDescription} maxRows={5}
            onValueChange={handleInputChange}
          />
        </div>

        <div className="row m-0 col-10 p-0 mt-2">
          <div className="col-6">
            <FxInput name="price" variant="outlined" label="Fee" size="small"
              value={item.price} type="number"
              onValueChange={handleInputChange}
            />
          </div>
          <div className="col-6">
            <FxInput name="zipCode" variant="outlined" label="ZipCode*" size="small"
              value={item.zipCode} onValueChange={handleInputChange}
            />
            {
              error && !item.zipCode &&
              <div className="error"> {error} </div>
            }
          </div>
        </div>

        <div className="col-2 mt-2 text-right">
          <Button variant="contained" size="small" color="primary"
            onClick={saveProviderClinic}>Save</Button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderClinicForm;
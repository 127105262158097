import React, { useEffect, useState } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { parseISO } from 'date-fns';
import './FxDate.styles.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { localStorageService } from '../helpers/localStorageService';

export const FxDate = (props: any) => {
  let defaultVal: any = null;
  let defaultInputValue = "";
  const [dateVal, setDateVal] = useState(defaultVal);
  const [minDate, setMinDate] = useState(props.minDate);
  const preferences = useSelector((store: any) => store.preferences);

  const pickerFormatMap: any = {
    "DD/MM/YYYY": "dd/MM/yyyy",
    "MM/DD/YYYY": "MM/dd/yyyy"
  };
  const dateFormat: any = localStorageService.getItem('dateformat');
  console.log('dateFormat=', dateFormat)
  let pickerDateFormat = pickerFormatMap[dateFormat];
  if (!pickerDateFormat) {
    pickerDateFormat = "MM/dd/yyyy";
  }

  useEffect(() => {
    if (props.value) {
      setDateVal(props.value)
    } else {
      setDateVal(null)
    }
  }, [props.value]);

  if (props.visibility === false) {
    return <></>;
  }
  const { name, onValueChange, variant, endIcon, size = "small", fullWidth } = props;
  let inputProps = {};
  if (endIcon) {
    inputProps = {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton edge="end">
            <FontAwesomeIcon className="datefieldicon" icon={endIcon} />
          </IconButton>
        </InputAdornment>
      ),
    };
  }
  const onChange = (event: any) => {
    let value = event ? moment(event, [dateFormat]).format('YYYY-MM-DD') : "";
    if (value != 'Invalid date') {
      const data = { name: props.name, value };
      onValueChange(data);
    }
  };
  const openTo = props.openTo || "date";
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <DatePicker
        className={props.className}
        inputFormat={pickerDateFormat}
        label={props.label}
        value={dateVal ? parseISO(dateVal) : dateVal}
        onChange={onChange}
        minDate={minDate}
        openTo={openTo}
        renderInput={(params) => <TextField {...params} size={size} variant={variant == 'outlined' ? 'outlined' : 'standard'} />}
        views={["year", "month", "date"]}
      />
    </LocalizationProvider>
  );
};

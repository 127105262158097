import React, { useEffect, useState } from 'react';
import './ProviderCertificate.scss';
import { http, AlertService } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import { ImageViewer } from '../../general/ImageViewer/ImageViewer';
import { PdfViewer } from '../../general/PdfViewer/PdfViewer';
import ProviderCertificateForm from '../ProviderCertificateForm/ProviderCertificateForm';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const ProviderCertificate = (props: any) => {
  const initialList = [];
  const [providerCertificates, setProviderCertificates] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [docData, setDocData] = useState(null);
  const [docName, setDocName] = useState(null);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [certificateFormOpen, setCertificateFormOpen] = React.useState(false);

  const getProviderCertificates = () => {
    let inputData = {
      providerId: props.providerId
    }
    let apiOptions: any = {
      url: 'providercertificate/query', data: inputData
    };
    http.post(apiOptions).then(response => {
      setProviderCertificates(response.data);
    });
  }

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setMessage("Do you want to delete " + item.certificateName + "?");
    setOpen(true);
  };

  const confirmMessage = () => {
    setOpen(false);
    deleteCertificate(selectedItem);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const deleteCertificate = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `providercertificate/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderCertificates();
    })
  }

  const downloadDocument = (item) => {
    setDocName(item.certificateName);
    let apiOptions: any = {
      url: `providercertificate/download/${item.id}`,
      method: 'get',
      fileName: item.fileName,
      documentName: item.documentName,
      data: {},
    }
    if (isImage(item)) {
      apiOptions.callback = viewImage;
      http.view(apiOptions);
    } else if (isPdf(item)) {
      apiOptions.callback = viewPdf;
      http.view(apiOptions);
    }
    else {
      http.download(apiOptions);
    }
  }

  const isImage = (item) => {
    return (item.fileExtension == '.jpeg' || item.fileExtension == '.jpg' || item.fileExtension == '.png');
  }

  const isPdf = (item) => {
    return item.fileExtension == '.pdf';
  }

  const viewImage = (res: any) => {
    setDocData(res.base64data);
    setImageViewerOpen(true);
  }

  const viewPdf = (res: any) => {
    setDocData(res.base64data);
    setPdfViewerOpen(true);
  }

  const handleImageViewerClose = () => {
    setImageViewerOpen(false);
  }

  const handlePdfViewerClose = () => {
    setPdfViewerOpen(false);
  }

  const openCertificateForm = () => {
    setCertificateFormOpen(true);
  }

  const handleCertificateFormClose = () => {
    setCertificateFormOpen(false);
    getProviderCertificates();
  }


  useEffect(() => {
    getProviderCertificates();
  }, []);


  const body = (
    <div className="content-container1 card provcert-ui">
      <div className="title-font">
        <span>Certificates</span>
        <span onClick={openCertificateForm} className="float-right pointer"><AddCircleOutlineIcon /></span>
      </div>

      <div>
        {
          providerCertificates.map((item) => (
            <div className="row col-12 px-3 p-0 m-0 even border-bottom py-2">
              <div className="col-6">{item.certificateName}</div>
              <div className="col-4 text-center">
                <Button variant="contained" size="small" color="primary"
                  onClick={() => downloadDocument(item)}>View Document</Button>
              </div>
              <div className="col-2">
                <span onClick={() => confirmDelete(item)}>
                  <DeleteIcon color="primary" />
                </span>
              </div>
            </div>
          ))
        }
        {
          providerCertificates && providerCertificates.length == 0 &&
          <div className="mt-2 text-center">
            <span>No Certificates</span>
          </div>
        }
        <div className="row m-0 col-12 p-0 pl-3 pt-3">
          <span className='color-aaa'>(not displayed to patients; for internal verification only)</span>
        </div>
      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
        <Dialog
          maxWidth={false}
          open={imageViewerOpen}
          onClose={handleImageViewerClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ImageViewer handleClose={handleImageViewerClose} docData={docData} docName={docName} />
        </Dialog>
        <Dialog
          maxWidth={false}
          open={pdfViewerOpen}
          onClose={handlePdfViewerClose}
          aria-labelledby="alert-dialog-title1"
          aria-describedby="alert-dialog-description1">
          <PdfViewer handleClose={handlePdfViewerClose} docData={docData} docName={docName} />
        </Dialog>
        <Dialog
          open={certificateFormOpen}
          maxWidth="sm"
          onClose={handleCertificateFormClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ProviderCertificateForm handleCertificateFormClose={handleCertificateFormClose} providerId={props.providerId} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderCertificate;
import moment from 'moment';
import { Constants } from './constants';

export const formatService = {
    getCurrentDate: getCurrentDate,
    getCurrentTime: getCurrentTime,
    parseDate: parseDate,
    getCurrentDateForServer: getCurrentDateForServer,
    getDateStringForServer: getDateStringForServer,
    getNextDate: getNextDate,
    getFormattedTimeForServer: getFormattedTimeForServer,
    getConvertedTime: getConvertedTime,
    getConvertedDate: getConvertedDate,
    getTimeDisplay: getTimeDisplay,
    getCurrentDateTime: getCurrentDateTime,
    isBefore: isBefore,
    addDays: addDays,
    substractDays: substractDays,
    getConvertToDateFormat: getConvertToDateFormat,
    substractDaysInDisplayFormat: substractDaysInDisplayFormat,
    getPreviousDateByHours: getPreviousDateByHours
}

const predefinedMap: any = {
    currentDate: {},
    dayPlusSeven: { "addValue": 7, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" },
    dayPlusSix: { "addValue": 6, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" }
};

function parseDate(strInput: any, dateFormat= Constants.DateFormat) {
    var dateOptions = predefinedMap[strInput];
    if (dateOptions) {
        return getComputedDate(dateOptions, dateFormat);
    }

    var result = "";
    if (strInput) {
        result = moment(strInput, [Constants.ServerDateFormat, dateFormat]).format(dateFormat);
    }
    return result;
}

function getComputedDate(opts: any, dateFormat = Constants.DateFormat) {
    var result = moment();
    if (opts.addPart) {
        result = result.add(opts.addValue || 0, opts.addPart)
    }
    if (opts.startOrEnd) {
        result = opts.startOrEnd == 'start' ? result.startOf(opts.startOrEndPart) : result.endOf(opts.startOrEndPart);
    }
    if (opts.addDayValue) {
        result = result.day(opts.addDayValue)
    }
    return result.format(dateFormat);
}

function getCurrentDateForServer() {
    let result = moment().format(Constants.ServerDateFormat);
    return result;
}

function getDateStringForServer(inputDate: any, pickerFormat = Constants.DateFormat) {
    if(inputDate){
        let result = moment(inputDate, [Constants.ServerDateFormat, pickerFormat]).format(Constants.ServerDateFormat);
        return result;
    }
    return inputDate;
}

function getNextDate(inputDate: any) {
    let result = moment(inputDate).add(1, 'days').format(Constants.ServerDateFormat)
    return result;
}

function getCurrentDate(dateFormat=Constants.DateFormat) {
    let result = moment().format(dateFormat);
    return result;
}

function getCurrentTime() {
    let result = moment().format(Constants.ServerTimeFormat);
    return result;
}

function getConvertedTime(inputDate: any) {
    let result = moment(inputDate, ["hh:mm A"]).format(Constants.ServerTimeFormat);
    return result;
}

function getFormattedTimeForServer(inputDate: any) {
    let result = moment(inputDate, ["hh:mm A"]).format(Constants.ServerTimeFormat);
    return result;
}

function getConvertedDate(inputDate: any) {
    let result = moment(inputDate,[Constants.DateDisplayFormat, Constants.DateFormat,Constants.ServerDateFormat]).format(Constants.ServerDateFormat);
    return result;
}

function getTimeDisplay(inputDate: any) {
    let result = moment(inputDate,["hh:mm:ss"]).format(Constants.TimeFormat12Hour);
    return result;
}

function getCurrentDateTime() {
    var result = "";
    result = moment().format(Constants.DateTimeFormat);
    return result;
}

function isBefore(startDate:any, endDate:any) {
    return moment(endDate, [Constants.ServerDateFormat, Constants.DateFormat]).isBefore(moment(startDate, [Constants.ServerDateFormat, Constants.DateFormat]));
 }

 function addDays(inputDate:any,number:any) {
    var result = "";
    result = moment(inputDate).add(number, 'days').format(Constants.ServerDateFormat);
    return result;
 }

 function substractDays(inputDate:any,number:any) {
    var result = "";
    result = moment(inputDate).add(-number, 'days').format(Constants.ServerDateFormat);
    return result;
 }

 function getConvertToDateFormat(inputDate:any) {
    let result = moment(inputDate,[Constants.DateFormat]).format(Constants.DateFormat);
    return result;
}

function substractDaysInDisplayFormat(inputDate:any,number:any, dateFormat = Constants.DateFormat) {
    var result = "";
    result = moment(inputDate,[Constants.DateFormat]).add(-number, 'days').format(dateFormat);
    return result;
 }

 function getPreviousDateByHours(hours: any, inputDate: any) {
    var result = moment(inputDate).subtract(hours, 'hours').format(Constants.ServerDateFormat);
    return result;
}

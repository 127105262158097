import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { localStorageService } from '../helpers/localStorageService';

export const FxDateTimeDisplay = (props:any) => {
    const dateFormat: any = localStorageService.getItem('dateformat');

    const getDateTimeDisplay = () => {
        let getDate = props.value;
        if(getDate) {
            const dateTimeDisplay = moment.utc(getDate).local().format(`${dateFormat} HH:mm a`);
            return dateTimeDisplay;
        }
    }

    return(
        <span>
          {getDateTimeDisplay()}
        </span>       
    );
};
import React, { useState, useEffect } from 'react';
import { FxDate, FxInput, FxRadioGroup, AlertService, http, formatService } from '../fx-core';
import './PatientRegistration.css';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const PatientRegistration = (props) => {
    const labelStyle = {
        margin: 0,
        display: 'block'
    };
    const history = useHistory();
    const propsObj = props.location.state;
    const [customerId, setCustomerId] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const lookup = {
        gender: [
            {
                code: "male",
                text: "Male"
            },
            {
                code: "female",
                text: "Female"
            },
        ],
        race: [
            {
                code: "White",
                text: "White"
            },
            {
                code: "Black/African American",
                text: "Black/African American"
            },
            {
                code: "American Indian/Alaska Native",
                text: "American Indian/Alaska Native"
            },
            {
                code: "Asian",
                text: "Asian"
            },
            {
                code: "Native Hawaiian/Other Pacific Islander",
                text: "Native Hawaiian/Other Pacific Islander"
            },
            {
                code: "Other",
                text: "Other"
            },
            {
                code: "Patient DeclinedlUnknown",
                text: "Patient DeclinedlUnknown"
            },
        ],
        ethnicity: [
            {
                code: "Spanish/Hispanic Origin",
                text: "Spanish/Hispanic Origin"
            },
            {
                code: "Not of Hispanic Origin",
                text: "Not of Hispanic Origin"
            },
            {
                code: "Patient Declined/Unknown",
                text: "Patient Declined/Unknown"
            }
        ]
    };

    let defaultDemographics: any = {
        firstName: '',
        middleName: '',
        lastName: '',
        streetAddress: '',
        zipCode: '',
        city: '',
        homePhone: '',
        workPhone: '',
        cellPhone: '',
        pharmacyNameAddress: '',
        copayAmount: '',
        emailAddress: '',
        howManyInsurancePlans: '',
        sex: '',
        dateOfBirth: '',
        race: '',
        socialSecurity: '',
        primaryDoctor: '',
        ethnicity: '',
        primaryLanguage: '',
        secondaryLanguage: '',
        primaryCountry: '',
        secondaryCountry: ''
    };
    let defaultPrimaryInsurance: any = {
        primaryInsuranceCompanyName: '',
        primaryInsuranceCompanyAddress: '',
        primaryInsuranceCity: '',
        primaryInsuranceState: '',
        primaryInsuranceZip: '',
        primaryNameOfInsPolicyHolder: '',
        primaryInsuranceDateOfBirth: '',
        primaryInsuranceSex: '',
        primaryInsuranceSocialSecurity: '',
        primaryInsuredPolicy: '',
        primaryInsuredEmployer: '',
        primaryEmployerCityStateZip: '',
        primaryInsuranceGroup: '',
        primaryPatientInsurancePolicy: '',
        primaryEffecticeDateOfInsurance: '',
        primaryRelationshipToInsured: '',
        primaryIFOWRDOI: ''
    };
    let defaultSecondaryInsurance: any = {
        secondaryInsuranceCompanyName: '',
        secondaryInsuranceCompanyAddress: '',
        secondaryInsuranceCity: '',
        secondaryInsuranceState: '',
        secondaryInsuranceZip: '',
        secondaryNameOfInsPolicyHolder: '',
        secondaryInsuranceDateOfBirth: '',
        secondaryInsuranceSex: '',
        secondaryInsuranceSocialSecurity: '',
        secondaryInsuredPolicy: '',
        secondaryInsuredEmployer: '',
        secondaryEmployerCityStateZip: '',
        secondaryInsuranceGroup: '',
        secondaryPatientInsurancePolicy: '',
        secondaryEffecticeDateOfInsurance: '',
        secondaryRelationshipToInsured: '',
        secondaryIFOWRDOI: ''
    };
    const [demographics, setDemographics] = useState(defaultDemographics);
    const [primaryInsurance, setPrimaryInsurance] = useState(defaultPrimaryInsurance);
    const [secondaryInsurance, setSecondaryInsurance] = useState(defaultSecondaryInsurance);
    const [patientFormId, setPatientFormId] = useState(0);

    const handleInputDemographics = (item) => {
        handleDemographicsChange({ [item.name]: item.value });
    }

    const handleDemographicsChange = (data) => {
        setDemographics(prevState => {
            return { ...prevState, ...data }
        });
    };
    const handleInputPrimaryInsurance = (item) => {
        handlePrimaryInsuranceChange({ [item.name]: item.value });
    }

    const handlePrimaryInsuranceChange = (data) => {
        setPrimaryInsurance(prevState => {
            return { ...prevState, ...data }
        });
    };
    const handleInputSecInsurance = (item) => {
        handleSecInsuranceChange({ [item.name]: item.value });
    }

    const handleSecInsuranceChange = (data) => {
        setSecondaryInsurance(prevState => {
            return { ...prevState, ...data }
        });
    };

    const patientRegistration = () => {
        console.log(demographics);
    };

    // const printPatientRegistration = () => {
    //     history.push({
    //         pathname: '/printpatientregistration',
    //         state: { formModel: formModel, isLogin: true }
    //     });
    //     console.log(formModel);
    // };

    const navigateBack = () => {
        if (propsObj.isLogin) {
            history.push('/');
        }
        else {
            // history.push({
            //     pathname: '/registerlanding',
            //     state: { code: item.referralCode ? item.referralCode : null }
            // });
        }
    }

    const resolveCustomerId = (token) => {
        let apiOptions: any = {
            url: `customer/resolvecustomerid/${token}`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            setCustomerId(result.customerId);
            if (result.customerId) {
                resolveCustomerCallback(result.customerId);
            }
        });
    }

    const resolveCustomerCallback = (custId) => {
        getPatientForms(custId);
    }

    const getCustomerById = (id) => {
        let apiOptions: any = {
            url: `customer/${id}/portal`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            setDemographics({
                "firstName": result.firstName, "lastName": result.lastName,
                "emailAddress": result.email, "homePhone": result.mobile
            });
        });
    }

    const getPatientForms = (custId) => {
        let inputData = {
            formCode: "patientregistration",
            customerId: custId
        };
        let apiOptions: any = {
            url: `patientform/query`, data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            patientFormsCallBack(result, custId);
        });
    }

    const patientFormsCallBack = (res, custId) => {
        if (res.length > 0) {
            for (let item of res) {
                setPatientFormId(item.id);
                if (item.sectionKey == "Demographics") {
                    setDemographics(JSON.parse(item.sectionData));
                }
                else if (item.sectionKey == "PrimaryInsurance") {
                    setPrimaryInsurance(JSON.parse(item.sectionData));
                }
                else if (item.sectionKey == "SecondaryInsurance") {
                    setSecondaryInsurance(JSON.parse(item.sectionData));
                }
            }
        }
        else {
            getCustomerById(custId);
        }
    }

    const collectFormDetails = () => {
        let formDetails = [];
        let demographicData = {
            sectionKey: "Demographics",
            sectionData: JSON.stringify(demographics)
        };
        formDetails.push(demographicData);
        let primaryInsuranceData = {
            sectionKey: "PrimaryInsurance",
            sectionData: JSON.stringify(primaryInsurance)
        };
        formDetails.push(primaryInsuranceData);
        let secInsuranceData = {
            sectionKey: "SecondaryInsurance",
            sectionData: JSON.stringify(secondaryInsurance)
        };
        formDetails.push(secInsuranceData);
        return formDetails;
    }

    const saveDetails = () => {
        let patientFormList = collectFormDetails();
        let inputData = {
            formCode: "patientregistration",
            customerId: customerId,
            patientFormId: patientFormId,
            patientForms: patientFormList
        };
        let apiOptions: any = {
            url: `patientform/save`, data: inputData
        };
        http.post(apiOptions).then(response => {
            AlertService.showSuccessMsg();
        });
    };

    const submitDetails = () => {
        setIsClicked(true);
        let patientFormList = collectFormDetails();
        let inputData = {
            formCode: "patientregistration",
            customerId: customerId,
            patientForms: patientFormList,
            currentDate: formatService.getCurrentDateForServer()
        };
        let apiOptions: any = {
            url: `patientform/submit`, data: inputData
        };
        http.post(apiOptions).then(response => {
            saveCallBack();
        });
    };

    const saveCallBack = () => {
        setIsSubmitted(true);
        AlertService.showSuccessMsg();
        setIsClicked(false);
    }

    useEffect(() => {
        let token = props.match.params.tk ? props.match.params.tk : "";
        if (token) {
            resolveCustomerId(token);
        }
    }, []);
    return (
        <div className="w-90 m-y-2">
            <b className="font-20"><i>New healthcare mandates require all Patient Registration information fields be completed:</i></b>
            <div className="text-center bg-title-color">PATIENT REGISTRATION</div>
            {!isSubmitted ?
                <span>
                    <div className="outlined row m-0">
                        <div className="p-0 col-4 row m-0">
                            <div className="p-0 col-8">
                                <label>FIRST NAME:</label>
                                <FxInput name="firstName" variant="standard"
                                    fullWidth
                                    value={demographics.firstName}
                                    onValueChange={handleInputDemographics} />
                            </div>
                            <div className="p-0 col-4">
                                <label>MI:</label>
                                <FxInput name="middleName" variant="standard"
                                    fullWidth
                                    value={demographics.middleName}
                                    onValueChange={handleInputDemographics} />
                            </div>
                        </div>
                        <div className="p-0 col-8">
                            <label>LAST NAME:</label>
                            <FxInput name="lastName" variant="standard"
                                fullWidth
                                value={demographics.lastName}
                                onValueChange={handleInputDemographics} />
                        </div>

                        <div className="p-0 col-4">
                            <label>STREET ADDRESS:</label>
                            <FxInput name="streetAddress" variant="standard"
                                fullWidth
                                value={demographics.streetAddress}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-4">
                            <label>ZIP CODE:</label>
                            <FxInput name="zipCode" variant="standard"
                                fullWidth
                                value={demographics.zipCode}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-4">
                            <label>CITY:</label>
                            <FxInput name="city" variant="standard"
                                fullWidth
                                value={demographics.city}
                                onValueChange={handleInputDemographics} />
                        </div>

                        <div className="p-0 col-4">
                            <label>HOME PHONE:</label>
                            <FxInput name="homePhone" variant="standard"
                                fullWidth
                                value={demographics.homePhone}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-4">
                            <label>WORK PHONE:</label>
                            <FxInput name="workPhone" variant="standard"
                                fullWidth
                                value={demographics.workPhone}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-4">
                            <label>CELL PHONE:</label>
                            <FxInput name="cellPhone" variant="standard"
                                fullWidth
                                value={demographics.cellPhone}
                                onValueChange={handleInputDemographics} />
                        </div>

                        <div className="p-0 col-4">
                            <label>PHARMACY NAME & ADDRESS:</label>
                            <FxInput name="pharmacyNameAddress" variant="standard"
                                fullWidth
                                value={demographics.pharmacyNameAddress}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-4">
                            <label>COPAY AMOUNT:</label>
                            <FxInput name="copayAmount" variant="standard"
                                fullWidth
                                value={demographics.copayAmount}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-4">
                            <label>EMAIL ADDRESS:</label>
                            <FxInput name="emailAddress" variant="standard"
                                fullWidth
                                value={demographics.emailAddress}
                                onValueChange={handleInputDemographics} />
                        </div>

                        <div className="p-0 col-4">
                            <label>HOW MANY INSURANCE PLANS?:</label>
                            <FxInput name="howManyInsurancePlans" variant="standard"
                                fullWidth
                                value={demographics.howManyInsurancePlans}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-4 border-bottom-line">
                            <label style={labelStyle}>SEX:</label>
                            <FxRadioGroup
                                name="sex"
                                options={lookup.gender}
                                valueField="code"
                                displayField="text"
                                className="flex-row"
                                onValueChange={handleInputDemographics}
                                selectedValue={demographics.sex}
                                fieldsetClassName=" display-flex flex-row align-items-center justify-content-between"
                                radioClassName="flex-row"
                            />
                        </div>
                        <div className="p-0 col-4">
                            <label>DATE OF BIRTH:</label>
                            <FxDate name="dateOfBirth" variant="standard"
                                fullWidth className="m-0"
                                value={demographics.dateOfBirth} onValueChange={handleInputDemographics} />
                        </div>

                        <div className="p-0 col-12 border-bottom-line">
                            <label style={labelStyle}>RACE (check one):</label>
                            <FxRadioGroup
                                name="race"
                                options={lookup.race}
                                valueField="code"
                                displayField="text"
                                className="flex-row"
                                onValueChange={handleInputDemographics}
                                selectedValue={demographics.race}
                                fieldsetClassName=" display-flex flex-row align-items-center justify-content-between"
                                radioClassName="flex-row"
                            />
                        </div>

                        <div className="p-0 col-4">
                            <label>SOCIAL SECURITY #:</label>
                            <FxInput name="socialSecurity" variant="standard"
                                fullWidth
                                value={demographics.socialSecurity}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-8">
                            <label>PRIMARY DOCTOR:</label>
                            <FxInput name="primaryDoctor" variant="standard"
                                fullWidth
                                value={demographics.primaryDoctor}
                                onValueChange={handleInputDemographics} />
                        </div>
                        <div className="p-0 col-4">
                            <label style={labelStyle}>ETHNICITY:</label>
                            <FxRadioGroup
                                name="ethnicity"
                                options={lookup.ethnicity}
                                valueField="code"
                                displayField="text"
                                className=""
                                onValueChange={handleInputDemographics}
                                selectedValue={demographics.ethnicity}
                                fieldsetClassName=" display-flex flex-row align-items-center justify-content-between"
                            />
                        </div>
                        <div className="p-0 col-8 row m-0">
                            <div className="p-0 col-6">
                                <div className="p-0 col-12">
                                    <label>PRIMARY LANGUAGE:</label>
                                    <FxInput name="primaryLanguage" variant="standard"
                                        fullWidth
                                        value={demographics.primaryLanguage}
                                        onValueChange={handleInputDemographics} />
                                </div>
                                <div className="p-0 col-12">
                                    <label>SECONDARY LANGUAGE:</label>
                                    <FxInput name="secondaryLanguage" variant="standard"
                                        fullWidth
                                        value={demographics.secondaryLanguage}
                                        onValueChange={handleInputDemographics} />
                                </div>
                            </div>
                            <div className="p-0 col-6">
                                <div className="p-0 col-12">
                                    <label>COUNTRY:</label>
                                    <FxInput name="primaryCountry" variant="standard"
                                        fullWidth
                                        value={demographics.primaryCountry}
                                        onValueChange={handleInputDemographics} />
                                </div>
                                <div className="p-0 col-12">
                                    <label>COUNTRY:</label>
                                    <FxInput name="secondaryCountry" variant="standard"
                                        fullWidth
                                        value={demographics.secondaryCountry}
                                        onValueChange={handleInputDemographics} />
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="text-center bg-title-color">PRIMARY INSURANCE INFORMATION</div>
                    <div className="outlined row m-0">
                        <div className="p-0 col-12">
                            <label>PRIMARY INSURANCE COMPANY NAME:</label>
                            <FxInput name="primaryInsuranceCompanyName" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryInsuranceCompanyName}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>

                        <div className="p-0 col-4">
                            <label>INS. COMPANY ADDRESS:</label>
                            <FxInput name="insuranceCompanyAddress" variant="standard"
                                fullWidth
                                value={primaryInsurance.insuranceCompanyAddress}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>CITY:</label>
                            <FxInput name="primaryInsuranceCity" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryInsuranceCity}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                        <div className="p-0 col-4 row m-0">
                            <div className="p-0 col-6">
                                <label>STATE:</label>
                                <FxInput name="primaryInsuranceState" variant="standard"
                                    fullWidth
                                    value={primaryInsurance.primaryInsuranceState}
                                    onValueChange={handleInputPrimaryInsurance} />
                            </div>
                            <div className="p-0 col-6">
                                <label>ZIP:</label>
                                <FxInput name="primaryInsuranceZip" variant="standard"
                                    fullWidth
                                    value={primaryInsurance.primaryInsuranceZip}
                                    onValueChange={handleInputPrimaryInsurance} />
                            </div>
                        </div>
                        <div className="p-0 col-4">
                            <label>NAME OF INSURANCE POLICY HOLDER:</label>
                            <FxInput name="primaryNameOfInsPolicyHolder" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryNameOfInsPolicyHolder}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                        <div className="p-0 col-4 row m-0">
                            <div className="p-0 col-6">
                                <label>DATE OF BIRTH:</label>
                                <FxDate name="primaryInsuranceDateOfBirth" variant="standard"
                                    fullWidth className="m-0"
                                    value={primaryInsurance.primaryInsuranceDateOfBirth} onValueChange={handleInputPrimaryInsurance} />
                            </div>
                            <div className="p-0 col-6 border-bottom-line">
                                <label style={labelStyle}>SEX:</label>
                                <FxRadioGroup
                                    name="primaryInsuranceSex"
                                    options={lookup.gender}
                                    valueField="code"
                                    displayField="text"
                                    className="flex-row"
                                    onValueChange={handleInputPrimaryInsurance}
                                    selectedValue={primaryInsurance.primaryInsuranceSex}
                                    fieldsetClassName=" display-flex flex-row align-items-center justify-content-between"
                                    radioClassName="flex-row"
                                />
                            </div>
                        </div>
                        <div className="p-0 col-4">
                            <label>SOCIAL SECURITY #:</label>
                            <FxInput name="primaryInsuranceSocialSecurity" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryInsuranceSocialSecurity}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>

                        <div className="p-0 col-4">
                            <label>INSURED'S POLICY #:</label>
                            <FxInput name="primaryInsuredPolicy" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryInsuredPolicy}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>INSURED'S EMPLOYER:</label>
                            <FxInput name="primaryInsuredEmployer" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryInsuredEmployer}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>EMPLOYER CITY/STATE/ZIP:</label>
                            <FxInput name="primaryEmployerCityStateZip" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryEmployerCityStateZip}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>

                        <div className="p-0 col-4">
                            <label>INSURANCE GROUP #:</label>
                            <FxInput name="primaryInsuranceGroup" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryInsuranceGroup}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>PATIENT'S INSURANCE POLICY #:</label>
                            <FxInput name="primaryPatientInsurancePolicy" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryPatientInsurancePolicy}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>EFFECTIVE DATE OF INSURANCE:</label>
                            <FxInput name="primaryEffecticeDateOfInsurance" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryEffecticeDateOfInsurance}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>

                        <div className="p-0 col-4">
                            <label>RELATIONSHIP TO INSURED:</label>
                            <FxInput name="primaryRelationshipToInsured" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryRelationshipToInsured}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                        <div className="p-0 col-8">
                            <label>IF AUTO OR WORK RELATED,DATE OF INJURY:</label>
                            <FxInput name="primaryIFOWRDOI" variant="standard"
                                fullWidth
                                value={primaryInsurance.primaryIFOWRDOI}
                                onValueChange={handleInputPrimaryInsurance} />
                        </div>
                    </div>


                    <div className="text-center bg-title-color">SECONDARY INSURANCE INFORMATION</div>
                    <div className="outlined row m-0">
                        <div className="p-0 col-12">
                            <label>SECONDARY INSURANCE COMPANY NAME:</label>
                            <FxInput name="secondaryInsuranceCompanyName" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryInsuranceCompanyName}
                                onValueChange={handleInputSecInsurance} />
                        </div>

                        <div className="p-0 col-4">
                            <label>INS. COMPANY ADDRESS:</label>
                            <FxInput name="secondaryInsuranceCompanyAddress" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryInsuranceCompanyAddress}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>CITY:</label>
                            <FxInput name="secondaryInsuranceCity" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryInsuranceCity}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                        <div className="p-0 col-4 row m-0">
                            <div className="p-0 col-6">
                                <label>STATE:</label>
                                <FxInput name="secondaryInsuranceState" variant="standard"
                                    fullWidth
                                    value={secondaryInsurance.secondaryInsuranceState}
                                    onValueChange={handleInputSecInsurance} />
                            </div>
                            <div className="p-0 col-6">
                                <label>ZIP:</label>
                                <FxInput name="secondaryInsuranceZip" variant="standard"
                                    fullWidth
                                    value={secondaryInsurance.secondaryInsuranceZip}
                                    onValueChange={handleInputSecInsurance} />
                            </div>
                        </div>
                        <div className="p-0 col-4">
                            <label>NAME OF INSURANCE POLICY HOLDER:</label>
                            <FxInput name="secondaryNameOfInsPolicyHolder" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryNameOfInsPolicyHolder}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                        <div className="p-0 col-4 row m-0">
                            <div className="p-0 col-6">
                                <label>DATE OF BIRTH:</label>
                                <FxDate name="secondaryInsuranceDateOfBirth" variant="standard"
                                    fullWidth className="m-0"
                                    value={secondaryInsurance.secondaryInsuranceDateOfBirth} onValueChange={handleInputSecInsurance} />
                            </div>
                            <div className="p-0 col-6 border-bottom-line">
                                <label style={labelStyle}>SEX:</label>
                                <FxRadioGroup
                                    name="secondaryInsuranceSex"
                                    options={lookup.gender}
                                    valueField="code"
                                    displayField="text"
                                    className="flex-row"
                                    onValueChange={handleInputSecInsurance}
                                    selectedValue={secondaryInsurance.secondaryInsuranceSex}
                                    fieldsetClassName=" display-flex flex-row align-items-center justify-content-between"
                                    radioClassName="flex-row"
                                />
                            </div>
                        </div>
                        <div className="p-0 col-4">
                            <label>SOCIAL SECURITY #:</label>
                            <FxInput name="secondaryInsuranceSocialSecurity" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryInsuranceSocialSecurity}
                                onValueChange={handleInputSecInsurance} />
                        </div>

                        <div className="p-0 col-4">
                            <label>INSURED'S POLICY #:</label>
                            <FxInput name="secondaryInsuredPolicy" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryInsuredPolicy}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>INSURED'S EMPLOYER:</label>
                            <FxInput name="secondaryInsuredEmployer" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryInsuredEmployer}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>EMPLOYER CITY/STATE/ZIP:</label>
                            <FxInput name="secondaryEmployerCityStateZip" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryEmployerCityStateZip}
                                onValueChange={handleInputSecInsurance} />
                        </div>

                        <div className="p-0 col-4">
                            <label>INSURANCE GROUP #:</label>
                            <FxInput name="secondaryInsuranceGroup" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryInsuranceGroup}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>PATIENT'S INSURANCE POLICY #:</label>
                            <FxInput name="secondaryPatientInsurancePolicy" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryPatientInsurancePolicy}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                        <div className="p-0 col-4">
                            <label>EFFECTIVE DATE OF INSURANCE:</label>
                            <FxInput name="secondaryEffecticeDateOfInsurance" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryEffecticeDateOfInsurance}
                                onValueChange={handleInputSecInsurance} />
                        </div>

                        <div className="p-0 col-4">
                            <label>RELATIONSHIP TO INSURED:</label>
                            <FxInput name="secondaryRelationshipToInsured" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryRelationshipToInsured}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                        <div className="p-0 col-8">
                            <label>IF AUTO OR WORK RELATED,DATE OF INJURY:</label>
                            <FxInput name="secondaryIFOWRDOI" variant="standard"
                                fullWidth
                                value={secondaryInsurance.secondaryIFOWRDOI}
                                onValueChange={handleInputSecInsurance} />
                        </div>
                    </div>

                    <div className="text-right mt-2 mb-5">
                        <span className="pr-2">
                            <Button variant="contained" size="medium" color="primary" onClick={saveDetails}>Save Draft</Button>
                        </span>
                        <span className="pr-2">
                            {/* <Button variant="contained" size="medium" color="primary" onClick={printPatientRegistration}>Print</Button> */}
                        </span>
                        <span className="pr-2">
                            {!isClicked &&
                                <Button variant="contained" size="medium" color="primary" onClick={submitDetails}>Submit</Button>

                            }
                            {isClicked &&
                                <Button variant="contained" size="medium" color="primary" disabled>Submit</Button>

                            }
                        </span>
                        {/* <Button variant="contained" size="medium" onClick={navigateBack}>Cancel</Button> */}
                    </div>
                </span>
                :
                <div className="m-center">
                    Thank you. Your patient registration form submitted. You can share/download the form using MYMEDIFAM mobile app.
                </div>
            }

        </div>
    )
};

export default PatientRegistration;
import React, { useEffect, useState } from 'react';
import './Appointments.scss';
import { FxInput, FxDate, FxSelect, FxCurrency, http, AlertService, FxDateDisplay, useNavigationService, session, formatService } from '../../fx-core';
import { Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import Dialog from '@material-ui/core/Dialog';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DescriptionIcon from '@material-ui/icons/Description';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import moment from 'moment';
import AppointmentDocuments from '../AppointmentDocuments/AppointmentDocuments';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import QuestionIcon from '@material-ui/icons/HelpOutline';
import AppointmentQuestions from '../AppointmentQuestions/AppointmentQuestions';
import AppointmentQuestionForm from '../AppointmentQuestionForm/AppointmentQuestionForm';
import ScanIcon from '@material-ui/icons/Scanner';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import { useHistory } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import DocumentForm from '../DocumentForm/DocumentForm';
import EventIcon from '@material-ui/icons/Event';

const Appointments = (props: any) => {
  const { pageTitle } = useNavigationService();
  const history = useHistory();

  let defaultItem: any = {
    fromDate: null,
    toDate: null,
    appointmentStatusId: -1,
    customerName: '',
    dateOfBirth: null,
    comments: ''
  };
  const [item, setItem] = useState(defaultItem);
  const initialList = [];
  const [appointmentStatus, setAppointmentStatus] = useState(initialList);
  const [appointmentList, setAppointmentList] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [docDialogOpen, setDocDialogOpen] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState(defaultItem);
  const [valError, setValError] = useState(null);
  const [comments, setComments] = useState(null);
  const [appointmentId, setAppointmentId] = React.useState(0);
  const [questionFormOpen, setQuestionFormOpen] = React.useState(false);
  const [questionDialogOpen, setQuestionDialogOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(0);
  const [scanList, setScanList] = useState(initialList);
  const [documentFormOpen, setDocumentFormOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notes, setNotes] = React.useState(null);

  const popOverNotes = (event, item) => {
    setNotes(item.scanNotes);
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    let options = { item: { [item.name]: item.value } };
    getProviderAppointments(options);
  }

  const handleSearchInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };


  const initLookup = () => {
    let inputData = {
      lookups: {
        AppointmentStatus: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setAppointmentStatus(response.data.AppointmentStatus);
    });
  }

  const getProviderAppointments = (options: any = {}) => {

    let selectedStatusId = item.appointmentStatusId;
    let selectedFromDate: any = item.fromDate;
    let selectedToDate: any = item.toDate;
    let selectedName: any = item.customerName;
    let selectedDob: any = item.dateOfBirth;
    if (options && options.item) {
      selectedStatusId = options.item.appointmentStatusId ? options.item.appointmentStatusId : item.appointmentStatusId;
      selectedFromDate = options.item.fromDate || options.item.fromDate == "" ? options.item.fromDate : item.fromDate;
      selectedToDate = options.item.toDate || options.item.toDate == "" ? options.item.toDate : item.toDate;
      //selectedName = options.item.customerName;
      selectedDob = options.item.dateOfBirth || options.item.dateOfBirth == "" ? options.item.dateOfBirth : item.dateOfBirth;
    }
    setValError(null);
    if (moment(selectedToDate).isBefore(selectedFromDate)) {
      setValError("To date should be greater than or equal to From date");
      return;
    }
    let filters = {
      providerId: session.getItem('providerId'),
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      appointmentStatusId: selectedStatusId,
      customerName: selectedName,
      customerDateOfBirth: selectedDob
    }
    let inputData = {
      filters: filters
    }
    let apiOptions: any = {
      url: 'provider/appointments', data: inputData
    };
    http.post(apiOptions).then(response => {
      for (let item of response.data) {
        var splitTime = item.startTime.split(":");
        item.slotTime = splitTime[0].trim() + ":" + splitTime[1].trim();
        item.showScanDetails = false;
      }
      setAppointmentList(response.data);
    });
  }

  const confirmCancel = (item: any) => {
    let cancelBeforeDate = formatService.getPreviousDateByHours(item.cancellationHours, item.appointmentDate);
    setMessage("Do you want to cancel the appointment #" + item.appointmentIdentifier + "?");
    setSelectedItem(item);
    setAction('cancel');
    setOpen(true);
  };

  const confirmCheckedIn = (item: any) => {
    setSelectedItem(item);
    setAction('checkin');
    setMessage("Do you want to check in the appointment #" + item.appointmentIdentifier + "?");
    setOpen(true);
  };

  const confirmNoShow = (item: any) => {
    setSelectedItem(item);
    setAction('noshow');
    setMessage("Do you want to update as no show appointment #" + item.appointmentIdentifier + "?");
    setOpen(true);
  };

  const confirmPatientArrived = (item: any) => {
    setAppointmentId(item.id);
    setAction('arrive');
    setMessage("Do you want to update as patient arrived appointment #" + item.appointmentIdentifier + "?");
    setOpen(true);
  };

  const confirmCompleted = (item: any) => {
    setSelectedItem(item);
    if (item.paymentMode === 'cod') {
      setAction('completeandpaid');
    }
    else {
      setAction('complete');
    }
    setMessage("Do you want to complete the appointment #" + item.appointmentIdentifier + "?");
    setOpen(true);
  };

  const confirmMessage = (action: any, comments: any) => {
    setOpen(false);

    if (action == 'cancel') {
      selectedItem.comments = comments;
      cancelAppointment(selectedItem);
    }
    else if (action == 'checkin') {
      checkInAppointment(selectedItem);
    }
    else if (action == 'noshow') {
      noShowAppointment(selectedItem);
    }
    else if (action == 'complete') {
      completeAppointment(selectedItem);
    }
    else if (action === 'completeandpaid') {
      completeAppointmentAndPaid(selectedItem);
    }
    else if (action === 'arrive') {
      userArrivedAppointment();
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const cancelAppointment = (item: any) => {
    let inputData = {
      id: item.id,
      comments: item.comments,
      storeServiceSlotId: item.storeServiceSlotId,
      customerId: item.customerId
    }
    let apiOptions: any = {
      url: 'provider/appointment/cancel', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderAppointments({});
    })
  }

  const userArrivedAppointment = () => {
    let inputData = {
      id: appointmentId
    }
    let apiOptions: any = {
      url: 'provider/appointment/arrived', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderAppointments({});
    })
  }

  const checkInAppointment = (item: any) => {
    let inputData = {
      id: item.id
    }
    let apiOptions: any = {
      url: 'provider/appointment/checkin', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderAppointments({});
    })
  }

  const noShowAppointment = (item: any) => {
    let inputData = {
      id: item.id
    }
    let apiOptions: any = {
      url: 'provider/appointment/noshow', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderAppointments({});
    })
  }

  const completeAppointment = (item: any) => {
    let inputData = {
      id: item.id
    }
    let apiOptions: any = {
      url: 'provider/appointment/complete', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderAppointments({});
    })
  }

  const completeAppointmentAndPaid = (item: any) => {
    let inputData = {
      id: item.id
    }
    let apiOptions: any = {
      url: 'provider/appointment/completeandpaid',
      data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderAppointments({});
    })
  }

  const getDocumentsByApptId = (item: any) => {
    setAppointmentId(item.id);
    setDocDialogOpen(true);
  }

  const handleDocDialogClose = () => {
    setDocDialogOpen(false);
  }

  const handleQuestionFormClose = () => {
    setQuestionFormOpen(false);
    let options = { item: item };
    getProviderAppointments(options);
  }

  const getQuestionsByApptId = (item: any) => {
    setAppointmentId(item.id);
    setCustomerId(item.customerId);
    setQuestionDialogOpen(true);
  }

  const handleQuestionsClose = () => {
    setQuestionDialogOpen(false);
  }

  const getScansByApptId = (item: any) => {
    item.showScanDetails = !item.showScanDetails;
    setAppointmentId(item.id);
    getScanListByApptId(item.id)
  }

  const getScanListByApptId = (id: any) => {
    let inputData = {
      id: id
    }
    let apiOptions: any = {
      url: `appointmentscan/appointment/detail/${inputData.id}`,
      data: inputData
    };
    http.get(apiOptions).then(response => {
      setScanList(response.data);
    })
  }

  const openViewer = (detail, scan, item, studyName) => {
    const title = "#" + item.appointmentIdentifier + " - " + item.customerName + " - " + `${scan.scanName} - ${studyName}`;
    history.push({
      pathname: `/dicomviewer/${detail.studyId}/${title}/2`,
    });
  }

  const openScanForm = (item) => {
    const scanId = 0;
    const title = "#" + item.appointmentIdentifier + " - " + item.customerName + " - " + "Scan Form";
    history.push({
      pathname: `/scanform/${scanId}/${title}/2/${item.id}/${item.customerId}`,
    });
  }

  const openDocumentForm = (item) => {
    setAppointmentId(item.id);
    if (item.appointmentForId && item.appointmentForId == 3) {
      setCustomerId(item.memberCustomerId);
    }
    else {
      setCustomerId(item.customerId);
    }
    setDocumentFormOpen(true);
  }

  const handleDocumentFormClose = () => {
    setDocumentFormOpen(false);
    let options = { item: item };
    getProviderAppointments(options);
  }

  const searchProviders = () => {
    let options = { item: item };
    getProviderAppointments(options);
  }

  const openNotesForm = (item) => {
    const title = "#" + item.appointmentIdentifier + " - " + item.customerName + " - " + "Doctor Notes Form";
    history.push({
      pathname: `/doctornotesform/${item.id}/${title}`,
    });
  }

  useEffect(() => {
    initLookup();
    let options = { item: item };
    getProviderAppointments(options);
  }, []);

  const getAppointmentStatusColor = (status) => {
    if (status == 'Booked') { return 'primary'; }
    if (status == 'PatientArrived') { return 'info'; }
    if (status == 'Completed') { return 'success'; }
    if (status == 'Cancelled') { return 'danger'; }
  }

  const body = (
    <div className="content-container appointments">
      <div className="title-font">
        <span>{pageTitle}</span>
      </div>
      <div className="container-fluid">
        <div className="row col-12 my-4 appointment-filter m-0 p-0">
          <div className="col-2">
            <FxDate name="fromDate" label="From Date" variant="outlined"
              value={item.fromDate} onValueChange={handleInputChange} />
          </div>

          <div className="col-2">
            <FxDate name="toDate" label="End Date" variant="outlined"
              value={item.toDate} onValueChange={handleInputChange} />
          </div>
          {valError &&
            <div className="error"> {valError} </div>}

          <div className="col-2">
            <FxDate name="dateOfBirth" label="Date of birth" variant="outlined"
              value={item.dateOfBirth} onValueChange={handleInputChange} />
          </div>

          <div className="col-2">
            <FxSelect name="appointmentStatusId" variant="outlined" label="Appointment Status"
              options={appointmentStatus}
              selectedValue={item.appointmentStatusId}
              valueField="id"
              displayField="text"
              onValueChange={handleInputChange} />
          </div>

          <div className="col-3">
            <FxInput name="customerName" label="First name/Last name/Email" placeholder="First name/Last name/Email" variant="outlined" size="small"
              value={item.customerName} onValueChange={handleSearchInputChange} />
          </div>
          <div className="col-1">
            <Button variant="contained" size="small" color="primary" onClick={searchProviders}>Search</Button>
          </div>
        </div>
        {
          appointmentList.map((item) => (
            <div className="col-12 mymedifam-gridbox">
              <div className="text-center">
                #{item.appointmentIdentifier}
              </div>
              <div className="row m-0">

                <div className="col-3">
                  <div className='d-flex'>
                    <PersonIcon className='mr-2' fontSize="small" color="action" />
                    <div className='text-truncate text-bold text-capitalize'>{item.customerName}</div>
                  </div>
                  {item.customerEmail &&
                    <div className='d-flex'>
                      <MailIcon className='mr-2' fontSize="small" color="action" />
                      <div className='text-truncate'>{item.customerEmail}</div>
                    </div>
                  }
                  {item.customerMobile &&
                    <span>
                      <a href="tel:{{item.customerMobile}}">
                        <PhoneIcon className='mr-2' fontSize="small" color="action" />
                        {item.customerMobile}
                      </a></span>
                  }
                  {item.customerDateOfBirth &&
                    <div> DOB: <FxDateDisplay value={item.customerDateOfBirth} /></div>
                  }
                </div>

                <div className="col-3">
                  <div className='appt-clinicName' title={item.clinicName}><HospitalIcon fontSize="small" color="action" />
                    <div className='text-truncate'>{item.clinicName}</div>
                  </div>
                  <span className='mr-2'>
                    <EventIcon fontSize="small" color="action" /> <FxDateDisplay value={item.appointmentDate} />
                  </span>
                  <ScheduleIcon fontSize="small" color="action" /> {item.slotTime}
                  <div>
                    <span className={'mr-2 text-bold font-12 text-' + (getAppointmentStatusColor(item.appointmentStatus))}> {item.appointmentStatus}</span>
                    {item.price > 0 &&
                      <FxCurrency value={item.price} />
                    }
                  </div>
                </div>

                <div className="col-1">
                  {item.documentCount > 0 &&
                    <StyledBadge className="pointer" color="secondary" badgeContent={item.documentCount} onClick={() => getDocumentsByApptId(item)}>
                      <DescriptionIcon className="font-20" color="action" />
                    </StyledBadge >
                  }
                  {item.scanCount > 0 &&
                    <div className='mt-1'>
                      <StyledBadge className="pointer" color="secondary" onClick={() => getScansByApptId(item)} badgeContent={item.scanCount}>
                        <ScanIcon className="font-20" color="action" />
                      </StyledBadge >
                    </div>
                  }
                </div>

                <div className="col-2 appointment-actions">
                  <span>
                    {item.appointmentForId != 3 &&
                      <a className='pointer' onClick={() => openScanForm(item)}>Upload Scan</a>
                    }
                  </span>
                  <span><a className='pointer' onClick={() => openDocumentForm(item)}>Upload Document</a></span>
                  <span><a className='pointer' onClick={() => openNotesForm(item)}>Notes</a></span>
                </div>

                {
                  item.appointmentStatusId === 1 &&
                  <div className="col-2 appointment-actions">
                    <span><a className='pointer' onClick={() => confirmPatientArrived(item)}>User Arrived</a></span>
                    <span><a className='pointer' onClick={() => confirmNoShow(item)}>No Show</a></span>
                    <a className='pointer text-danger' onClick={() => confirmCancel(item)}>Cancel</a>
                  </div>
                }
                <div className="col-2 appointment-actions">
                  {
                    item.appointmentStatusId === 6 &&
                    <span>
                      <a className='pointer' onClick={() => confirmCheckedIn(item)}>Check In</a>
                    </span>
                  }
                  {
                    item.appointmentStatusId === 3 && item.paymentMode !== 'cod' &&
                    <span><a className='pointer' onClick={() => confirmCompleted(item)}>Complete</a></span>
                  }
                  {
                    item.appointmentStatusId === 3 && item.paymentMode === 'cod' &&
                    <span><a className='pointer' onClick={() => confirmCompleted(item)}>{'Complete & Paid'}</a></span>
                  }
                </div>

                {item.notes &&
                  <div className="col-6"> Notes:
                    <span> {item.notes}</span>
                  </div>
                }

                {
                  item.comments &&
                  <div className="col-12 bg-highlight">
                    Cancelled Reason:
                    <span> {item.comments}</span>
                  </div>
                }

                {item.id == appointmentId && item.showScanDetails &&
                  scanList.map((row) => (
                    <div className="col-12 row m-0 p-0 bg-highlight">
                      <span className="col-12">{row.scan.scanName}
                        <span>
                          {row.scan.scanNotes &&
                            <span>
                              <span className="pointer" onClick={(ev) => popOverNotes(ev, row.scan)}> <InfoIcon /></span>
                              <Popover
                                id={id}
                                open={popoverOpen}
                                anchorEl={anchorEl}
                                onClose={closePopover}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <span className="pl-2 pr-2">{notes}</span>
                              </Popover>
                            </span>
                          }
                        </span>
                      </span>
                      {
                        row.scanDetails.map((detail, i) => (
                          <div className="col-12">
                            <span className="pl-5">
                              - Study{i + 1}
                              <span className="pl-1 pointer" onClick={() => openViewer(detail, row.scan, item, `Study${i + 1}`)}>
                                <EyeIcon />
                              </span>
                            </span>
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }

        <div>
          <Dialog
            open={open}
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <ConfirmDialog message={message} action={action} handleClose={handleClose} onConfirmCallback={confirmMessage} />
          </Dialog>
        </div>
        <div>
          <Dialog
            open={docDialogOpen}
            maxWidth="sm"
            onClose={handleDocDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <AppointmentDocuments handleDocDialogClose={handleDocDialogClose} apptId={appointmentId} />
          </Dialog>
          <Dialog
            open={questionFormOpen}
            maxWidth="sm"
            onClose={handleDocDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <AppointmentQuestionForm handleQuestionFormClose={handleQuestionFormClose} apptId={appointmentId} custId={customerId} />
          </Dialog>
          <Dialog
            open={documentFormOpen}
            maxWidth="sm"
            onClose={handleDocumentFormClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DocumentForm handleDocumentFormClose={handleDocumentFormClose} apptId={appointmentId} custId={customerId} />
          </Dialog>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default Appointments;
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import './DicomScans.css';
import DicomUploader from '../dicomuploader/DicomUploader';
import { Button } from '@material-ui/core';
import DicomViewer from '../dicomviewer/DicomViewer';

const DicomScans = () => {
  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);

  const openUploader = () => {
    setOpen(true);
  }
  const closeUploader = (result:any) => {
    console.log('uploaded files =', result);
    setOpen(false);
  }

  const openViewer = () => {
    setOpenView(true);
  }
  const closeViewer = (result:any) => {
    setOpenView(false);
  }

  return (
    <>
      <div className="content-container">
          <div className="title-font">
              <span>Dicom Uploader</span>
          </div>
          <div>
          <Button className="ml-2 mt-2" variant="contained" size="small" color="primary" 
            onClick={() => openUploader()}>Upload Dicom Files</Button>

          <Button className="ml-2 mt-2" variant="contained" size="small" color="primary" 
            onClick={() => openViewer()}>View Dicom Files</Button>
        </div>     
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
              <DicomUploader onClose={closeUploader}/>
        </Dialog>

        <Dialog
          open={openView}
          maxWidth="md"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
              <DicomViewer onClose={closeViewer}/>
        </Dialog>
     </div>
     </>
  );
}

export default DicomScans;
import React, { useState, useEffect } from 'react';
import './DashboardCardForm.scss';
import { AlertService, http, FxInput, FxTextarea, FxSelect, general } from '../../fx-core';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog } from '@material-ui/core';
import dummyImage from "./../../../assets/images/dummy.png";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';

const DashboardCardForm = (props) => {

    const defaultItem = {
        id: props.cardId ? props.cardId : 0,
        title: "",
        description: "",
        controlId: 0,
        imageUrl: "",
        imagePath:"",
        targetTitle: "",
        displayOrder:0
    };
    const [item, setItem] = useState(defaultItem);
    const [error, setError] = useState(null);
    const [controls, setControls] = useState([]);
    const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
    const [uploadConfig, setUploadConfig] = useState({});

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getCustomerControls = () => {
        let apiOptions = {
            url: 'control/customer/query'
        };
        http.get(apiOptions).then(response => {
            setControls(response.data);
        });
    }

    const getDashboardCardById = () => {
        let apiOptions: any = {
            url: `userdashboardcard/${item.id}`
        };
        http.get(apiOptions).then(res => {
            let result = res.data;
            result.imageUrl = general.getImageUrl(result.imagePath);
            setItem(result);
        });
    };

    const saveDashboardCard = () => {
        if (!item.id && !item.title) {
            setError("This field is required");
            return;
        }
        let apiOptions: any = {
            url: `userdashboardcard/save`,
            data: item
        };
        http.post(apiOptions).then(res => {
            saveDashboardCardCallBack(res);
        });
    };

    const saveDashboardCardCallBack = (res) => {
        AlertService.showSuccessMsg();
        props.handleFormClose();
    };

    const handleClose = () => {
        props.handleFormClose();
    }

    const openPhotoCapture = () => {
        let photoCaptureConfig = {
            fileUploadConfig: { url: 'userdashboardcard/savefileimage' },
            id: item.id
        };
        setUploadConfig(photoCaptureConfig);
        setPhotoCaptureOpen(true);
    }

    const handlePhotoCaptureClose = (res) => {
        setPhotoCaptureOpen(false);
        let imageUrl = general.getImageUrl(res.filePath);
        handleItemChange({ "imagePath": res.filePath });
        handleItemChange({ "imageUrl": imageUrl });
        if (item.id > 0) {
            getDashboardCardById();
        }
    }

    useEffect(() => {
        getCustomerControls();
        if (props.cardId) {
            getDashboardCardById();
        }
    }, []);

    const body = (
        <div className="cardform-ui">
            <div className="row title-font mx-auto">
                <div className="col-11 pl-0">
                    <span>Dashboard Card Form</span>
                </div>
                <div className="col-1 float-right pointer" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="row p-2 m-0">
                <div className="col-3">
                    <div className="profile-photo">
                        <a className="pointer">
                            {!item.imageUrl && <img src={dummyImage} alt="dummy" className="profile-photo"></img>}
                            {item.imageUrl && <img src={item.imageUrl} alt="prod" className="profile-photo"></img>}
                        </a>
                        <div className="text-center camera-align" onClick={openPhotoCapture}>
                            <CameraAltIcon />
                        </div>
                    </div>

                </div>
                <div className="col-9">
                    <div className="col-12">
                        <FxInput name="title" variant="outlined" label="Title*" size="small"
                            value={item.title} className="txtstyle"
                            onValueChange={handleInputChange}
                        />

                        {error && !item.title &&
                            <div className="error"> {error} </div>}
                    </div>

                    <div className="col-12">
                        <FxTextarea name="description" variant="outlined" label="Description" size="small"
                            value={item.description} maxRows={5}
                            onValueChange={handleInputChange}
                        />
                    </div>
                    <div className="col-12">
                        <FxSelect name="controlId" variant="outlined" label="Screens"
                            options={controls} className="selectStyle"
                            selectedValue={item.controlId} valueField="id" displayField="controlCode"
                            onValueChange={handleInputChange} />
                    </div>
                    <div className="col-12">
                        <FxInput name="targetTitle" variant="outlined" label="Target Title" size="small"
                            value={item.targetTitle} className="txtstyle"
                            onValueChange={handleInputChange}
                        />

                        {error && !item.title &&
                            <div className="error"> {error} </div>}
                    </div>
                    <div className="col-12">
                        <FxInput name="displayOrder" variant="outlined" label="Display Order" size="small"
                            value={item.displayOrder} className="txtstyle"
                            onValueChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-4 pr-4 mr-0 pb-4 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={saveDashboardCard}>Save</Button>
                </span>
            </div>
            <Dialog
                open={photoCaptureOpen}
                onClose={handlePhotoCaptureClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <PhotoCapture uploadConfig={uploadConfig} handlePhotoCaptureClose={handlePhotoCaptureClose} />
            </Dialog>
        </div>
    );

    return (
        <div>
            {body}
        </div>
    );

}

export default DashboardCardForm;
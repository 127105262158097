import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import './DicomViewer.css';
import DicomUploader from '../dicomuploader/DicomUploader';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Iframe from 'react-iframe';
import { http } from '../fx-core';
import { DicomConfig } from '../../config/DicomConfig';
import { useHistory } from "react-router-dom";

const DicomViewer = (props: any) => {
  const history = useHistory();

  const [url, setUrl] = useState("");
  const strTitle = props.match && props.match.params ? props.match.params.title : "Dicom Viewer";
  const [title, setTitle] = useState(strTitle);
  const [type, setType] = useState(props.match && props.match.params ? parseInt(props.match.params.type) : "");

  //const url = "https://mmf-dicom-viewer.web.app/projects/mymedifam-306712/locations/us-central1/datasets/mmf-dicom-ds/dicomStores/mmf-dicom-store/study/1.2.840.113845.11.1000000001951524609.20170401080739.8236951"

  const getAccessToken = (studyId: any) => {
    let apiOptions: any = {
      url: `dicom/getToken`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      //studyId = '1.2.840.113845.11.1000000001951524609.20170401080739.8236951';
      let url = `https://mmf-dicom-viewer.web.app/projects/${DicomConfig.project}/locations/${DicomConfig.location}/datasets/${DicomConfig.dataset}/dicomStores/${DicomConfig.dicomStore}/study/${studyId}?token=${result.accessToken}`;
      setUrl(url);
    });
  }

  const navToScans = () => {
    history.push({
      pathname: '/scans', state: {}
    });
  }

  const navToAppointments = () => {
    history.push({
      pathname: '/appointments', state: {}
    });
  }

  useEffect(() => {
    console.log(props);
    let stId = props.match && props.match.params.stid ? props.match.params.stid : "";
    if (stId) {
      getAccessToken(stId);
    }
  }, []);

  const iframeProps: any = { display: "initial" };
  return (<div className="content-container">
    <div className="title-font">
      {type == 1 &&
        <span className="custom-link pointer breadcrumbs-style" onClick={navToScans}>Scans</span>
      }
      {type == 2 &&
        <span className="custom-link pointer breadcrumbs-style" onClick={navToAppointments}>Appointments</span>
      }
      <span> / {title}</span>
    </div>

    <div className="mt-4 ml-3">
      {url && <div>
        <Iframe url={url}
          width="1300px"
          height="800px"
          id="myId"
          className="myClassname"
          {...iframeProps}
          position="relative" />
      </div>}
    </div>
  </div>);
}

export default DicomViewer;
import React, { useEffect, useState } from 'react';
import './RegisterProviderList.scss';
import { FxDate, http, formatService, FxSelect, FxDateDisplay } from '../../fx-core';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Link } from '@material-ui/core';
import IconPhone from '@material-ui/icons/Phone';
import { useSelector } from 'react-redux';
import { Constants } from '../../fx-core/helpers/constants';

export const RegisterProviderList = (props: any) => {

    const preferences = useSelector((store: any) => store.preferences);
    const dateFormat = Constants.ServerDateFormat;

    let defaultFilter: any = {
        fromDate: formatService.substractDaysInDisplayFormat(formatService.getCurrentDate(), 7, dateFormat),
        toDate: formatService.getCurrentDate(dateFormat),
        specializationId: -1,
        serviceId: -1,
        clinicId: -1
    };

    const [filter, setFilter] = useState(defaultFilter);
    const [providerList, setPatientList] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [services, setServices] = useState([]);
    const [clinics, setClinics] = useState([]);
    const [valError, setValError] = useState(null);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { item: { [item.name]: item.value } };
        getRegisterProviderList(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getRegisterProviderList = (options: any = {}) => {
        let selectedFromDate: any = filter.fromDate;
        let selectedToDate: any = filter.toDate;
        let specializationId: any = filter.specializationId;
        let serviceId: any = filter.serviceId;
        let clinicId: any = filter.clinicId;
        if (options && options.item) {
            selectedFromDate = options.item.hasOwnProperty('fromDate') ? options.item.fromDate : selectedFromDate;
            selectedToDate = options.item.hasOwnProperty('toDate') ? options.item.toDate : selectedToDate;
            specializationId = options.item.specializationId ? options.item.specializationId : specializationId;
            serviceId = options.item.serviceId ? options.item.serviceId : serviceId;
            clinicId = options.item.clinicId ? options.item.clinicId : clinicId;
        }
        setValError(null);
        if (formatService.isBefore(selectedFromDate, selectedToDate)) {
            setValError("To date should be greater than or equal to From date");
            return;
        }
        let inputData = {
            filters: {
                fromDate: formatService.getDateStringForServer(selectedFromDate, dateFormat),
                toDate: formatService.getDateStringForServer(selectedToDate, dateFormat),
                specializationId: specializationId,
                serviceId: serviceId,
                clinicId: clinicId
            }
        };
        let apiOptions: any = {
            url: 'provider/admin/registerlist',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setPatientList(response.data);
        });
    }

    const initLookup = () => {
        let inputData = {
            lookups: {
                Specializations: { default: true },
                Services: { default: true },
                Clinics: { default: true },
            },
            filters: {}
        };
        let apiOptions = {
            url: 'Options/GetLookupOptions',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setSpecializations(response.data.Specializations);
            setServices(response.data.Services);
            setClinics(response.data.Clinics);
        });
    }


    useEffect(() => {
        initLookup();
        getRegisterProviderList();
    }, []);

    return (
        <>
            <div className="row col-12 m-0 p-0">
                <div className="col-6 row m-0 p-0">
                    <div className="col-6 pl-0">
                        <FxDate name="fromDate" label="From Date" variant="outlined" fullWidth
                            value={filter.fromDate} onValueChange={handleInputChange} />
                    </div>
                    <div className="col-6">
                        <FxDate name="toDate" label="To Date" variant="outlined" fullWidth
                            value={filter.toDate} onValueChange={handleInputChange} />
                    </div>
                    {
                        valError &&
                        <div className="error"> {valError} </div>
                    }
                </div>
                <div className="col-6 row m-0 p-0">
                    <div className="col-6">
                        <FxSelect name="specializationId" variant="outlined" label="Specializations" fullWidth
                            options={specializations}
                            selectedValue={filter.specializationId} valueField="id"
                            displayField="text" className="selectClass" onValueChange={handleInputChange} />
                    </div>
                </div>
                <div className="col-6 row m-0 mb-3 p-0">
                    <div className="col-6 pl-0">
                        <FxSelect name="serviceId" variant="outlined" label="Services" fullWidth
                            options={services}
                            selectedValue={filter.serviceId} valueField="id"
                            displayField="text" onValueChange={handleInputChange} />
                    </div>
                    <div className="col-6">
                        <FxSelect name="clinicId" variant="outlined" label="Clinics" fullWidth
                            options={clinics}
                            selectedValue={filter.clinicId} valueField="id"
                            displayField="text" onValueChange={handleInputChange} />
                    </div>
                </div>
            </div>

            <div>
                <TableContainer component={Paper}>
                    <Table stickyHeader className="table table-striped">
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-head table-font">Name</TableCell>
                                <TableCell className="table-head table-font">Mobile</TableCell>
                                <TableCell className="table-head table-font">Email</TableCell>
                                <TableCell className="table-head table-font">Experiance</TableCell>
                                <TableCell className="table-head table-font">Specializations</TableCell>
                                <TableCell className="table-head table-font">Services</TableCell>
                                <TableCell className="table-head table-font">Educations</TableCell>
                                <TableCell className="table-head table-font">Registered Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                providerList.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <div className="table-font">
                                                <span>{row.prefix} {row.firstName}</span> &nbsp;
                                                <span>{row.lastName}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <span className="mobileno-color table-font">
                                                    <Link href="tel:{{row.mobile}}">
                                                        <IconPhone fontSize="small" />
                                                        <span>{row.mobile}</span>
                                                    </Link>
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div> <span className="table-font">{row.email}</span></div>
                                        </TableCell>
                                        <TableCell>
                                            <div> <span className="table-font">{row.experience}</span></div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <span className="table-font">{row.specializations.join(",")}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <span className="table-font">{row.services.join(",")}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <span className="table-font">{row.qulifications.join(",")}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <FxDateDisplay value={row.registrationDate} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};
import React, { useEffect, useState } from 'react';
import './RequestAppointment.scss';
import { FxInput, http, FxDate, AlertService, session, FxDateDisplay, localStorageService, formatService, FormValidator, FxSelect, useNavigationService, FxMobile } from '../../fx-core';
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';
import { Constants } from '../../fx-core/helpers/constants';
import * as _ from 'lodash';

const RequestAppointment = (props: any) => {
  const { pageTitle } = useNavigationService();
  let defaultItem = {
    firstName: "",
    lastName: "",
    customerEmail: "",
    customerMobile: "",
    customerDateOfBirth: formatService.getCurrentDate(Constants.ServerDateFormat),
    storeServiceSlotId: "",
    providerId: session.getItem('providerId'),
    registrationDate: "",
    providerClinicId: '',
    selectedServiceDate: "",
    countryCode: "+1"
  };
  let location = useLocation();
  const [item, setItem] = useState(defaultItem);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [providerInfo, setProviderInfo] = useState({ firstName: "", lastName: "" });
  const [errors, setErrors] = useState({});
  const [currentContext, setCurrentContext] = useState({ startDate: "", endDate: "" });
  const [isPreviousFlag, setIsPreviousFlag] = useState(false);
  const defaultExternal: any = location.pathname == '/requestappointment' ? true : false;
  const [external, setExternal] = useState(defaultExternal);
  const [providerClinics, setProviderClinics] = useState([]);
  const [error, setError] = useState(null);
  const [countryCode, setCountryCode] = useState([]);
  const [deviceType, setDeviceType] = useState('');

  const handleInputChange = (item) => {
    if (item.name == "mobile") {
      handleItemChange({ "customerMobile": item.value });
    }
    else {
      handleItemChange({ [item.name]: item.value });
    }
  }

  const handleComboChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    handleItemChange({ storeServiceSlotId: 0 });
    handleItemChange({ selectedServiceDate: "" });
    getAvailableSlots({ providerClinicId: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const validationMap_Item = {
    firstName: ["required", "alphabets", "emptystring"],
    lastName: ["required", "alphabets", "emptystring"],
    customerMobile: ["required", "mobileno", "maxlen", "minlen"],
    customerEmail: ["required", "email", "emailvalid"],
    customerDateOfBirth: ["required"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      if (key == "customerMobile") {
        let itemVal = { name: key, value: item[key], validations: validationMap[key], param: 10 }
        items.push(itemVal);
      }
      else {
        let result = { name: key, value: item[key], validations: validationMap[key] }
        items.push(result);
      }
    }
    let validationResult = FormValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const initMethods = () => {
    if (item.providerId) {
      initLookup();
      getProviderProfile();
    }
  }

  const initLookup = () => {
    let inputData = {
      lookups: {
        ProviderClinics: { default: false },
        CountryCode: { default: false }
      },
      filters: {
        providerId: item.providerId
      }
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setCountryCode(response.data.CountryCode);
      let provClinicId = 0;
      setProviderClinics(response.data.ProviderClinics);
      if (response.data.ProviderClinics.length > 0) {
        provClinicId = response.data.ProviderClinics[0].id;
        handleItemChange({ providerClinicId: response.data.ProviderClinics[0].id });
      }
      getAvailableSlots({ providerClinicId: provClinicId });
    });
  }

  const getProviderProfile = () => {
    let inputData = {
      id: item.providerId
    };
    let apiOptions: any = {
      url: 'provider/profile',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      setProviderInfo(result);
    })
  }

  function getDaysBetweenDates(startDate, endDate) {
    var slotStartDate = formatService.getDateStringForServer(startDate);
    var slotEndDate = formatService.getDateStringForServer(endDate);
    var dates = [];

    while (slotStartDate <= slotEndDate) {
      dates.push(formatService.getDateStringForServer(slotStartDate));
      slotStartDate = formatService.getNextDate(slotStartDate);
    }
    return dates;
  };

  const getAvailableSlots = (options: any) => {
    var parser = UAParser(); // gets device type
    setDeviceType(parser.device.type);
    handleItemChange({ providerId: item.providerId });

    let convertedCurrentDate = formatService.getCurrentDateForServer();
    let selectedStartDate = options.startDate ? options.startDate : convertedCurrentDate;
    let startDate = formatService.getDateStringForServer(selectedStartDate);
    let endDate = options.endDate ? options.endDate : formatService.addDays(startDate, parser.device.type == 'mobile' ? 2 : 6);
    let provClinicId = options.providerClinicId ? options.providerClinicId : item.providerClinicId;
    setCurrentContext(prevState => {
      return { ...prevState, startDate }
    });
    setCurrentContext(prevState => {
      return { ...prevState, endDate }
    });
    let dates = getDaysBetweenDates(startDate, endDate);

    let inputData = {
      filters: {
        dates: dates,
        providerId: item.providerId,
        providerClinicId: provClinicId ? provClinicId : 0,
        currentDate: convertedCurrentDate,
        currentTime: formatService.getCurrentTime()
      }
    };
    let apiOptions: any = {
      url: 'provider/availableslots/requestappointment',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      slotsCallBack(response.data);
    })
  }

  const slotsCallBack = (response: any) => {
    for (var item of response) {
      for (var slot of item.slots) {
        var splitTime = slot.startTime.split(":");
        slot.slotTime = splitTime[0].trim() + ":" + splitTime[1].trim();
        slot.selectedFlag = false;
      }
    }
    setAvailableSlots(response);
  }

  const saveRequestAppointment = () => {
    const isValid = bulkValidate();
    if (isValid) {
      if (!item.providerClinicId) {
        setError("Provider Clinic is required");
        return;
      }
      if (!item.storeServiceSlotId) {
        AlertService.showErrorMsg("Please select any slot");
        return;
      }
      let convertedServiceDate = formatService.getDateStringForServer(item.selectedServiceDate);
      if (formatService.isBefore(item.customerDateOfBirth, convertedServiceDate)) {
        AlertService.showErrorMsg("Future selection of date of birth is not allowed");
        return;
      }
      item.registrationDate = formatService.getCurrentDateForServer();
      let apiOptions: any = {
        url: 'appointment/provider/request',
        data: item
      };
      http.post(apiOptions).then(response => {
        requestCallback();
      })
    }
  }

  const requestCallback = () => {
    AlertService.showSuccessMsg();
    handleItemChange({ firstName: defaultItem.firstName });
    handleItemChange({ lastName: defaultItem.lastName });
    handleItemChange({ customerEmail: defaultItem.customerEmail });
    handleItemChange({ customerMobile: defaultItem.customerMobile });
    handleItemChange({ customerDateOfBirth: defaultItem.customerDateOfBirth });
    handleItemChange({ storeServiceSlotId: defaultItem.storeServiceSlotId });
    handleItemChange({ selectedServiceDate: defaultItem.selectedServiceDate });
    let options = { providerClinicId: item.providerClinicId };
    getAvailableSlots({ options });

  }

  const selectedSlot = (item: any) => {
    //to clear existing slots selection
    for (var itemObj of availableSlots) {
      let lookupItem = _.find(itemObj.slots, { selectedFlag: true });
      if (lookupItem && lookupItem.id != item.id) {
        lookupItem.selectedFlag = false;
      }
    }
    setAvailableSlots(availableSlots);

    item.selectedFlag = !item.selectedFlag;
    if (!item.selectedFlag) {
      handleItemChange({ storeServiceSlotId: 0 });
      handleItemChange({ selectedServiceDate: "" });
    }
    else {
      handleItemChange({ storeServiceSlotId: item.id });
      handleItemChange({ selectedServiceDate: item.serviceDate });
    }
  }

  const goToNextSlots = () => {
    let tempDate = formatService.addDays(currentContext.endDate, 1);
    let options = { startDate: tempDate, providerClinicId: item.providerClinicId };
    getAvailableSlots(options);
    setPreviousFlag(tempDate);
  }

  const goToPreviousSlots = () => {
    var parser = UAParser(); // gets device type
    let endDate = formatService.substractDays(currentContext.startDate, 1);
    let startDate = formatService.substractDays(currentContext.startDate, parser.device.type == 'mobile' ? 3 : 7);
    let options = { startDate: startDate, endDate: endDate, providerClinicId: item.providerClinicId };
    getAvailableSlots(options);
    setPreviousFlag(startDate);
  }

  const setPreviousFlag = (inputDate: any) => {
    let currentDate = formatService.getCurrentDateForServer();
    if (inputDate == currentDate) {
      setIsPreviousFlag(false);
    }
    else {
      setIsPreviousFlag(true);
    }
  }

  useEffect(() => {
    initMethods();
  }, []);


  const body = (
    <div className={external ? "req-appt req-appt-external-margin" : "req-appt req-appt-margin"}>
      <div className="pb-2">
        <div className="title-font">
          {!external && <span>
            <span>{pageTitle} by Dr.</span>
            <span className="ml-1">{providerInfo.firstName}</span>
            <span className="ml-1">{providerInfo.lastName}</span>
          </span>}
          {external && <span>
            <span>Dr.</span>
            <span className="ml-1">{providerInfo.firstName}</span>
            <span className="ml-1">{providerInfo.lastName}</span>
          </span>}
        </div>
      </div>
      <div className="row m-0 px-3 req-appt-container-col">
        <div className="col-12 col-lg-4">
          <div className="requestlabel">
            {!external && <span>Request Appointment on behalf of the Client</span>}
            {external && <span>Book Appointment</span>}
          </div>
          <div className="mt-3">
            <FxInput name="firstName" variant="outlined" label="First name*"
              value={item.firstName} onValueChange={handleInputChange} className=" btn-150" />

            <span className="pl1per">
              <FxInput name="lastName" variant="outlined" label="Last name*"
                value={item.lastName} onValueChange={handleInputChange} className="btn-145" />
            </span>
            {(hasError("firstName", "required") || hasError("firstName", "emptystring")) && (
              <div className="error">First name is required</div>
            )}
            {
              hasError("firstName", "alphabets") &&
              <div className="error">First name should contain only alphabets</div>
            }
            {(hasError("lastName", "required") || hasError("lastName", "emptystring")) && (
              <div className="error">Last name is required</div>
            )}
            {
              hasError("lastName", "alphabets") &&
              <div className="error">Last name should contain only alphabets</div>
            }
          </div>

          <div className="">
            <FxInput name="customerEmail" variant="outlined" label="Email*"
              value={item.customerEmail} onValueChange={handleInputChange} className="btn-300" />
            {hasError("customerEmail", "required") && (
              <div className="error">Email is required</div>
            )}
            {(hasError("customerEmail", "email") || hasError("customerEmail", "emailvalid")) && (
              <div className="error">Please enter valid email</div>
            )}
          </div>

          <div className="">
            <FxMobile value={item.customerMobile} onValueChange={handleInputChange} countryCode={countryCode}
              validation={true} />
            {hasError("customerMobile", "required") && (
              <div className="error">Mobile is required</div>
            )}
            {
              hasError("customerMobile", "mobileno") &&
              <div className="error">Mobile should contain only numbers</div>
            }
            {
              (hasError("customerMobile", "maxlen") || hasError("customerMobile", "minlen")) &&
              <div className="error">Mobile should accept 10 digits</div>
            }
          </div>

          <div className="">
            <FxDate name="customerDateOfBirth" label="Date of birth*"
              variant="outlined" openTo={"year"}
              value={item.customerDateOfBirth} onValueChange={handleInputChange} className="btn-300" />
            {hasError("customerDateOfBirth", "required") && (
              <div className="error">Date of birth is required</div>
            )}
          </div>

          <div className="">
            <FxSelect name="providerClinicId" variant="outlined" label="Provider clinic*"
              options={providerClinics}
              selectedValue={item.providerClinicId}
              valueField="id"
              displayField="text"
              onValueChange={handleComboChange} />
            {error && !item.providerClinicId &&
              <div className="error">{error}</div>
            }
          </div>
          <div className="col-12 pl-0 pt-2">
            <span className="font-13">Our front office will contact you to confirm your appointment</span>
          </div>

          {deviceType != 'mobile' && deviceType != 'tablet' &&
            <div className="text-center">
              <Button variant="contained" size="small" color="primary" onClick={saveRequestAppointment}>
                Request Now
              </Button>
            </div>
          }
        </div>

        <div className="mt-3 mt-lg-0 col-12 col-lg-8 row mx-0">
          <div className={`col-1 p-0 ${(availableSlots && availableSlots.length > 0) ? 'req-appt-left-arrow' : ''}`}>
            {isPreviousFlag &&
              <span>
                <i className="fas fa-caret-left arrowfont pointer" onClick={goToPreviousSlots}></i>
              </span>
            }
          </div>
          <div className="col-10 row m-0 px-1">
            {availableSlots.map((item) => (
              <div className={`p-1 text-center ${(deviceType == 'mobile') ? 'col-4' : 'col-1Point5'}`}>
                <div className="table-head req-appt-table-head table-font py-1 w-100 sticky-top">
                  <FxDateDisplay value={item.serviceDate} />
                </div>
                {
                  item.slots && item.slots.map((row) => (
                    <div className="py-1 text-center">
                      {!row.selectedFlag &&

                        <button className="slottime-btn" onClick={() => selectedSlot(row)}>
                          <span>{formatService.getTimeDisplay(row.slotTime)}</span>
                        </button>
                      }
                      {row.selectedFlag &&
                        <button className="slottime-btn" onClick={() => selectedSlot(row)}
                          style={{ backgroundColor: 'orange', color: 'white' }}>
                          <span>{formatService.getTimeDisplay(row.slotTime)}</span>
                        </button>
                      }
                    </div>
                  ))
                }
              </div>
            ))
            }

            {availableSlots && availableSlots.length == 0 &&
              <div className="col-12 text-center my-3 font-24">
                No Slots available
              </div>
            }
          </div>
          <div className={`col-1 p-0 ${(availableSlots && availableSlots.length > 0) ? 'req-appt-right-arrow' : ''}`}>
            <span>
              <i className="fas fa-caret-right arrowfont pointer" onClick={goToNextSlots}></i>
            </span>
          </div>
        </div>
        {(deviceType == 'mobile' || deviceType == 'tablet') &&
          <div className="col-12 text-center my-3">
            <Button variant="contained" size="small" color="primary" onClick={saveRequestAppointment}>
              Request Now
            </Button>
          </div>
        }
      </div>

    </div >
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default RequestAppointment;
import React, { useEffect, useState } from 'react';
import './ProviderGeneral.scss';
import { FxInput, http, AlertService, FxTextarea, general, session, FxMobile, FormValidator, FxContact } from '../../fx-core';
import { Button, Dialog, Link } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import { MobileVerificationDialog } from '../MobileVerificationDialog/MobileVerificationDialog';
import { EmailVerificationDialog } from '../EmailVerificationDialog/EmailVerificationDialog';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import dummyImage from "./../../../assets/images/dummy.png";
import { ProviderDueDialog } from "../ProviderDueDialog/ProviderDueDialog";
import * as _ from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';

const ProviderGeneral = (props: any) => {
  let defaultForm = {
    prefix: "",
    userName: "",
    firstName: "",
    lastName: "",
    experience: "",
    about: "",
    email: "",
    mobile: "",
    countryCode: "",
    isMobileVerified: false,
    isEmailVerified: false,
    imageUrl: "",
    currency: ""
  };
  const [item, setItem] = useState(defaultForm);
  const [mobileVerifyOpen, setMobileVerifyOpen] = useState(false);
  const [emailVerifyOpen, setEmailVerifyOpen] = useState(false);
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [delAccOpen, setDelAccOpen] = useState(false);
  const [currency, setCurrency] = useState(session.getItem("currency"));
  const [error, setError] = useState(null);
  const [countryCode, setCountryCode] = useState([]);
  const [errors, setErrors] = useState({});
  const [providerContacts, setProviderContacts] = useState([]);
  const [contactError, setContactError] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
  }

  const handleFilterChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        CountryCode: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setCountryCode(response.data.CountryCode);
    });
  }

  //Validation Starts
  const validationMap = {
    prefix: ["required", "emptystring"],
    experience: ["required", "number"],
    about: ["required", "emptystring"],
    email: ["required", "email", "emailvalid"],
    mobile: ["required", "mobileno", "maxlen"]
  };

  const bulkValidate = () => {
    let items = [];
    for (var key in validationMap) {
      if (key == "mobile") {
        let itemVal = { name: key, value: item[key], validations: validationMap[key], param: 10 }
        items.push(itemVal);
      }
      else {
        let itemVal = { name: key, value: item[key], validations: validationMap[key] }
        items.push(itemVal);
      }
    }
    let validationResult = FormValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation Ends

  const getProviderProfile = () => {
    let inputData = {
      id: props.providerId
    };
    let apiOptions: any = {
      url: 'provider/profile/withcontacts',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      result.imageUrl = general.getImageUrl(result.profileImagePath);
      setItem(result);
      setProviderContacts(result.providerContacts);
      initLookup();
    })
  }

  const saveProfile = () => {
    const isValid = bulkValidate();
    if (isValid) {
      if (providerContacts && providerContacts.length > 0) {
        for (let contact of providerContacts) {
          contact.isShowError = false;
          if (!contact.phone) {
            contact.isShowError = true;
            setContactError("Field is required");
            return;
          }
        }
      }
      let inputData = {
        id: props.providerId,
        prefix: item.prefix,
        about: item.about,
        mobile: item.mobile,
        countryCode: item.countryCode,
        email: item.email,
        experience: item.experience,
        providerContacts: providerContacts
      };
      let apiOptions: any = {
        url: 'provider/profile/save',
        data: inputData
      };
      http.post(apiOptions).then(response => {
        AlertService.showSuccessMsg();
        getProviderProfile();
      })
    }
  }

  const openMobileVerify = () => {
    setMobileVerifyOpen(true);
  };

  const closeMobileVerify = () => {
    setMobileVerifyOpen(false);
  };

  const onSuccessMobileVerify = (res) => {
    if (res == "success") {
      handleFilterChange({ isMobileVerified: true });
    }
    closeMobileVerify();
  };

  const openEmailVerify = () => {
    setEmailVerifyOpen(true);
  };

  const closeEmailVerify = () => {
    setEmailVerifyOpen(false);
  };

  const onSuccessEmailVerify = (res) => {
    if (res == "success") {
      handleFilterChange({ isEmailVerified: true });
    }
    closeEmailVerify();
  };

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      cameraConfig: { url: 'provider/savecameraimage' },
      fileUploadConfig: { url: 'provider/savefileimage' }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
    getProviderProfile();
  }

  const mailDeepLink = () => {
    let inputData: any = {
      id: props.providerId
    };

    let apiOptions: any = {
      url: `provider/${inputData.id}/maildeeplinkqrcode`,
      data: inputData
    };

    http.get(apiOptions).then(res => {
      AlertService.showSuccessMsg("Personalized link has been mailed to your email address");
    });
  };

  const openDeletAccountDialog = () => {
    setDelAccOpen(true);
  };

  const handleClose = () => {
    setDelAccOpen(false);
  };

  const getExternalLink = () => {
    let inputData: any = {
      id: props.providerId
    };

    let apiOptions: any = {
      url: `provider/${inputData.id}/mailgetlink`,
      data: inputData
    };
    http.get(apiOptions).then(res => {
      AlertService.showSuccessMsg("Appointment link has been mailed to your email address");
    });
  };

  const handleContactInputChange = (item, idx) => {
    if (item.name == "countryCode") {
      let itemObj = providerContacts[idx];
      itemObj.countryCode = item.value;
    }
    if (item.name == "phone") {
      let itemObj = providerContacts[idx];
      itemObj.phone = item.value;
    }
  }

  const addContact = () => {
    let contactList = [];
    if (providerContacts.length > 0) {
      for (var item of providerContacts) {
        contactList.push(item);
      }
      let newItem = { id: 0, countryCode: "", phone: "", providerId: props.providerId };
      contactList.push(newItem);
    }
    else {
      let newItem = { id: 0, countryCode: "", phone: "", providerId: props.providerId };
      contactList.push(newItem);
    }
    setProviderContacts(contactList);
  }

  const confirmDelete = (item: any, idx: any) => {
    setSelectedItem(item);
    setSelectedIndex(idx);
    setMessage("Do you want to delete this contact ?");
    setConfirmOpen(true);
  };

  const confirmMessage = () => {
    setConfirmOpen(false);
    deleteContact();
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  }

  const deleteContact = () => {
    let itemObj = providerContacts[selectedIndex];
    if (itemObj.id > 0) {
      itemObj.status = 2;
    }
    else {
      providerContacts.splice(selectedIndex, 1);
    }
  }

  useEffect(() => {
    getProviderProfile();
  }, []);


  const body = (
    <div className="content-container2 card">
      <div className="title-font pl-4">
        <span>General</span>
      </div>
      <div className="row px-3 m-0 mt-4">
        <div className="row m-0 col-12 p-0">
          <div className="col-4">
            <div className="profile-photo">
              <a className="pointer">
                {!item.imageUrl && <img src={dummyImage} alt="dummy" className="profile-photo"></img>}
                {item.imageUrl && <img src={item.imageUrl} alt="prod" className="profile-photo"></img>}
              </a>
              <div className="text-center camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon />
              </div>
            </div>

          </div>

          <div className="col-8">
            <div className='row pl-2'>
              <div className='col-4'>
                <FxInput name="prefix" variant="outlined" label="Prefix" size="small" fullWidth
                  value={item.prefix} onValueChange={handleInputChange}
                />
                {
                  (hasError("prefix", "required") || hasError("prefix", "emptystring")) &&
                  <div className="validation-msg">Field is required</div>
                }
              </div>
              <div className="col-8 name-style">
                <PersonIcon fontSize="small" color="action" />
                <span className="pl-1">{item.firstName}</span><span className="ml-1">{item.lastName}</span>
              </div>
            </div>
            <div className="pt-1">
              {countryCode && countryCode.length > 0 &&
                <FxMobile value={item.mobile} onValueChange={handleInputChange} countryCode={countryCode} 
                validation={true} />
              }
              {/* <CallIcon fontSize="small" color="action" /> */}
              {
                hasError("mobile", "required") &&
                <div className="validation-msg">Field is required</div>
              }
              {
                hasError("mobile", "mobileno") &&
                <div className="validation-msg">Mobile should contain only numbers</div>
              }
              {
                hasError("mobile", "maxlen") &&
                <div className="validation-msg">Mobile should accept 10 digits</div>
              }
              {
                !item.isMobileVerified &&
                <div className="text-right" >
                  <Link className="pointer font-12" onClick={openMobileVerify}>Verify</Link>
                </div>
              }
              {
                item.isMobileVerified &&
                <div className="text-right verified">Verified</div>
              }
            </div>
            <div>
              {/* <EmailIcon fontSize="small" color="action" /> */}
              <FxInput name="email" variant="outlined" label="Email*" size="small" fullWidth
                value={item.email} onValueChange={handleInputChange}
              />
              {
                hasError("email", "required") &&
                <div className="validation-msg">Field is required</div>
              }
              {
                (hasError("email", "email") || hasError("email", "emailvalid")) &&
                <div className="validation-msg">Please enter valid email id</div>
              }
              {
                !item.isEmailVerified &&
                <div className="text-right" >
                  <Link className="pointer font-12" onClick={openEmailVerify}>Verify</Link>
                </div>
              }
              {
                item.isEmailVerified &&
                <div className="text-right verified">Verified</div>
              }
            </div>
            <div>
              <FxInput name="experience" variant="outlined" label="Years of Experience*" size="small" fullWidth
                value={item.experience} onValueChange={handleInputChange} />
              {
                hasError("experience", "required") &&
                <div className="validation-msg">Field is required</div>
              }
              {
                hasError("experience", "number") &&
                <div className="validation-msg">Experience should contain only numbers</div>
              }
              {/* <span className="ml-1">Years of Experience</span> */}
            </div>
          </div>
        </div>
        <div className="row col-12 pt-3 m-0">
          <FxTextarea name="about" variant="outlined" label="About*" size="small"
            value={item.about} maxRows={5}
            onValueChange={handleInputChange}
          />
          {
            (hasError("about", "required") || hasError("about", "emptystring")) &&
            <div className="validation-msg">Field is required</div>
          }
        </div>

        <div className="row col-12 pt-3 m-0">

          <div className='col-12 m-0'>
            <Link className="pointer" onClick={addContact}>Add Contact No.</Link>
          </div>
          <div className="row col-11 m-0">
            {countryCode && countryCode.length > 0 &&
              providerContacts.map((item, i) => (
                <div className="col-10">
                  {
                    item.status != 2 &&
                    <span className='row'>
                      <span className='col-11'>
                        <FxContact value={item.phone} onValueChange={(e) => handleContactInputChange(e, i)} countryCode={countryCode} />
                        {item.isShowError && contactError &&
                          <div className="validation-msg">{contactError}</div>
                        }
                      </span>
                      <span className='col-1 pt-2'>
                        <DeleteIcon color="primary" className="pointer" onClick={() => confirmDelete(item, i)} />
                      </span>
                    </span>
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>


      <div className="row m-0 px-3 pt-2">
        <div className="col-8">
          <div className="">
            <Link className="pointer" onClick={mailDeepLink}>Get link for your specific QR code</Link>
          </div>
          <div className="">
            <Link className="pointer" onClick={getExternalLink}>Link to embed book appointment feature on website</Link>
          </div>
          <div className="">
            <Link className="pointer" onClick={openDeletAccountDialog}>Delete Account</Link>
          </div>
        </div>
        <div className="col-4 text-right">
          <Button variant="contained" size="small" color="primary" onClick={() => saveProfile()}>Update</Button>
        </div>
      </div>
      <Dialog
        open={mobileVerifyOpen}
        onClose={closeMobileVerify}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <MobileVerificationDialog handleVerification={onSuccessMobileVerify} closeDialog={closeMobileVerify} />
      </Dialog>
      <Dialog
        open={emailVerifyOpen}
        onClose={closeEmailVerify}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <EmailVerificationDialog handleVerification={onSuccessEmailVerify} closeDialog={closeEmailVerify} />
      </Dialog>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
      <Dialog
        open={delAccOpen}
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ProviderDueDialog handleClose={handleClose} />
      </Dialog>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleClose={handleConfirmClose} onConfirmCallback={confirmMessage} />
      </Dialog>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderGeneral;
import React, { useState } from 'react';
import './ReasonDialog.scss';
import { FxInput, AlertService } from '../../fx-core';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export const ReasonDialog = (props) => {

    const [reason, setReason] = useState("");

    const handleInputChange = (item) => {
        setReason(item.value);
    };

    const handleClose = () => {
        props.closeReasonDialog()
    };

    const confirmOk = () => {
        if (reason.trim().length <= 0) {
            AlertService.showErrorMsg("Please specify reason");
            return;
        }
        props.handleReason(reason)
    };

    return (
        <div className="modalalign">
            <div className="col-12 title-font">
                <span className="col-10">
                    <span>Reason</span>
                </span>
                <span className="col-1 float-right pointer mr-3" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </span>
            </div>
            <div className="p-2">
                <FxInput name="reason" variant="outlined" label="Comments" size="small"
                    value={reason}
                    onValueChange={handleInputChange}
                />
            </div>
            <div className="text-right p-2">
                <span>
                    <Button variant="contained" size="small" color="primary" onClick={handleClose}>No</Button>
                </span>
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={confirmOk}>Yes</Button>
                </span>
            </div>
        </div>
    )
};
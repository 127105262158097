import { localStorageService } from "../components/fx-core";

const initialState = {
  DateFormat : 'MM/DD/YYYY'
};
  
export const preferences = (state = initialState, action:any) =>{
  switch (action.type) {
    case "UPDATE_PREFERENCES":
      localStorageService.setItem('currency', action.payload.Currency)
      localStorageService.setItem('dateformat', action.payload.DateFormat)
      return {
          ...state, ...action.payload,
      };
    default:
      return state
  }
}


import React, { useState, useEffect } from 'react';
import './ChangeDisplayForm.scss';
import { AlertService, http, FxInput } from '../../fx-core';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';

const ChangeDisplayForm = (props) => {

    const defaultItem = {
        id: props.controlId,
        display: props.controlDisplay ? props.controlDisplay : ""
    };
    const [item, setItem] = useState(defaultItem);
    const [error, setError] = useState(null);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const saveControlDisplay = () => {
        if (!item.display) {
            setError("This field is required");
            return;
        }
        let inputData = {
            id: item.id,
            display: item.display
        }
        let apiOptions: any = {
            url: `control/updatedisplay`,
            data: inputData
        };
        http.put(apiOptions).then(res => {
            saveDisplayCallBack(res);
        });
    };

    const saveDisplayCallBack = (res) => {
        AlertService.showSuccessMsg();
        props.handleChangeDisplayFormClose();
    };

    const handleClose = () => {
        props.handleChangeDisplayFormClose();
    }

    useEffect(() => {
    }, []);

    const body = (
        <div className="changedisplayform-ui">
            <div className="row title-font mx-auto">
                <div className="col-10 pl-0">
                    <span>Change Display Form</span>
                </div>
                <div className="col-2 float-right pointer" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="col-12">
                <FxInput name="display" variant="outlined" label="Display" size="small"
                    value={item.display}
                    onValueChange={handleInputChange}
                />
                {error && !item.display &&
                    <div className="error"> {error} </div>}
            </div>
            <div className="text-right p-3 mt-2">
                <Button variant="contained" size="small" color="primary" onClick={saveControlDisplay}>Save</Button>
            </div>
        </div>
    );

    return (
        <div>
            {body}
        </div>
    );

}

export default ChangeDisplayForm;
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import './Login.scss';
import { useHistory } from "react-router-dom";
import logo from "./../../assets/logo.png";
import { FxInput, http, session, AlertService, useNavigationService, localStorageService } from '../fx-core';
//import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import ForgotPassword from '../forgotpassword/ForgotPassword';

const useStyles = makeStyles((theme) => ({

  loginBtn: {
    flexGrow: 1,
    background: '#269592'
  },
  header: {
    textAlign: 'center',
    background: '#269592',
    color: '#ff'
  },
  card: {}
}));


const Login = (props: any) => {
  const { navigate } = useNavigationService();
  const globalDispatch = useDispatch();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  let [external, setExternal] = useState(false);
  const [open, setOpen] = useState(false);

  let defaultFormModel: any = {
    userName: '',
    password: ''
  };
  const [formModel, setFormModel] = useState(defaultFormModel);

  const handleInputChange = (item) => {
    handleFormModelChange({ [item.name]: item.value });
  }

  const handleFormModelChange = (data) => {
    setFormModel(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleLogin = () => {
    if (!formModel.userName || !formModel.password) {
      AlertService.showErrorMsg("Username/Password is required");
      return;
    }
    let inputData = { UserName: formModel.userName, Password: formModel.password };
    let apiOptions = {
      url: 'security/AuthenticateUser',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      let authToken = result.authToken;

      session.setItem('sessionid', authToken.sessionId);
      session.setItem('id_token', "token " + authToken.sessionId);
      session.setItem('roleCode', authToken.roleCode);
      session.setItem('email', authToken.email);
      session.setItem('userId', authToken.userId);
      session.setItem('employeeId', authToken.employeeId);
      session.setItem('providerId', authToken.providerId);
      session.setItem('customerId', authToken.customerId);
      session.setItem('currency', authToken.currency);
      session.setItem('roleId', authToken.roleId);
      session.setItem('imagStorageUrl', result.config.imagStorageUrl);

      if (authToken.roleCode != 'SUPERADMIN' && authToken.roleCode != 'PROVIDER' && authToken.roleCode != 'CUSTOMER') {
        alert('You does not have permission for this app!');
      } else {
        initiateUserSession(authToken);
      }
    })
  }

  const initiateUserSession = (authToken: any, landingRoute="") => {
    let inputData = {
      userId: authToken.userId,
      providerId: authToken.providerId,
      roleCode: authToken.roleCode
    };
    let apiOptions = {
      url: 'Options/InitiateUserSession',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;

      if (authToken.roleCode === 'SUPERADMIN') {
        localStorageService.setItem('dateformat', 'MM/DD/YYYY')
        let navData = {
          targetRoute: '/providers',
          targetTitle: "Providers",
          targetData: {},
          sourceState: {},
          eventSource: '',
          skipAppendCode: true
        };
        navigate(navData);
      }
      else if (authToken.roleCode === 'PROVIDER') {
        computePreferences(result.preferences);
        const targetTitle = landingRoute == '/requestappointment' ? "Book Appointment" : "Check Appointments";
        landingRoute = landingRoute || '/appointments';
        let navData = {
          targetRoute: landingRoute,
          targetTitle: targetTitle,
          targetData: {},
          sourceState: {},
          eventSource: '',
          skipAppendCode: true
        };
        navigate(navData);
      }
      else if (authToken.roleCode === 'CUSTOMER') {
        localStorageService.setItem('dateformat', 'MM/DD/YYYY');
        let navData = {
          targetRoute: '/scans',
          targetTitle: "DICOM Files",
          targetData: {},
          sourceState: {},
          eventSource: '',
          skipAppendCode: true
        };
        navigate(navData);
      }
    })
  }

  const computePreferences = (preferencesList: any) => {
    let preferences = [];
    if (preferencesList) {
      for (let idx in preferencesList) {
        let item = preferencesList[idx];
        //postProcessPreference(item); - use this if any post process requried
        preferences[item.preferenceKey] = item.preferenceValue;
      }
    }
    dispatch({ type: "UPDATE_PREFERENCES", payload: preferences });
  }

  const resolveGetLinkToken = (token) => {
    let apiOptions: any = {
      url: `provider/getlinktoken/${token}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      let authToken = result.authToken;

      session.setItem('sessionid', authToken.sessionId);
      session.setItem('id_token', "token " + authToken.sessionId);
      session.setItem('roleCode', authToken.roleCode);
      session.setItem('email', authToken.email);
      session.setItem('userId', authToken.userId);
      session.setItem('employeeId', authToken.employeeId);
      session.setItem('providerId', authToken.providerId);
      // session.setItem('imagStorageUrl', result.config.imagStorageUrl);

      if (authToken.roleCode === 'PROVIDER') {
        initiateUserSession(authToken, '/requestappointment');
      } else {
        alert('You does not have permission for this app!');
      }
    })
  }

  const navigateToRegisterUser = () => {
    history.push({
      pathname: '/registerpatient',
      state: { referralCode: "", isLogin: true }
    });
  };

  const navigateToRegisterProvider = () => {
    history.push({
      pathname: '/registerprovider',
      state: { referralCode: "", isLogin: true }
    });
  };

  const navigateToPatientRegistration = () => {
    history.push({
      pathname: '/patientregistration',
      state: { referralCode: "", isLogin: true }
    });
  }

  const navigateToHealthHistory = () => {
    history.push({
      pathname: '/healthhistory',
      state: { referralCode: "", isLogin: true }
    });
  }

  const openForgotPasswordDrawer = () => {
    setOpen(true);
  };

  const closeForgotPwdDrawer = () => {
    setOpen(false);
  }

  useEffect(() => {
    console.log(props);
    let token = props.match.params.tk ? props.match.params.tk : "";
    if (token) {
      dispatch({ type: "UPDATE_EXTERNAL_LOGIN", payload: { isExternalLogin: true } });
      setExternal(true);
      resolveGetLinkToken(token);
    }
  }, []);

  const body = (
    <form className='container' noValidate>
      <div className='login-content text-center'>We are HIPPA compliant, and end to end encrypted</div>
      <Card className={classes.card} variant="outlined">
        <div className="login-row">
          <i className="fas fa-user-lock fa-2x"></i>
          <span className="align-name">Login</span>
        </div>
        <CardContent>
          <div>
            <FxInput name="userName" variant="standard" label="Username*"
              fullWidth
              value={formModel.userName}
              onValueChange={handleInputChange} />

            <FxInput name="password" variant="standard" label="Password*"
              fullWidth
              type="password"
              value={formModel.password}
              onValueChange={handleInputChange} />
          </div>
        </CardContent>

        <div className="mb-3 pl-4 font-14">
          <span onClick={openForgotPasswordDrawer} className="pointer registertext">Forgot Password?</span>
        </div>

        <div className="px-3">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className={classes.loginBtn}
            onClick={handleLogin}
            fullWidth
          >
            Login
          </Button>
        </div>
        <div className="d-flex align-items-center justify-content-between pb-2 pt-3 font-14 px-4">
          <div className="">
            <span>New User? </span><br></br>
            <span onClick={navigateToRegisterUser} className="pointer registertext">Register User</span>
          </div>

          <div className="text-right">
            <span>New Service Provider? </span><br></br>
            <span onClick={navigateToRegisterProvider} className="pointer registertext">Register Provider</span>
          </div>
        </div>
        {/* <div className="text-center py-2">
          <span onClick={navigateToPatientRegistration} className="pointer registertext">Patient Registration</span>
        </div>
        <div className="text-center py-2">
          <span onClick={navigateToHealthHistory} className="pointer registertext">Health History</span>
        </div> */}
      </Card>
    </form>
  );

  const loadingDiv = (
    <div className="loadingText">
      Loading... Please Wait
    </div>
  );

  return (
    <div className='login'>
      {external == false && body}
      {external == true && loadingDiv}
      <Drawer variant="persistent" anchor="right" className="drawer-forgotpwd" open={open}>
        <ForgotPassword handleClose={closeForgotPwdDrawer} />
      </Drawer>
    </div>
  );

}
export default Login;

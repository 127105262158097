
export const session = {
    clear: clear,
    getItem: getItem,
    setItem: setItem
}
 function clear() {
    sessionStorage.clear();
}

 function setItem(key: any, value: any) {
    return sessionStorage.setItem(key, value);
}

function getItem(key: any) {
    return sessionStorage.getItem(key);
} 
import React, { useState } from 'react';
import './PrivacyPolicy.scss';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog } from '@material-ui/core';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';

const PrivacyPolicy = (props: any) => {
  const [termsopen, setTermsOpen] = useState(false);

  const openTermsAndConditions = () => {
    setTermsOpen(true);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };

  const handleClose = () => {
    props.handlePrivacyPolicyClose();
  }

  const body = (
    <div className='privacy-policy'>
      <div className="row title-font-terms sticky-top">
        <div className="col-10 pl-4">
          <span>Privacy Policy</span>
        </div>
        <div className="col-2 text-right pointer pr-3" onClick={handleClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </div>
      </div>      
      <div className="my-2 mx-4 container">
        <div className="mt-2 font-16 text-center">
          <div><b>MYMEDIFAM Privacy Policy</b></div>
        </div>
        <div className="mt-2">
          <div>Effective date: December 6, 2021</div>
        </div>
        <div className="mt-2 text-justify font-13">
          <div>
            <p>This Privacy Policy of the MYMEDIFAM, Inc. <a onClick={openTermsAndConditions} className='pointer link-clr'>Terms of Service</a> Agreement may be updated at any time at the sole
              discretion of MYMEDIFAM™ in accordance with the amendment provision of these terms.</p>
          </div>
          <div>
            <p>At MYMEDIFAM, Inc. (“<b>MYMEDIFAM</b>,” “<b>we</b>”, “<b>us</b>”, “<b>our</b>”, or “<b>ours</b>”), we are
              dedicated to protecting our user’s (“<b>user</b>”, “<b>you</b>”, “<b>your</b>”, “<b>yours</b>”) privacy, and we
              take care of your personal information that we collect when you access or use our services. This Privacy Policy
              is meant to help those that use our website, software, and services (collectively called Services) to explore
              providers, book appointments, save personal information, order prescriptions. <b>BY USING OR ACCESSING THE
                SERVICES, YOU ACKNOWLEDGE THAT YOU ACCEPT THE PRACTICES AND POLICIES OUTLINED IN THIS POLICY. IF YOU USE THE
                SERVICES ON BEHALF OF SOMEONE ELSE, INCLUDING, WITHOUT LIMITATION, FAMILY MEMBERS OR A LEGAL REPRESENTATIVE),
                YOU REPRESENT THAT YOU ARE AUTHORIZED BY SUCH INDIVIDUAL OR ENTITY TO ACCEPT THIS PRIVACY POLICY ON SUCH
                INDIVIDUAL’S BEHALF.</b> Any use of MYMEDIFAM’s Services is at all times subject to the Agreement (as the term
              “<b>Agreement</b>” is defined in our <b><a onClick={openTermsAndConditions} className='pointer link-clr'>Terms of Service</a> Agreement</b>, which incorporates this Privacy Policy).
            </p>
          </div>
          <div>
            <p>Certain information that MYMEDIFAM collects about Users may be considered to be protected health information
              (“<b>PHI</b>”) subject to the U.S. Health Insurance Portability and Accountability Act (“<b>HIPAA</b>”). </p>
          </div>
          <div>
            <p>MYMEDIFAM may only use and disclose PHI in the ways permitted by HIPAA or as authorized by the User pursuant to
              the <a onClick={openTermsAndConditions} className='pointer link-clr'>Terms of Service</a> Agreement and this Privacy Policy.</p>
          </div>
        </div>
        <div>
          <div><b>I. What & Why</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <div>
            <p>We collect and use the following information to provide, improve, and protect our Services:</p>
          </div>
          <div>
            <ol type="1">
              <li><i>Account information.</i> We collect, and associate with your account, the information you provide to us
                when you do things such as sign up create your account, upgrade to a paid plan, and set up two-factor
                authentication, including providing us with your name, email address, phone number, payment info, and physical
                address. Some of our Services permit you to access your accounts and your information via other third-party
                service providers.</li>
              <li><i>Your Information.</i> Our Services are designed to make it simple for you to access your files,
                documents, comments, messages, and other materials within our Software (collectively, “<b>Your
                  Information</b>”), collaborate with others, and work across multiple devices. To make that possible, we may
                store information locally, process, and transmit Your Information as well as information related to it. This
                related information includes your profile information that makes it easier to collaborate with and share Your
                Information with others within our Services and Software, as well as adjust the size of the file and access
                the time it was uploaded and your usage activity.</li>
              <li><i>Contacts.</i> You may choose to give us access to your contacts within our Services and Software to make
                it easy for you to do things like share Your Information, send messages, and invite others to use the
                Services. If you do, we store those contacts on our server systems for you to use.</li>
              <li><i>Device information.</i> We also collect information from and about the devices you use to access the
                Services. This includes information regarding your IP addresses, the device you use, and identifiers
                associated with your devices. Your devices, depending on their settings, may also transmit location
                information to the Services or other third parties.</li>
              <li><i>Usage information.</i> We collect information related to how you use the Services, including actions you
                take in your account, such as sharing, editing, viewing, and moving files or folders. We use this information
                to improve our Services, develop new services and features, and protect MYMEDIFAM users.</li>
              <li><i>Marketing.</i> We give users the option to use some of our Services free of charge. These free Services
                are made possible by the fact that some users upgrade to one of our paid Services. If you register for our
                free Services, we will, from time to time, send you information about upgrades when permissible. Users who
                receive these marketing materials can opt-out at any time. If you do not want to receive offers on additional
                services from us, simply click the ‘”unsubscribe” link in any email or update your preferences in your
                MYMEDIFAM account.</li>
              <li><i>Whom We Contact.</i> We may sometimes contact people who do not have a MYMEDIFAM account. For recipients
                in the EU, we or a third party will obtain consent before contacting you. If you receive an email and no
                longer wish to be contacted by MYMEDIFAM, you can unsubscribe and remove yourself from our contact list via
                the message itself.</li>
              <li><i>Bases for processing your data.</i>We collect and use the personal data described above in order to
                provide you with the Services in a reliable and secure manner.</li>
            </ol>
          </div>
        </div>
        <div>
          <div><b>II. With Whom</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <div>
            <p>We may share your information as discussed below: </p>
          </div>
          <div>
            <ol type="1">
              <li><i>Others working for and with MYMEDIFAM.</i> MYMEDIFAM uses certain trusted third parties, e.g. providers
                of customer support and IT services, to help us provide, improve, protect, and promote our Services. These
                third parties will access your information only to perform tasks on our behalf in compliance with this Privacy
                Policy, and we remain responsible for their handling of your information in compliance with our instructions.
              </li>
              <li><i>Other users.</i> Our Services display information like your name, profile picture, device, and email
                address to other users in places like your user profile and sharing notifications. When you register your
                MYMEDIFAM account with an email address on a domain owned by your employer or organization, we may help
                administrators find you and your team by making some of your basic information, including your name, team
                name, profile picture, and email address, which are visible to other users of the same domain. This helps you
                synchronize with the teams you can join and helps other users share files with you.</li>
              <li><i>Third Parties.</i> Certain features let you make additional information available to other users within
                our Services and Software, and to our selected third parties. MYMEDIFAM may publish health related articles,
                or products, or maybe sponsored by business associates to disseminate or advertise specific health-related
                information; none of the aforementioned should be considered as an endorsement or referral. You need to make
                an informed decision after consulting with your health care provider. All sponsored information will be
                labelled as such. We are not providing medical advice to you with these articles, products or treatments, and
                you should always consult with your health care providers before relying upon any health-related information.
                Some articles, products or treatments may be sponsored by third-party advertisers and we do not validate any
                claims made by third parties.</li>
              <li><i>Law & Order and the Public Interest.</i> We may disclose your information to third parties if we
                determine that such disclosure is reasonably necessary to (a) comply with any applicable law, regulation,
                legal process, or appropriate government request; (b) protect any person from death or serious bodily injury;
                (c) prevent fraud or abuse of MYMEDIFAM or our users; (d) protect MYMEDIFAM's rights, property, safety, or
                interest; or (e) perform a task carried out in the public interest.</li>
              <li><i>Compliance disclosures.</i> Your security and data security are critical to us. We comply with HIPAA and
                other regulatory protocols and laws as well as apply industry standards of security protection for our
                Software and our users.</li>
              <li><i>Patient Clinical Trial Opportunities.</i> Research & Development. We may inform patients of suitable
                trials based on the collected information. Anonymized or pseudonymized patient data may also be used by
                MYMEDIFAM for research and development purposes to enable understanding human health conditions and
                treatments, and that involve either MYMEDIFAM alone or MYMEDIFAM along with its business associates</li>
            </ol>
          </div>
        </div>
        <div>
          <div><b>III. How</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <div>
            <ol type="1">
              <li><i>Security.</i> We have a team dedicated to keeping your information secure and testing for
                vulnerabilities. We also continue to work on features to keep your information safe in addition to things like
                two-factor authentication, encryption of files at rest, and alerts when new devices and apps are linked to
                your account. We deploy automated technologies to detect abusive behavior and content that may harm our
                Services, you, or other users.</li>
              <li><i>User Controls.</i> You can access, amend, download, and delete your personal information by logging into
                your MYMEDIFAM account </li>
              <li><i>Retention.</i> When you sign up for an account with us, we will retain the information you provide and
                store within our Services and Software for as long as your account is in existence or as long as we need the
                information to provide you the Services or to be compliant with federal and international laws, such as HIPAA.
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div><b>IV. Where</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <p><i>Around the world.</i> To provide you with the Services, we may store, process, and transmit information in the
            United States and locations around the world, including those outside of your country. Your information may also
            be stored locally on the devices you use to access the Services. If you are provided services as a result of a
            referral within the United States, those care providers are subject to HIPAA compliance and other U.S. federal,
            state, and local laws for the management of your personal health information. If you decide to receive services
            outside of the United States, foreign service providers may or may not maintain data management practices and
            protocols that would comply with HIPAA according to the laws of their jurisdictions. As a result, please be aware
            that your data protection and access may be greatly affected by the jurisdiction in which you receive care. We
            make a commitment internally to provide data management services to our users in compliance with the data privacy
            laws in the jurisdictions in which our users are located.</p>
        </div>
        <div>
          <div><b>V. Changes</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <ol type="1">
            <li><i>Change of Control.</i> If we are involved in a reorganization, merger, acquisition, or sale of our assets,
              your information may be transferred as part of that deal. We will notify you, such as via a message to the email
              address associated with your account, of any such deals or promotions and provide you with details regarding
              your choices for participation.</li>
            <li><i>Amendments.</i> We may revise this Privacy Policy from time to time and will post the most current version
              on our website as amended. We have the right to amend this Privacy Policy at any time at our sole discretion
              with notice via posting to you. If a revision meaningfully reduces your rights, we will notify you directly.
            </li>
          </ol>
        </div>
        <div>
          <div><b>VI. Your Right to Control and Access Your Information</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <div>
            <p>You have control over your personal information and how it is collected, used, and shared. For example, you
              have a right to:</p>
          </div>
          <div>
            <ul>
              <li>erase or delete all or some of Your Information in your MYMEDIFAM account.</li>
              <li>change or correct personal data, and you can manage your account and the content contained in it, as well as
                edit some of your personal data, through your MYMEDIFAM account page.</li>
              <li>access and take your data. You can download a copy of Your Information in a machine-readable format.</li>
            </ul>
          </div>
        </div>
        <div>
          <div><b>VII. TOKENS</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <div>
            <p>A “<b>token</b>” can be a data tracking or security feature where a data element or set of elements is
              effectively replaced ("<b>tokenized</b>") with a non-sensitive alternative. In our Service and Software, your
              user identification data is assigned a token, and then goes through a security authentication.</p>
          </div>
          <div>
            <p>If you share your user identification and password with anyone else, it is not our responsibility and from the
              time that you share this information and have not updated your password, your account is no longer secure.</p>
          </div>
        </div>
        <div>
          <div><b>VIII. COOKIES</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <div>
            <p>A “<b>cookie</b>” in the context of our Service and Software is a file designed to contain a small amount of
              user and interaction information stored on a user’s computer. The cookie file interacts with the user and the
              Service and Software to provide a tailored experience to the user through its awareness of information held
              within the file. Cookies also have the ability to carry all or parts of the information stored within the file
              to other websites or apps that the user may visit or to third parties.</p>
          </div>
          <div>
            <ol type="1">
              <li>
                <div><i>Cookies Types</i></div>
                <div>
                  <p>A “<b>third-party cookie</b>” is placed by a domain other than that of the website, app, or our Software
                    the user is visiting.</p>
                </div>
                <div>
                  <p>A “<b>session cookie</b>” is temporary, linking the actions of a user during the any given browser or
                    software app session. When the browser or software application is closed, the cookie is deleted.</p>
                </div>
                <div>
                  <p>A “<b>permanent cookie,</b>” or a “<b>persistent cookie,</b>” is stored for a specific period of time,
                    activates each time the user visits the webpage or software application, and creates new information
                    records each time.</p>
                </div>
              </li>
              <li>
                <div><i>Our Use of Cookies</i></div>
                <div>
                  <p>We do not use cookies in our Software, but we do use JWT Tokens to maintain your identity authentication,
                    encryption, and access to our Software and Services, and these JWT Tokens authenticate the user’s identity
                    every time the user logs into the Services and Software, specifically into the Software portable device
                    application.</p>
                </div>
              </li>
              <li>
                <div><i>Interest-based Promotions</i></div>
                <div>
                  <p>In some cases, our Software and Service may engage in interest-based advertising to enhance your
                    experience and display advertisements likely to be of interest to you. We and our advertising partners
                    display these interest-based advertisements using information you provide to us when you interact with our
                    Service and Software. Interest-based ads also sometimes referred to as “personalized or targeted ads,” are
                    displayed to you based upon user-activity information such as making selections or choosing filters in our
                    Software or visiting provider websites that contain our content or ads. We use different digital marketing
                    networks and ad exchanges, and we use a range of technologies, such as pixels, web beacons, ad tags,
                    cookies, and mobile identifiers.</p>
                </div>
                <div>
                  <p>Learn more about interest-based ads or opt-out of receiving third-party interest based ads now or in the
                    future, by visiting: <u>http://optout.aboutads.info</u> or <u>www.youronlinechoices.eu</u>. </p>
                </div>
                <div>
                  <p>NOTE: If you opt out of interest-based advertising now or in the future within our Software, you still
                    may see some advertisements within our Software and while using our Services, but they won’t be tailored
                    to your interests.</p>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div><b>IX.	Contact</b></div>
        </div>
        <div className="pl-3 text-justify font-13">
          <div>
            <p>If you reside in North America, including, without limitation, the United States, Canada, Mexico, your personal
              information is controlled by MYMEDIFAM, Inc., a corporation registered in the State of New Jersey in the United
              States. For all other users, your personal information is controlled by MYMEDIFAM, Inc., a corporation
              registered in the State of New Jersey in the United States.</p>
          </div>
          <div>
            <p>Do you have any questions or concerns about MYMEDIFAM, Inc. , our Services, and privacy?</p>
          </div>
          <div>
            <p>If we cannot answer your question regarding your data in this Agreement or when you contact us, you have the
              right to contact your local data protection supervisory authority.</p>
          </div>
          <div>
            <p>Our Chief Data Officer is <b>Rachel William Anantha</b>, whom you can reach by:</p>
          </div>

          <div><u>Mail at</u>:</div>
          <div><b>MYMEDIFAM INC.</b></div>
          <div><b>339 Sunset Road</b></div>
          <div><b>Skillman NJ, USA</b></div>
          <br />
          <div>Or, <u>Email at</u>:</div>
          <div><a href="" className='link-clr'><b>support@mymedifam.com</b></a></div>
        </div>
      </div>      
      <Dialog open={termsopen}
        maxWidth="md" onClose={handleTermsClose}        
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <TermsAndConditions handleTermsClose={handleTermsClose} />
      </Dialog>      
    </div>
  );


  return (
    <div>
      {body}
    </div>
  );

}

export default PrivacyPolicy;
import React from 'react';
import './App.scss';
import './project.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './components/login/Login';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import Header from './components/header/Header';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { ProgressProvider } from './components/fx-core';
import CreateSlots from './components/provider/CreateSlots/CreateSlots';
import ProviderList  from './components/admin/ProviderList/ProviderList';
import ProviderFormContainer  from './components/admin/ProviderFormContainer/ProviderFormContainer';
import ConfirmDialog from './components/general/ConfirmDialog/ConfirmDialog';
import ProviderProfile  from './components/provider/ProviderProfile/ProviderProfile';
import ProviderGeneral  from './components/provider/ProviderGeneral/ProviderGeneral';
import ProviderClinic  from './components/provider/ProviderClinic/ProviderClinic';
import ProviderService  from './components/provider/ProviderService/ProviderService';
import ProviderEducation  from './components/provider/ProviderEducation/ProviderEducation';
import ProviderRegistration  from './components/provider/ProviderRegistration/ProviderRegistration';
import ProviderSpecialization  from './components/provider/ProviderSpecialization/ProviderSpecialization';
import Appointments  from './components/provider/Appointments/Appointments';
import ReviewList  from './components/provider/ReviewList/ReviewList';
import AppointmentDocuments from './components/provider/AppointmentDocuments/AppointmentDocuments';
import PhotoCapture from './components/general/PhotoCapture/PhotoCapture';
import ImageSlides from './components/general/ImageSlides/ImageSlides';
import RequestAppointment from './components/provider/RequestAppointment/RequestAppointment';
import ProviderPaymentOptions from './components/provider/ProviderPaymentOptions/ProviderPaymentOptions';
import ReportReviewForm from './components/provider/ReportReviewForm/ReportReviewForm';
import ReportedReviews from './components/provider/ReportedReviews/ReportedReviews';
import ViewReviewReports from './components/admin/ViewReviewReports/ViewReviewReports';
import InsuranceList from './components/admin/InsuranceList/InsuranceList';
import InsuranceForm from './components/admin/InsuranceForm/InsuranceForm';
import VitalList from './components/admin/VitalList/VitalList';
import VitalForm from './components/admin/VitalForm/VitalForm';
import UsersReport from './components/admin/UsersReport/UsersReport';
import RegisterProvider from './components/registerprovider/RegisterProvider';

import RegisterPatient from './components/registerpatient/RegisterPatient';
import RegisterLanding from './components/registerlanding/RegisterLanding';
import AppReleaseList from './components/admin/AppReleaseList/AppReleaseList';
import AppReleaseForm from './components/admin/AppReleaseForm/AppReleaseForm';
import ProviderPreference from './components/provider/ProviderPreference/ProviderPreference';
import AppointmentQuestions from './components/provider/AppointmentQuestions/AppointmentQuestions';
import AppointmentQuestionForm from './components/provider/AppointmentQuestionForm/AppointmentQuestionForm';
import PatientRegistration from './components/patientregistration/PatientRegistration';
import PrintPatientRegistration from './components/printpatientregistration/PrintPatientRegistration';
import HealthHistoryForm from './components/healthhistoryform/HealthHistoryForm';
import PrintHealthHistory from './components/healthhistoryform/printhealthhistoryform/PrintHealthHistory';
import DicomUploader from './components/dicomuploader/DicomUploader';
import DicomScans from './components/dicomscans/DicomScans';
import ScanList from './components/patient/scanlist/ScanList';
import ScanForm from './components/patient/scanform/ScanForm';
import DicomViewer from './components/dicomviewer/DicomViewer';
import DocumentForm from './components/provider/DocumentForm/DocumentForm';
import SpecializationList from './components/admin/SpecializationList/SpecializationList';
import SpecializationForm from './components/admin/SpecializationForm/SpecializationForm';
import ServiceList from './components/admin/ServiceList/ServiceList';
import ServiceForm from './components/admin/ServiceForm/ServiceForm';
import ProviderCertificateForm from './components/provider/ProviderCertificateForm/ProviderCertificateForm';
import DashboardCardList from './components/admin/DashboardCardList/DashboardCardList';
import DashboardCardForm from './components/admin/DashboardCardForm/DashboardCardForm';
import RoleList from './components/admin/RoleList/RoleList';
import RoleControlMapList from './components/admin/RoleControlMapList/RoleControlMapList';
import ChangeDisplayForm from './components/admin/ChangeDisplayForm/ChangeDisplayForm';
import ChangeParentForm from './components/admin/ChangeParentForm/ChangeParentForm';
import NotificationList from './components/provider/NotificationList/NotificationList';
import NotificationPreferences from './components/provider/NotificationPreferences/NotificationPreferences';
import FaqList from './components/admin/FaqList/FaqList';
import FaqForm from './components/admin/FaqForm/FaqForm';
import ProviderFaqList from './components/provider/ProviderFaqList/ProviderFaqList';
import DoctorNotesForm from './components/provider/DoctorNotesForm/DoctorNotesForm';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#269592"
    },
    secondary: {
      main: "#84d8d4"
    }
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif',].join(','),    
  },
});

export const App = () => {
  const hasLogin = window.location.pathname !== "/";
  console.log('window.location.pathname=', window.location.pathname);


  return (
    <MuiThemeProvider theme={theme}>
      <ProgressProvider>
        <Router>
          <Header />
          <div className="app-container">
            <Switch>
              <Route exact={true} path="/" component={Login} />
              <Route path="/login/:tk" component={Login} />
              <Route path="/createslots" component={CreateSlots} />
              <Route path="/providers" component={ProviderList} />
              <Route path="/providerform" component={ProviderFormContainer} />
              <Route path="/confirmdialog" component={ConfirmDialog} />
              <Route path="/providerprofile" component={ProviderProfile} />
              <Route path="/providergeneral" component={ProviderGeneral} />
              <Route path="/providerclinic" component={ProviderClinic} />
              <Route path="/providerservice" component={ProviderService} />
              <Route path="/providerspecialization" component={ProviderSpecialization} />
              <Route path="/providereducation" component={ProviderEducation} />
              <Route path="/providerregistration" component={ProviderRegistration} />
              <Route path="/appointments" component={Appointments} />
              <Route path="/reviewlist" component={ReviewList} />
              <Route path="/appointmentdocuments" component={AppointmentDocuments} />
              <Route path="/photocapture" component={PhotoCapture} />
              <Route path="/imageslides" component={ImageSlides} />
              <Route path="/requestappointment" component={RequestAppointment} />
              <Route path="/requestappointmentportal" component={RequestAppointment} />
              <Route path="/providerpaymentoptions" component={ProviderPaymentOptions} />
              <Route path="/reportreviewform" component={ReportReviewForm} />
              <Route path="/reportedreviews" component={ReportedReviews} />
              <Route path="/viewreviewreports" component={ViewReviewReports} />
              <Route path="/insurancelist" component={InsuranceList} />
              <Route path="/insuranceform" component={InsuranceForm} />
              <Route path="/vitallist" component={VitalList} />
              <Route path="/vitalform" component={VitalForm} />
              <Route path="/usersreport" component={UsersReport} />
              <Route path="/registerprovider" component={RegisterProvider} />
              <Route path="/registerpatient" component={RegisterPatient} />
              <Route path="/registerlanding" component={RegisterLanding} />
              <Route path="/register/:tk" component={RegisterLanding} />
              <Route path="/appreleaselist" component={AppReleaseList} />
              <Route path="/appreleaseform" component={AppReleaseForm} />
              <Route path="/providerpreference" component={ProviderPreference} />
              <Route path="/appointmentquestions" component={AppointmentQuestions} />
              <Route path="/appointmentquestionsform" component={AppointmentQuestionForm} />
              <Route path="/patientregistration/:tk" component={PatientRegistration} />
              <Route path="/printpatientregistration" component={PrintPatientRegistration} />
              <Route path="/healthhistoryform/:tk" component={HealthHistoryForm} />
              <Route path="/printhealthhistory" component={PrintHealthHistory} />
              <Route path="/dicomscans" component={DicomScans} />
              <Route path="/scans" component={ScanList} />
              <Route path="/scanform/:id/:title/:type/:apptId/:custId" component={ScanForm} />
              <Route path="/dicomviewer/:stid/:title/:type" component={DicomViewer} />
              <Route path="/documentform" component={DocumentForm} />
              <Route path="/specializationlist" component={SpecializationList} />
              <Route path="/specializationform" component={SpecializationForm} />
              <Route path="/servicelist" component={ServiceList} />
              <Route path="/serviceform" component={ServiceForm} />
              <Route path="/certificateform" component={ProviderCertificateForm} />
              <Route path="/dashboardcardlist" component={DashboardCardList} />
              <Route path="/dashboardcardform" component={DashboardCardForm} />
              <Route path="/rolelist" component={RoleList} />
              <Route path="/rolecontrolmaplist" component={RoleControlMapList} />
              <Route path="/changedisplayform" component={ChangeDisplayForm} />
              <Route path="/changeparentform" component={ChangeParentForm} />
              <Route path="/notificationlist" component={NotificationList} />
              <Route path="/notificationpreferences" component={NotificationPreferences} />
              <Route path="/faqlist" component={FaqList} />
              <Route path="/faqform" component={FaqForm} />
              <Route path="/providerfaqs" component={ProviderFaqList} />
              <Route path="/doctornotesform/:apptId/:title" component={DoctorNotesForm} />
            </Switch>
          </div>
          <ToastContainer />
        </Router>
      </ProgressProvider>
    </MuiThemeProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import './ProviderForm.scss';
import { FxInput, FxTextarea, FxSelect, AlertService, http, session, formatService, FormValidator } from '../../fx-core';
import { Button,Link } from '@material-ui/core';

const ProviderForm = (props) => {

    const defaultProvider = {
        prefix: "Dr.",
        userName: "",
        firstName: "",
        lastName: "",
        experience: "",
        about: "",
        email: "",
        mobile: "",
        id: 0,
        providerUserName: "",
        currency: "USD",
        registrationDate: "",
        isSelfSignUp: false,
        dateFormat: "MM/DD/YYYY",
        cancellationFee: 0,
        cancellationHours: 24,
        showReviews: "Yes"
    };
    const [provider, setProvider] = useState(defaultProvider);
    const [errors, setErrors] = useState({});
    const [currencyList, setCurrencyList] = useState([]);
    const [dateFormatList, setDateFormatList] = useState([]);

    const validationMap = {
        userName: ["required", "alphanum"],
        firstName: ["required", "alphabets"],
        lastName: ["required", "alphabets"],
        experience: ["required", "number"],
        about: ["required"],
        email: ["required", "email", "emailvalid"],
        mobile: ["required", "mobileno"],
        cancellationFee: ["number"],
        cancellationHours: ["number"]
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            if (provider.id > 0 && key == "userName") {
                continue;
            }
            let item = { name: key, value: provider[key], validations: validationMap[key] }
            items.push(item);
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                Currency: { default: false },
                DateFormat: { default: false }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'Options/GetLookupOptions',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setCurrencyList(response.data.Currency);
            setDateFormatList(response.data.DateFormat);
        });
    }

    const addProvider = () => {
        const isValid = bulkValidate();
        if (isValid) {
            provider.registrationDate = formatService.getCurrentDateForServer();
            let apiOptions: any = {
                url: `provider/add`,
                data: provider
            };
            http.post(apiOptions).then(res => {
                saveProviderCallBack(res);
            });
        }
    };

    const updateProvider = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `provider/update`,
                data: provider
            };
            http.put(apiOptions).then(res => {
                saveProviderCallBack(res);
            });
        }
    };

    const saveProviderCallBack = (res) => {
        AlertService.showSuccessMsg();
        if (props.providerId > 0) {
            getProviderById();
        }
        else {
            props.back();
        }
    };

    const getProviderById = () => {
        let apiOptions: any = {
            url: `provider/${props.providerId}`
        };
        http.get(apiOptions).then(res => {
            getProviderByIdCallBack(res);
        });
    };

    const getProviderByIdCallBack = (res) => {
        setProvider(res.data);
    };

    const handleInputChange = (item) => {
        handleProviderObjChange({ [item.name]: item.value });
    };

    const handleCheckbox = (event) => {
        handleProviderObjChange({ [event.target.name]: event.target.checked });
    };

    const handleProviderObjChange = (data) => {
        setProvider(prevState => {
            return { ...prevState, ...data }
        });
    };

    const mailDeepLink = () => {
        let inputData: any = {
            id: props.providerId
        };

        let apiOptions: any = {
            url: `provider/${inputData.id}/maildeeplinkqrcode`,
            data: inputData
        };

        http.get(apiOptions).then(res => {
            AlertService.showSuccessMsg("Mailed link successfully");
        });
    };

    useEffect(() => {
        initLookup();
        if (props.providerId > 0) {
            getProviderById();
        }
    }, []);

    return (
        <div className="content-container2 card">
            <div className="title-font">
                <span>Provider</span>
            </div>
            <div>
                <div className="row mt-2 px-3 m-0">
                    <div className="col-4">
                        {
                            provider.id == 0 &&
                            <div className="">
                                <FxInput name="userName" variant="outlined" label="Username*" size="small"
                                    value={provider.userName}
                                    onValueChange={handleInputChange}
                                />
                                {
                                    hasError("userName", "required") &&
                                    <div className="validation-msg">Field is required</div>
                                }
                                {
                                    hasError("userName", "alphanum") &&
                                    <div className="validation-msg">Username should contain alphabets and numbers</div>
                                }
                            </div>
                        }


                        {
                            provider.id > 0 &&
                            <div className="">
                                <FxInput name="userName" variant="outlined" label="Username" size="small"
                                    value={provider.providerUserName}
                                    disabled={true}
                                />
                            </div>
                        }
                    </div>

                    <div className="col-4">
                        <FxInput name="firstName" variant="outlined" label="First name*" size="small"
                            value={provider.firstName}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("firstName", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("firstName", "alphabets") &&
                            <div className="validation-msg">First name should contain only alphabets</div>
                        }
                    </div>

                    <div className="col-4">
                        <FxInput name="lastName" variant="outlined" label="Last name*" size="small"
                            value={provider.lastName}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("lastName", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("lastName", "alphabets") &&
                            <div className="validation-msg">Last name should contain only alphabets</div>
                        }
                    </div>

                    <div className="col-4">
                        <FxInput name="mobile" variant="outlined" label="Mobile*" size="small"
                            value={provider.mobile}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("mobile", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("mobile", "mobileno") &&
                            <div className="validation-msg">Mobile should contain only numbers</div>
                        }
                    </div>
                    <div className="col-4">
                        <FxInput name="email" variant="outlined" label="Email*" size="small"
                            value={provider.email}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("email", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            (hasError("email", "email") || hasError("email", "emailvalid")) &&
                            <div className="validation-msg">Please enter valid email id</div>
                        }
                    </div>
                    <div className="col-4">
                        <FxInput name="experience" variant="outlined" label="Experience*" size="small"
                            value={provider.experience}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("experience", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                        {
                            hasError("experience", "number") &&
                            <div className="validation-msg">Experience should contain only numbers</div>
                        }
                    </div>
                    <div className="col-12">
                        <FxTextarea name="about" variant="outlined" label="About*" size="small"
                            value={provider.about} maxRows={5}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("about", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                    </div>
                    {
                        props.providerId == 0 &&
                        <div className="col-12">
                            <FxSelect name="currency" variant="outlined" label="Currency"
                                options={currencyList} className="selectStyle"
                                selectedValue={provider.currency} valueField="code" displayField="text"
                                onValueChange={handleInputChange} />
                        </div>
                    }
                    {
                        props.providerId == 0 &&
                        <div className="col-12 row m-0 p-0">
                            <div className="col-4">
                                <FxSelect name="dateFormat" variant="outlined" label="Date Format" fullWidth
                                    options={dateFormatList}
                                    selectedValue={provider.dateFormat} valueField="code" displayField="text"
                                    onValueChange={handleInputChange} />
                            </div>
                            <div className="col-4">
                                <FxInput name="cancellationFee" variant="outlined" label="Cancellation Fee" size="small" fullWidth
                                    value={provider.cancellationFee} onValueChange={handleInputChange}
                                />
                                {
                                    hasError("cancellationFee", "number") &&
                                    <div className="validation-msg">Cancellation fee should contain only numbers</div>
                                }
                            </div>
                            <div className="col-4">
                                <FxInput name="cancellationHours" variant="outlined" label="Cancellation Hours" size="small" fullWidth
                                    value={provider.cancellationHours} onValueChange={handleInputChange}
                                />
                                {
                                    hasError("cancellationHours", "number") &&
                                    <div className="validation-msg">Cancellation hours should contain only numbers</div>
                                }
                            </div>
                        </div>
                    }

                    <div className="row m-0 col-12 p-0 mt-3">
                        <div className='col-6'>
                            {
                                provider.id > 0 &&
                                <Link className="pointer" onClick={mailDeepLink}>Get Personalized Link</Link>
                            }
                        </div>
                        <div className='col-6 text-right'>
                            {
                                props.providerId == 0 &&
                                <span className="pl-2">
                                    <Button variant="contained" size="small" color="primary" onClick={props.back}>Cancel</Button>
                                </span>
                            }
                            <span className="pr-2">
                                {
                                    provider.id == 0 &&
                                    <Button variant="contained" size="small" color="primary" onClick={addProvider}>Save</Button>
                                }
                                {
                                    provider.id > 0 &&
                                    <Button variant="contained" size="small" color="primary" onClick={updateProvider}>Save</Button>
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProviderForm;
import React from 'react';
import { IconButton, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FxInput.styles.scss';

export const FxInput = (props:any) => {
    if (props.visibility === false) {
        return <></>;
    }
    const { name, onValueChange, variant, startIcon, endIcon, size="small" } = props;
    let inputProps = {};
    if(endIcon) {
        inputProps={
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <FontAwesomeIcon className="textfieldicon" icon={endIcon}/>
                </IconButton>
              </InputAdornment>
            ),
          };
    }
    const onChange = (event:any) => {
        console.log(event);
        const data = { name, value: event.target.value };
        onValueChange(data);
    };

    const controlType = props.type || 'text'; // possible values - text, number, password
    return (
        <TextField
            className={props.className +' fx-input'}
            type={controlType}
            size={size}
            name={props.name}
            value={props.value}
            onChange={onChange}
            label={props.label}
            variant={variant === 'outlined' ? 'outlined' : 'standard'}
            disabled={props.disabled}
            required={props.required}
            fullWidth={props.fullWidth}
            InputProps={inputProps}
            style={props.style}
            InputLabelProps={{ shrink: true }}
        />
    );
};

import React, { useState, useEffect } from 'react';
import './RoleControlMapList.scss';
import { http, FxSelect, AlertService } from '../../fx-core';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, Checkbox } from '@material-ui/core';
import ChangeDisplayForm from '../ChangeDisplayForm/ChangeDisplayForm';
import ChangeParentForm from '../ChangeParentForm/ChangeParentForm';
import * as _ from 'lodash';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

const RoleControlMapList = (props) => {
    const history = useHistory();
    const defaultItem = {
        controlTargetTp: "",
        roleId: props.location.state ? props.location.state.roleId : 0
    };
    const [item, setItem] = useState(defaultItem);
    const [roleControls, setRoleControls] = useState([]);
    const [controlTargetType, setControlTargetType] = useState([]);
    const [roleName, setRoleName] = useState(props.location.state ? props.location.state.roleName : '');
    const [controlId, setControlId] = useState(0);
    const [controlDisplay, setControlDisplay] = useState(null);
    const [changeDisplayOpen, setChangeDisplayOpen] = useState(false);
    const [changeParentOpen, setChangeParentOpen] = useState(false);
    const [moduleCode, setModuleCode] = useState(null);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        if (item.name == "controlTargetTp") {
            getRoleControls(item.value);
        }
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleCheckbox = (e, id, index) => {
        let itemObj = roleControls.find((i: any) => i.id == id);
        itemObj.isRoleControlMapEntry = e.target.checked;
        roleControls.splice(index, 1, itemObj);
        updateControls(roleControls);
    };

    const updateControls = (data: any) => {
        setRoleControls((prevState: any) => {
            return [...data]
        });
    }


    const initLookup = () => {
        let inputData = {
            lookups: {
                ControlTargetType: { default: false }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'Options/GetLookupOptions',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let defaultCtrlTp;
            setControlTargetType(response.data.ControlTargetType);
            if (response.data.ControlTargetType.length > 0) {
                defaultCtrlTp = response.data.ControlTargetType[0].code;
                handleItemChange({ controlTargetTp: response.data.ControlTargetType[0].code });
            }
            getRoleControls(defaultCtrlTp);
        });
    }

    const getRoleControls = (type) => {
        var moduleCode;
        if (roleName == "Provider Role") {
            moduleCode = "provider";
        }
        else if (roleName == "Customer Role") {
            moduleCode = "customer";
        }
        setModuleCode(moduleCode);
        let inputData = {
            id: item.roleId,
            controlTargetType: type ? type : item.controlTargetTp,
            moduleCode: moduleCode
        }
        let apiOptions: any = {
            url: `role/screenlist`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            setRoleControls(res.data);
        });
    };

    const back = () => {
        history.goBack();
    };

    const changeDisplay = (item) => {
        setControlId(item.id);
        setControlDisplay(item.display);
        setChangeDisplayOpen(true);
    }

    const handleChangeDisplayFormClose = () => {
        setChangeDisplayOpen(false);
        getRoleControls(item.controlTargetTp);
    };

    const changeParent = (id) => {
        setControlId(id);
        setChangeParentOpen(true);
    }

    const handleChangeParentFormClose = () => {
        setChangeParentOpen(false);
        getRoleControls(item.controlTargetTp);
    };

    const handleRLDDChange = (items) => {
        setRoleControls(items);
    }

    const saveOrder = () => {
        console.log(roleControls);
        let roleControlItems = [];
        let displayOrderVal = 0;
        for (let itemObj of roleControls) {
            if (itemObj.isRoleControlMapEntry) {
                let roleControlMap = {
                    roleId: item.roleId,
                    controlId: itemObj.id,
                    displayOrder: displayOrderVal
                }
                roleControlItems.push(roleControlMap);
                displayOrderVal++;
            }
        }
        let inputData = {
            id: item.roleId,
            items: roleControlItems,
            controlTargetType: item.controlTargetTp
        }
        let apiOptions: any = {
            url: `role/ManageRoleControlMaps`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            AlertService.showSuccessMsg();
        });
    }

    useEffect(() => {
        initLookup();
    }, []);

    const itemRenderer = (row: any, i: number): JSX.Element => {
        return (
            <div className="row col-12 m-0 border-bottom py-1">
                <div className="col-1 pl-4">
                    <span> <i className="fas fa-grip-vertical"></i></span>
                </div>
                <div className="col-7">
                    <span> {row.controlDisplay}</span>
                </div>
                <div className="col-4 btn-style">
                    <span className="float-right">

                        <span className="table-font pl-2">
                            <Checkbox name="isRoleControlMapEntry" color="primary" checked={row.isRoleControlMapEntry}
                                value={row.isRoleControlMapEntry} onChange={(e) => handleCheckbox(e, row.id, i)} />
                        </span>

                        <span className="table-font pl-2">
                            <Button variant="contained" size="small" color="primary"
                                onClick={() => changeDisplay(row)}> Change Display
                            </Button>
                        </span>
                        <span className="table-font pl-2">
                            <Button variant="contained" size="small" color="primary"
                                onClick={() => changeParent(row.id)}> Change Parent
                            </Button>
                        </span>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className="content-container">
            <div className="title-font">
                <span>Screen Form - {roleName}</span>
            </div>

            <div className="px-3">
                <div className="py-3">
                    <FxSelect name="controlTargetTp" variant="outlined" label="Screens"
                        options={controlTargetType} className="selectStyle"
                        selectedValue={item.controlTargetTp} valueField="code" displayField="text"
                        onValueChange={handleInputChange} />
                </div>

                <div className="border py-1 dragdroptitle text-center text-bold">
                    Drag & Drop
                </div>
                <RLDD
                    cssClasses="list-container"
                    items={roleControls}
                    itemRenderer={itemRenderer}
                    onChange={handleRLDDChange}
                />

                <div className="text-right">
                    <span className="pl-3">
                        <Button variant="contained" size="small" onClick={back}>Back</Button>
                    </span>
                    <span className="pl-3">
                        <Button variant="contained" size="small" color="primary" onClick={saveOrder}>Save</Button>
                    </span>
                </div>
            </div>
            <Dialog
                className="rolecontrolmapdialogue"
                open={changeDisplayOpen}
                maxWidth="sm"
                onClose={handleChangeDisplayFormClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ChangeDisplayForm controlId={controlId} controlDisplay={controlDisplay} handleChangeDisplayFormClose={handleChangeDisplayFormClose} />
            </Dialog>
            <Dialog
                className="rolecontrolmapdialogue"
                open={changeParentOpen}
                maxWidth="sm"
                onClose={handleChangeParentFormClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ChangeParentForm controlId={controlId} controlTargetType={item.controlTargetTp} moduleCode={moduleCode}
                    roleId={item.roleId} handleChangeParentFormClose={handleChangeParentFormClose} />
            </Dialog>
        </div>
    )
};

export default RoleControlMapList;
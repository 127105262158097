
const initialState = {};
  
export const cart = (state = initialState, action:any) =>{
  switch (action.type) {
    case "UPDATE_CART":
      return {
          ...state, ...action.payload,
      };
    default:
      return state
  }
}


import { localStorageService } from "../components/fx-core";

const initialState = {
  isExternalLogin: false
};
  
export const externallogin = (state = initialState, action:any) =>{
  switch (action.type) {
    case "UPDATE_EXTERNAL_LOGIN":
      localStorageService.setItem("external", action.payload.isExternalLogin);
      return {
          ...state, ...action.payload,
      };
    default:
      return state
  }
}


import React, { useEffect, useState } from 'react';
import './CreateSlots.css';
import { FxInput, FxDate, FxSelect, FxTime, http, AlertService, FxDateDisplay, formatService, session, useNavigationService } from '../../fx-core';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonIcon from '@material-ui/icons/Person';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { orange, grey } from '@material-ui/core/colors';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import { MobileVerificationDialog } from '../MobileVerificationDialog/MobileVerificationDialog';
import { EmailVerificationDialog } from '../EmailVerificationDialog/EmailVerificationDialog';
import { useSelector } from 'react-redux';
import { Constants } from '../../fx-core/helpers/constants';
import { parseISO } from 'date-fns';

const CreateSlots = () => {
  const { pageTitle } = useNavigationService();
  const initialList = [];
  const [providerClinics, setProviderClinics] = useState(initialList);
  const [valDateError, setValDateError] = useState(null);
  const [valEndTimeError, setValEndTimeError] = useState(null);
  const [valStartTimeError, setValStartTimeError] = useState(null);
  const [requiredError, setRequiredError] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [slotDetails, setSlotDetails] = useState([]);
  const [message, setMessage] = useState(null);
  const [action, setAction] = React.useState(null);
  const [providerName, setProviderName] = React.useState(null);
  const [mobileVerifyOpen, setMobileVerifyOpen] = useState(false);
  const [emailVerifyOpen, setEmailVerifyOpen] = useState(false);
  const preferences = useSelector((store: any) => store.preferences);
  const dateFormat = preferences.DateFormat;
  const [startMinDate, setStartMinDate] = useState(parseISO(formatService.getCurrentDateForServer()));
  const [numValidator, setNumValidator] = useState(null);

  let defaultFilter: any = {
    startDate: formatService.getCurrentDate(Constants.ServerDateFormat),
    endDate: formatService.parseDate("dayPlusSeven", Constants.ServerDateFormat),
    startTime: "09:00",
    endTime: "17:00",
    interval: 30,
    providerClinicId: ''
  };
  const [filter, setFilter] = useState(defaultFilter);
  const [open, setOpen] = React.useState(false);
  const [providerId, setProviderId] = React.useState(null);
  const [slotItem, setSlotItem] = React.useState({});
  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
  }

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleStatusChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
  }

  const initLookup = () => {
    let providerId = session.getItem('providerId');
    setProviderId(providerId);
    if (providerId) {
      let inputData = {
        lookups: {
          ProviderClinics: { default: false }
        },
        filters: {
          providerId: providerId
        }
      };
      let apiOptions = {
        url: 'Options/GetLookupOptions',
        data: inputData
      };
      http.post(apiOptions).then(response => {
        setProviderClinics(response.data.ProviderClinics);
        if (response.data.ProviderClinics.length > 0) {
          handleFilterChange({ providerClinicId: response.data.ProviderClinics[0].id });
        }
      });
    }
  }

  function getDaysBetweenDates(startDate, endDate) {
    var slotStartDate = formatService.getDateStringForServer(startDate, dateFormat);
    var slotEndDate = formatService.getDateStringForServer(endDate, dateFormat);
    var dates = [];

    while (slotStartDate <= slotEndDate) {
      dates.push(formatService.getDateStringForServer(slotStartDate, dateFormat));
      slotStartDate = formatService.getNextDate(slotStartDate);
    }
    return dates;
  };

  function getCurrentFilter() {
    let dates = getDaysBetweenDates(filter.startDate, filter.endDate);
    let filters = {
      dates: dates,
      startTime: formatService.getFormattedTimeForServer(filter.startTime),
      endTime: formatService.getFormattedTimeForServer(filter.endTime),
      interval: filter.interval,
      providerClinicId: filter.providerClinicId,
      providerId: providerId
    };
    let inputData = {
      filters: filters
    };
    return inputData;
  };


  const getProviderSlots = () => {
    let inputData = getCurrentFilter();
    let apiOptions: any = {
      url: 'provider/slots',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      slotsCallBack(response.data);
    })
  }


  const getOrCreateProviderSlots = () => {
    setValDateError(null);
    setValStartTimeError(null);
    setValEndTimeError(null);
    setRequiredError(null);
    setNumValidator(null);
    if (!filter.endDate || !filter.startDate ||
      !filter.startTime || !filter.endTime || !filter.providerClinicId || !filter.interval
      || (filter.interval && filter.interval.toString().trim().length == 0)) {
      setRequiredError("This field is required");
      return;
    }

    if (filter.interval && isNaN(filter.interval)) {
      setNumValidator("Interval should contain only numbers");
      return;
    }

    let selectedStartDate = formatService.getConvertedDate(filter.startDate);
    let selectedEndDate = formatService.getConvertedDate(filter.endDate);
    if (formatService.isBefore(selectedStartDate, selectedEndDate)) {
      setValDateError("End date should be greater than or equal to Start date");
      return;
    }

    if (filter.startTime) {
      let selectedStartTime = formatService.getConvertedTime(filter.startTime);
      let startDateTime = selectedStartDate + " " + selectedStartTime;
      let currentDateTime = formatService.getCurrentDateTime();

      if (formatService.isBefore(currentDateTime, startDateTime)) {
        setValStartTimeError("Please select future start time ");
        return;
      }
    }

    if (filter.startTime && filter.endTime) {
      let selectedStartTime = formatService.getConvertedTime(filter.startTime);
      let startDateTime = selectedStartDate + " " + selectedStartTime;

      let selectedEndTime = formatService.getConvertedTime(filter.endTime);
      let endDateTime = selectedStartDate + " " + selectedEndTime;

      if (formatService.isBefore(startDateTime, endDateTime)) {
        setValEndTimeError("End time should be greater than start time");
        return;
      }
    }

    let inputData = getCurrentFilter();
    let apiOptions: any = {
      url: 'provider/slots/getorcreate',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      console.log(response.data);
      slotsCallBack(response.data);
    }).catch(err => {
      if (err && err.correctiveActionCode) {
        if (err.correctiveActionCode == 'VERIFY_EMAIL') {
          openEmailVerify();
        }
        else if (err.correctiveActionCode == 'VERIFY_MOBILE') {
          openMobileVerify();
        }
      }
    });
  }

  const slotsCallBack = (response: any) => {
    for (var item of response) {
      setProviderName(item.providerName);
      for (var slot of item.slots) {
        var splitTime = slot.startTime.split(":");
        slot.slotTime = splitTime[0].trim() + ":" + splitTime[1].trim();
      }
    }
    setSlotDetails(response);
    setCurrentStep(2);
  }

  const navigateToSlots = () => {
    getOrCreateProviderSlots();
  };

  const navigateToBack = () => {
    setFilter(defaultFilter);
    initLookup();
    setCurrentStep(1);
  };

  const deleteAllSlotsConfirm = (item: any) => {
    setSlotItem(item);
    setAction('delete');
    setMessage("Do you want to delete all slots?");
    setOpen(true);
  };

  const confirmMessage = (action: any) => {
    setOpen(false);
    if (action == 'delete') {
      deleteSlots(slotItem);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const deleteSlots = (item: any) => {
    let inputData = {
      providerClinicId: item.providerClinicId,
      serviceDate: item.serviceDate,
    };
    let apiOptions: any = {
      url: 'provider/slots/delete', data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderSlots();
    })
  }

  const blockSlots = (item: any) => {
    let inputData = {
      providerClinicId: item.providerClinicId,
      serviceDate: item.serviceDate,
    };
    let apiOptions: any = {
      url: 'provider/slots/block', data: inputData
    };
    http.post(apiOptions).then(response => {
      getProviderSlots();
    })
  }

  const unBlockSlots = (item: any) => {
    let inputData = {
      providerClinicId: item.providerClinicId,
      serviceDate: item.serviceDate,
    };
    let apiOptions: any = {
      url: 'provider/slots/unblock', data: inputData
    };
    http.post(apiOptions).then(response => {
      getProviderSlots();
    })
  }

  const toggleSlot = (item: any) => {
    if (item.slotStatusId == 2) {
      return;
    }
    else if (item.slotStatusId == 1) {
      item.slotStatusId = 3;
    }
    else if (item.slotStatusId == 3) {
      item.slotStatusId = 1;
    }

    let inputData = {
      id: item.id,
      slotStatusId: item.slotStatusId
    }
    let apiOptions: any = {
      url: 'provider/slot/toggle', data: inputData
    };
    http.post(apiOptions).then(response => {
      getProviderSlots();
    })
  }

  const openMobileVerify = () => {
    setMobileVerifyOpen(true);
  };

  const closeMobileVerify = () => {
    setMobileVerifyOpen(false);
  };

  const onSuccessMobileVerify = (res) => {
    closeMobileVerify();
    if (res == "success") {
      getOrCreateProviderSlots()
    }
  };

  const openEmailVerify = () => {
    setEmailVerifyOpen(true);
  };

  const closeEmailVerify = () => {
    setEmailVerifyOpen(false);
  };

  const onSuccessEmailVerify = (res) => {
    closeEmailVerify();
    if (res == "success") {
      getOrCreateProviderSlots()
    }
  };

  useEffect(() => {
    initLookup();
  }, []);


  const body = (
    <div className="slot-container">
      <div className="title-font">
        <span>{pageTitle}</span>
      </div>

      <div className="container-fluid px-5">
        {currentStep === 1 &&
          <div>
            <div className="row col-8 m-0">
              <div className="col-3 pt-3"> Start Date* </div>
              <div className="col-9">
                <FxDate name="startDate" label="Start Date" variant="outlined" minDate={startMinDate}
                  value={filter.startDate} onValueChange={handleInputChange} />
                {requiredError && !filter.startDate &&
                  <div className="error"> {requiredError} </div>}
              </div>
            </div>

            <div className="row col-8 m-0">
              <div className="col-3 pt-3"> End Date* </div>
              <div className="col-9">
                <FxDate name="endDate" label="End Date" variant="outlined" minDate={startMinDate}
                  value={filter.endDate} onValueChange={handleInputChange} />
                {requiredError && !filter.endDate &&
                  <div className="error"> {requiredError} </div>}
                {valDateError &&
                  <div className="error"> {valDateError} </div>}
              </div>
            </div>


            <div className="row col-8 m-0">
              <div className="col-3 pt-4"> Start Time* </div>
              <div className="col-9">
                <FxTime name="startTime" label="Start Time" variant="outlined"
                  value={filter.startTime} onValueChange={handleInputChange} />
                {requiredError && !filter.startTime &&
                  <div className="error"> {requiredError} </div>}
                {valStartTimeError &&
                  <div className="error"> {valStartTimeError} </div>}
              </div>
            </div>

            <div className="row col-8 m-0">
              <div className="col-3 pt-4"> End Time* </div>
              <div className="col-9">
                <FxTime name="endTime" label="End Time" variant="outlined"
                  value={filter.endTime} onValueChange={handleInputChange} />
                {requiredError && !filter.endTime &&
                  <div className="error"> {requiredError} </div>}
                {valEndTimeError &&
                  <div className="error"> {valEndTimeError} </div>}
              </div>
            </div>

            <div className="row col-8 m-0">
              <div className="col-3 pt-3"> Provider Clinic* </div>
              <div className="col-9">
                <FxSelect name="providerClinicId" variant="outlined" label="Provider Clinic"
                  options={providerClinics}
                  selectedValue={filter.providerClinicId}
                  valueField="id"
                  displayField="text"
                  onValueChange={handleStatusChange} />
                {requiredError && !filter.providerClinicId &&
                  <div className="error"> {requiredError} </div>}
              </div>
            </div>

            <div className="row col-8 m-0">
              <div className="col-3 pt-2"> Time Interval (minutes)* </div>
              <div className="col-9">
                <FxInput name="interval" variant="outlined" label="Interval" size="small"
                  value={filter.interval} onValueChange={handleInputChange}
                />
                {requiredError && (!filter.interval || (filter.interval && filter.interval.toString().trim().length == 0)) &&
                  <div className="error"> {requiredError} </div>}
                {numValidator &&
                  <div className="error"> {numValidator} </div>}
              </div>
            </div>

          </div>
        }

        {currentStep === 2 &&
          slotDetails.length > 0 &&
          <>
            <div className="row p-0 pb-3 col-12 m-0">
              <div className="col-7"><b>{providerName}</b></div>
              <div className="col-5 row m-0 justify-content-end">
                <span><StopRoundedIcon color="primary" />Available</span>

                <div className="pl-3">
                  <span><StopRoundedIcon style={{ color: orange[500] }} />Booked</span>
                </div>

                <div className="pl-3">
                  <span><StopRoundedIcon style={{ color: grey[500] }} />Not Available</span>
                </div>
              </div>
            </div>
            {
              slotDetails.map((item) => (
                <div className="row m-0 py-2 even">
                  <div className="col-6">
                    <span className="font-15">
                      <b>
                        <FxDateDisplay value={item.serviceDate} />
                      </b>
                      <HospitalIcon className='ml-3' color="primary" /> {item.providerClinicName}</span>
                  </div>

                  <div className="col-6 text-right pr-3">
                    <span onClick={() => deleteAllSlotsConfirm(item)} className="pointer">
                      <DeleteIcon color="primary" />
                      <span className="ml-1 cursor">Delete</span>
                    </span>
                    <span className="ml-2 mr-2 pointer" onClick={() => blockSlots(item)}>
                      <LockIcon color="primary" />
                      Block
                    </span>
                    <span className="pointer" onClick={() => unBlockSlots(item)}>
                      <LockOpenIcon color="primary" />
                      UnBlock
                    </span>
                  </div>

                  <div className="col-12 mt-1">
                    {
                      item.slots.map((slot) => (

                        <span onClick={() => toggleSlot(slot)}>
                          {slot.slotStatusId === 1 &&
                            <button className="slot-btn mx-2 my-1">
                              {slot.slotTime}
                            </button>
                          }
                          {slot.slotStatusId === 2 &&
                            <button className="slot-btn mx-2 my-1" style={{ backgroundColor: 'orange', color: 'white' }}>
                              {slot.slotTime}
                            </button>
                          }
                          {slot.slotStatusId === 3 &&
                            <button className="slot-btn mx-2 my-1" style={{ backgroundColor: 'grey', color: 'white' }}>
                              {slot.slotTime}
                            </button>
                          }
                        </span>

                      ))
                    }
                  </div>

                </div>
              ))
            }
          </>
        }

        {currentStep === 1 &&
          <div className="row col-8 m-0">
            <div className="col-3"> </div>
            <div className="col-9 pt-3 button-align">

              <Button variant="contained" size="small" color="primary" onClick={navigateToSlots}>
                Create Slots
              </Button>

            </div>
          </div>
        }
        {currentStep === 2 &&
          <div className="text-right mb-5">
            <Button variant="contained" size="small" color="primary" onClick={navigateToBack}>
              Back
            </Button>
          </div>
        }

      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} action={action} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
        <Dialog
          open={mobileVerifyOpen}
          onClose={closeMobileVerify}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <MobileVerificationDialog handleVerification={onSuccessMobileVerify} closeDialog={closeMobileVerify} />
        </Dialog>
        <Dialog
          open={emailVerifyOpen}
          onClose={closeEmailVerify}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <EmailVerificationDialog handleVerification={onSuccessEmailVerify} closeDialog={closeEmailVerify} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default CreateSlots;
import React, { useEffect, useState } from 'react';
import './ScanList.css';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { session, FxDateDisplay, http, AlertService, useNavigationService } from "../../fx-core";
import ScannerIcon from '@material-ui/icons/Scanner';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import DateIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import ScanIcon from '@material-ui/icons/ScannerOutlined';
import NoteIcon from '@material-ui/icons/Note';

const ScanList = (props: any) => {
  const { pageTitle } = useNavigationService();

  const history = useHistory();
  let defaultItem: any = {
    customerId: session.getItem('customerId')
  };
  const [item, setItem] = useState(defaultItem);
  const initialList = [];
  const [scanList, setScanList] = useState(initialList);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [message, setMessage] = useState(null);
  const [open, setOpen] = React.useState(false);

  const getScanList = () => {
    let inputData = {
      customerId: item.customerId
    }
    let apiOptions: any = {
      url: 'scan/details/query', data: inputData
    };
    http.post(apiOptions).then(response => {
      setScanList(response.data);
    });
  }

  const openScanForm = (scanId = 0) => {
    const apptId = 0;
    const title = "Scan Form";
    history.push({
      pathname: `/scanform/${scanId}/${title}/1/${apptId}/${item.customerId}`,
    });
  };

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setMessage("Do you want to delete " + item.scanName + "?");
    setOpen(true);
  };

  const confirmMessage = () => {
    setOpen(false);
    deleteScan(selectedItem);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const deleteScan = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `scan/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getScanList();
    })
  }

  const openViewer = (detail, item, studyName) => {
    const title = `${item.scan.scanName} - ${studyName}`;
    history.push({
      pathname: `/dicomviewer/${detail.studyId}/${title}/1`,
    });
  }

  useEffect(() => {
    getScanList();
  }, []);


  const body = (
    <div className="content-container">
      <div className="title-font">
        <span>{pageTitle}</span>
        <span onClick={() => openScanForm()} className="pointer"><AddCircleOutlineIcon /></span>
      </div>

      <div>
        {
          scanList.map((item) => (
            <div className="row mx-2 pl-3 pt-1 col-12 alignbox">
              <div className="col-4">
                <ScannerIcon />
                {item.scan.scanName}
              </div>
              <div className="col-4">
                <DateIcon /> <FxDateDisplay value={item.scan.uploadedDate} />
              </div>
              <div className="col-4">
                <PersonIcon /> {item.scan.uploadedByName}
              </div>
              {
                item.scanDetails.map((detail, i) => (
                  <div className="col-12">
                    <ScanIcon />
                    <span className="col-4">
                      Study{i + 1}
                    </span>
                    <span className="col-1 pointer" onClick={() => openViewer(detail, item, `Study${i + 1}`)}>
                      <EyeIcon />
                    </span>
                  </div>

                ))
              }
              <div className="col-8">
                {item.scan.notes &&
                  <span className="pb-1 pl-1">
                    <NoteIcon />{item.scan.notes}
                  </span>
                }
              </div>
              <div className="col-4">
                {!item.scan.isUploadedByProvider &&
                  <span>
                    <span>
                      <Button variant="contained" size="small" color="primary" onClick={() => openScanForm(item.scan.id)}>
                        Edit</Button>
                    </span>
                    <span>
                      <Button variant="contained" className="ml-2" size="small" color="danger" onClick={() => confirmDelete(item.scan)}>
                        Delete</Button>
                    </span>
                  </span>
                }
              </div>
            </div>
          ))
        }

      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
      </Dialog>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ScanList;
import React, { useEffect, useState } from 'react';
import './AppointmentQuestionForm.css';
import CloseIcon from '@material-ui/icons/Close';
import { http, AlertService, FxDateDisplay } from '../../fx-core';
import Switch from "react-switch";
import { Button } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import * as _ from 'lodash';

const AppointmentQuestionForm = (props: any) => {
  let defaultItem: any = {
    appointmentIdentifier: 0,
    appointmentDate: null,
    slotTime: null
  };
  const initialList = [];
  const [item, setItem] = useState(defaultItem);
  const [questionList, setQuestionList] = useState(initialList);


  const getQuestionList = () => {
    let apiOptions: any = {
      url: `appointmentquestion/query/arrived`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      for (let item of result) {
        item.toggle = false;
      }
      setQuestionList(response.data);
    })
  }

  const getAppointmentById = () => {
    let apiOptions: any = {
      url: `stores/service/appointment/${props.apptId}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      var splitTime = result.startTime.split(":");
      result.slotTime = splitTime[0].trim() + ":" + splitTime[1].trim();
      setItem(result);
      getQuestionList();
    })
  }

  const handleClose = () => {
    props.handleQuestionFormClose();
  }

  const handleAnswer = (item: any, index: any) => {
    setQuestionList([]);
    var questList = JSON.parse(JSON.stringify(questionList));
    questList.splice(index, 1);
    _.insert = function (arr, index, item) {
      questList.splice(index, 0, item);
    };
    _.insert(questList, index, item);
    for (let itemVal of questList) {
      if (item.id == itemVal.id && item.questionTypeId == 1) {
        item.toggle = !item.toggle;
      }
    }
    setQuestionList(questList);
  }

  const saveQuestionsAndMarkStatusAsArrived = () => {
    for (let item of questionList) {
      item.questionAnswer = item.toggle;
    }
    let inputData = {
      appointmentId: props.apptId,
      customerId: props.custId,
      appointmentQuestions: questionList
    };
    let apiOptions: any = {
      url: `appointment/arrived`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      props.handleQuestionFormClose();
    })
  }

  useEffect(() => {
    getAppointmentById();
  }, []);


  const body = (
    <div className="questionformContainer scroll-ui">
      <div className="custom-head">
        <div className="row title-font mx-auto">
          <div className="col-11 pl-2">
            <span>Questions</span>
          </div>
          <div className="col-1 text-right pr-0 pointer" onClick={handleClose}>
            <CloseIcon fontSize="small" color="secondary" />
          </div>
        </div>
        <div className="row col-12 mx-auto my-2">
          <div className="col-4">
            #{item.appointmentIdentifier}
          </div>

          <div className="col-4">
            <FxDateDisplay value={item.appointmentDate} />
          </div>

          <div className="col-4">
            <span className="float-right">
              <ScheduleIcon fontSize="small" color="action" />
              {item.slotTime}
            </span>
          </div>
        </div>
        <div>
          {
            questionList && questionList.map((item, i) => (
              <div className="m-2 pt-3 row questionformgridbox">
                <span className="col-10">{item.questionDescription} </span>
                <span className="col-2">
                  <Switch onChange={() => handleAnswer(item, i)} checked={item.toggle}
                    checkedIcon={false} handleDiameter={12} width={35} height={20}
                    className="togglestyle float-right" />
                </span>
              </div>
            ))}
        </div>
        <div className="m-2 pr-3 float-right">
          <span>
            <Button variant="contained" size="small" color="primary"
              onClick={saveQuestionsAndMarkStatusAsArrived}>Save</Button>
          </span>
          <span className="ml-1">
            <Button variant="contained" size="small" onClick={() => handleClose()}>Cancel</Button>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default AppointmentQuestionForm;
import React, { useState, useEffect } from 'react';
import './Header.scss';
import logo from "./../../assets/logo.png";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useLocation } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import IconButton from '@material-ui/core/IconButton';
import Leftmenu from '../leftmenu/Leftmenu';
import { useSelector } from 'react-redux';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useHistory } from "react-router-dom";
import { session } from "../fx-core/helpers/sessionService";
import { localStorageService } from "../fx-core/helpers/localStorageService";

const Header = () => {
  const history = useHistory();

  let location = useLocation();
  const externallogin = useSelector((store: any) => store.externallogin);
  const [open, setOpen] = useState(false);
  const roleCode = session.getItem("roleCode");

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const openNotifications = () => {
    history.push({
      pathname: '/notificationlist',
      state: {}
    });
  }

  if (location.pathname == '/' || location.pathname == '/login' || location.pathname.indexOf('/login/') > -1
    || location.pathname == '/registerlanding'
    || location.pathname.indexOf('/register/') > -1 || location.pathname == '/registerprovider'
    || location.pathname.indexOf('/requestappointment/') > -1 
    || location.pathname == '/registerpatient' || location.pathname.indexOf('/patientregistration/') > -1 ||
    location.pathname == '/printpatientregistration' || location.pathname == '/healthhistory' || location.pathname == '/printhealthhistory'
    || location.pathname.indexOf('/healthhistoryform/') > -1) {
    return <></>;
  } else {
    return (
      <div>
        <AppBar>
          <Toolbar>
            <div className="col-2 left-header">
              <div className="logo">
                <img className="header-img" src={logo} alt="Logo" />
              </div>
              { location.pathname != '/requestappointment' &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start">
                <MenuRoundedIcon />
              </IconButton>
              }
            </div>

            <div className="col-8">
              <h2 className="titlestyle m-0">MYMEDIFAM</h2>
              <div className='header-content text-center'>We are HIPPA compliant, and end to end encrypted</div>
            </div>
            <div className="col-2 headeralign">
              {roleCode == "PROVIDER" && location.pathname != '/requestappointment' &&
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={openNotifications}
                  edge="end">
                  <NotificationsIcon />
                </IconButton>
              }
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="persistent" anchor="left" className="drawer-leftmenu" open={open}>
          <div onClick={toggleDrawer}>
            <Leftmenu />
          </div>
        </Drawer>
      </div>
    );
  }
}
export default Header;
import React from 'react';
import NumberFormat from 'react-number-format';
import { localStorageService } from '../../fx-core';

export const FxCurrency = (props: any) => {

    const getCurrency = () => {
        let number = props.value;
        const currency = number.toFixed(2);
        return currency;
    }

    const getCurrencySymbol = () => {
        let currencySymbol;
        let currency = props.currency;
        currency = currency ? currency : localStorageService.getItem("currency");
        if(currency == 'USD')
        {
            currencySymbol = '$';
        }
        if(currency == 'INR')
        {
            currencySymbol = '₹';
        }
        return currencySymbol;
    }

    return (
        <>
            <NumberFormat
                value={getCurrency()}
                prefix={getCurrencySymbol()}
                displayType={'text'}
                thousandSeparator={true}
                renderText={value => <span>{value}</span>} />
        </>
    );

};
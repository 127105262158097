import React, { useEffect, useState } from 'react';
import './ProviderDueDialog.scss';
import { http } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import ConfirmDialog from "../../general/ConfirmDialog/ConfirmDialog";
import { session } from "../../fx-core/helpers/sessionService";
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

export const ProviderDueDialog = (props: any) => {
    const history = useHistory();
    let defaultDue = {
        pendingAppointmentsCount: 0,
        pendingPaymentsCount: 0
    };
    const [providerDues, setProviderDues] = useState(defaultDue);
    const [hasDue, setHasDue] = useState(true);
    const [open, setOpen] = useState(false);

    const getProviderDues = () => {
        let apiOptions: any = {
            url: 'appointment/getproviderdues',
            data: {}
        };
        http.post(apiOptions).then(response => {
            getProviderDuesCallBack(response.data);
        });
    };

    const getProviderDuesCallBack = (res) => {
        setProviderDues(res);
        if (res.pendingAppointmentsCount === 0 && res.pendingPaymentsCount === 0) {
            setHasDue(false);
        }
    };

    const handleDialogClose = () => {
        props.handleClose();
    };

    const confirmDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteProvider = () => {
        const providerId = session.getItem("providerId");
        let apiOptions: any = {
            url: `provider/${providerId}`
        };
        http.delete(apiOptions).then(response => {
            deleteProviderCallBack(response.data);
        });
    };

    const deleteProviderCallBack = (res) => {
        if (res > 0) {
            localStorage.clear();
            history.push('/');
        }
    };

    useEffect(() => {
        getProviderDues();
    }, []);

    return (
        <div className="providerDueContainer">
            <div className="row title-font mx-auto">
                <div className="col-10 pl-0">
                    <span>Delete Account</span>
                </div>
                <div className="col-2 float-right pointer" onClick={handleDialogClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="mt-3 pl-3">
                {
                    hasDue && 
                    <span className="notes">Please clear all the dues to delete account</span>
                }
            </div>
            <div className="mt-3 pl-3">
                {
                    providerDues.pendingAppointmentsCount === 0 &&
                    <span>No Pending Appointments</span>
                }
                {
                    providerDues.pendingAppointmentsCount > 0 &&
                    <span>Number of Pending Appointments - {providerDues.pendingAppointmentsCount}</span>
                }
            </div>
            <div className="mt-3 pl-3">
                {
                    providerDues.pendingPaymentsCount === 0 &&
                    <span>No Pending Payments</span>
                }
                {
                    providerDues.pendingPaymentsCount > 0 &&
                    <span>Number of Pending Payments - {providerDues.pendingPaymentsCount}</span>
                }
            </div>
            <div className="mt-3 alignright">
                <span className="pl-3">
                    <Button variant="contained" size="small" color="primary" disabled={hasDue}
                        onClick={() => confirmDelete()}>Delete</Button>
                </span>
            </div>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message="Are you sure you want to delete?" handleClose={handleClose} onConfirmCallback={deleteProvider} />
            </Dialog>
        </div>
    );
};
import React, { useState } from 'react';
import './TermsAndConditions.scss';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog } from '@material-ui/core';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';

const TermsAndConditions = (props: any) => {
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

  const openPrivacyPolicy = () => {
    setPrivacyPolicyOpen(true);
  };

  const handlePrivacyPolicyClose = () => {
    setPrivacyPolicyOpen(false);
  };

  const handleClose = () => {
    props.handleTermsClose();
  }

  const body = (
    <div className="confirmContainer">
      <div className="row title-font-terms sticky-top">
        <div className="col-10 pl-4">
          <span>Terms And Conditions</span>
        </div>
        <div className="col-2 text-right pointer pr-3" onClick={handleClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </div>
      </div>
      <div className="my-2 mx-4">
        <div className="mt-2 font-16 text-center">
          <div><b>MYMEDIFAM™ Terms of Service Agreement</b></div>
        </div>
        <div className="mt-2">
          <div><b>Effective Date: December 6, 2021</b></div>
        </div>
        <div className="mt-2 text-justify font-13">
          <p>Thank you for using MYMEDIFAM™! This Terms of Service Agreement (“<b>Terms</b>” or “<b>Agreement</b>”) between
            you, our user, (“<b>you</b>”, “<b>your</b>”, “<b>yours</b>”, “<b>user</b>”, or “<b>users</b>”) and MYMEDIFAM™
            (“<b>MyMediFam</b>”, “<b>MMF</b>”, “<b>we</b>”, “<b>us</b>”, “<b>our</b>”, “<b>ours</b>”) covers your use and
            access to our user services, our client software, community code of conduct, as amended from time to time, and our
            websites (collectively, the "<b>Services</b>"). Our <a onClick={openPrivacyPolicy} className='pointer link-clr'>Privacy Policy</a> is incorporated into these Terms by reference
            and explains how we collect and use your information, and the Terms outlines your responsibilities,
            representations and warranties, indemnifications, and other user rights and responsibilities when using our
            Services. By using our Services, you accept the Terms and agree to be bound by the Terms and our <a onClick={openPrivacyPolicy} className='pointer link-clr'>Privacy Policy</a>.
            If you’re using our Services in your role as its employee, contractor, owner, affiliate, or agent of an
            organization, you accept the Terms and agree to be bound by the Terms on behalf of that organization.</p>
        </div>
        <div className="mt-2">
          <div>
            <div><b>1. Your Information & Your Permissions</b></div>
          </div>
          <div className="pl-3 text-justify font-13">
            <div>
              <p>When you use our Services, you share with us your files, content, messages, contacts, and other data related
                to our performance of the Services (collectively, “<b>Your Information</b>”). These Terms do not give us any
                rights to Your Information except for the limited rights that enable us to offer and enhance our Services. We
                may track user behaviors, patterns, and trends so as to better our services to managing your health.</p>
            </div>
            <div>
              <p>When you agree to our Terms, you agree to our Software’s ability to access and store Your Information. Your
                permission to do those things, extends to our collaborators, contractors, affiliates, and trusted third
                parties with whom we work.</p>
            </div>
            <div>
              <p>Medical history forms and medical records, which are for yourself or on behalf of another party who has
                authorized you to provide this information to us, will be approved and reviewed by you to ensure its accuracy.
                We do not verify or validate the accuracy of any medical history forms or medical records.</p>
            </div>
            <div>
              <p>MYMEDIFAM from time to time engages third parties, such as, without limitation, business associates, vendors,
                and takes no responsibility for the services, user information tracking, content, policies, and practices of
                such parties.</p>
            </div>
            <div>
              <p>MYMEDIFAM, from time to time, may publish health related articles, or products, or maybe sponsored by
                business associates to disseminate or advertise specific health-related information; none of the
                aforementioned should be considered as an endorsement or referral. You need to make an informed decision after
                consulting with your health care provider. All sponsored information will be labelled as such. We are not
                providing medical advice to you with these articles, products or treatments, and you should always consult
                with your health care providers before relying upon any health-related information. Some articles, products or
                treatments may be sponsored by third-party advertisers and we do not validate any claims made by third
                parties.</p>
            </div>
          </div>
          <div>
            <div><b>2. Your Responsibilities</b></div>
          </div>
          <div className="pl-3">
            <div><i>i. Responsibilities of All Users.</i></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>You are responsible for all healthcare-related expenses paid to, and ensuring the proper management and
                protection of your information shared with, your selected healthcare provider(s) after you have received a
                referral or a list of potential healthcare providers from our Service and Software.</p>
            </div>
            <div>
              <p>You should safeguard your credentials, such as your user ID and password, and any other information you
                submit through our Software, without limitation. It is your responsibility, if in any event you believe that
                your credentials are compromised or stolen, you should notify us immediately by sending an email to <a
                  href="" className='link-clr'>support@mymedifam.com</a>.</p>
            </div>
            <div>
              <p>You are responsible for your conduct. Your Information and you must comply with the Terms. We may review your
                conduct and content for compliance with the Terms. </p>
            </div>
            <div>
              <p>You may use our Services only as permitted by applicable law, please check your local law for the age of
                digital consent. If you don’t meet the age requirements for digital consent within your local jurisdiction,
                you may not use the Services. MYMEDIFAM’s Services are solely for users who have reached the legal age of
                consent in their jurisdiction, and for the avoidance of doubt in the United States, for users who are 18-years
                old or older and otherwise have the legal capacity to enter into contracts on their own behalf. If you are a
                minor (age defined differently by each state/ country), then you may not use the service independently, but
                can avail of the services under the supervision of your legal guardian.</p>
            </div>
            <div>
              You hereby agree not to misuse the Services or help anyone else to do so. You may use MYMEDIFAM for
              non-commercial and lawful purposes only. Your usage should not damage or impair our services and mechanisms of
              providing services, including but not limited to the network and servers. For example, without limitation, you
              understand and agreement that you or your agent must not attempt or do any of the following in connection with
              the Services:
            </div>
            <div>
              <ol type="a">
                <li>Access, tamper with, or use non-public areas or parts of the Services, or shared areas of the Services you
                  haven't been invited to;</li>
                <li>Interfere with or disrupt any user, host, or network; for example, by sending a virus, overloading,
                  flooding, spamming, or mail-bombing any part of the Services;</li>
                <li>Access, search, or create accounts for the Services by any means other than our publicly supported
                  interfaces (for example, scraping or creating accounts in bulk);</li>
                <li>Probe, scan, or test the vulnerability of any system or network;</li>
                <li>Breach or otherwise circumvent any security or authentication measures;</li>
                <li>Send unsolicited communications, promotions or advertisements, or spam;</li>
                <li>Send altered, deceptive or false source-identifying information, including spoofing or phishing;</li>
                <li>Promote or advertise products or services other than your own without appropriate authorization;</li>
                <li>Abuse referrals or promotions to get more storage space than deserved;</li>
                <li>Sell the Services unless specifically authorized to do so;</li>
                <li>Publish or share materials that are unlawfully pornographic or indecent, or that contain extreme acts of
                  violence or terrorist activity, including terror propaganda;</li>
                <li>Advocate bigotry or hatred against any person or group of people based on their race, religion, ethnicity,
                  sex, gender identity, sexual preference, disability, or impairment;</li>
                <li>Harass or abuse MYMEDIFAM personnel or representatives or agents performing services on behalf of
                  MYMEDIFAM;</li>
                <li>Violate the law in any way, including storing, publishing or sharing material that's fraudulent,
                  defamatory, or misleading; or</li>
                <li>Violate the privacy or infringe on the rights of others.</li>
              </ol>
            </div>
          </div>
          <div className="pl-3">
            <div><i>ii. Responsibilities of Healthcare Providers.</i></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>1. By registering a health care provider user account with MYMEDIFAM, you agree to accurately share your
                Medical Practice License, relevant certificates, education, years of experience, and any records that may
                prevent you from practicing in certain jurisdictions. You are liable for any discrepancies found for any of
                the information you in a healthcare provider user account.</p>
            </div>
            <div>
              <p>2. You will not use MYMEDIFAM to engage in, directly or indirectly, unfair practices such as, but not limited
                to:</p>
            </div>
            <div>
              <ol type="a">
                <li>violation of laws or regulation related to antitrust, competition, or consumer protection, and</li>
                <li>deceptive, unfair, or anti-competitive practices, including, without limitation, you may not make fake
                  healthcare appointments on another healthcare provider’s appointment page using MYMEDIFAM.</li>
              </ol>
            </div>
            <div>
              <p>3. MYMEDIFAM is not a health care provider. MYMEDIFAM does not endorse or recommend any particular health
                care provider, procedure, treatment, diagnosis, medical opinion, or other information that may appear through
                the Services and is provided by the health care provider or its staff. Upon registering with MYMEDIFAM, health
                care providers are responsible for their account information and services description and their credentialing
                information, including certificates, licenses, etc. MYMEDIFAM is not responsible or liable for the accuracy of
                any of this information. MYMEDIFAM is not liable for cancelled or unfulfilled appointments, or any loss or
                injury resulting from the aforementioned, or arising from, or related to, the use of the services offered by
                MYMEDIFAM. You are solely responsible for choosing your health care provider, such as, without limitation,
                your selections based upon the experience, qualification, and specialty.</p>
            </div>
          </div>
          <div>
            <div><b>3. Software</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <p>MYMEDIFAM is the sole owner of our application and any other software and digital works of the Services
              (collectively, the “Software”),and your use of the Software or submission of Your Information does not create
              any rights of ownership in our Software for you. Some of our Services allow you to use our Software, which we
              will update or change from time to time and may update and change automatically with or without prior notice to
              you. So long as you comply fully with the Terms, we grant you a limited, nonexclusive, non-sublicensable,
              nontransferable, unassignable, revocable user’s license to use the Software for the sole and limited purpose of
              access, use, and individual display of the Services. Unless the following restrictions are prohibited by law,
              you agree not to reverse engineer, decompile, or create derivative works of the Software or Services, attempt to
              do so, or assist your employees, employer, contractors, assigns, affiliates, or agents or anyone else in doing
              so.</p>
          </div>
          <div>
            <div><b>4. Early Access Services</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <p>We sometimes release products and features that we are still testing and evaluating. Such Services have been
              marked “phase I”, “beta”, “preview”, “early access”, or “evaluation” (or with words or phrases with similar
              meanings) and may not be as reliable as the other MYMEDIFAM™ Services and Software.</p>
          </div>
          <div>
            <div><b>5. Our Information</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <p>The Services are protected by copyright, trademark, patent, and the other US and foreign laws to the greatest
              extent applicable. For the avoidance of doubt, these terms do not grant you any right, title, or interest in the
              Services, others’ content in the Services, MYMEDIFAM™ trademarks, logos, and other brand features. Your feedback
              is welcome, but you should note that we may use comments or suggestions without any obligation to you and such
              comments and suggestions become the sole property of us upon your submission of them.</p>
          </div>
          <div>
            <div><b>6. Copyright</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>We respect the intellectual property of others and ask that you do the same. We respond promptly to notices
                of alleged copyright infringement if they comply with the law, and such notices should be reported using our
                Copyright Policy. We reserve the right to delete or disable content alleged to be infringing and terminate
                accounts of repeat infringers. You agree to indemnify and hold harmless MYMEDIFAM™ for any and all actions you
                take or allow regarding your activity with your Software and Services which may cause or contribute to any
                copyright or trademark infringement and your risk and liability for such indemnification is uncapped. Our
                designated agent for notice of alleged copyright infringement on the Services is:</p>
            </div>
            <div>
              <ul style={{ listStyleType: 'none' }}>
                <li>Rachel William Anantha</li>
                <li>MYMEDIFAM™</li>
                <li>339 Sunset Rd</li>
                <li>Skillman, NJ</li>
                <li><a href="" className='link-clr'>support@mymedifam.com</a></li>
              </ul>
            </div>
          </div>
          <div>
            <div><b>7. MYMEDIFAM™ Organizational Accounts</b></div>
          </div>
          <div className="font-13 text-justify">
            <ol type="i">
              <li><b>Email address.</b> If you register a MYMEDIFAM™ user account with an email address provisioned by your
                organization, your organization may be able to block your use of MYMEDIFAM™ until you transition to your own
                personal account on MYMEDIFAM™ is completed. </li>
              <li><b>Using MYMEDIFAM™.</b> If you join MYMEDIFAM™, you must use the Service and Software in compliance with
                this Agreement and comply with your organization's terms and policies. Please note that MYMEDIFAM™ accounts
                are subject to your organization's control. Your administrators may be able to access, disclose, restrict, or
                remove information in or from your MYMEDIFAM™ account. They may also be able to restrict or terminate your
                access to a MYMEDIFAM™ account. If you convert an existing MYMEDIFAM™ account into part of a MYMEDIFAM™ team,
                your administrators may prevent you from later disassociating your account from the MYMEDIFAM™.</li>
            </ol>
          </div>
          <div>
            <div><b>8. Termination and Limited Access</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>You are free to stop using MYMEDIFAM™ Services, Software, and your User account at any time. We reserve the
                right to suspend, deactivate, delete, temporary limit, or temporarily or permanently terminate your access to
                your user account, the Services, and Software with or without email notice to you if:</p>
            </div>
            <div>
              <ol type="a">
                <li>you’re in breach of these Terms,</li>
                <li>you’re using the Services or Software in a manner that would cause a risk of harm or loss to us or other
                  users, or</li>
                <li>you have not paid your dues in a timely manner,</li>
                <li>MYMEDIFAM™ needs to comply with a legal or safety requirement, or</li>
                <li>for any other reason, including inactivity.</li>
              </ol>
            </div>
            <div>
              <p>In some instances, and at our sole discretion, we shall provide you with the opportunity to remedy the cause
                of the suspension, deactivation, limited access to the Services or Software, or temporary termination, and to
                export Your Information from our Services and Software. If five (5) days after such notice, you fail to
                complete any and all actions we ask of you in the notice, we will have the right to terminate or suspend your
                access to the Services and Software, or enable limited access to your account, permanently. We are not liable
                to you or any third party for any suspension, deactivation, deletion, temporary limitation of use, or
                temporary or permanent termination under any circumstances.</p>
            </div>
            <div>
              <p>If we discontinue Services before the end of any fixed or minimum term for which you have paid us, we’ll
                refund to you a pro-rata portion of the fees you have pre-paid and for which you haven't received Services,
                less an administrative costs fee of no more than 15% of the remaining balance repaid, and if such
                administrative fee for reimbursement is limited to a lower percentage in your jurisdiction by law, then the
                remaining balance shall be repaid less the maximum amount permitted by law for such administrative costs fee.
              </p>
            </div>
            <div>
              <p>We make no promises or guarantees that we shall provide you with notice before your, or your accounts’,
                suspension, deactivation, account deletion, temporary limitation of access, or temporary or permanent
                termination if:</p>
            </div>
            <div>
              <ol type="a">
                <li>we, in good faith, believe that you have participated in a material breach of these Terms,</li>
                <li>doing so would create legal liability for us or compromise our ability to provide the Services to our
                  other users to any degree, or</li>
                <li>we're prohibited from doing so by law.</li>
              </ol>
            </div>
          </div>
          <div>
            <div><b>9. Assignment.</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <p>We reserve the right to assign the Terms at any time, with or without prior notice to you, including without
              limitation, to any parent, subsidiary, or any affiliated company of ours, or due to, in whole or in part, a sale
              to, merger with, or other transfer of equity, contracts, assets, Services, to another entity. You agree that you
              may not assign, transfer, sublicense, or share in your rights and responsibilities as a party to this Terms to
              anyone else and any attempt to do so is in violation of this section and therefore shall be null and void.</p>
          </div>
          <div>
            <div><b>10. Disclaimers; Indemnification. </b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>We strive to provide great Software and Services, and you acknowledge that we have no control over, and to
                the fullest extent possible under the applicable law, we have no duty to take action or otherwise mitigate the
                effects of: (1) Your Information as you have provided it to us or as you have formally requested we amend it,
                as applicable, (2) what Software and Services you access, (3) your experience, effects, and any other results
                you have after receiving a referral through our Services, (4) which users gain access to our Software and
                Services, (5) how you may interpret our Services, (6) your actions after you access or are exposed to our
                Software and Services.</p>
            </div>
            <div>
              <p>We verify providers by asking their registration and license information. We do not take liability if
                providers do not provide accurate information. There may be health care providers, which are located within
                your search area, but who are not visible on MYMEDIFAM. MYMEDIFAM may place providers registered with
                MYMEDIFAM or on contract with MYMEDIFAM above non-registered providers. MYMEDIFAM may receive a commission
                from registered providers once you book an appointment with them. MYMEDIFAM does not endorse or recommend any
                specific healthcare provider.</p>
            </div>
            <div>
              <p>If disputes should arise between users of MYMEDIFAM, such as, without limitation, between healthcare
                providers, then MYMEDIFAM is under no obligation to resolve inter-user disputes and disclaims involvement in
                any such dispute.</p>
            </div>
            <div>
              <p>We are not a healthcare provider. MYMEDIFAM™ does not endorse or recommend any particular healthcare
                provider, procedure, treatment, diagnosis, medical opinion, or other information that may appear through the
                Services and is provided by the healthcare provider or its staff. Upon registering with MYMEDIFAM, healthcare
                providers are responsible for their account information and services description and their credentialing
                information (including certificates, licenses, etc.); MYMEDIFAM is not responsible or liable for the accuracy
                of any of this information. MYMEDIFAM is not liable for cancelled or unfulfilled appointments, or any loss or
                injury resulting from the aforementioned, or arising from, or related to, the use of the services offered by
                MYMEDIFAM. You are solely responsible for choosing your healthcare provider, such as, without limitation, your
                selections based upon the experience, qualification, and specialty.</p>
            </div>
            <div>
              <p>Providers who are onboarded and healthcare provider users of MYMEDIFAM will appear first in the search
                results of our patient users, but the order of these results does not indicate quality of service or a higher
                likelihood of matching your needs than other search results for this type of healthcare provider. The order of
                search results does not imply the quality of the provider and is a result of the filters chosen by the user.
                After our healthcare provider credential verification process, we are not liable for any kind of care or
                treatment provided. We verify providers by asking their registration and license information. We do not take
                liability if providers do not provide accurate information.</p>
            </div>
            <div>
              <p>We are not responsible or liable for the intellectual property infringement or rights compliance (without
                limitation, copyright, trademark, trade secret, or patent ownership or licensing compliance), legality or
                decency of any material accessed through our Software or Services, or which is otherwise contained therein. We
                have no fiduciary duty to you or any other special relationship to or with you. TO THE FULLEST EXTENT
                PERMITTED BY LAW, MYMEDIFAM™ AND ITS AFFILIATES, CONTRACTORS, CONSULTANTS, SUPPLIERS, AND DISTRIBUTORS MAKE NO
                WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SOFTWARE AND SERVICES. THE SOFTWARE AND SERVICES ARE LICENSED
                AND PROVIDED “AS AVAILABLE” AND "AS IS." WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE ARE NOT RESPONSIBLE FOR THE VALUE, ACCURACY, EFFECTIVENESS,
                RELIABILITY, OR QUALITY OF THE RESULTS OF YOUR USE OF OUR SOFTWARE OR SERVICES. WE ARE NOT RESPONSIBLE FOR THE
                QUALITY, ACCURACY, TIMELINESS, OR COMPLETENESS OF THE OTHER USERS’ INFORMATION PROVIDED TO OUR SERVICES AND
                THEREBY PROVIDED TO YOU THROUGH THE SOFTWARE AND SERVICES. WE MAKE NO GUARANTEE THAT YOU WILL BE ABLE TO USE
                THE SERVICES AT TIMES OR LOCATIONS OF YOUR CHOOSING. WE DO NOT WARRANT THAT THE SOFTWARE OR SERVICES WILL
                OPERATE UNINTERRUPTED, BUG-FREE, ERROR-FREE, OR FREE FROM DEFECTS AT ANY TIME, OR THAT LOSS OF DATA, INCLUDING
                YOUR INFORMATION, WILL NOT OCCUR, OR THAT THE SERVICES OR SOFTWARE ARE FREE FROM COMPUTER VIRUSES,
                CONTAMINANTS OR OTHER HARMFUL ITEMS. EXCEPT AS EXPRESSLY SET FORTH HEREIN, WE MAKE NO WARRANTIES ABOUT THE
                SOFTWARE, SERVICES, OR FUNCTIONS MADE ACCESSIBLE BY US OR ANY OTHER SECURITY ASSOCIATED WITH THE TRANSMISSION
                OF ANY AND ALL OF YOUR OR OTHER USERS’ SENSITIVE INFORMATION. To the extent that all or any portion of this
                section is in violation of the law in your jurisdiction, such portion of this section does not apply to you.
              </p>
            </div>
          </div>
          <div>
            <div><b>11. Limitation of Liability</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>MYMEDIFAM™ in its performance of the Services acts as a passive referral service between health service
                providers and users who are potential patients or patients, and we our health service provider verification
                process does not impute liability upon MYMEDIFAM™ for the quality, timing, or nature of the services, care, or
                treatment which our users may purchase or receive from any health service provider listed in our Software and
                made available to the user by our Services. This limit of our liability is the furthest extent permissible
                under the laws of your jurisdiction and is only limited by applicable law to the extent required. Excluded
                from our limitation of liability is our liability for fraud. In states and countries where the exclusions and
                limitations of this Section are prohibited, we are responsible to you only for breach of our contract with
                you. YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US IS THE CANCELLATION OF YOUR MYMEDIFAM™ ACCOUNT. IN
                NO EVENT SHALL OUR CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF YOUR USE
                OF THE SERVICES, REGARDLESS OF THE FORM OF ACTION, EXCEED THE GREATER OF: (a) THE TOTAL AMOUNT OF FEES, IF
                ANY, THAT YOU PAID TO US TO CREATE OR MAINTAIN YOUR ACCOUNT FOR USE OF THE SOFTWARE AND SERVICES, OR (b) $100;
                EXCEPT THAT, FOR ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF YOUR USE OF THE CUMULATIVE AMOUNT OF THE
                SUBSCRIPTION FEE AND ANY TRANSACTION PROCESSING FEES FOR THE SERVICES (“<b>LIABILITY LIMIT</b>”), IN NO EVENT
                SHALL OUR CUMULATIVE LIABILITY THEREOF REGARDLESS OF THE FORM OF ACTION EXCEED THE TOTAL AMOUNT OF LIABILITY
                LIMIT, IF ANY, REMITTED TO AND RETAINED BY US FOR PROVIDING SERVICES FOR APPOINTMENTS MADE BY YOU IN THE SIX
                (6) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE APPLICABLE CLAIM.</p>
            </div>
            <div>
              <p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT
                LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL MYMEDIFAM™ BE LIABLE TO YOU (OR TO ANY THIRD
                PARTY CLAIMING UNDER OR THROUGH YOU) FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR
                EXEMPLARY DAMAGES ARISING FROM YOUR USE OF, OR INABILITY TO USE, THE SERVICES. THESE EXCLUSIONS APPLY TO ANY
                CLAIMS FOR LOST PROFITS, LOST DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, WORK STOPPAGE, ACCURACY OF
                RESULTS, COMPUTER FAILURE OR MALFUNCTION, ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ANY SUBSTITUTE GOODS,
                SERVICES, OR TECHNOLOGY, OR MEDICAL MALPRACTICE OR NEGLIGENCE OF HEALTHCARE PROVIDERS UTILIZED THROUGH USE OF
                THE SERVICES, OR ANY MATTER BEYOND OUR REASONABLE CONTROL, EVEN IF WE KNEW OR SHOULD HAVE KNOWN OF THE
                POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION
                OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, OUR LIABILITY SHALL BE
                LIMITED IN ACCORDANCE HEREIN TO THE MAXIMUM EXTENT PERMITTED BY LAW.</p>
            </div>
            <div>
              <p>This paragraph doesn’t affect any of your jurisdiction’s consumer rights, including any applicable data
                privacy rights, that cannot be waived or limited by any contract or agreement.</p>
            </div>
            <div>
              <p>In countries where exclusions or limitations of liability are allowed, MYMEDIFAM™, its affiliates,
                contractors, consultants, suppliers or distributors are not liable for:</p>
            </div>
            <div>
              <ol type="i">
                <li>any indirect, special, incidental, punitive, exemplary, or consequential damages, or</li>
                <li>any loss of use, data, business, or profits, regardless of legal theory.</li>
              </ol>
            </div>
            <div>
              <p>These exclusions or limitations will apply regardless of whether or not MYMEDIFAM™ or any of its affiliates
                has been warned of the possibility of such damages.</p>
            </div>
            <div>
              <p>If you use the services for any commercial, business, or re-sale purpose, MYMEDIFAM™ its affiliates,
                suppliers or distributors will have no liability to you for any loss of profit, loss of business, business
                interruption, or loss of business opportunity. MYMEDIFAM™ and its affiliates are not responsible for the
                conduct, whether online or offline, of any third-party user of the Software or Services.</p>
            </div>
            <div>
              <p>IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE YOUR RIGHTS WITH RESPECT TO CALIFORNIA CIVIL CODE SECTION 1542,
                WHICH SAYS “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST
                IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
                AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”</p>
            </div>
          </div>
          <div>
            <div><b>12. Resolving Disputes</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>MYMEDIFAM™ wants to sort things out first. We want to address your concerns without needing a formal legal
                claim. Before filing a claim against MYMEDIFAM™, you agree to try to resolve the dispute informally by
                contacting support@mymedifam.com We’ll try to resolve the dispute informally by contacting you via email. If a
                dispute is not resolved within 60 days of submission, you or MYMEDIFAM™ may bring a formal proceeding.</p>
            </div>
            <div>
              <p><b>Judicial forum for disputes.</b> You and MYMEDIFAM™ agree that any judicial proceeding to resolve claims
                relating to these Terms or the Services will be brought in the federal court of USA or state courts of
                Somerset County, New Jersey, subject to the mandatory arbitration provisions below. Both you and MYMEDIFAM™
                consent to venue and personal jurisdiction in such courts. If you reside in a country (for example, European
                Union member states) with laws that give consumers the right to bring disputes in their local courts, this
                paragraph doesn’t affect those requirements.</p>
            </div>
            <div>
              <p><b>IF YOU’RE A U.S. RESIDENT, YOU ALSO AGREE TO THE FOLLOWING MANDATORY ARBITRATION PROVISIONS:</b></p>
            </div>
            <div>
              <p>We both agree to arbitrate. You and MYMEDIFAM™ agree to resolve any claims relating to these Terms or
                the Services through final and binding arbitration by a single arbitrator, except as set forth under
                Exceptions to Agreement to Arbitrate below. This includes disputes arising out of or relating to
                interpretation or application of this “Mandatory Arbitration Provisions” section, including its
                enforceability, revocability, or validity.</p>
            </div>
            <div>
              <p><b>Opt-out of Agreement to Arbitrate.</b> You can decline this Agreement to arbitrate by contact us within 30
                days of first registering your account. However, if you agreed to a previous version of these Terms that
                allowed you to opt-out of arbitration, your previous choice to opt-out or not opt-out remains to bind.</p>
            </div>
            <div>
              <p><b>Arbitration Procedures.</b> The American Arbitration Association (AAA) will administer the arbitration
                under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer-Related Disputes. The
                arbitration will be held in the United States, in Somerset County, New Jersey (NJ), or any other location to
                which the parties mutually agree.</p>
            </div>
            <div>
              <p><b>Arbitration Fees and Incentives.</b> The AAA rules will govern payment of all arbitration fees. MYMEDIFAM
                will pay all arbitration fees for individual arbitration for claims less than USD $750, and INR Rs. 15,000.
                MYMEDIFAM will seek its attorneys' fees and costs in arbitration, if the arbitrator determines that your claim
                is frivolous.</p>
            </div>
            <div>
              <p><b>Exceptions to Agreement to Arbitrate.</b> Either you or MYMEDIFAM may assert claims, if they qualify, in
                small claims court in Somerset County, State of New Jersey, United States, or any United States county where
                you live or work. Either party may bring a lawsuit solely for injunctive relief to stop unauthorized use or
                abuse of the Services or Software, or intellectual property infringement (for example, without limitation, any
                trademark, trade secret, copyright, or patent rights) without first engaging in arbitration or the informal
                dispute-resolution process described above. If the agreement to arbitrate is found not to apply to you or your
                claim, you agree to the exclusive jurisdiction of the state and federal courts in Somerset County, New Jersey
                to resolve your claim.</p>
            </div>
            <div>
              <p><b>NO CLASS ACTIONS.</b> You may only resolve disputes with us on an individual basis and may not bring a
                claim as a plaintiff or a class member in a class, consolidated, or representative action. Class arbitrations,
                class actions, private attorney general actions, and consolidation with other arbitrations aren’t allowed. If
                this specific paragraph is held unenforceable, then the entirety of this “Mandatory Arbitration Provisions”
                section will be deemed void.</p>
            </div>
          </div>
          <div>
            <div><b>13. Controlling Law</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            These Terms will be governed by the laws of the State of New Jersey and the United States, except for its
            conflicts of laws principles. However, some U.S. States (such as California, Massachusetts, New York, Illinois,
            and others) and some countries (including those in the European Union) have laws that require agreements to be
            governed by the local laws of the consumer's applicable state or country. This Section doesn’t override those
            laws.
          </div>
          <div>
            <div><b>14. Entire Agreement</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <p>These Terms constitute the entire agreement between you and MYMEDIFAM™ with respect to the subject matter of
              these Terms and supersede and replace any other prior or contemporaneous agreements, or terms and conditions
              applicable to the subject matter of these Terms. These Terms create no third-party beneficiary rights.</p>
          </div>
          <div>
            <div><b>15. Waiver, Severability & Assignment</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <p>Failure of MYMEDIFAM™ to enforce a provision is not a waiver of its right to do so later. If a provision is
              found unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term
              will be substituted reflecting our intent as closely as possible. You may not assign any of your rights under
              these Terms, and any such attempt will be void. MYMEDIFAM™ may assign its rights to any of its affiliates or
              subsidiaries, or to any successor in interest of any business associated with the Services.</p>
          </div>
          <div>
            <div><b>16. Modifications</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>We may revise these Terms from time to time to better reflect:</p>
            </div>
            <div>
              <ol type="a">
                <li>changes to the law,</li>
                <li>new regulatory requirements, or</li>
                <li>improvements or enhancements made to our Services, or </li>
                <li>any reason at our sole discretion and with notice to you.</li>
              </ol>
            </div>
            <div>
              <p>If an update to these Terms of Service affects your use of the Services or your legal rights as a user of our
                Services, we’ll notify you prior to the update's effective date by sending an email to the email address
                associated with your account or via an in-product notification. These updated terms will be effective no less
                than 30 days from when we notify you.</p>
            </div>
            <div>
              <p>If you don’t agree with the updates we make, you shall cancel your account before they become effective.
                Where applicable, we will offer you a prorated refund based on the amounts you have prepaid for Services and
                your account cancellation date, less administrative costs of no more than 15% (or the maximum percentage
                permitted for administrative costs upon reimbursement in your jurisdiction if the legal limit is less than
                15%) of the remaining balance repaid. By continuing to use or access the Services after the updates come into
                effect, you agree to be bound by the revised Terms.</p>
            </div>
          </div>
          <div>
            <div><b>17. Contact</b></div>
          </div>
          <div className="pl-3 font-13 text-justify">
            <div>
              <p>If you have any questions or concerns, or wish to submit notice of a claim, please contact us at:</p>
            </div>
            <div>
              <ul style={{ listStyleType: 'none' }}>
                <li>Rachel William Anantha</li>
                <li>Head of Support</li>
                <li><b>MYMEDIFAM INC.</b></li>
                <li><b>339 Sunset Road</b></li>
                <li><b>Skillman NJ, USA</b></li>
              </ul>
            </div>
            <div>
              <p>Or, email us at <a href="" className='link-clr'>support@mymedifam.com</a></p>
            </div>
          </div>
        </div>
      </div >
      <Dialog open={privacyPolicyOpen}
        maxWidth="md" onClose={handlePrivacyPolicyClose}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <PrivacyPolicy handlePrivacyPolicyClose={handlePrivacyPolicyClose} />
      </Dialog>
    </div >
  );


  return (
    <div>
      {body}
    </div>
  );

}

export default TermsAndConditions;
import React, { useState } from 'react';
import './ForgotPassword.scss';
import { FxInput, http, AlertService, FormValidator } from '../fx-core';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ForgotPassword = (props: any) => {

    const defaultItem = {
        identity: "",
        otp: "",
        newPassword: "",
        confirmPassword: ""
    };
    const [item, setItem] = useState(defaultItem);
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});
    const [pwdNotMatched, setPwdNotMatched] = useState(false);

    const bulkValidate = () => {
        let valItems = [];
        if (step == 1) {
            valItems.push({ name: "identity", value: item.identity, validations: ["required"] })
        }
        else if (step == 2) {
            valItems.push({ name: "otp", value: item.otp, validations: ["required"] })
        }
        else {
            valItems.push({ name: "newPassword", value: item.newPassword, validations: ["required", "pwdvalid"] })
            valItems.push({ name: "confirmPassword", value: item.confirmPassword, validations: ["required", "pwdvalid"] })
        }
        let validationResult = FormValidator.bulkValidate(valItems);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    };

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    };

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });

        item.validations = ["required"];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getOtp = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let inputData = {
                identity: item.identity
            };
            let apiOptions: any = {
                url: `user/GetOTPForForgotPassword`,
                data: inputData
            };
            http.post(apiOptions).then(res => {
                getOtpCallBack(res);
            });
        }
    };

    const getOtpCallBack = (res) => {
        setStep(2);
    };

    const verifyOtp = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let inputData = {
                identity: item.identity,
                otp: item.otp
            };
            let apiOptions: any = {
                url: `user/VerifyOTPForForgotPassword`,
                data: inputData
            };
            http.post(apiOptions).then(res => {
                verifyOtpCallBack(res);
            });
        }
    };

    const verifyOtpCallBack = (res) => {
        if (res.data.status) {
            setStep(3);
        }
        else {
            AlertService.showErrorMsg("Please enter valid One Time Password/OTP");
        }
    };

    const checkPwdMatch = () => {
        let match = false;
        if (item.newPassword !== item.confirmPassword) {
            setPwdNotMatched(true);
            match = false;
        }
        else {
            setPwdNotMatched(false);
            match = true;
        }
        return match;
    }

    const updatePassword = () => {
        const isValid = bulkValidate();
        if (isValid) {
            const isPwdMatched = checkPwdMatch();
            if (isPwdMatched) {
                let inputData = {
                    identity: item.identity,
                    otp: item.otp,
                    password: item.newPassword
                };
                let apiOptions: any = {
                    url: `user/ForgotPassword`,
                    data: inputData
                };
                http.post(apiOptions).then(res => {
                    updatePasswordCallBack(res);
                });
            }
        }
    };

    const updatePasswordCallBack = (res) => {
        AlertService.showSuccessMsg("Password changed!");
        handleClose();
    };

    const handleClose = () => {
        props.handleClose();
    };

    const backToPrevious = () => {
        setItem(prevState => {
            return { ...prevState, ...{ otp: "" } }
        });
        setStep(1);
    };

    return (
        <div className="">
            <div className="row title-font mx-auto">
                <div className="col-11 pl-2">
                    <span>Forgot Password</span>
                </div>
                <div className="col-1 text-right pr-0 pointer" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="text-center">
                {
                    step == 1 &&
                    <div className='col-12 px-3'>
                        <div className="mt-4">
                            <FxInput name="identity" variant="outlined" label="Username/Email/Phone No*" size="small"
                                value={item.identity}
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("identity", "required") &&
                                <div className="validation-msg">This field is required</div>
                            }
                        </div>
                        <div className="mt-3">
                            <Button variant="contained" size="small" color="primary" onClick={() => getOtp()}>Send one time password/OTP</Button>
                        </div>
                        <div className="mt-1 text-center">
                            <span className="table-font">One Time Password/OTP will be sent to your email and mobile</span>
                        </div>
                    </div>
                }
                {
                    step == 2 &&
                    <div className='col-12 px-3'>
                        <div className="mt-4">
                            <FxInput name="otp" variant="outlined" label="Enter One Time Password/OTP" size="small"
                                value={item.otp} type="number"
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("otp", "required") &&
                                <div className="validation-msg">One Time Password/OTP is required</div>
                            }
                        </div>
                        <div className="mt-3">
                            <Button variant="contained" size="small" color="primary" onClick={() => verifyOtp()}>Verify One Time Password/OTP</Button>
                            <span className="pl-2">
                                <Button variant="contained" size="small" onClick={() => backToPrevious()}>Back</Button>
                            </span>
                        </div>
                        <div className="mt-1 text-center">
                            <span className="table-font">One Time Password/OTP successfully sent to your email and mobile</span>
                        </div>
                    </div>
                }
                {
                    step == 3 &&
                    <div className='col-12 px-3'>
                        <div className="mt-4">
                            <FxInput name="newPassword" variant="outlined" label="New Password" size="small"
                                value={item.newPassword} type="password"
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("newPassword", "required") &&
                                <div className="validation-msg">New Password is required</div>
                            }
                            {
                                hasError("newPassword", "pwdvalid") &&
                                <div className="validation-msg">New Password should contain atleast one uppercase,one lowercase,one number and one special character(@$!%*?&) with minimum 8 characters</div>
                            }
                        </div>
                        <div className="mt-2">
                            <FxInput name="confirmPassword" variant="outlined" label="Confirm Password" size="small"
                                value={item.confirmPassword} type="password"
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("confirmPassword", "required") &&
                                <div className="validation-msg">Confirm Password is required</div>
                            }
                            {
                                hasError("confirmPassword", "pwdvalid") &&
                                <div className="validation-msg">Confirm Password should contain atleast one uppercase,one lowercase,one number and one special character(@$!%*?&) with minimum 8 characters</div>
                            }
                            {
                                pwdNotMatched &&
                                <div className="validation-msg">Passwords must match</div>
                            }
                        </div>
                        <div className="mt-3">
                            <Button variant="contained" size="small" color="primary" onClick={() => updatePassword()}>Save</Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ForgotPassword;
import React, { useEffect, useState } from 'react';
import './FaqList.scss';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Dialog, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { http, FxInput, AlertService, useNavigationService } from '../../fx-core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import FaqForm from '../FaqForm/FaqForm';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import * as _ from 'lodash';

const FaqList = () => {
    const { pageTitle } = useNavigationService();
    let defaultFilter: any = {
        question: '',
        answer: '',
        searchTxt: '',
        roleId: 0,
        id: 0
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [faqList, setFaqList] = useState([]);
    const [faqFormOpen, setFaqFormOpen] = useState(false);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);
    const [faqId, setFaqId] = useState(0);
    const [message, setMessage] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getFaqList({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getFaqList(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getFaqList = (options: any = {}) => {
        let startIndex = 0;
        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            filter.searchTxt = options.filter.searchTxt;
        }
        let filters = {
            searchTxt: filter.searchTxt
        };
        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `faq/query`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getFaqListCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getFaqListCallBack = (res, pagerOptions) => {
        const result = res.data;
        result.items = _.orderBy(result.items, ['id'], ['asc']);
        setFaqList(result.items);
        computePagerData(result, pagerOptions);
    };

    const openFaqForm = (id) => {
        setFaqId(id);
        setFaqFormOpen(true);
    };

    const handleFormClose = () => {
        setFaqFormOpen(false);
        let options = { pager: { pageNo: 1 }, filter: filter };
        getFaqList(options);
    };

    const confirmDelete = (item) => {
        setMessage("Do you want to delete this question ?");
        setFaqId(item.id);
        setDeleteDialogOpen(true);
    };

    const handleCloseFaq = () => {
        setFaqId(0);
        setDeleteDialogOpen(false);
    };

    const confirmMessage = () => {
        setDeleteDialogOpen(false);
        deleteFaq(faqId);
    };

    const deleteFaq = (id) => {
        let apiOptions: any = {
            url: `faq/${id}`
        };
        http.delete(apiOptions).then(res => {
            deleteFaqCallBack(res);
        })
    };

    const deleteFaqCallBack = (res) => {
        AlertService.showSuccessMsg();
        setFaqId(0);
        let options = { pager, filter };
        getFaqList(options);
    };


    useEffect(() => {
        let options = { pager: { pageNo: 1 }, filter: filter };
        getFaqList(options);
    }, []);

    return (
        <div className="content-container faqlist-ui">
            <div className="title-font">
                <span>{pageTitle}</span>
                <span onClick={() => openFaqForm(null)} className="pointer"><AddCircleOutlineIcon /></span>
            </div>
            <div className="px-3">
                <div className="col-4 py-3">
                    <FxInput name="searchTxt" variant="outlined" label="Search Question"
                        size="small" value={filter.searchTxt} onValueChange={handleInputChange}
                    />
                </div>
                <TableContainer component={Paper}>
                    <Table stickyHeader className="table table-striped">
                        <TableHead>
                            <TableRow>
                                <TableCell width="90%" className="table-head table-font">Question/Answer</TableCell>
                                <TableCell className="table-head table-font">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {faqList && faqList.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>
                                        <div className="table-font">
                                            <span className='font-14 text-bold'>{row.question}</span>
                                        </div>
                                        <div className="table-font">
                                            <span>{row.answer}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <span className="table-font">
                                            <EditIcon
                                                color="primary"
                                                className='pointer'
                                                onClick={() => openFaqForm(row.id)} />
                                        </span>
                                        <span className="table-font ml-3">
                                            <DeleteOutlineIcon
                                                color="primary"
                                                className='pointer'
                                                onClick={() => confirmDelete(row)} />
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="pt-2 row m-0 col-12 justify-content-between">
                    <div className="bottom-info pt-3">{pager.pagerInfo}</div>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                        padding={1}
                        paddingRight={1}
                    >
                        <Pagination
                            page={pager.pageNo}
                            count={pager.pageCount}
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                            onChange={pageChanged}
                        />
                    </Box>
                </div>
            </div>
            <Dialog
                open={faqFormOpen}
                maxWidth="sm"
                onClose={handleFormClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <FaqForm id={faqId} handleFormClose={handleFormClose} />
            </Dialog>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseFaq}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleClose={handleCloseFaq} onConfirmCallback={confirmMessage} />
            </Dialog>
        </div>
    )
};

export default FaqList;
import { IAppAction } from ".";

const initialState = {
    navStack: []
};
  

export const navData= (state: any = initialState, action: IAppAction): any => {
  console.log("inside navDataReducer", state, action);
  switch (action.type) {
    case "nav-push":
      let draftHead = null;
      if (state.navStack.length > 0 && action.payload.sourceState) {
        let head = state.navStack[state.navStack.length - 1];
        draftHead = { ...head, ...{ pageState: action.payload.sourceState } };
      }
      if (draftHead) {
        const draftNavStack = state.navStack.filter(
          (e: any, idx: number) => idx < state.navStack.length - 1
        );
        return {
          ...state,
          ...{ navStack: [...draftNavStack, draftHead, action.payload] },
        };
      } else {
        return {
          ...state,
          ...{ navStack: [...state.navStack, action.payload] },
        };
      }
    case "nav-goTo":
      const index = action.payload.index;
      const draftNavStack = state.navStack.filter(
        (e: any, idx: number) => idx < index + 1
      );
      return { ...state, ...{ navStack: draftNavStack } };
    case "nav-pop":
      if (state.navStack.length > 0) {
        let index = state.navStack.length - 1;
        const draftNavStack = state.navStack.filter(
          (e: any, idx: number) => idx < index
        );
        return { ...state, ...{ navStack: draftNavStack } };
      }
      return state;
    case "nav-clear":
      state.navStack = [];
      return state;
    default:
      return state;
  }
};


import React, { useState, useEffect } from 'react';
import { FxDate, FxInput, FxRadioGroup, formatService, AlertService, http } from '../fx-core';
import './HealthHistoryForm.css';
import hh from "./HealthHistoryForm.json";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { SectionForm } from './sectionform/SectionForm';
import * as _ from 'lodash';

const HealthHistoryForm = (props) => {
    const addStyle = {
        color: 'red'
    };
    const history = useHistory();
    const propsObj = props.location.state;

    let defaultFormModel: any = {
        otherDiseaseRows: [],
        otherDiseaseValues: []
    };
    const [formModel, setFormModel] = useState(defaultFormModel);
    const [customerId, setCustomerId] = useState(0);
    const [patientFormId, setPatientFormId] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleInputChange = (item) => {
        handleFormModelChange({ [item.name]: item.value });
    }

    const handleFormModelChange = (data) => {
        setFormModel(prevState => {
            return { ...prevState, ...data }
        });
    };

    const healthHistory = () => {
        console.log(formModel);
    };

    const printHealthHistory = () => {
        history.push({
            pathname: '/printhealthhistory',
            state: { custId: customerId }
        });
        console.log(formModel);
    };

    const navigateBack = () => {
        if (propsObj.isLogin) {
            history.push('/');
        }
    }

    const resolveCustomerId = (token) => {
        let apiOptions: any = {
            url: `customer/resolvecustomerid/${token}`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            setCustomerId(result.customerId);
            if (result.customerId) {
                resolveCustomerCallback(result.customerId);
            }
        });
    }

    const resolveCustomerCallback = (custId) => {
        getHealthHistoryForms(custId);
    }

    const getCustomerById = (id) => {
        let apiOptions: any = {
            url: `customer/${id}/portal`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            for (let item of hh.healthhistory) {
                if (item.sectionKey == "patient") {
                    let itemObj = _.find(hh.healthhistory, { sectionKey: item.sectionKey });
                    let itemValObj = _.find(itemObj.Items, { key: "patientName" });
                    itemValObj.value = result.firstName;
                }
            }
            setLoaded(true);
        });
    }

    const getHealthHistoryForms = (custId) => {
        let inputData = {
            formCode: "healthhistory",
            customerId: custId
        };
        let apiOptions: any = {
            url: `patientform/query`, data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            patientFormsCallBack(result, custId);
        });
    }

    const patientFormsCallBack = (res, custId) => {
        if (res.length > 0) {
            for (let item of res) {
                setPatientFormId(item.id);
                //Comment here - while correcting layouts, otherwise data from db overwrites
                let itemObj = _.find(hh.healthhistory, { sectionKey: item.sectionKey });
                if (itemObj) {
                    itemObj.Items = (JSON.parse(item.sectionData)).Items;
                }
            }
            setLoaded(true);
        }
        else {
            getCustomerById(custId);
        }
        console.log(hh.healthhistory);
    }

    const collectFormDetails = () => {
        let formDetails = [];
        for (let item of hh.healthhistory) {
            let itemObj = _.find(hh.healthhistory, { sectionKey: item.sectionKey });
            let patientData = {
                sectionKey: item.sectionKey,
                sectionData: JSON.stringify(itemObj)
            };
            formDetails.push(patientData);
        }
        return formDetails;
    }

    const saveDetails = () => {
        console.log(hh.healthhistory);
        let patientFormList = collectFormDetails();
        let inputData = {
            formCode: "healthhistory",
            customerId: customerId,
            patientFormId: patientFormId,
            patientForms: patientFormList
        };
        let apiOptions: any = {
            url: `patientform/save`, data: inputData
        };
        http.post(apiOptions).then(response => {
            AlertService.showSuccessMsg();
            getHealthHistoryForms(customerId);
        });
    };

    const submitDetails = () => {
        setIsClicked(true);
        let patientFormList = collectFormDetails();
        let inputData = {
            formCode: "healthhistory",
            customerId: customerId,
            patientForms: patientFormList,
            currentDate: formatService.getCurrentDateForServer()
        };
        let apiOptions: any = {
            url: `patientform/submit`, data: inputData
        };
        http.post(apiOptions).then(response => {
            AlertService.showSuccessMsg();
            setIsSubmitted(true);
            setIsClicked(false);
        });
    };

    useEffect(() => {
        let token = props.match.params.tk ? props.match.params.tk : "";
        if (token) {
            resolveCustomerId(token);
        }
    }, []);
    return (
        <div className="w-90 m-y-2">
            <div className="text-align-center font-28">HEALTH HISTORY</div>

            {!isSubmitted ?
                <span>
                    <div className="text-align-center sub-title outlined">Welcome to our practice. As a new patient, please fill out the information found below to the best of your ability</div>
                    {loaded &&
                        hh.healthhistory.map((row, idx) => (
                            <SectionForm row={row} key={idx} />
                        ))
                    }
                    <div className="border-tow-top">
                        To the best of my knowledge, the questions on this form have been accurately answered. I understand that providing incorrect information can be dangerous to my health. It is my responsibility to inform the doctor's office of any changes in my medical status. I also authorize the healthcare staff to perform the necessary services I may need.
                    </div>

                    <div className="text-right mt-2 mb-5">
                        <span className="pr-2">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={saveDetails}
                            >
                                Save Draft
                            </Button>
                        </span>
                        <span className="pr-2">
                            {!isClicked &&
                                <Button variant="contained" size="medium" color="primary" onClick={submitDetails}>Submit</Button>

                            }
                            {isClicked &&
                                <Button variant="contained" size="medium" color="primary" disabled>Submit</Button>

                            }
                        </span>
                        {/* <span className="pr-2">
                    <Button variant="contained" size="medium" color="primary" onClick={printHealthHistory}>Print</Button>
                </span> */}

                    </div>
                </span>
                :
                <div className="m-center">
                    Thank you. Your health history form submitted. You can share/download the form using MYMEDIFAM mobile app.
                </div>
            }

        </div >
    )
};

export default HealthHistoryForm;
import React, { useState, useEffect } from 'react';
import { session } from '../fx-core';
import './PrintPatientRegistration.css';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const PrintPatientRegistration = (props) => {
    const history = useHistory();
    let patientRegistrationItem: any = {};

    if (props.location && props.location.state && props.location.state.formModel) {
        patientRegistrationItem = props.location.state.formModel;
    }

    const navigateBack = () => {
        history.push({
            pathname: '/patientregistration'
        });
    }

    return (
        <div className="printpatientregistration w-90 m-y-2">
            <b className="font-20">
                <i>New healthcare mandates require all Patient Registration information fields be completed:</i>
            </b>
            <div className="text-center bg-title-color">PATIENT REGISTRATION</div>
            <div className="outlined row m-0">
                <div className="p-0 col-4 row m-0 border-bottom-line">
                    <div className="p-0 col-8">
                        <label>FIRST NAME:</label>
                        {patientRegistrationItem.firstName}
                    </div>
                    <div className="p-0 col-4">
                        <label>MI:</label>
                        {patientRegistrationItem.middleName}
                    </div>
                </div>
                <div className="p-0 col-8 border-bottom-line">
                    <label>LAST NAME:</label>
                    {patientRegistrationItem.lastName}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>STREET ADDRESS:</label>
                    {patientRegistrationItem.streetAddress}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>ZIP CODE:</label>
                    {patientRegistrationItem.zipCode}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>CITY:</label>
                    {patientRegistrationItem.city}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>HOME PHONE:</label>
                    {patientRegistrationItem.homePhone}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>WORK PHONE:</label>
                    {patientRegistrationItem.workPhone}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>CELL PHONE:</label>
                    {patientRegistrationItem.cellPhone}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>PHARMACY NAME & ADDRESS:</label>
                    {patientRegistrationItem.pharmacyNameAddress}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>COPAY AMOUNT:</label>
                    {patientRegistrationItem.copayAmount}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>EMAIL ADDRESS:</label>
                    {patientRegistrationItem.emailAddress}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>HOW MANY INSURANCE PLANS?:</label>
                    {patientRegistrationItem.howManyInsurancePlans}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>SEX:</label>
                    {patientRegistrationItem.sex}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>DATE OF BIRTH:</label>
                    {patientRegistrationItem.dateOfBirth}
                </div>

                <div className="p-0 col-12 border-bottom-line">
                    <label>RACE (check one):</label>
                    {patientRegistrationItem.race}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>SOCIAL SECURITY #:</label>
                    {patientRegistrationItem.socialSecurity}
                </div>
                <div className="p-0 col-8 border-bottom-line">
                    <label>PRIMARY DOCTOR:</label>
                    {patientRegistrationItem.primaryDoctor}
                </div>
                <div className="p-0 col-4 h-122 border-bottom-line">
                    <label>ETHNICITY:</label>
                    {patientRegistrationItem.ethnicity}
                </div>
                <div className="p-0 col-8 h-122 row m-0 border-bottom-line">
                    <div className="p-0 col-6">
                        <div className="p-0 col-12">
                            <label>PRIMARY LANGUAGE:</label>
                            {patientRegistrationItem.primaryLanguage}
                        </div>
                        <div className="p-0 col-12">
                            <label>SECONDARY LANGUAGE:</label>
                            {patientRegistrationItem.secondaryLanguage}
                        </div>
                    </div>
                    <div className="p-0 col-6">
                        <div className="p-0 col-12">
                            <label>COUNTRY:</label>
                            {patientRegistrationItem.primaryCountry}
                        </div>
                        <div className="p-0 col-12">
                            <label>COUNTRY:</label>
                            {patientRegistrationItem.secondaryCountry}
                        </div>
                    </div>
                </div>

            </div>


            <div className="text-center bg-title-color">PRIMARY INSURANCE INFORMATION</div>
            <div className="outlined row m-0">
                <div className="p-0 col-12 border-bottom-line">
                    <label>PRIMARY INSURANCE COMPANY NAME:</label>
                    {patientRegistrationItem.primaryInsuranceCompanyName}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>INS. COMPANY ADDRESS:</label>
                    {patientRegistrationItem.insuranceCompanyAddress}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>CITY:</label>
                    {patientRegistrationItem.primaryInsuranceCity}
                </div>
                <div className="p-0 col-4 row m-0 border-bottom-line">
                    <div className="p-0 col-6">
                        <label>STATE:</label>
                        {patientRegistrationItem.primaryInsuranceState}
                    </div>
                    <div className="p-0 col-6">
                        <label>ZIP:</label>
                        {patientRegistrationItem.primaryInsuranceZip}
                    </div>
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>NAME OF INSURANCE POLICY HOLDER:</label>
                    {patientRegistrationItem.primaryNameOfInsPolicyHolder}
                </div>
                <div className="p-0 col-4 row m-0 border-bottom-line">
                    <div className="p-0 col-6">
                        <label>DATE OF BIRTH:</label>
                        {patientRegistrationItem.primaryInsuranceDateOfBirth}
                    </div>
                    <div className="p-0 col-6">
                        <label>SEX:</label>
                        {patientRegistrationItem.primaryInsuranceSex}
                    </div>
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>SOCIAL SECURITY #:</label>
                    {patientRegistrationItem.primaryInsuranceSocialSecurity}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>INSURED'S POLICY #:</label>
                    {patientRegistrationItem.primaryInsuredPolicy}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>INSURED'S EMPLOYER:</label>
                    {patientRegistrationItem.primaryInsuredEmployer}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>EMPLOYER CITY/STATE/ZIP:</label>
                    {patientRegistrationItem.primaryEmployerCityStateZip}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>INSURANCE GROUP #:</label>
                    {patientRegistrationItem.primaryInsuranceGroup}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>PATIENT'S INSURANCE POLICY #:</label>
                    {patientRegistrationItem.primaryPatientInsurancePolicy}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>EFFECTIVE DATE OF INSURANCE:</label>
                    {patientRegistrationItem.primaryEffecticeDateOfInsurance}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>RELATIONSHIP TO INSURED:</label>
                    {patientRegistrationItem.primaryRelationshipToInsured}
                </div>
                <div className="p-0 col-8 border-bottom-line">
                    <label>IF AUTO OR WORK RELATED,DATE OF INJURY:</label>
                    {patientRegistrationItem.primaryIFOWRDOI}
                </div>
            </div>


            <div className="text-center bg-title-color">SECONDARY INSURANCE INFORMATION</div>
            <div className="outlined row m-0">
                <div className="p-0 col-12 border-bottom-line">
                    <label>SECONDARY INSURANCE COMPANY NAME:</label>
                    {patientRegistrationItem.secondaryInsuranceCompanyName}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>INS. COMPANY ADDRESS:</label>
                    {patientRegistrationItem.secondaryInsuranceCompanyAddress}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>CITY:</label>
                    {patientRegistrationItem.secondaryInsuranceCity}
                </div>
                <div className="p-0 col-4 row m-0 border-bottom-line">
                    <div className="p-0 col-6">
                        <label>STATE:</label>
                        {patientRegistrationItem.secondaryInsuranceState}
                    </div>
                    <div className="p-0 col-6">
                        <label>ZIP:</label>
                        {patientRegistrationItem.secondaryInsuranceZip}
                    </div>
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>NAME OF INSURANCE POLICY HOLDER:</label>
                    {patientRegistrationItem.secondaryNameOfInsPolicyHolder}
                </div>
                <div className="p-0 col-4 row m-0 border-bottom-line">
                    <div className="p-0 col-6">
                        <label>DATE OF BIRTH:</label>
                        {patientRegistrationItem.secondaryInsuranceDateOfBirth}
                    </div>
                    <div className="p-0 col-6">
                        <label>SEX:</label>
                        {patientRegistrationItem.secondaryInsuranceSex}
                    </div>
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>SOCIAL SECURITY #:</label>
                    {patientRegistrationItem.secondaryInsuranceSocialSecurity}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>INSURED'S POLICY #:</label>
                    {patientRegistrationItem.secondaryInsuredPolicy}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>INSURED'S EMPLOYER:</label>
                    {patientRegistrationItem.secondaryInsuredEmployer}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>EMPLOYER CITY/STATE/ZIP:</label>
                    {patientRegistrationItem.secondaryEmployerCityStateZip}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>INSURANCE GROUP #:</label>
                    {patientRegistrationItem.secondaryInsuranceGroup}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>PATIENT'S INSURANCE POLICY #:</label>
                    {patientRegistrationItem.secondaryPatientInsurancePolicy}
                </div>
                <div className="p-0 col-4 border-bottom-line">
                    <label>EFFECTIVE DATE OF INSURANCE:</label>
                    {patientRegistrationItem.secondaryEffecticeDateOfInsurance}
                </div>

                <div className="p-0 col-4 border-bottom-line">
                    <label>RELATIONSHIP TO INSURED:</label>
                    {patientRegistrationItem.secondaryRelationshipToInsured}
                </div>
                <div className="p-0 col-8 border-bottom-line">
                    <label>IF AUTO OR WORK RELATED,DATE OF INJURY:</label>
                    {patientRegistrationItem.secondaryIFOWRDOI}
                </div>
            </div>

            <div className="text-right mt-2 mb-5">
                {/* <span className="pr-2">
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={patientRegistration}
                    >
                        Save
                    </Button>
                </span>
                <span className="pr-2">
                    <Button variant="contained" size="medium" color="primary" onClick={printPatientRegistration}>Print</Button>
                </span> */}
                <Button variant="contained" size="medium" onClick={navigateBack}>Back</Button>
            </div>
        </div>
    )
};

export default PrintPatientRegistration;
import React, { useEffect, useState } from 'react';
import './ProviderPreference.css';
import { FxInput, http, AlertService, FxSelect } from '../../fx-core';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';

const ProviderPreference = (props: any) => {
  const dispatch = useDispatch();

  let defaultForm = {
    currency: "",
    dateFormat: "",
    cancellationFee: 0,
    cancellationHours: 0,
    showReviews: "Yes"
  };
  const [item, setItem] = useState(defaultForm);
  const [preferenceList, setPreferenceList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [dateFormatList, setDateFormatList] = useState([]);
  const [yesNo, setYesNo] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        Currency: { default: false },
        DateFormat: { default: false },
        YesNo: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setCurrencyList(response.data.Currency);
      setDateFormatList(response.data.DateFormat);
      setYesNo(response.data.YesNo);
    });
  }

  const getProviderPreferences = () => {
    let apiOptions: any = {
      url: `preference/provider/${props.providerId}`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      setPreferenceList(result);
      for (let item of result) {
        if (item.preferenceKey == "DateFormat") {
          handleItemChange({ "dateFormat": item.preferenceValue });
        }
        if (item.preferenceKey == "Currency") {
          handleItemChange({ "currency": item.preferenceValue });
        }
        if (item.preferenceKey == "CancellationFee") {
          handleItemChange({ "cancellationFee": item.preferenceValue });
        }
        if (item.preferenceKey == "CancellationHours") {
          handleItemChange({ "cancellationHours": item.preferenceValue });
        }
        if (item.preferenceKey == "ShowReviews") {
          handleItemChange({ "showReviews": item.preferenceValue });
        }
      }
    })
  }

  const collectPreferences = () => {
    let preflist = [];
    for (let pref of preferenceList) {
      if (pref.preferenceKey == "DateFormat") {
        pref.preferenceValue = item.dateFormat;
        preflist.push(pref);
      }
      if (pref.preferenceKey == "Currency") {
        pref.preferenceValue = item.currency;
        preflist.push(pref);
      }
      if (pref.preferenceKey == "CancellationFee") {
        pref.preferenceValue = item.cancellationFee;
        preflist.push(pref);
      }
      if (pref.preferenceKey == "CancellationHours") {
        pref.preferenceValue = item.cancellationHours;
        preflist.push(pref);
      }
      if (pref.preferenceKey == "ShowReviews") {
        pref.preferenceValue = item.showReviews;
        preflist.push(pref);
      }
    }
    return preflist;
  }

  const saveProviderPreferences = () => {
    var prefList = collectPreferences();
    let apiOptions = { url: 'preference/save', data: prefList };
    http.post(apiOptions).then(res => {
      savePreferenceCallback();
    })
  }

  const savePreferenceCallback = () => {
    AlertService.showSuccessMsg("Preference updated successfully");
    dispatch({ type: "UPDATE_PREFERENCES", payload: { DateFormat: item.dateFormat, Currency: item.currency } });
    getProviderPreferences();
  }

  useEffect(() => {
    initLookup();
    getProviderPreferences();
  }, []);


  const body = (
    <div className="content-container1 card">
      <div className="title-font pl-4">
        <span>Preferences</span>
      </div>
      <div className="row m-0 px-3">
        <div className="col-6">
          <div className="">
            <FxSelect name="currency" variant="outlined" label="Currency" fullWidth
              options={currencyList}
              selectedValue={item.currency} valueField="code" displayField="text"
              onValueChange={handleInputChange} />
          </div>
          <div className="">
            <FxSelect name="dateFormat" variant="outlined" label="Date Format" fullWidth
              options={dateFormatList}
              selectedValue={item.dateFormat} valueField="code" displayField="text"
              onValueChange={handleInputChange} />
          </div>
          <div className="">
            <FxSelect name="showReviews" variant="outlined" label="Show Reviews" fullWidth
              options={yesNo}
              selectedValue={item.showReviews} valueField="code" displayField="text"
              onValueChange={handleInputChange} />
          </div>
        </div>
        <div className="col-6">
          <div className="">
            <FxInput type="number" name="cancellationFee" variant="outlined" label="Cancellation Fee" size="small" fullWidth
              value={item.cancellationFee} onValueChange={handleInputChange}
            />
          </div>
          <div className="">
            <FxInput type="number" name="cancellationHours" variant="outlined" label="Cancellation Hours" size="small" fullWidth
              value={item.cancellationHours} onValueChange={handleInputChange}
            />
          </div>
          <div className="mt-3 text-right">
            <Button variant="contained" size="small" color="primary" onClick={() => saveProviderPreferences()}>Update</Button>
          </div>
        </div>
      </div>

    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderPreference;
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect } from 'react';
import './FxRadioGroup.styles.scss';

export const FxRadioGroup = (props: any) => {
    const { disabled, caption, name, onValueChange } = props;

    const onChange = (event: any) => {
        const data = { name, value: event.target.value };
        if (onValueChange) onValueChange(data);
    };

    return (
        <>
            <FormControl className={props.fieldsetClassName}>
                {caption &&
                    <FormLabel className={props.legendClassName}>{caption}</FormLabel>
                }
                <RadioGroup
                    aria-label={props.name}
                    name={props.name}
                    defaultValue={props.selectedValue}
                    onChange={onChange}
                    value={props.selectedValue}
                    className={props.radioClassName}>
                    {props.options.map((option: any, index: number) => (
                        <FormControlLabel value={option[props.valueField]} control={<Radio />} label={option[props.displayField]} disabled={disabled} />
                    ))
                    }
                </RadioGroup>
            </FormControl>
        </>
    );
};
import React, { useEffect, useState } from 'react';
import './ProviderReferral.scss';
import { http, session, FxTextarea } from '../../fx-core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '@material-ui/core';
import referAfriend from "../../../assets/referAfriend.svg";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon
} from "react-share";

const ProviderReferral = (props: any) => {

  const [referralText, setReferralText] = useState(null);
  const [copied, setCopied] = useState(false);

  const getProviderReferralText = () => {
    const userId = session.getItem("userId");
    let apiOptions: any = {
      url: `user/sharereferraltext/${userId}`, data: {}
    };
    http.get(apiOptions).then(response => {
      setReferralText(response.data.referralText);
    });
  }

  useEffect(() => {
    getProviderReferralText();
  }, []);


  const body = (
    <div className="content-container1 card">
      <div className="title-font">
        <span>Refer Friends</span>
      </div>
      <div className="row m-0 mt-2">
        <div className="col-3">
          <img src={referAfriend} alt="Refer a friend" style={{width:"100%"}}  />
        </div>
        <div className="col-9">
          <div className="pl-2 pb-4">
            Please click and share referral details with any provider or user
          </div>
          <span>
            <TwitterShareButton url={referralText}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </span>
          <span>
            <TelegramShareButton url={referralText} >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </span>
          <span>
            <WhatsappShareButton url={referralText} separator=":: " >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </span>
          <span>
            <EmailShareButton url={referralText} subject="MYMEDIFAM - Referral">
              <EmailIcon size={32} round />
            </EmailShareButton>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderReferral;
import React, { useEffect, useState } from 'react';
import './RegisterPatientList.scss';
import { FxDate, http, formatService, FxDateDisplay } from '../../fx-core';
import { Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Link } from '@material-ui/core';
import IconPhone from '@material-ui/icons/Phone';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Constants } from '../../fx-core/helpers/constants';

export const RegisterPatientList = (props: any) => {
    const preferences = useSelector((store: any) => store.preferences);
    const dateFormat = Constants.ServerDateFormat;

    let defaultFilter: any = {
        fromDate: formatService.substractDaysInDisplayFormat(formatService.getCurrentDate(), 7, dateFormat),
        toDate: formatService.getCurrentDate(dateFormat)
    };

    const [filter, setFilter] = useState(defaultFilter);
    const [patientList, setPatientList] = useState([]);
    const [valError, setValError] = useState(null);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { item: { [item.name]: item.value } };
        getRegisterPatientList(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getRegisterPatientList = (options: any = {}) => {
        let selectedFromDate: any = filter.fromDate;
        let selectedToDate: any = filter.toDate;
        if (options && options.item) {
            selectedFromDate = options.item.fromDate ? options.item.fromDate : filter.fromDate;
            selectedToDate = options.item.toDate ? options.item.toDate : filter.toDate;
        }
        setValError(null);
        if (moment(selectedToDate).isBefore(selectedFromDate)) {
            setValError("To date should be greater than or equal to From date");
            return;
        }
        let inputData = {
            filters: {
                fromDate: formatService.getDateStringForServer(selectedFromDate, dateFormat),
                toDate: formatService.getDateStringForServer(selectedToDate, dateFormat)
            }
        };
        let apiOptions: any = {
            url: 'customers/admin/registerlist',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setPatientList(response.data);
        });
    }


    useEffect(() => {
        getRegisterPatientList();
    }, []);

    return (
        <div className="">
            <div className="row col-12 m-0">
                <div className="col-5 row justify-content-between">
                    <div className="col-6">
                        <div>
                            <FxDate name="fromDate" label="From Date" variant="outlined" fullWidth
                                value={filter.fromDate} onValueChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div>
                            <FxDate name="toDate" label="To Date" variant="outlined" fullWidth
                                value={filter.toDate} onValueChange={handleInputChange} />
                        </div>
                    </div>
                    {
                        valError &&
                        <div className="error"> {valError} </div>
                    }
                </div>
            </div>
            <div className='mt-3'>
                <TableContainer component={Paper}>
                    <Table stickyHeader className="table table-striped">
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-head table-font">Name</TableCell>
                                <TableCell className="table-head table-font">Mobile</TableCell>
                                <TableCell className="table-head table-font">Email</TableCell>
                                <TableCell className="table-head table-font">Registered Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                patientList.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <div className="table-font">
                                                <span>{row.firstName}</span> &nbsp;
                                                <span>{row.lastName}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <span className="mobileno-color table-font">
                                                    <Link href="tel:{{row.mobile}}">
                                                        <IconPhone fontSize="small" />
                                                        <span>{row.mobile}</span>
                                                    </Link>
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div> <span className="table-font">{row.email}</span></div>
                                        </TableCell>
                                        <TableCell>
                                            <FxDateDisplay value={row.registrationDate} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
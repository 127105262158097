import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    LocalizationProvider,
    TimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import ScheduleIcon from '@material-ui/icons/Schedule';
import './FxTime.styles.scss';
import { TextField } from '@material-ui/core';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';

export const FxTime = (props: any) => {
    let defaultVal :any = null;
    const [timeVal, setTimeVal] = useState(defaultVal);
    const { onValueChange, variant } = props;
    
    useEffect(() => {
        if (props.value) {
            let value = moment().format('YYYY-MM-DD') + " " + props.value;
            defaultVal = new Date(moment(value).format('YYYY-MM-DD hh:mm a'));
            setTimeVal(defaultVal)
        } else {
            setTimeVal(null)
        }
      }, [props.value]);

    const onChange = (changedValue: any) => {
        setTimeVal(changedValue);
        let value = moment(changedValue).format('hh:mm a');
        const data = { name: props.name, value };
        onValueChange(data);
    };
    const size = props.size || "small"

    return (
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <TimePicker
                label={props.label}
                value={timeVal}
                onChange={onChange}
                openPickerIcon={<ScheduleIcon />}
                renderInput={(params) => <TextField {...params} size={size} variant={variant == 'outlined' ? 'outlined' : 'standard'}/>} />
        </LocalizationProvider>
    );
};

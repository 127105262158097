import React, { useState, useEffect } from 'react';
import './SectionForm.css';
import { SectionFormField } from '../sectionformfield/SectionFormField';

export const SectionForm = (props:any) => {
    return ( <div>
                {props.row &&  props.row.sectionDisplay &&
                    <div className="mt-2 col"><b>{props.row.sectionDisplay}</b></div>
                }
                <div className="p-0 row m-0">
                    { props.row && props.row.Items && props.row.Items.map((item, b) => (
                            <div key={b} className={props.row.col ? "col-" + (12 / props.row.col) : "col-12 p-0"}>
                                <SectionFormField item={item} />
                            </div>))
                    }
                </div>
            </div>
    )
};

import React, { useEffect, useState } from 'react';
import './ProviderInsurance.scss';
import { FxInput, FxSelect, http, AlertService } from '../../fx-core';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';

const ProviderInsurance = (props: any) => {
  let defaultItem: any = {
    insuranceId: 0,
    insuranceName: null,
    providerId: props.providerId
  };
  const initialList = [];
  const [insurances, setInsurances] = useState(initialList);
  const [item, setItem] = useState(defaultItem);
  const [providerInsurances, setProviderInsurances] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [error, setError] = React.useState(null);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        Insurances: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setInsurances(response.data.Insurances);
    });
  }


  const saveProviderInsurance = () => {
    setError(null);
    if (!item.insuranceId) {
      setError("This field is required");
      return;
    }
    let inputData = {
      ...item
    }
    let apiOptions: any = {
      url: 'providerinsurance/add',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      saveInsuranceCallBack(response);
    });
  }

  const saveInsuranceCallBack = (res) => {
    handleItemChange({ insuranceId: '' });
    AlertService.showSuccessMsg();
    initLookup();
    getProviderInsurances();
  };

  const getProviderInsurances = () => {
    let inputData = {
      providerId: props.providerId
    }
    let apiOptions: any = {
      url: 'providerinsurance/query', data: inputData
    };
    http.post(apiOptions).then(response => {
      setProviderInsurances(response.data);
    });
  }

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setMessage("Do you want to delete " + item.insuranceName + "?");
    setOpen(true);
  };

  const confirmMessage = () => {
    setOpen(false);
    deleteService(selectedItem);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const deleteService = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `providerinsurance/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderInsurances();
    })
  }


  useEffect(() => {
    initLookup();
    getProviderInsurances();
  }, []);


  const body = (
    <div className="content-container1 card provider-ins">
      <div className="title-font">
        <span>Insurances</span>
      </div>
      <div className="row m-0 px-3">
        <div className="col-6">
          <FxSelect name="insuranceId" variant="outlined" label="Insurance*"
            options={insurances}
            selectedValue={item.insuranceId}
            valueField="id"
            displayField="text"
            onValueChange={handleInputChange} />
          {
            error && !item.insuranceId &&
            <div className="error"> {error} </div>
          }
        </div>

        <div className="mt-3">
          <Button variant="contained" size="small" color="primary"
            onClick={() => saveProviderInsurance()}>Add</Button>
        </div>
      </div>

      <div>
        <div className="row m-0 col-12 p-0 px-3 justify-content-between">
          {
            providerInsurances.map((item) => (
              <div className="row m-0 col-prov-edu-5 border-bottom mt-2">
                <div className="col-10 text-truncate" title={item.insuranceName}>{item.insuranceName}</div>
                <div className="col-2">
                  <span onClick={() => confirmDelete(item)}>
                    <DeleteIcon color="primary" />
                  </span>
                </div>
              </div>
            ))
          }

        </div>
      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderInsurance;
import React from 'react';
import './ProviderFormContainer.scss';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ProviderForm from '../ProviderForm/ProviderForm';
import ProviderClinic from '../../provider/ProviderClinic/ProviderClinic';
import ProviderService from '../../provider/ProviderService/ProviderService';
import ProviderRegistration from '../../provider/ProviderRegistration/ProviderRegistration';
import ProviderEducation from '../../provider/ProviderEducation/ProviderEducation';
import ProviderSpecialization from '../../provider/ProviderSpecialization/ProviderSpecialization';
import ProviderInsurance from '../../provider/ProviderInsurance/ProviderInsurance';
import ProviderPreference from '../../provider/ProviderPreference/ProviderPreference';

const ProviderFormContainer = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const providerId = propsObj.id;

    const back = () => {
        history.goBack();
    };

    return (
        <div className=' provider-form-container px-3'>
            <div>
                {
                    providerId == 0 &&
                    <div className="col-12 p-0 px-1">
                        <div className="col-8 mx-auto p-0 px-1">
                            <ProviderForm providerId={providerId} back={back} />
                        </div>
                    </div>
                }
                {
                    providerId > 0 &&
                    <>
                        <div className="col-12 p-0 row mx-auto">
                            <div className="col-6 p-0 px-1">
                                <ProviderForm providerId={providerId} />
                            </div>
                            <div className="col-6 p-0 px-1">
                                <ProviderClinic providerId={providerId} />
                            </div>
                            <div className="col-6 p-1 pt-4">
                                <ProviderPreference providerId={providerId} />
                            </div>
                            <div className="col-6 p-1 pt-4">
                                <ProviderEducation providerId={providerId} />
                            </div>
                            <div className="col-6 p-1 pt-4">
                                <ProviderRegistration providerId={providerId} />
                            </div>
                            <div className="col-6 p-1 pt-4">
                                <ProviderSpecialization providerId={providerId} />
                            </div>
                            <div className="col-6 p-1 pt-4">
                                <ProviderInsurance providerId={providerId} />
                            </div>
                            <div className="col-6 p-1 pt-4">
                                <ProviderService providerId={providerId} />
                            </div>
                        </div>
                        <div className="col-12 mt-2 pr-3 mb-5 text-right">
                            <span>
                                <Button variant="contained" size="small" color="primary" onClick={back}>Back</Button>
                            </span>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default ProviderFormContainer;
import React, { useState, useEffect } from 'react';
import './Leftmenu.scss';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IconPeople from '@material-ui/icons/People';
import IconExitToApp from '@material-ui/icons/ExitToApp';
import { useHistory } from "react-router-dom";
import IconList from '@material-ui/icons/List';
import { useLocation } from "react-router-dom";
import { session } from "../fx-core/helpers/sessionService";
import IconPerson from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import GradeIcon from '@material-ui/icons/Grade';
import EventIcon from '@material-ui/icons/Event';
import FileCopy from '@material-ui/icons/FileCopy';
import { http, useNavigationService } from '../fx-core';
import * as _ from 'lodash';

const Leftmenu = (props: any) => {
  const { navigate } = useNavigationService();

  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();
  const [menus, setMenus] = useState([]);

  const getMenus = () => {
    let apiOptions: any = {
      url: `role/getAssignedWebControls`
    };
    http.post(apiOptions).then(response => {
      getMenusCallBack(response.data);
    })
  }

  const getMenusCallBack = (items) => {
    let menus = [];
    //let items = _.orderBy(res, ['displayOrder'], ['asc']);
    for (let item of items) {

      let menu: any = {
        title: item.display, route: item.sRef, icon: item.iconRef, controlCode: item.controlCode,
        parentControlCode: item.parentControlCode, displayOrder: item.displayOrder
      };
      menus.push(menu);
    }
    //adding logout menu at last
    menus.push({
      title: 'Logout',
      route: '/',
      icon: <IconExitToApp />,
      controlCode: 'logout'
    });

    let appMenuMap = {};
    for (let item of menus) {
      appMenuMap[item.controlCode] = item;
    }

    // Grouping and display order chagnes for provider menus starts
    let groupedMenus = [];
    for (let key in appMenuMap) {
      let item = appMenuMap[key];
      if (item.parentControlCode) {
        let parentMenu = appMenuMap[item.parentControlCode];
        if (parentMenu) {
          parentMenu.submenu = parentMenu.submenu || [];
          parentMenu.submenu.push(item);
        }
      }
      else {
        groupedMenus.push(item);
      }
    }
    for (let key in appMenuMap) {
      let item = appMenuMap[key];
      if (item.submenu && item.submenu.length > 0) {
        item.submenu = _.orderBy(item.submenu, ['displayOrder'], ['asc']);
      }
    }

    menus = _.orderBy(groupedMenus, ['displayOrder'], ['asc']);
    setMenus(menus);
  }

  const openMenu = (menu) => {
    if (menu.title == "Logout") {
      localStorage.clear();
      session.clear();
    }
    //history.push(menu.route);
    let navData = {
      targetRoute: menu.route,
      targetTitle: menu.title,
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  useEffect(() => {
    if (location.pathname != '/' && location.pathname.indexOf('/login') == -1) {
      getMenus();
    }
  }, []);

  if (location.pathname == '/' || location.pathname == '/login') {
    return <></>;
  }
  
  return (
    <List className={classes.menuheight} disablePadding>
      {menus.map((menu: any, i) => (
        <span>
          {(!menu.parentControlCode && !menu.route) &&
            <ListItem key={i} button className={classes.menuItem} selected={true}>
              <ListItemIcon className={classes.menuItemIcon}>
                <span> <i className={menu.icon}></i></span>
              </ListItemIcon>
              <ListItemText primary={menu.title} />
            </ListItem>
          }

          {menu.route &&
            <ListItem key={i} button className={classes.menuItem} selected={true} onClick={() => openMenu(menu)}>
              <ListItemIcon className={classes.menuItemIcon}>
                {menu.title == "Logout" &&
                  <span>{menu.icon}</span>
                }
                {menu.title != "Logout" &&
                  <span> <i className={menu.icon}></i></span>
                }
              </ListItemIcon>
              <ListItemText primary={menu.title} />
            </ListItem>
          }

          {menu.submenu && menu.submenu.map((s: any, i) => (
            <ListItem key={i} button className={classes.submenuItem} selected={true} onClick={() => openMenu(s)}>
              <ListItemIcon className={classes.menuItemIcon}>
                <span> <i className={s.icon}></i></span>
              </ListItemIcon>
              <ListItemText primary={s.title} />
            </ListItem>
          ))
          }
        </span>
      ))
      }
    </List>
  );

};

// const drawerWidth = 350;

const useStyles = makeStyles(theme =>
  createStyles({
    menuheight: {
      background: '#269592',
      color: '#ffffff',
      height: '100%',
    },
    menuItem: {
    },
    menuItemIcon: {
      color: '#ffffff',
    },
    submenuItem: {
      marginLeft: 15
    },
  }),
)

export default Leftmenu;
import { httpErrorToStr, checkDicomFile } from './helpers';
import { api } from 'dicomweb-client';
import { session } from '../../fx-core';

class DicomUploadService {
  async smartUpload(files:any, url:any, uploadCallback:any, cancellationToken:any) {
    const CHUNK_SIZE = 1; // Only one file per request is supported so far
    const MAX_PARALLEL_JOBS = 50; // FIXME: tune MAX_PARALLEL_JOBS number

    let filesArray = Array.from(files);
    if (filesArray.length === 0) {
      throw new Error('No files were provided.');
    }

    let parallelJobsCount = Math.min(filesArray.length, MAX_PARALLEL_JOBS);
    let completed = false;

    const processJob = async (resolve:any, reject:any=null) => {
      while (filesArray.length > 0) {
        if (cancellationToken.get()) return;
        let chunk = filesArray.slice(0, CHUNK_SIZE);
        filesArray = filesArray.slice(CHUNK_SIZE);
        let error:any = null;
        let result : any;
        try {
          if (chunk.length > 1) throw new Error('Not implemented');
          if (chunk.length === 1) { 
            result = await this.simpleUpload(chunk[0], url);
          }
        } catch (err) {
          // It looks like a stupid bug of Babel that err is not an actual Exception object
          error = httpErrorToStr(err);
        }
        chunk.forEach((file:any) => uploadCallback(file.fileId, result, error));
        if (!completed && filesArray.length === 0) {
          completed = true;
          resolve();
          return;
        }
      }
    };

    await new Promise(resolve => {
      let result = [];
      for (let i = 0; i < parallelJobsCount; i++) {
        processJob(resolve);
      }
    });
  }

  async simpleUpload(file:any, url:any) {
    const client = this.getClient(url);
    const loadedFile:any = await this.readFile(file);
    const content = loadedFile.content;
    if (!checkDicomFile(content)) {
      throw new Error('This is not a valid DICOM file.');
    }
    let result = await client.storeInstances({ datasets: [content] });
    //console.log('simpleUpload : result = ', result);
    return result;
  }

  readFile(file:any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({
          name: file.name,
          size: file.size,
          type: file.type,
          content: reader.result,
        });
      };
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(file);
    });
  }

  getClient(url:any) {
    var accessToken = session.getItem('dicomAccessToken');
    const headers = { Authorization : "Bearer " +accessToken};
    return new api.DICOMwebClient({
      url,
      headers,
    });
  }
}

export default new DicomUploadService();

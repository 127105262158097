import { DicomConfig } from '../../../config/DicomConfig';
import { GoogleConfig } from '../../../config/GoogleConfig';
import GoogleCloudApi from './GoogleCloudApi';

const getActiveServer = () => {
  let { project, location, dataset, dicomStore } = DicomConfig;
  let servers:any = [];
  if (GoogleConfig.enableGoogleCloudAdapter) {
    GoogleCloudApi.urlBase = GoogleConfig.healthcareApiEndpoint;
    const pathUrl = GoogleCloudApi.getUrlBaseDicomWeb(
      project,
      location,
      dataset,
      dicomStore
    );
    const data = {
      project,
      location,
      dataset,
      dicomStore,
      wadoUriRoot: pathUrl,
      qidoRoot: pathUrl,
      wadoRoot: pathUrl,
    };
    servers = getServers(data, dicomStore);
  }
  const isActive = (a:any) => a.active === true;
  return servers && servers.find(isActive);
};

const getServers = (data:any, name:any) => {
  const {
    wadoUriRoot,
    qidoRoot,
    wadoRoot,
    dataset = '',
    dicomStore = '',
    location = '',
    project = '',
  } = data;

  return [
    {
      name: name,
      dataset,
      dicomStore,
      location,
      project,
      imageRendering: 'wadors',
      thumbnailRendering: 'wadors',
      type: 'dicomWeb',
      active: true,
      wadoUriRoot,
      qidoRoot,
      wadoRoot,
      supportsFuzzyMatching: false,
      qidoSupportsIncludeField: false,
    },
  ];
};

const isValidServer = (server:any) => {
  return (
    server &&
    !!server.dataset &&
    !!server.dicomStore &&
    !!server.location &&
    !!server.project
  );
};

const isEqualServer = (server:any = {}, toCompare:any = {}) => {
  const serverLength = Object.keys(server).length;
  const toCompareLength = Object.keys(toCompare).length;

  if (!serverLength || !toCompareLength) {
    return false;
  }

  return (
    server.dataset === toCompare.dataset &&
    server.dataset === toCompare.dataset &&
    server.dicomStore === toCompare.dicomStore &&
    server.location === toCompare.location &&
    server.project === toCompare.project
  );
};

export { getActiveServer };

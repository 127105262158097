import axios from 'axios';
import { session } from "./sessionService";
import { saveAs } from 'file-saver';
import { ProgressManager } from '../progressmanager';
import { AlertService } from './alertService';
import { AppSettings } from '../../../utils/AppSettings';

const apiBasePath =  AppSettings.REACT_APP_API_URL;
const webBasePath =  AppSettings.REACT_WEB_URL;

export const axiosInstance = axios.create({
    baseURL: apiBasePath,
    timeout: 60000,
});

axiosInstance.interceptors.request.use((value:any) => {
    ProgressManager.open();
    return value;
}, undefined);

axiosInstance.interceptors.response.use((response) => {
    ProgressManager.close();
    if(response.status === 401) {
         AlertService.showErrorMsg("Unauthorized!");
    }
    return response;
}, (error) => {
    ProgressManager.close();
    if (error.response && error.response.data) {
        if(error.response.data.message) {
            AlertService.showErrorMsg(error.response.data.message);
        }
        return Promise.reject(error.response.data);
    }
    if(error.response.status == 401) {
        AlertService.showErrorMsg("Unauthorized");
        window.location.href = `${webBasePath}`;
    }
	
    return Promise.reject(error);
});
  
export const http ={
    get: get,
    post:post,
    put:put,
    delete: deleteRequest,
    download: download,
    open: open,
    view: viewDocument
}

async function get(options:any){
    let token = session.getItem('id_token');
    let config = {};
    if(token) {
        config = {
            headers: {
            'Authorization': `${token}`
            }
        };        
    }
    const response = await axiosInstance.get(options.url, config);
    return response;
}

async function post(options:any) {
     let token = session.getItem('id_token');
     var config = {};
     if(token) {
        config = {
            headers: {
            'Authorization': `${token}`
            }
        };
    }
    const response = await axiosInstance.post(options.url, options.data, config);
    return response;
}

async function put(options:any){
    let token = session.getItem('id_token');
    var config = {};
    if(token) {
       config = {
           headers: {
           'Authorization': `${token}`
           }
       };
   }
   const response = await axiosInstance.put(options.url, options.data, config);
   return response;
}


async function deleteRequest(options:any){
    let token = session.getItem('id_token');
    var config = {};
    if(token) {
       config = {
           headers: {
           'Authorization': `${token}`
           }
       };
   }
    const response = await axiosInstance.delete(options.url, config);
    return response;
}

async function download(options:any) {
    let url = options.url;
    let method = options.method;
    let data = options.data;
    let fileName = options.fileName;
    let token = session.getItem('id_token');
    let config:any = {
        baseURL: apiBasePath,
        url,
        method,
        data,
        responseType: 'blob',
      };
    if(token) {
       config = {...config, ...{
           headers: {
           'Authorization': `${token}`
           }
       }};
   }
  
   ProgressManager.open();
   axios.request(config)
      .then((res) => {
        let blob = res.data;
        saveAs(blob, options.fileName);
        ProgressManager.close();
      }).catch( (err) => {
        ProgressManager.close();
        console.log(err);
      });
}

async function open(options:any) {
    let url = options.url;
    let method = options.method;
    let data = options.data;
    let fileName = options.fileName;
    let token = session.getItem('id_token');
    let config:any = {
        baseURL: apiBasePath,
        url,
        method,
        data,
        responseType: 'blob',
      };
    if(token) {
       config = {...config, ...{
           headers: {
           'Authorization': `${token}`
           }
       }};
   }
  
   ProgressManager.open();
   axios.request(config)
      .then((res) => {
        ProgressManager.close();
        if(options.callback) { 
            options.callback(res);  
        }
        else
        {
            let blob = res.data;
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
        }
        
      }).catch( (err) => {
        ProgressManager.close();
        console.log(err);
      });
}


async function viewDocument(options:any) {
    let url = options.url;
    let method = options.method;
    let data = options.data;
    let fileName = options.fileName;
    let token = session.getItem('id_token');
    let config:any = {
        baseURL: apiBasePath,
        url,
        method,
        data,
        responseType: 'blob',
      };
    if(token) {
       config = {...config, ...{
           headers: {
           'Authorization': `${token}`
           }
       }};
   }
  
   ProgressManager.open();
   axios.request(config)
      .then((res) => {
        ProgressManager.close();
        return res.data;
      })
      .then(blobToBase64)
      .then(finalResult => { 
        options.callback({ base64data: finalResult, options: options});
      })
      .catch( (err) => {
        ProgressManager.close();
        console.log(err);
      });
}

const blobToBase64 = (blob:any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
};

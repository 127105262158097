import React, { useState, useEffect } from 'react';
import { FxDate, FxInput, FxRadioGroup, FxSelect, session } from '../../fx-core';
import './SectionFormField.css';
import * as _ from 'lodash';
import { SectionForm } from '../sectionform/SectionForm';

export const SectionFormField = (props) => {
    const lookup = {
        yesno: [
            {
                code: "no",
                text: "no"
            },
            {
                code: "yes",
                text: "yes"
            }
        ],
        relationship: [
            {
                code: "father",
                text: "father"
            },
            {
                code: "mother",
                text: "mother"
            },
            {
                code: "siblings",
                text: "siblings"
            },
            {
                code: "spouse",
                text: "spouse"
            },
            {
                code: "children",
                text: "children"
            }
        ]
    };
    const [formModel, setFormModel] = useState({});


    const handleInputChange = (item) => {
        props.item.value = item.value;
        if (item.name == "anyOtherDisease") {
            if (item.value == 'yes') {
                addRow(props.item);
            }
            else {
                props.item.listedValues = [];
            }
        }
        handleInputItemChange({ [item.name]: item.value });
    }

    const handleInputItemChange = (data) => {
        setFormModel(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleDiseaseListChange = (i, item) => {
        let itemValue = props.item.listedValues[i];
        itemValue[item.name] = item.value;
        handleInputItemChange({ [item.name]: item });
    }

    const handleTextInputChange = (item) => {
        let lookupItem = _.find(props.item.text, { key: item.name });
        lookupItem.value = item.value;
        handleInputItemChange({ [item.name]: item });
    }

    const handleItemListChange = (i, item) => {
        let itemValue = props.item.value[i];
        itemValue[item.name] = item.value;
        handleInputItemChange({ [item.name]: item });
    }

    const handleRadioChange = (item) => {
        props.item.value = item.value;
        handleInputItemChange({ [item.name]: item });
    }

    const addRow = (item) => {
        if (item.key == "anyOtherDisease") {
            item.listedValues.push({ "anyOtherDisease": "" });
        }
        else if (item.key == "previousHospitalizations") {
            item.value.push({ "illness": "", "when": "", "address": "" });
        }
        else if (item.key == "medications") {
            item.value.push({ "medicine": "" });
        }
        else if (item.key == "familyMedicalHistory") {
            item.value.push({ "relationship": "", "age": "", "diseases": "", "causeofdeath": "" });
        }
        handleInputItemChange({ [item.key]: item });
    }

    const removeRow = (i, item) => {
        item.value.splice(i, 1);
        handleInputItemChange({ [item.key]: item });
    }

    const removeFromDiseaseList = (i) => {
        props.item.listedValues.splice(i, 1);
        handleInputItemChange({ [props.item.value]: props.item.value });
    }

    const removeItemFromList = (i) => {
        props.item.value.splice(i, 1);
        handleInputItemChange({ [props.item.value]: props.item.value });
    }

    return (
        <div>
            {props.item.type == 'text' &&
                <FxInput label={props.item.display} name={props.item.key} variant="standard"
                    fullWidth
                    value={props.item.value}
                    onValueChange={handleInputChange} />}
            {props.item.type == 'date' &&
                <FxDate label={props.item.display} name={props.item.key} variant="standard"
                    fullWidth className="m-0"
                    value={props.item.value} onValueChange={handleInputChange} />
            }
            {props.item.type == 'yesno' &&
                <div className={props.item.col ? "col-" + (12 / props.item.col) : ""}>
                    <FxRadioGroup
                        name={props.item.key}
                        caption={props.item.display}
                        options={lookup.yesno}
                        valueField="code"
                        displayField="text"
                        onValueChange={handleInputChange}
                        selectedValue={props.item.value}
                        fieldsetClassName=" display-flex flex-row align-items-center justify-content-between"
                        legendClassName=" display-inline-flex m-0"
                        radioClassName="flex-row"
                    />
                    <div>
                        {(props.item.key == 'anyOtherDisease' && props.item.value == "yes") &&
                            <div className="p-0 row m-0">
                                <div className="col-11"></div>
                                <div className="col-1 p-0">
                                    <b onClick={() => addRow(props.item)}><i className="fas fa-plus-circle"></i></b>
                                </div>
                                {
                                    props.item.listedValues.map((row, i) => (
                                        <div className="col-12 p-0 row m-0">
                                            <div className="col-11 pl-0">{row[i]}
                                                <FxInput name="anyOtherDisease" variant="standard" fullWidth
                                                    value={row["anyOtherDisease"]}
                                                    onValueChange={(ev) => handleDiseaseListChange(i, ev)} />
                                            </div>
                                            <div className="col-1 p-0">
                                                {props.item.listedValues.length > 1 &&
                                                    <b onClick={() => removeFromDiseaseList(i)}><i className="fas fa-minus-circle"></i></b>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>
            }
            {props.item.type == 'list' && props.item.header && props.item.header.length > 0 &&
                <div className="p-0 row m-0">
                    {
                        props.item.header.map((header, c) => (
                            <div key={c} className={"mt-2 col-" + (header.col)}><b>{header.display}</b></div>
                        ))
                    }
                    <div className="col-1">
                        <b className="mr-5" onClick={() => addRow(props.item)}><i className="fas fa-plus-circle"></i></b>
                    </div>
                </div>
            }
            {props.item.type == 'list' && props.item.header && props.item.value && props.item.value.length > 0 &&
                <div>
                    {
                        props.item.value.map((valueObj, d) => (
                            <div key={d} className="p-0 row m-0">
                                {
                                    props.item.header.map((header, e) => (
                                        <>
                                            <div key={e} className={"col-" + (header.col)}>
                                                {header.type == 'combo' &&
                                                    <FxSelect name={header.key} variant="standard"
                                                        options={lookup.relationship} selectedValue={valueObj[header.key]} valueField="code" displayField="text"
                                                        onValueChange={(ev) => handleItemListChange(d, ev)} />
                                                }
                                                {header.type == 'text' &&
                                                    <FxInput name={header.key} variant="standard"
                                                        fullWidth
                                                        value={valueObj[header.key]}
                                                        onValueChange={(ev) => handleItemListChange(d, ev)} />
                                                }
                                            </div>
                                        </>
                                    ))
                                }
                                <div className="col-1">
                                    {props.item.value.length > 1 &&
                                        <b onClick={() => removeItemFromList(d)}><i className="fas fa-minus-circle"></i></b>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
            {/* {props.item.key == "medications" && props.item.type == 'list' && props.item.value && props.item.value.length > 0 &&
                <div>
                    <span>
                        <b className="mr-5 mb-4 float-right" onClick={() => addRow(props.item)}>+</b>
                    </span>
                    {
                        props.item.value.map((valueObj, f) => (
                            <div key={f} className="p-0 row m-0">
                                <div className="col-11">
                                    <FxInput name={valueObj[f]} variant="standard"
                                        fullWidth
                                        value={valueObj[valueObj[f]]}
                                        onValueChange={(ev) => handleItemListChange(f, ev)} />
                                </div>
                                <div className="col-1">
                                    {props.item.value.length > 1 &&
                                        <b onClick={() => removeItemFromList(f)}>-</b>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            } */}
            {!props.item.sectionDisplay && props.item.helpText &&
                <div className="font-12">{props.item.helpText}</div>
            }
            {props.item.type == 'radiogroup' && props.item.Options && props.item.Options.length > 0 &&
                <>
                    <div className={props.item.axis == "x" ? "display-flex flex-row align-items-center" : ""}>
                        {props.item.key &&
                            <div><b><i>{props.item.display}</i></b></div>
                        }
                        {props.item.helpText &&
                            <div className="font-12">{props.item.helpText}</div>
                        }

                        <div className="row m-0 align-items-center">
                            <FxRadioGroup
                                name={props.item.key}
                                options={props.item.Options}
                                valueField="key"
                                displayField="display"
                                onValueChange={handleRadioChange}
                                selectedValue={props.item.value}
                                radioClassName={props.item.axis == "x" ? "flex-row" : ""}
                            />
                        </div>
                    </div>
                </>
            }
            {props.item.type == 'section' &&
                <div className={props.item.axis == "x" ? "display-flex flex-row align-items-center" : ""}>
                    <SectionForm row={props.item} />
                </div>
            }
            {props.item.text && props.item.text.length > 0 &&
                <div>
                    {props.item.text.map((textControl, g) => (
                        <div key={g} className="p-0 row m-0">
                            <FxInput label={textControl.display} name={textControl.key} variant="standard"
                                fullWidth
                                value={textControl.value}
                                onValueChange={handleTextInputChange} />
                        </div>
                    ))
                    }
                </div>
            }
        </div>
    )
};

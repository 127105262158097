import React, { useEffect, useState } from 'react';
import './ProviderFaqList.scss';
import { http } from '../../fx-core';
import { session, useNavigationService } from "../../fx-core";

const ProviderFaqList = () => {

  const { pageTitle } = useNavigationService();
  const initialList = [];
  const [faqList, setFaqList] = useState(initialList);
  const [roleId, setRoleId] = React.useState(parseInt(session.getItem('roleId')));

  const getFaqList = () => {
    let inputData = {
      roleId: roleId
    }
    let apiOptions: any = {
      url: `faq/query/byrole`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setFaqList(response.data);
    });
  }

  useEffect(() => {
    getFaqList();
  }, []);


  const body = (
    <div className="content-container provfaq-ui">
      <div className="title-font">
        <span>{pageTitle}</span>
      </div>

      <div className='px-3 pt-3'>
        {
          faqList.map((item) => (
            <div className="row m-0 pt-1 col-12 alignbox">
              <span className="col-12">
                <div>
                  <span className='font-14 text-bold'>{item.question}</span>
                </div>
                <div>
                  {item.answer}</div>
              </span>
            </div>
          ))
        }
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderFaqList;
import React, { useEffect, useState } from 'react';
import './ReportedReviews.scss';
import { http, AlertService, FxDateTimeDisplay } from '../../fx-core';
import Rating from '@material-ui/lab/Rating';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import { session } from "../../fx-core";
import PersonIcon from '@material-ui/icons/Person';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';

const ReportedReviews = (props: any) => {

  const initialList = [];
  const [reportedReviews, setReportedReviews] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [review, setReview] = React.useState({ rating: 0, ratingDate: '', userName: '', reviewComment: '' });
  const [action, setAction] = React.useState(null);

  const getReportedReviews = () => {
    let inputData = {
      reportedById: session.getItem('userId'),
      reviewId: props.reviewId
    }
    let apiOptions: any = {
      url: 'reviewreport/reported', data: inputData
    };
    http.post(apiOptions).then(response => {
      setReportedReviews(response.data);
    });
  }

  const getReviewById = () => {
    let apiOptions: any = {
      url: `review/${props.reviewId}`
    };
    http.get(apiOptions).then(response => {
      setReview(response.data);
    });
  }

  const confirmCancel = (item: any) => {
    setSelectedItem(item);
    setAction('cancelreview');
    setMessage("Do you want to cancel this review?");
    setOpen(true);
  };


  const confirmMessage = () => {
    setOpen(false);
    markReviewAsCancelled(selectedItem)
  };

  const handleClose = () => {
    setOpen(false);
  }

  const markReviewAsCancelled = (item: any) => {
    let inputData = {
      id: item.id,
    }
    let apiOptions: any = {
      url: 'reviewreport/cancel', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg("Cancelled successfully");
      getReportedReviews();
    });
  }

  useEffect(() => {
    getReviewById();
    getReportedReviews();
  }, []);


  const body = (
    <>
      <div className="row m-0 title-font mx-auto">
        <div className="col-11 pl-0">
          <span>Reported Reviews</span>
        </div>
        <div className="col-1 float-right pointer" onClick={props.handleReportedReviewsClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </div>
      </div>

        <div className="row m-0 col-12 mt-2">
          <div className="col-8">
            <div className='d-flex'>
              <PersonIcon className='mr-2' fontSize="small" color="action" />
              {review.userName}
            </div>
            <div className='font-12'>
              <FxDateTimeDisplay value={review.ratingDate} />
            </div>
          </div>
          <div className="col-4">
            <Rating name="read-only" value={review.rating} readOnly />
          </div>

          {review.reviewComment &&
            <div className="col-12 font-12">
              {review.reviewComment}
            </div>
          }
        </div>

      <div className="my-3 font-14">
        <div className="row m-0 rpt-rvws-title-font pl-0">
          <div className="col-4">
            <span>Review Report Type</span>
          </div>
          <div className="col-4">
            <span>Reported Time</span>
          </div>
          <div className="col-2">
            <span>Status</span>
          </div>
          <div className="col-2">
            <span>Actions</span>
          </div>
        </div>
        {
          reportedReviews.map((item) => (
            <div className="row m-0 p-1 even">
              <div className="col-4">
                {item.reviewReportType}
              </div>

              <div className="col-4">
                <FxDateTimeDisplay value={item.reportedDateTime} />
              </div>

              <div className="col-2">
                {item.reviewReportStatus}
              </div>

              {item.reviewReportStatusId == 1 &&
                <div className="col-2 text-right">
                  <Button variant="contained" size="small" color="primary" onClick={() => confirmCancel(item)}>
                    Cancel</Button>
                </div>
              }

              {item.reporterComments &&
                <div className="col-12 mt-2">
                  <CommentIcon />
                  {item.reporterComments}
                </div>
              }
              {item.resolverId > 0 &&
                <div className="divider row m-0 col-12">
                  {
                    item.resolverComments &&
                    <div className="col-12">
                      <span><b>Resolver Comments:</b>
                        <span> {item.resolverComments}</span>
                      </span>
                    </div>
                  }

                  {
                    item.resolvedDateTime &&
                    <div className="col-12">
                      <span>
                        <span className="mr-1"><b>Resolved on </b></span>
                        <FxDateTimeDisplay value={item.resolvedDateTime} /></span>
                    </div>
                  }
                </div>
              }
            </div>
          ))

        }
      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} action={action} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
      </div>
    </>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ReportedReviews;
import React from 'react';
import moment from 'moment';
import DateIcon from '@material-ui/icons/DateRangeOutlined';
import { useSelector } from 'react-redux';
import { localStorageService } from '../helpers/localStorageService';
import { Constants } from '../helpers/constants';

export const FxDateDisplay = (props:any) => {

    const getDateDisplay = () => {
        let getDate = props.value;
        if(getDate) {
            const dateFormat: any = localStorageService.getItem('dateformat');
            const dateDisplay = moment(getDate).format(dateFormat);
            return dateDisplay;
        }
    }

    return(
        <span>
          {getDateDisplay()}
        </span>       
    );
};
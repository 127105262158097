import React, { useEffect, useState } from 'react';
import './ReviewList.css';
import { http, AlertService, useNavigationService, FxDateDisplay } from '../../fx-core';
import Rating from '@material-ui/lab/Rating';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import { session } from "../../fx-core";
import { Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Badge from '@material-ui/core/Badge';
import ReportReviewForm from '../ReportReviewForm/ReportReviewForm';
import ReportedReviews from '../ReportedReviews/ReportedReviews';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import EventIcon from '@material-ui/icons/EventAvailable';

const ReviewList = (props: any) => {
  const { pageTitle } = useNavigationService();
  let defaultItem: any = {
    comments: ''
  };
  const [item, setItem] = useState(defaultItem);
  const initialList = [];
  const [reviewList, setReviewList] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [featured, setFeatured] = React.useState(false);
  const [reportFormOpen, setReportFormOpen] = React.useState(false);
  const [reportedReviewOpen, setReportedReviewOpen] = React.useState(false);
  const [reviewId, setReviewId] = useState(0);

  const getReviewList = () => {
    let inputData = {
      providerId: session.getItem('providerId')
    }
    let apiOptions: any = {
      url: 'review/provider', data: inputData
    };
    http.post(apiOptions).then(response => {
      setReviewList(response.data);
    });
  }

  const confirmApprove = (item: any) => {
    setSelectedItem(item);
    setAction('approve');
    setMessage("Do you want to approve this review ?");
    setOpen(true);
  };


  const confirmReject = (item: any) => {
    setSelectedItem(item);
    setAction('reject');
    setMessage("Do you want to reject this review ?");
    setOpen(true);
  };

  const confirmFeatured = (item: any) => {
    setSelectedItem(item);
    setAction('featured');
    if (item.isFeatured) {
      setMessage("Do you want to mark this review as not featured ?");
    }
    else {
      setMessage("Do you want to mark this review as featured ?");
    }
    setOpen(true);
  };

  const confirmMessage = (action: any, comments: any) => {
    setOpen(false);
    if (action == 'approve') {
      markAsApproved(selectedItem);
    }
    else if (action == 'reject') {
      markAsRejected(selectedItem);
    }
    else if (action == 'featured') {
      markAsFeatured(selectedItem);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const markAsApproved = (item: any) => {
    let inputData = {
      id: item.id,
      reviewStatusId: 2
    }
    updateReviewStatus(inputData);
  }

  const markAsRejected = (item: any) => {
    let inputData = {
      id: item.id,
      reviewStatusId: 3
    }
    updateReviewStatus(inputData);
  }

  const updateReviewStatus = (item: any) => {
    let apiOptions: any = {
      url: 'review/updatestatus', data: item
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getReviewList();
    })
  }


  const markAsFeatured = (item: any) => {
    let inputData = {
      id: item.id,
      isFeatured: !item.isFeatured
    }
    let apiOptions: any = {
      url: 'review/featured', data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getReviewList();
    })
  }

  const reportReview = (item: any) => {
    setReviewId(item.id);
    setReportFormOpen(true);
  }

  const handleReportFormClose = () => {
    setReportFormOpen(false);
    getReviewList();
  }

  const openReportedReviews = (item: any) => {
    setReviewId(item.id);
    setReportedReviewOpen(true);
  }

  const handleReportedReviewsClose = () => {
    setReportedReviewOpen(false);
  }

  useEffect(() => {
    getReviewList();
  }, []);


  const body = (
    <div className="content-container">
      <div className="title-font">
        <span>{pageTitle}</span>
      </div>

      <div className='px-3 pt-3'>
        {
          reviewList.map((item) => (
            <div className="col-12 mymedifam-gridbox">
              <div className="text-center">
                #{item.appointmentNo}
              </div>
              <div className="row m-0">

                <div className="col-3">
                  <div className='d-flex'>
                    <PersonIcon className='mr-2' fontSize="small" color="action" />
                    <div className='text-truncate text-bold text-capitalize'>{item.userName}</div>
                  </div>
                </div>
                <div className="col-3">
                  <div className='d-flex'>
                    <HospitalIcon className='mr-2' fontSize="small" color="action" />
                    {item.apptClinicName}
                  </div>
                  <div className='d-flex'>
                    <EventIcon className='mr-2' fontSize="small" color="action" />
                    <FxDateDisplay value={item.appointmentDate} />
                  </div>
                </div>
                <div className="col-2 text-center">
                  <Rating name="read-only" value={item.rating} readOnly />

                  <div className=''>
                    {item.isFeatured &&
                      <span className='text-info text-bold'>Featured</span>}
                    {!item.isFeatured &&
                      <span className='text-info text-bold'>Not Featured</span>}
                  </div>

                </div>


                {/* <div className="col-2 m-2">
                {
                  item.reviewStatusId === 1 &&
                  <span>
                    <span>
                      <Button variant="contained" size="small" color="primary" onClick={() => confirmApprove(item)}>Approve</Button>
                    </span>
                    <span className="pl-4">
                      <Button variant="contained" size="small" color="primary" onClick={() => confirmReject(item)}>Reject</Button>
                    </span>
                  </span>
                }
              </div> */}
                <div className="row m-0 col-4 appointment-actions">
                  {!item.isFeatured &&
                    <span className='mr-2'>
                      <a className='pointer' onClick={() => confirmFeatured(item)}>Mark As Featured</a>
                    </span>
                  }
                  {item.isFeatured &&
                    <span className='mr-2'>
                      <a className='pointer' onClick={() => confirmFeatured(item)}>UnMark As Featured</a>
                    </span>
                  }
                  <span className='mr-2'>
                    <a className='pointer' onClick={() => reportReview(item)}>Report</a>
                  </span>
                  {item.reportedCount > 0 &&
                    <span>
                      <a className='pointer' onClick={() => openReportedReviews(item)}>Reported Reviews</a>
                    </span>
                  }
                </div>

                {item.reviewComment &&
                  <div className="col-12 bg-highlight">
                    {item.reviewComment}
                  </div>
                }
              </div>
            </div>
          ))

        }

      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} action={action} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
        <Dialog
          open={reportFormOpen}
          maxWidth="sm"
          onClose={handleReportFormClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ReportReviewForm reviewId={reviewId} handleReportFormClose={handleReportFormClose} />
        </Dialog>
        <Dialog
          open={reportedReviewOpen}
          maxWidth="sm"
          onClose={handleReportedReviewsClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ReportedReviews reviewId={reviewId} handleReportedReviewsClose={handleReportedReviewsClose} />
        </Dialog>
      </div>
    </div >
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ReviewList;
import React, { useEffect, useState } from 'react';
import './MobileVerificationDialog.scss';
import { http, AlertService, FxInput } from '../../fx-core';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export const MobileVerificationDialog = (props) => {

    const [otp, setOtp] = useState("");

    const verifyMobile = () => {
        let apiOptions: any = {
            url: `user/verify/mobile`,
            data: {}
        };
        http.post(apiOptions);
    };

    const handleInputChange = (item) => {
        setOtp(item.value);
    };

    const handleClose = () => {
        props.closeDialog()
    };

    const verifyMobileStatus = () => {
        if (otp.trim().length <= 0) {
            AlertService.showErrorMsg("Please provide one time password/OTP");
            return;
        }        
        let apiOptions: any = {
            url: `user/verify/mobile/status`,
            data: { otp }
        };
        http.post(apiOptions).then(res => {
            verifyMobileStatusCallBack(res);
        });
    };

    const verifyMobileStatusCallBack = (res) => {
        if(res && res.data) {
            onSuccessMobileVerify(res.data);
        }
    };

    const onSuccessMobileVerify = (res) => {
        if (res.isVerified) {
            props.handleVerification("success");
        }
        else {
            AlertService.showErrorMsg("Please provide valid one time password/OTP");
        }
    };

    useEffect(() => {
        verifyMobile();
    }, []);

    return (
        <div className="modalalign">
            <div className="col-12 title-font">
                <span className="col-10">Mobile Verification</span>
                <span className="col-1 float-right pointer mr-3" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </span>
            </div>
            <div className="col-12 mt-2 ml-2">
                <span>Please enter the one time password/OTP received in your mobile</span>
            </div>
            <div className="col-12 mt-2 ml-2">
                <FxInput name="otp" variant="outlined" label="One Time Password/OTP" size="small"
                    value={otp}
                    onValueChange={handleInputChange}
                />
            </div>
            <div className="float-right mr-2 mb-2">
                <Button variant="contained" size="small" color="primary" onClick={verifyMobileStatus}>Verify</Button>
            </div>
        </div>
    );
};
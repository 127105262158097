import React from 'react';
import './BusinessAgreement.scss';
import CloseIcon from '@material-ui/icons/Close';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import SignaturePage from './SignaturePage/SignaturePage';

const BusinessAgreement = (props: any) => {

  const tableIndia = [
    { "col1": "Unit – provider/ practice", "col2": "MMF Price per month (INR)", "col3": "Monthly Discounted Price (INR)", "col4": "Yearly Price (Book value, INR)", "col5": "Yearly discounted price (INR)" },
    { "col1": "Single", "col2": 1000, "col3": 1000, "col4": 12000, "col5": 10800 },
    { "col1": "Two", "col2": 2000, "col3": 1900, "col4": 24000, "col5": 21600 },
    { "col1": "Three", "col2": 3000, "col3": 2850, "col4": 36000, "col5": 32400 },
    { "col1": "Four", "col2": 4000, "col3": 3800, "col4": 48000, "col5": 43200 },
    { "col1": "Five", "col2": 5000, "col3": 4750, "col4": 60000, "col5": 54000 },
    { "col1": "Six", "col2": 6000, "col3": 5400, "col4": 72000, "col5": 61200 },
    { "col1": "Seven", "col2": 7000, "col3": 6300, "col4": 84000, "col5": 71400 }
  ];

  const tableUSA = [
    { "col1": "Unit – provider/ practise", "col2": "MMF Price per month ($)", "col3": "Monthly Discounted Price ($)", "col4": "Yearly Price (Book value, $)", "col5": "Yearly discounted price ($)" },
    { "col1": "Single", "col2": 150, "col3": 150, "col4": 1800, "col5": 1620 },
    { "col1": "Two", "col2": 300, "col3": 285, "col4": 3600, "col5": 3240 },
    { "col1": "Three", "col2": 450, "col3": 427.5, "col4": 5400, "col5": 4860 },
    { "col1": "Four", "col2": 600, "col3": 570, "col4": 7200, "col5": 6480 },
    { "col1": "Five", "col2": 750, "col3": 712.5, "col4": 9000, "col5": 8100 },
    { "col1": "Six", "col2": 900, "col3": 810, "col4": 10800, "col5": 9180 },
    { "col1": "Seven", "col2": 1050, "col3": 945, "col4": 12600, "col5": 10710 }
  ];

  const handleClose = () => {
    props.handleBusinessAgreementClose();
  }

  const body = (
    <div className="confirmContainer business-agreement">
      <div className="row title-font-terms sticky-top">
        <div className="col-10 pl-4">
          <span>Business Associate Agreement</span>
        </div>
        <div className="col-2 text-right pointer pr-3" onClick={handleClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </div>
      </div>
      <div className="my-2 mx-4">
        <div className="mt-2 font-16 text-center">
          <div><b>MYMEDIFAM™</b></div>
        </div>
        <div className="mt-2 font-16 text-center">
          <div><b>BUSINESS ASSOCIATE AGREEMENT</b></div>
        </div>
        <div className='font-13'>
          <p>This Business Associate Agreement (“<b>Agreement</b>” or “<b>BAA</b>”) is made and entered by and between
            ______________________________________________________________  (“<b>COVERED ENTITY</b>”) and MYMEDIFAM INC. (“<b>BUSINESS ASSOCIATE</b>”),
            which shall be individually referred to herein as the “<b>Party</b>” and collectively as the “<b>Parties</b>”.</p>
        </div>
        <div className='font-13'>
          <p><b>WHEREAS</b>, COVERED ENTITY shall make available and/or transfer to BUSINESS ASSOCIATE certain information in conjunction with goods
            or services that are confidential and must be afforded special treatment and protection. </p>
        </div>
        <div className='font-13'>
          <p><b>WHEREAS</b>, BUSINESS ASSOCIATE shall have access to and/or receive from COVERED ENTITY certain information, that can be used or
            disclosed only in accordance with this Agreement and the United States Department of Health and Human Services (“<b>HHS</b>”) if you
            fall within the geographical limits of the United States jurisdiction, Health Insurance Portability and Accountability Act and
            its Privacy and Security Rules, Social Security Act, and the HITECH Act Standards (collectively referred to hereinafter as “<b>HIPAA</b>”);.</p>
        </div>
        <div className='font-13'>
          <p><b>NOW THEREFORE</b>, the Parties agree to the terms as follows:</p>
        </div>
        <div className='pl-3 font-13'>
          <div><b>1.	PERMITTED USES AND DISCLOSURES OF SHARED INFORMATION</b></div>
          <div className='pl-3'><p>Except as otherwise limited in this Agreement:</p></div>
          <div>
            <ol type="a">
              <li>Each Party may use information shared by the other Party (the “<b>Shared Information</b>”) for the limited purpose of facilitating
                the performance of each Party pursuant to terms of this Agreement, including, without limitation, the BUSINESS ASSOCIATE’s
                data aggregation services to COVERED ENTITY as defined in <b>Exhibit A</b> (<b>Statement of Work & Payment Terms</b>) and permitted by 45
                CFR § 164.504(e)(2)(i)(B) and in accordance with applicable law. </li>
              <li>BUSINESS ASSOCIATE may use Shared Information to report violations of law to appropriate Federal and State authorities,
                consistent with 45 CFR § 164.502(j)(1).</li>
              <li>BUSINESS ASSOCIATE is permitted to use Shared Information as necessary to properly manage or administer its commercial activity.</li>
            </ol>
          </div>
          <div><b>2.	REPORTS OF IMPROPER USE OR DISCLOSURE</b></div>
          <div className='pl-3'>
            <p>BUSINESS ASSOCIATE hereby agrees to report to COVERED ENTITY no later than 60 days from the discovery of the breach, any and
              all breaches or improper uses or disclosures of Shared Information aside from those permitted in this Agreement or by HIPAA
              or other applicable law.</p>
          </div>
          <div><b>3.	SAFEGUARDS TO PREVENT IMPROPER DISCLOSURES</b></div>
          <div className='pl-3'>
            <p>BUSINESS ASSOCIATE agrees to use appropriate safeguards to prevent use or disclosure of the “<b>Protected Health Information</b>”
              as defined by HIPAA in any manner other than as provided for by this Agreement and as required by HIPAA. Upon request and
              with a minimum of five business days of notice, BUSINESS ASSOCIATE shall allow COVERED ENTITY to review its data security
              measures, and procedures.</p>
          </div>
          <div><b>4.	MITIGATION PROCEDURES</b></div>
          <div className='pl-3'>
            <p>BUSINESS ASSOCIATE agrees to mitigate, in accordance with reasonable industry practices, harmful effects in violation of
              applicable law that are known to Business Associate due to its use or disclosure of Shared Information in a manner contrary
              to terms of this Agreement.</p>
          </div>
          <div><b>5.	SUBCONTRACTORS AND AGENTS EMPLOYED BY BUSINESS ASSOCIATE</b></div>
          <div className='pl-3'>
            <p>BUSINESS ASSOCIATE agrees that any and all Shared Information provided or made available to its subcontractors or agents
              shall be executed under the same or more protective terms, conditions, and restrictions regarding the use and disclosure of
              Shared Information as are in this Agreement between COVERED ENTITY and BUSINESS ASSOCIATE.</p>
          </div>
          <div><b>6.	SANCTION PROCEDURES</b></div>
          <div className='pl-3'>
            <p>BUSINESS ASSOCIATE agrees to develop and implement a punitive course of action for its employees, subcontractors, or agents who
              violate terms of this Agreement or privacy regulations under HIPAA. </p>
          </div>
          <div><b>7.	RIGHT TO ACCESS BY THE FEDERAL GOVERNMENT’S DEPARTMENT OF HEALTH AND HUMAN SERVICES </b></div>
          <div className='pl-3'>
            <p>BUSINESS ASSOCIATE hereby agrees to make its internal practices (including policies and  procedures), books, and records
              relating to use or disclosure of information gained or received under terms of this Agreement available to the Secretary of the
              Department of Health and Human Services or the Secretary's designee for purpose of determining compliance with Privacy and Security
              standards under HIPAA.</p>
          </div>
          <div><b>8.	RIGHTS OF INDIVIDUALS TO ACCESS INFORMATION</b></div>
          <div>
            <ol type="a">
              <li>BUSINESS ASSOCIATE agrees to make available and provide individuals whose Protected Health Information is stored by
                BUSINESS ASSOCIATE the right to inspect and receive a copy of their stored Protected Health Information in accordance
                with 45 CFR § 164.524.</li>
              <li>BUSINESS ASSOCIATE agrees to cooperate in making an individual’s stored Protected Health Information available to them
                for amendment and agrees to document explicit modifications requested by the requesting individual in accordance
                with 45 CFR § 164.526.</li>
              <li>BUSINESS ASSOCIATE agrees to provide an account of an individual’s Protected Health Information disclosures to the
                individual in accordance with 45 CFR §. 164.528.</li>
            </ol>
          </div>
          <div><b>9.	ELECTRONIC TRANSACTIONS</b></div>
          <div className='pl-3'>
            <p>If BUSINESS ASSOCIATE conducts any HIPAA Standard Transaction for or on behalf of COVERED ENTITY, Business Associate shall
              comply in accordance with 45 CFR § 162.</p>
          </div>
          <div><b>10.	PROPERTY RIGHTS</b></div>
          <div className='pl-3'>
            <p>Shared Information shared by the COVERED ENTITY with the BUSINESS ASSOCIATE, including, but not limited to, de-identified
              Protected Health Information, shall be and remains the property of COVERED ENTITY, which hereby grants to BUSINESS ASSOCIATE a
              worldwide, perpetual, nonexclusive, royalty-free, sublicensable, assignable license for use of the Shared Information for the
              limited purpose of providing data aggregation services performed as per this Agreement. The “Property Rights” section survives
              the Agreement.</p>
          </div>
          <div><b>11.	TERMINATION</b></div>
          <div>
            <ol type="a">
              <li><b><u>Term</u></b>. The term of this Agreement shall commence as of the Effective Date and ends when all Shared Information
                provided by the Parties to each other is destroyed or returned to the sharing Party, or sixty (60) days from either Party’s
                notice of termination to the other Party, whichever occurs first in time. </li>
              <li><b><u>Termination for Cause</u></b>. Each Party agrees that the other Party has the right to immediately terminate this Agreement and seek relief under the “Disputes” section herein if the Party determines that the other Party has violated a material term of this Agreement.</li>
            </ol>
          </div>
          <div><b>12.	COMPLIANCE WITH LAW</b></div>
          <div className='pl-3'>
            <p>BUSINESS ASSOCIATE acknowledges that by accepting the Shared Information from COVERED ENTITY, it becomes a holder of medical
              records information under local, state and federal privacy laws of the various nations in which either Party operates or has
              users and is subject to the provisions of those law.  If the U.S. HIPAA Privacy Rule or Security Rule, or a local, state, or
              foreign nation’s privacy laws create a conflict of laws regarding the degree of protection that the BUSINESS ASSOCIATE must
              provide for Protected Health Information, the BUSINESS ASSOCIATE shall comply with the more restrictive legal protection
              requirement of the conflicting laws.</p>
          </div>
          <div><b>13.	GROUNDS FOR BREACH</b></div>
          <div className='pl-3'>
            <p>Non-compliance by BUSINESS ASSOCIATE (or any of its subcontractors or agents) with HIPAA in its performance of its duties
              pursuant to this Agreement is deemed a material breach of this Agreement. Failure of COVERED ENTITY timely to pay its
              subscription or other fees to BUSINESS ASSOCIATE, or COVERED ENTITY’s infringement of intellectual property, participation in
              fraud, embezzlement, or other criminal activity in the course of this engagement, or its failure to comply with the terms of
              this Agreement is deemed a material breach of this Agreement.</p>
          </div>
          <div><b>14.	GOOD FAITH</b></div>
          <div className='pl-3'>
            <p>Each Party agrees to act in good faith and with fair dealing in its performance of this Agreement.</p>
          </div>
          <div><b>15.	INDEMNIFICATION</b></div>
          <div className='pl-3'>
            <p>Each Party hereby indemnifies and holds harmless the other Party from and against any penalties, losses, claims, damages or
              liabilities (or actions in respect thereof) to which the other Party may become subject insofar as such penalties, losses,
              claims, damages or liabilities (or actions in respect thereof), which arise out of or are based upon any unauthorized use or
              disclosure by the indemnifying Party of Protected Health Information under the terms of this Agreement and HIPAA.</p>
          </div>
          <div><b>16.	DISPUTES</b></div>
          <div className='pl-3'>
            <p>Any and all controversies or claims arising from or relating to the terms defined under this Agreement shall be subject to
              settlement by mandatory and binding arbitration in accordance with the Commercial Arbitration Rules of the American Arbitration
              Association, except for injunctive relief, which legal remedy shall be preserved by each of the Parties. The Parties shall
              submit to arbitration proceedings in the jurisdiction of the State of New Jersey in the United States, which proceedings shall
              be overseen by one arbitrator in accordance with the rules and procedures of the American Arbitration Association. All
              judgments and rulings of the arbitrator shall be final and have the full force and effect of a court judgment or ruling upon
              the Parties, including those that result from the failure of one of the Parties to participate in the arbitration or to
              participate fully. EACH OF THE PARTIES HERETO EXPRESSLY WAIVES THE RIGHT TO TRIAL BY JURY IN RESOLVING ANY CLAIM OR
              COUNTERCLAIM RELATING TO OR ARISING OUT OF THIS AGREEMENT. THE MAXIMUM LIABILITY OF BUSINESS ASSOCIATE TO COVERED ENTITY UNDER
              THIS AGREEMENT SHALL BE THE TOTAL AMOUNT OF PAYMENTS MADE TO BUSINESS ASSOCIATE BY THE COVERED ENTITY IN THE SIX (6) MONTHS
              PRECEDING THE INITIATION OF ARBITRATION.</p>
          </div>
          <div><b>17.	ATTORNEY FEES</b></div>
          <div className='pl-3'>
            <p>Each Party agrees to bear its own legal expenses and any other  related costs incurred for any and all actions or proceedings
              associated with the terms of this Agreement, or from an alleged dispute, breach, default, or injunctive action associated with
              the terms of this Agreement, with the exception that each Party’s right to request legal fees and costs shifting from an
              adjudicator in the event that a ruling in favor of the requesting Party involves a finding of misrepresentation, fraud,
              embezzlement, data privacy law violation, or criminal activity.</p>
          </div>
          <div><b>18.	ASSIGNMENT</b></div>
          <div className='pl-3'>
            <p>Neither Party has the authority to assign or transfer its rights and duties pursuant to this Agreement without prior written
              notice to the other Party of no less than ten (10) days prior to the assignment or transfer date.</p>
          </div>
          <div><b>19.	ENTIRE AGREEMENT</b></div>
          <div className='pl-3'>
            <p>This Agreement and its exhibits represent the entire agreement between the Parties, and any and all verbal or written promises,
              representations, warranties, or agreements of the Parties made prior to the date of this Agreement are hereby superseded by this Agreement.</p>
          </div>
          <div><b>20.	AMENDMENT</b></div>
          <div className='pl-3'>
            <p>Both Parties agree to undertake such action as is necessary to amend this Agreement by mutually executing a signed writing,
              which may be voluntarily agreed to by, or be required of, the Parties from time to time and as is necessary for the Parties and
              the terms of this Agreement to comply with the requirements of HIPAA and other data privacy laws. </p>
          </div>
          <div><b>21.	INTERPRETATION</b></div>
          <div className='pl-3'>
            <p>Any ambiguity in this Agreement shall be resolved to permit COVERED ENTITY and BUSINESS ASSOCIATE to comply with HIPAA.</p>
          </div>
          <div className='pl-3'>
            <i>[THIS SPACE IS INTENTIONALLY LEFT BLANK.]</i>
          </div>
          <div><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></div>
          <div>
            <SignaturePage />
          </div>
          <div className='font-15 text-center pt-5'><b>EXHIBIT A:</b></div>
          <div className='font-15 text-center pt-2'><b>STATEMENT OF WORK & PAYMENT TERMS</b></div>
          <div>
            <ol type="1">
              <li>
                <div><b><u>Statement of Work: </u></b></div>
                <div>MMF platform will enable you to</div>
                <div>
                  <ul>
                    <li>Onboard new patients</li>
                    <li>Manage your appointments</li>
                    <li>Share records with patients</li>
                    <li>Track patient feed-back via reviews (if you choose to enable)</li>
                  </ul>
                </div>
              </li>
              <li>
                <div><b><u>Payment Terms.</u></b></div>
                <div>
                  <ol type="a">
                    <li>
                      <div><b>Pricing</b>: Please refer to the
                        pricing information provided separately by MYMEDIFAM Inc</div>
                      {/* <div className='py-4 mr-5'>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableCell>India</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableHead>
                            <TableBody>
                              {tableIndia.length > 0 && tableIndia.map((row, i) => (
                                 <TableRow>
                                 <TableCell>{row.col1}</TableCell>
                                 <TableCell>{row.col2}</TableCell>
                                 <TableCell>{row.col3}</TableCell>
                                 <TableCell>{row.col4}</TableCell>
                                 <TableCell>{row.col5}</TableCell>
                               </TableRow>
                              )) }                             
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      <div className='py-4 mr-5'>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableCell>USA</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableHead>
                            <TableBody>
                              {tableUSA.length > 0 && tableUSA.map((row, i) => (
                                 <TableRow>
                                 <TableCell>{row.col1}</TableCell>
                                 <TableCell>{row.col2}</TableCell>
                                 <TableCell>{row.col3}</TableCell>
                                 <TableCell>{row.col4}</TableCell>
                                 <TableCell>{row.col5}</TableCell>
                               </TableRow>
                              )) }                             
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div> */}
                    </li>
                    {/* <li>
                      <div><b>Payment Schedule</b>: Monthly subscription model. Payment will be made by the provider at the 1st of every month;
                        onboarding after the 1st of the month will be will be pro-rated for the remainder of period until the 1st of the next month.</div>
                    </li> */}
                  </ol>
                </div>
              </li>
            </ol>

          </div>
        </div>

      </div>
    </div>
  );


  return (
    <div>
      {body}
    </div>
  );
}
export default BusinessAgreement;
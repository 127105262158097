import React, { useEffect, useState } from 'react';
import './AppointmentDocuments.scss';
import CloseIcon from '@material-ui/icons/Close';
import { http, FxDateDisplay, FxDateTimeDisplay } from '../../fx-core';
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { ImageViewer } from '../../general/ImageViewer/ImageViewer';
import { PdfViewer } from '../../general/PdfViewer/PdfViewer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommentIcon from '@material-ui/icons/Comment';
import DateIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';

const AppointmentDocuments = (props: any) => {
  const initialList = [];
  const [documentList, setDocumentList] = useState(initialList);
  const [medicineList, setMedicineList] = useState(initialList);
  const [vitalList, setVitalList] = useState(initialList);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [docData, setDocData] = useState(null);
  const [docName, setDocName] = useState(null);
  const [selectedTab, setSelectedTab] = useState("vitals");
  const [filledFormList, setFilledFormList] = useState(initialList);
  const [vaccineList, setVaccineList] = useState(initialList);
  const [appointment, setAppointment] = useState({ appointmentIdentifier: "", appointmentDate: "", customerName: "" });

  const getAppointment = () => {
    let inputData = {
      id: props.apptId
    }
    let apiOptions: any = {
      url: `stores/service/appointment/${inputData.id}`
    };
    http.get(apiOptions).then(response => {
      setAppointment(response.data);
      getVitalList();
    })
  }

  const getDocumentList = () => {
    let inputData = {
      id: props.apptId
    }
    let apiOptions: any = {
      url: `appointmentdocument/appointment/${inputData.id}`
    };
    http.get(apiOptions).then(response => {
      setDocumentList(response.data);
    })
  }

  const getMedicineList = () => {
    let inputData = {
      id: props.apptId
    }
    let apiOptions: any = {
      url: `appointmentmedicine/appointment/${inputData.id}/detail`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      for (let item of result) {
        item.patientMedicine.durationValue = item.patientMedicine.duration + " " + item.patientMedicine.durationName;
      }
      setMedicineList(result);
    })
  }

  const getVitalList = () => {
    let inputData = {
      id: props.apptId
    }
    let apiOptions: any = {
      url: `appointmentvital/appointment/${inputData.id}/latest`
    };
    http.get(apiOptions).then(response => {
      setVitalList(response.data);
    })
  }

  const getFilledFormList = () => {
    let inputData = {
      id: props.apptId
    }
    let apiOptions: any = {
      url: `appointmentform/appointment/${inputData.id}/detail`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      setFilledFormList(result);
    })
  }

  const getVaccineList = () => {
    let inputData = {
      id: props.apptId
    }
    let apiOptions: any = {
      url: `appointmentvaccine/appointment/${inputData.id}/detail`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      setVaccineList(result);
    })
  }

  const handleClose = () => {
    props.handleDocDialogClose()
  }

  const isImage = (item) => {
    return (item.fileExtension == '.jpeg' || item.fileExtension == '.jpg' || item.fileExtension == '.png');
  }

  const isPdf = (item) => {
    return item.fileExtension == '.pdf';
  }

  const viewImage = (res: any) => {
    setDocData(res.base64data);
    setImageViewerOpen(true);
  }

  const viewPdf = (res: any) => {
    setDocData(res.base64data);
    setPdfViewerOpen(true);
  }

  const downloadDocument = (item) => {
    setDocName(item.documentName);
    let apiOptions: any = {
      url: `patientdocument/download/${item.patientDocumentId}`,
      method: 'get',
      fileName: item.fileName,
      documentName: item.documentName,
      data: {},
    }
    if (isImage(item)) {
      apiOptions.callback = viewImage;
      http.view(apiOptions);
    } else if (isPdf(item)) {
      apiOptions.callback = viewPdf;
      http.view(apiOptions);
    }
    else {
      http.download(apiOptions);
    }
  }

  const handleImageViewerClose = () => {
    setImageViewerOpen(false);
  }

  const handlePdfViewerClose = () => {
    setPdfViewerOpen(false);
  }

  const loadBySelectedTab = (type: any) => {
    setSelectedTab(type);
    if (type == 'medicines') {
      getMedicineList();
    }
    if (type == 'vitals') {
      getVitalList();
    }
    if (type == 'forms') {
      getFilledFormList();
    }
    if (type == 'vaccines') {
      getVaccineList();
    }
    if (type == 'documents') {
      getDocumentList();
    }
  }

  const downloadForm = (item) => {
    setDocName(item.formName);
    let apiOptions: any = {
      url: `patientfilledform/download/${item.patientFilledFormId}`,
      method: 'get',
      fileName: item.formName,
      documentName: item.formName,
      data: {},
    }
    if (isPdf(item)) {
      apiOptions.callback = viewPdf;
      http.view(apiOptions);
    }
    else {
      http.download(apiOptions);
    }
  }

  useEffect(() => {
    getAppointment();
  }, []);


  const body = (
    <div className="documentContainer scroll-ui">
      <div className="custom-head">
        <div className="row title-font mx-auto">
          <div className="col-11 pl-2">
            <span>Documents</span>
          </div>
          <div className="col-1 text-right pr-0 pointer" onClick={handleClose}>
            <CloseIcon fontSize="small" color="secondary" />
          </div>
        </div>

        <div className='row m-2'>
          <div className="col-4"><span>#{appointment.appointmentIdentifier}</span></div>
          <div className="col-4">
            <EventIcon fontSize="small" color="action" />
            <FxDateDisplay value={appointment.appointmentDate} />
          </div>
          <div className="col-4">
            <span className='float-right'>
              <PersonIcon fontSize="small" color="action" />
              {appointment.customerName}
            </span>
          </div>
        </div>

        <div className="row mx-auto title1">
          <div className="col-3 pl-0 text-center" onClick={() => loadBySelectedTab('vitals')}>
            {
              <span className={selectedTab === 'vitals' ? "selectedStyle pointer" : "pointer"}>Health Tracker</span>
            }
          </div>
          <div className="col-3 pl-0 text-center" onClick={() => loadBySelectedTab('documents')}>
            {
              <span className={selectedTab === 'documents' ? "selectedStyle pointer" : "pointer"}>Documents</span>
            }
          </div>
          <div className="col-2 pl-0 text-center" onClick={() => loadBySelectedTab('forms')}>
            {
              <span className={selectedTab === 'forms' ? "selectedStyle pointer" : "pointer"}>Forms</span>
            }
          </div>
          <div className="col-2 pl-0 text-center" onClick={() => loadBySelectedTab('medicines')}>
            {
              <span className={selectedTab === 'medicines' ? "selectedStyle pointer" : "pointer"}>Medicines</span>
            }
          </div>
          <div className="col-2 pl-0 text-center" onClick={() => loadBySelectedTab('vaccines')}>
            {
              <span className={selectedTab === 'vaccines' ? "selectedStyle pointer" : "pointer"}>Vaccines</span>
            }
          </div>
        </div>
        <div className='dividerstyle mt-1 mx-2'></div>
      </div>

      <div className="pt-5">
        {
          selectedTab == 'documents' &&
          <div className='pt-5'>
            {
              documentList && documentList.map((item) => (
                <div className="m-2 pt-2 row appointmentdocsgridbox">
                  <div className="col-4">{item.documentName}</div>
                  <div className="col-4">
                    {item.documentTypeId != 9999 &&
                      <span>{item.documentType}</span>
                    }
                    {item.documentTypeId == 9999 &&
                      <span>{item.otherDocumentType &&
                        <span> {item.otherDocumentType}</span>
                      }
                        {!item.otherDocumentType &&
                          <span> {item.documentType}</span>
                        }
                      </span>
                    }
                  </div>
                  <div className="col-4 text-center">
                    <Button variant="contained" size="small" color="primary"
                      onClick={() => downloadDocument(item)}>Download</Button>
                  </div>
                </div>
              ))
            }
            {
              documentList && documentList.length == 0 &&
              <div className="m-2 row">
                <span className="col-12 text-center pt-2">No documents shared!!</span> </div>
            }
          </div>

        }

        {
          selectedTab == 'medicines' &&
          <div className='pt-5'>
            {
              medicineList && medicineList.map((item) => (
                <div className="m-2 pt-2 row appointmentdocsgridbox">
                  <div className="col-12">{item.apptMedicine.medicineName} {item.patientMedicine.dosage} -
                    {item.patientMedicine.frequencyName} for {item.patientMedicine.durationValue}</div>
                  <div className="col-12">
                    <DateIcon />
                    <FxDateDisplay value={item.patientMedicine.startDate} /> -
                    <span className="pl-1">
                      <FxDateDisplay value={item.patientMedicine.endDate} /></span>
                  </div>
                  {item.patientMedicine.comments &&
                    <div className="col-12"> <CommentIcon />{item.patientMedicine.comments}</div>
                  }
                </div>
              ))}
            {
              medicineList && medicineList.length == 0 &&
              <div className="m-2 row">
                <span className="col-12 text-center pt-2">No medicines shared!!</span></div>
            }
          </div>
        }

        {
          selectedTab == 'vitals' &&
          <div className='pt-5'>
            {vitalList && vitalList.map((item) => (
              <div className="m-2 pt-2 row">
                <Accordion className="widthStyle">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />} >
                    <span>{item.apptVital.vitalName}({item.patientVitals.length})</span>
                  </AccordionSummary>
                  <div>
                    {item.patientVitals && item.patientVitals.map((row) => (
                      <div className="ml-2 mr-2 mb-2 row">
                        {row.vitalCode == 'cycle' ?
                          <div className="col-12">
                            <span className="col-6">
                              <FxDateDisplay value={row.cycleDate} />
                            </span>
                          </div>
                          :
                          <div className="col-12"><span className="col-6">
                            {row.capturedValue}  {row.vitalUOM}
                          </span>
                            <span className="col-6 float-right">
                              <FxDateDisplay value={row.capturedDate} />
                              <span className="pl-1">{row.capturedTime}</span>
                            </span></div>
                        }

                      </div>
                    ))}
                    {
                      item.patientVitals && item.patientVitals.length == 0 &&
                      <div className="ml-3 mr-2 mb-2 row">
                        No records!!</div>
                    }
                  </div>
                </Accordion>
              </div>
            ))}
            {vitalList && vitalList.length == 0 &&
              <div className="m-2 row">
                <span className="col-12 text-center pt-2">No health tracker details shared!!</span>
              </div>
            }
          </div>
        }

        {
          selectedTab == 'forms' &&
          <div className='pt-5'>
            {
              filledFormList && filledFormList.map((item) => (
                <div className="m-2 pt-2 row appointmentdocsgridbox">
                  <div className="col-8">{item.apptForm.formName}</div>
                  <div className="col-4">
                    <span className="float-right">
                      <Button variant="contained" size="small" color="primary"
                        onClick={() => downloadForm(item.apptForm)}>Download</Button>
                    </span>
                  </div>
                  <div className="col-12">
                    <span className="float-right">
                      <span className="pr-1">Filled on</span>
                      <FxDateTimeDisplay value={item.patientFilledForm.generatedDate} />
                    </span>
                  </div>
                </div>
              ))}
            {
              filledFormList && filledFormList.length == 0 &&
              <div className="m-2 row">
                <span className="col-12 text-center pt-2">No forms shared!!</span></div>
            }
          </div>
        }
        {
          selectedTab == 'vaccines' &&
          <div className='pt-5'>
            {
              vaccineList && vaccineList.map((item) => (
                <div className="m-2 pt-2 row appointmentdocsgridbox">
                  {item.patientVaccine.vaccinatedDate &&
                    <div className="col-12">
                      {item.apptVaccine.vaccineName} is vaccinated on -
                      <span className="pl-1">
                        <FxDateDisplay value={item.patientVaccine.vaccinatedDate} />
                      </span>
                    </div>
                  }
                  {!item.patientVaccine.vaccinatedDate &&
                    <div className="col-12">
                      {item.apptVaccine.vaccineName} is due on -
                      <span className="pl-1">
                        <FxDateDisplay value={item.patientVaccine.dueDate} />
                      </span>
                    </div>
                  }

                  {item.patientVaccine.hasSideEffects && item.patientVaccine.sideEffects &&
                    <div className="col-12"> <CommentIcon />{item.patientVaccine.sideEffects}</div>
                  }
                </div>
              ))}
            {
              vaccineList && vaccineList.length == 0 &&
              <div className="m-2 row">
                <span className="col-12 text-center pt-2">No vaccines shared!!</span></div>
            }
          </div>
        }
      </div>

      <div>
        <Dialog
          maxWidth={false}
          open={imageViewerOpen}
          onClose={handleImageViewerClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ImageViewer handleClose={handleImageViewerClose} docData={docData} docName={docName} />
        </Dialog>
        <Dialog
          maxWidth={false}
          open={pdfViewerOpen}
          onClose={handlePdfViewerClose}
          aria-labelledby="alert-dialog-title1"
          aria-describedby="alert-dialog-description1">
          <PdfViewer handleClose={handlePdfViewerClose} docData={docData} docName={docName} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default AppointmentDocuments;
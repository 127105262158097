import React, { useEffect, useState } from 'react';
import './RoleList.scss';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import { http, useNavigationService } from '../../fx-core';
import { useHistory } from "react-router-dom";

const RoleList = () => {
    const history = useHistory();
    const { pageTitle } = useNavigationService();
    let defaultFilter: any = {
        roleName: ''
    };

    const [roles, setRoles] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);


    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { filter: { [item.name]: item.value } };
        getRoleList(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getRoleList = (options: any = {}) => {
        if (options && options.filter) {
            filter.roleName = options.filter.roleName;
        }
        let inputData = {
            roleName: filter.roleName
        };
        let apiOptions: any = {
            url: `role/query`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            setRoles(res.data);
        })
    };

    const assignScreens = (item) => {
        history.push({
            pathname: '/rolecontrolmaplist',
            state: { roleId: item.id, roleName: item.roleName }
        });
    }

    useEffect(() => {
        let options = { filter: filter };
        getRoleList(options);
    }, []);

    return (
        <div className="content-container">
            <div className="title-font">
                <span>{pageTitle}</span>
            </div>
            <div className="px-3 pt-3">
                <TableContainer component={Paper}>
                    <Table stickyHeader className="table table-striped">
                        <TableHead>
                            <TableRow>
                                <TableCell width="60%" className="table-head table-font">Role</TableCell>
                                <TableCell className="table-head table-font">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roles && roles.length > 0 &&
                                roles.map((row, i) => (
                                    <TableRow key={i} className="table-font">
                                        <TableCell>
                                            <span>{row.roleName}</span>
                                        </TableCell>
                                        <TableCell>
                                            <div className="btn-style">
                                                <Button variant="contained" size="small" color="primary"
                                                    onClick={() => assignScreens(row)}> Assign Screens
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
};

export default RoleList;
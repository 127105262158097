import React, { useState, useEffect } from 'react';
import './VitalForm.scss';
import { FxInput, FxSelect, AlertService, http, FormValidator } from '../../fx-core';
import { Button } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from 'react-router-dom';

const VitalForm = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const vitalId = propsObj.id;

    const defaultVital = {
        vitalCode: "",
        vitalName: "",
        description: "",
        vitalUOM: "",
        isActive: true,
        isDefault: false,
        id: 0
    };
    const [vital, setVital] = useState(defaultVital);
    const [errors, setErrors] = useState({});

    const validationMap = {
        vitalCode: ["required"],
        vitalName: ["required"],
        vitalUOM: ["required"]
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            // if (key === "vitalUOMId") {
            //     let val = vital[key] === -1 ? "" : vital[key] + '';
            //     let item = { name: key, value: val, validations: validationMap[key] }
            //     items.push(item);
            // }
            let item = { name: key, value: vital[key], validations: validationMap[key] }
            items.push(item);
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const handleInputChange = (item) => {
        handleVitalObjChange({ [item.name]: item.value });
        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleVitalObjChange = (data) => {
        setVital(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleCheckbox = (event) => {
        handleVitalObjChange({ [event.target.name]: event.target.checked });
    };

    const addVital = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `vital/add`,
                data: vital
            };
            http.post(apiOptions).then(res => {
                saveVitalCallBack(res);
            });
        }
    };

    const updateVital = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `vital/update`,
                data: vital
            };
            http.put(apiOptions).then(res => {
                saveVitalCallBack(res);
            });
        }
    };

    const saveVitalCallBack = (res) => {
        AlertService.showSuccessMsg();
        if (vitalId > 0) {
            getVitalById();
        }
        else {
            back();
        }
    };

    const getVitalById = () => {
        let apiOptions: any = {
            url: `vital/${vitalId}`
        };
        http.get(apiOptions).then(res => {
            getVitalByIdCallBack(res);
        });
    };

    const getVitalByIdCallBack = (res) => {
        setVital(res.data);
    };

    const back = () => {
        history.goBack();
    };

    useEffect(() => {
        if (vitalId > 0) {
            getVitalById();
        }
    }, []);

    return (
        <div className="content-container ">
            <div className="title-font pl-4">
                <span>Vital</span>
            </div>
            <div className="">
                <div className="col-4 mx-auto pt-4">
                    <div className="col-12">
                        <FxInput name="vitalCode" variant="outlined" label="Code" size="small" fullWidth
                            value={vital.vitalCode}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("vitalCode", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                    </div>

                    <div className="col-12 pt-3">
                        <FxInput name="vitalName" variant="outlined" label="Name" size="small" fullWidth
                            value={vital.vitalName}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("vitalName", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                    </div>

                    <div className="col-12 pt-3">
                        <FxInput name="description" variant="outlined" label="Description" size="small" fullWidth
                            value={vital.description}
                            onValueChange={handleInputChange}
                        />
                    </div>

                    <div className="col-12 pt-3">
                        <FxInput name="vitalUOM" variant="outlined" label="Vital UOM" size="small" fullWidth
                            value={vital.vitalUOM}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("vitalUOM", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                    </div>

                    <div className="col-12 pt-3">
                        <Checkbox name="isActive" color="primary" checked={vital.isActive}
                            value={vital.isActive} onChange={handleCheckbox} />Is Active
                    </div>

                    <div className="col-12">
                        <Checkbox name="isDefault" color="primary" checked={vital.isDefault}
                            value={vital.isDefault} onChange={handleCheckbox} />Is Default
                    </div>

                </div>

                <div className="col-4 mx-auto text-right">
                    <span className="pl-2">
                        <Button variant="contained" size="small" color="primary" onClick={back}>Cancel</Button>
                    </span>
                    <span className="pl-2">
                        {
                            vital.id === 0 &&
                            <Button variant="contained" size="small" color="primary" onClick={addVital}>Save</Button>
                        }
                        {
                            vital.id > 0 &&
                            <Button variant="contained" size="small" color="primary" onClick={updateVital}>Save</Button>
                        }
                    </span>
                </div>
            </div>
        </div>
    )
};

export default VitalForm;
import React, { useEffect, useState } from 'react';
import './ProviderProfile.css';
import { session, useNavigationService } from "../../fx-core";
import ProviderGeneral from '../ProviderGeneral/ProviderGeneral';
import ProviderClinic from '../ProviderClinic/ProviderClinic';
import ProviderService from '../ProviderService/ProviderService';
import ProviderRegistration from '../ProviderRegistration/ProviderRegistration';
import ProviderEducation from '../ProviderEducation/ProviderEducation';
import ProviderSpecialization from '../ProviderSpecialization/ProviderSpecialization';
import ProviderPaymentOptions from '../ProviderPaymentOptions/ProviderPaymentOptions';
import ProviderInsurance from '../ProviderInsurance/ProviderInsurance';
import ProviderReferral from '../ProviderReferral/ProviderReferral';
import ProviderChangePassword from '../ProviderChangePassword/ProviderChangePassword';
import ProviderPreference from '../ProviderPreference/ProviderPreference';
import ProviderCertificate from '../ProviderCertificate/ProviderCertificate';

const ProviderProfile = (props: any) => {
  const { pageTitle } = useNavigationService();
  console.log('pageTitle=', pageTitle);
  let providerIdVal = session.getItem('providerId');
  const [providerId, setProviderId] = useState(providerIdVal);

  useEffect(() => {
  }, []);


  const body = (
    <div className="content-container pb-5">
      <div className="title-font">
        <span>{pageTitle}</span>
      </div>
      <div className="col-12 p-0 row mx-auto">
        <div className="col-6 p-0 pt-4 px-1">
          <ProviderGeneral providerId={providerId} />
        </div>
        <div className="col-6 p-0 pt-4 px-1">
          <ProviderClinic providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderPreference providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderEducation providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderRegistration providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderCertificate providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderSpecialization providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderService providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderPaymentOptions providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderInsurance providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderReferral providerId={providerId} />
        </div>
        <div className="col-6 p-1 pt-4">
          <ProviderChangePassword providerId={providerId} />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderProfile;
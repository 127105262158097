import React, { useState, useEffect } from 'react';
import './RegisterPatient.scss';
import { FxInput, AlertService, http, FxMobile, FormValidator } from '../fx-core';
import { Button, Dialog, Link } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import Banner from "./../../assets/banner.jpg";
import TermsAndConditions from '../general/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../general/PrivacyPolicy/PrivacyPolicy';
import Popover from "@material-ui/core/Popover";
import InfoIcon from '@material-ui/icons/Info';

var bgStyle = {
    width: "100%",
    height: "100vh",
    backgroundImage: "url(" + Banner + ")",
};

const RegisterPatient = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;

    const defaultItem = {
        userName: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        id: 0,
        referralCode: propsObj.referralCode ? propsObj.referralCode : "",
        accept: "",
        registrationDate: "",
        countryCode: "+1"
    };
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});
    const [valFlag, setValFlag] = useState(null);
    const [qrcode, setQrcode] = useState(null);
    const [link, setLink] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [countryCode, setCountryCode] = useState([]);
    const [termsopen, setTermsOpen] = useState(false);
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const popoverOpen = Boolean(anchorEl);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleCheckbox = (event) => {
        handleItemChange({ [event.target.name]: event.target.checked });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                CountryCode: { default: false }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'Options/GetLookupOptions',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setCountryCode(response.data.CountryCode);
        });
    }

    const validationMap = {
        userName: ["required", "alphanumspcl"],
        password: ["required", "pwdvalid"],
        firstName: ["required", "alphabets"],
        email: ["email", "emailvalid"],
        mobile: ["mobileno", "maxlen"],
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            if (key == "mobile") {
                let itemVal = { name: key, value: item[key], validations: validationMap[key], param: 10 }
                items.push(itemVal);
            }
            else {
                let itemVal = { name: key, value: item[key], validations: validationMap[key] }
                items.push(itemVal);
            }
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const registerPatient = () => {
        const isValid = bulkValidate();
        if (isValid) {
            if (!item.email && !item.mobile) {
                setValFlag("Either email or mobile is required");
                return;
            }
            if (!item.accept) {
                AlertService.showErrorMsg("Please accept terms of service and privacy policy");
                return;
            }
            let address = {
                addressTypeId: 1,//Home 
                isDefault: true//Setting as default address
            }
            let inputData = {
                customer: item,
                defaultPreferencelist: null,
                customerAddress: address
            };
            let apiOptions: any = {
                url: `customer/register/portal`,
                data: inputData
            };
            http.post(apiOptions).then(res => {
                savePatientCallBack(res);
            });
        }
    };

    const savePatientCallBack = (res) => {
        AlertService.showSuccessMsg();
        getQrCodeAndLink(res.data);
    };

    //Get qr code and mail link
    const getQrCodeAndLink = (custId) => {
        setCurrentStep(2);
        getQrCode(custId);
        getLinkUrl(custId);
    }

    //Get qrcode 
    const getQrCode = (custId) => {
        let apiOptions: any = {
            url: `customer/getqrcode/${custId}`,
            method: 'get'
        }
        apiOptions.callback = viewImage;
        http.view(apiOptions)
    }
    const viewImage = (res: any) => {
        let qrCode = res.base64data;
        setQrcode(qrCode);
    }

    const getLinkUrl = (custId) => {
        let apiOptions: any = {
            url: `customer/getmaillink/${custId}`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            setLink(result.linkUrl);
        });
    }

    const navigateBack = () => {
        if (propsObj.isLogin) {
            history.push('/');
        }
        else {
            history.push({
                pathname: '/registerlanding',
                state: { code: item.referralCode ? item.referralCode : null }
            });
        }
    }

    const openGooglePlaystore = (url) => {
        window.open('https://play.google.com/store/apps/details?id=app.mymedifam', '_blank');
    }
    const openAppStore = (url) => {
        window.open('https://apps.apple.com/us/app/mymedifam/id1598963734', '_blank');
    }

    const openTermsAndConditions = () => {
        setTermsOpen(true);
    };

    const handleTermsClose = () => {
        setTermsOpen(false);
    };

    const openPrivacyPolicy = () => {
        setPrivacyPolicyOpen(true);
    };

    const handlePrivacyPolicyClose = () => {
        setPrivacyPolicyOpen(false);
    };

    useEffect(() => {
        initLookup();
    }, []);

    return (
        <div className="reg-appt reg-patient reg-appt-margin" style={bgStyle}>
            <div className="title-font pl-4">
                <span>Register User</span>
            </div>
            <div className="row pt-5 pt-lg-0 m-0">
                <div className="col-12 col-md-0 col-lg-1"></div>
                <div className="col-12 row m-0 col-lg-5 my-auto px-3">
                <div className='col-12 reg-content text-center mb-5'>We are HIPPA compliant, and end to end encrypted</div>

                    <div className="col-12 labelHeading text-center">
                        Get more Details
                    </div>
                    <div className="col-12 text-center font-16 pt-3 labeldesc">
                        MYMEDIFAM helps you to find best Doctors in your location. <br />
                        MYMEDIFAM helps you to track your heath records, book appointments and medications.
                    </div>
                    <div className="col-12 text-center pt-3 p-0">
                        <span className="pr-3">
                            <Button variant="contained" size="large" color="primary" startIcon={<i className="fab fa-google-play"></i>}
                                onClick={openGooglePlaystore}>Google Play</Button>
                        </span>
                        <span>
                            <Button variant="contained" size="large" color="primary" startIcon={<i className="fab fa-apple"></i>}
                                onClick={openAppStore}>App Store</Button>
                        </span>
                    </div>
                </div>
                <div className="col-12 row m-0 col-lg-5 pt-5 mt-4 formstyle px-3">
                    {currentStep == 1 &&
                        <>
                            <div className="col-12 labelHeading text-center">
                                Register User
                            </div>
                            <div className=" col-12 col-md-6 col-lg-12 mt-3">
                                <FxInput name="firstName" variant="outlined" label="First name*" size="small" fullWidth
                                    value={item.firstName}
                                    onValueChange={handleInputChange}
                                />
                                {
                                    hasError("firstName", "required") &&
                                    <div className="validation-msg">Field is required</div>
                                }
                                {
                                    hasError("firstName", "alphabets") &&
                                    <div className="validation-msg">First name should contain only alphabets</div>
                                }
                            </div>

                            <div className=" col-12 col-md-6 col-lg-12 mt-3">
                                <FxInput name="lastName" variant="outlined" label="Last name" size="small" fullWidth
                                    value={item.lastName} onValueChange={handleInputChange}
                                />
                            </div>

                            <div className=" col-12 col-md-6 col-lg-12 mt-3">
                                <FxInput name="email" variant="outlined" label="Email" size="small" fullWidth
                                    value={item.email} onValueChange={handleInputChange}
                                />
                                <span className="mb-2 font-12">Either email or mobile is required*</span>
                                {
                                    (hasError("email", "email") || hasError("email", "emailvalid")) &&
                                    <div className="validation-msg">Please enter valid email id</div>
                                }
                            </div>

                            <div className=" col-12 col-md-6 col-lg-12 mt-3">
                                <FxMobile value={item.mobile} onValueChange={handleInputChange} countryCode={countryCode}
                                    validation={false} />
                                {
                                    hasError("mobile", "mobileno") &&
                                    <div className="validation-msg">Mobile should contain only numbers</div>
                                }
                                {
                                    hasError("mobile", "maxlen") &&
                                    <div className="validation-msg">Mobile should accept 10 digits</div>
                                }
                            </div>
                            {
                                valFlag &&
                                <div className="validation-msg">{valFlag}</div>
                            }

                            <div className=" col-12 col-md-6 col-lg-12 mt-3">
                                <FxInput name="userName" variant="outlined" label="Username*" size="small" fullWidth
                                    value={item.userName}
                                    onValueChange={handleInputChange}
                                />
                                {
                                    hasError("userName", "required") &&
                                    <div className="validation-msg">Field is required</div>
                                }
                                {
                                    hasError("userName", "alphanumspcl") &&
                                    <div className="validation-msg">Username should contain alphabets,numbers and special characters</div>
                                }
                            </div>

                            <div className=" col-12 col-md-6 col-lg-12 mt-3">
                                <FxInput type="password" name="password" variant="outlined" label="Password*" size="small" fullWidth
                                    value={item.password}
                                    onValueChange={handleInputChange}
                                />
                                {
                                    hasError("password", "required") &&
                                    <div className="validation-msg">Field is required</div>
                                }
                                {
                                    hasError("password", "pwdvalid") &&
                                    <div className="validation-msg">Password should contain atleast one uppercase,one lowercase,one number and one special character(@$!%*?&) with minimum 8 characters</div>
                                }
                            </div>

                            <div className='col-12 col-md-6 col-lg-12 mt-3'>
                                <div className='row px-2'>
                                    <span className='col-11'>
                                        <FxInput name="referralCode" variant="outlined" label="Referral Code" size="small" fullWidth
                                            value={item.referralCode} onValueChange={handleInputChange}
                                        />
                                    </span>
                                    <span className='col-1 float-right alignicon' onClick={(event) => {
                                        setAnchorEl(event.currentTarget);
                                    }}>
                                        <InfoIcon />
                                    </span>
                                </div>
                                <Popover
                                    anchorEl={anchorEl}
                                    open={popoverOpen}
                                    id={popoverOpen ? "simple-popover" : undefined}
                                    onClose={() => { setAnchorEl(null); }}
                                    transformOrigin={{ horizontal: "center", vertical: "top", }}
                                    anchorOrigin={{ horizontal: "center", vertical: "bottom", }}>
                                    <span className='px-2'>Please enter the 10 character code received if this application was referred
                                        to you by someone</span>                              
                                </Popover>
                            </div>

                            <div className=" col-12 mt-3 font-13">
                                <Checkbox name="accept" color="primary" value={item.accept} onChange={handleCheckbox} />
                                I have read and agreed to the
                                <Link className="pointer" onClick={openTermsAndConditions}> Terms of Service </Link>
                                and the
                                <Link className="pointer" onClick={openPrivacyPolicy}> Privacy Policy</Link>
                            </div>
                            <div className="row m-0 col-12 mt-4 pb-3 pr-3 justify-content-end">
                                <span className="pl-2">
                                    <Button variant="contained" size="medium" onClick={navigateBack}>Cancel</Button>
                                </span>
                                <span className="pl-2">
                                    <Button variant="contained" size="medium" color="primary" onClick={registerPatient}>Register</Button>
                                </span>
                            </div>
                        </>
                    }
                    {currentStep == 2 &&
                        <span className="text-center">
                            <div className="scan-text">Scan QRCODE</div>
                            <div className="p-2">
                                <img src={qrcode} alt="Qrcode Image" width="40%" />
                            </div>
                            <div className="p-2">or</div>
                            <div className="p-2">Open the link below in mobile to download the app on your mobile device.</div>
                            <div className="p-2 pb-4">{link}</div>

                            <div className="row mt-4 pb-3 pr-3 justify-content-end">
                                <span className="pl-2">
                                    <Button variant="contained" size="medium" onClick={navigateBack}>Back</Button>
                                </span>
                            </div>
                        </span>
                    }
                </div>
                <div className="col-12 col-md-0 col-lg-1"></div>

            </div>
            <Dialog open={termsopen}
                maxWidth="lg" onClose={handleTermsClose}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <TermsAndConditions handleTermsClose={handleTermsClose} />
            </Dialog>
            <Dialog open={privacyPolicyOpen}
                maxWidth="lg" onClose={handlePrivacyPolicyClose}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <PrivacyPolicy handlePrivacyPolicyClose={handlePrivacyPolicyClose} />
            </Dialog>
        </div>
    )
};

export default RegisterPatient;
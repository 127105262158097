
export const localStorageService = {
    getItem: getItem,
    setItem: setItem,
    clear: clear
}

 function setItem(key: any, value: any) {
    return localStorage.setItem(key, value);
}

function getItem(key: any) {
    return localStorage.getItem(key);
} 

function clear() {
    localStorage.clear();
}
import React, { useEffect, useState } from 'react';
import './ProviderEducation.scss';
import { FxInput, FxSelect, http, AlertService } from '../../fx-core';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';

const ProviderEducation = (props: any) => {
  let defaultItem: any = {
    qualification: null,
    providerId: props.providerId
  };
  const initialList = [];
  const [item, setItem] = useState(defaultItem);
  const [providerEducations, setProviderEducations] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [error, setError] = React.useState(null);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };


  const saveProviderEducation = () => {
    setError(null);
    if (!item.qualification) {
      setError("This field is required");
      return;
    }
    let apiOptions: any = {
      url: 'providereducation/add',
      data: item
    };
    http.post(apiOptions).then(response => {
      saveEducationCallBack(response);
    });
  }

  const saveEducationCallBack = (res) => {
    setItem({ qualification: '' });
    AlertService.showSuccessMsg();
    getProviderEducations();
  };

  const getProviderEducations = () => {
    let inputData = {
      providerId: props.providerId
    }
    let apiOptions: any = {
      url: 'providereducation/query', data: inputData
    };
    http.post(apiOptions).then(response => {
      setProviderEducations(response.data);
    });
  }

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setMessage("Do you want to delete " + item.qualification + "?");
    setOpen(true);
  };

  const confirmMessage = (action: any) => {
    setOpen(false);
    deleteEducation(selectedItem);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const deleteEducation = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `providereducation/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderEducations();
    })
  }

  useEffect(() => {
    getProviderEducations();
  }, []);


  const body = (
    <div className="content-container1 card provider-education">
      <div className="title-font pl-4">
        <span>Education</span>
      </div>
      <div className="row m-0 px-3">
        <div className="col-6 p-0">
          <FxInput name="qualification" variant="outlined" label="Qualification*" size="small"
            value={item.qualification}
            onValueChange={handleInputChange}
          />
          {
            error && !item.qualification &&
            <div className="error"> {error} </div>
          }
        </div>

        <div className="col-2 mt-3">
          <span>
            <Button variant="contained" size="small" color="primary"
              onClick={() => saveProviderEducation()}>Add</Button>
          </span>
        </div>
      </div>

      <div>
        <div className="row m-0 col-12 p-0 px-3 justify-content-between">
          {
            providerEducations.map((item) => (
              <div className="row m-0 col-prov-edu-5 border-bottom mt-2">
                <div className="col-10" title={item.qualification}>{item.qualification}</div>
                <div className="col-2">
                  <span onClick={() => confirmDelete(item)}>
                    <DeleteIcon color="primary" />
                    {/* <span className="ml-1">Delete</span> */}
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderEducation;
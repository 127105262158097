import React, { useEffect, useState } from 'react';
import './NotificationPreferences.scss';
import { http, AlertService } from '../../fx-core';
import { session } from "../../fx-core";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const NotificationPreferences = (props: any) => {
  const history = useHistory();
  const initialList = [];

  const [notificationPreferences, setNotificationPreferences] = useState(initialList);
  const [userId, setUserId] = React.useState(parseInt(session.getItem('userId')));
  const [roleCode, setRoleCode] = React.useState(session.getItem('roleCode'));
  const allNotificationPrefData = [
    {
      category: 'Share Records After Appointment',
      actions: [
        { action: 'Share Documents', emailKey: 'ShareDocumentMail', smsKey: 'ShareDocumentSMS', pushKey: 'ShareDocumentPush' },
        { action: 'Share Medicines', emailKey: '', smsKey: '', pushKey: 'ShareMedicinePush' },
        { action: 'Share Forms', emailKey: '', smsKey: '', pushKey: 'ShareFormPush' },
        { action: 'Share Vitals', emailKey: '', smsKey: '', pushKey: 'ShareVitalPush' },
        { action: 'Share Scans', emailKey: 'ShareScanMail', smsKey: 'ShareScanSMS', pushKey: 'ShareScanPush' },
        { action: 'Appointment Confirmation', emailKey: 'AppointmentConfirmationMail', smsKey: 'AppointmentConfirmationSMS', pushKey: 'AppointmentConfirmationPush' },
        { action: 'Cancel appointment', emailKey: 'AppointmentCancellationMail', smsKey: 'AppointmentCancellationSMS', pushKey: 'AppointmentCancelledPush' },
        { action: 'No-show for appointment', emailKey: 'AppointmentNoShowMail', smsKey: 'AppointmentNoShowSMS', pushKey: 'AppointmentNoShowPush' },
        { action: 'Check-in for appointment', emailKey: 'AppointmentCheckInMail', smsKey: 'AppointmentCheckInSMS', pushKey: 'AppointmentCheckInPush' },
        { action: 'User arrived for appointment', emailKey: 'AppointmentPatientArrivedMail', smsKey: 'AppointmentPatientArrivedSMS', pushKey: 'AppointmentPatientArrivedPush' },
        { action: 'Complete appointment', emailKey: 'AppointmentCompleteMail', smsKey: 'AppointmentCompleteSMS', pushKey: 'AppointmentCompletePush' },
        { action: 'Reminder for appointment', emailKey: 'AppointmentReminderMail', smsKey: 'AppointmentReminderSMS', pushKey: 'AppointmentReminderPush' },
        { action: 'Provider cancel appointment', emailKey: 'ProviderAppointmentCancellationMail', smsKey: 'ProviderAppointmentCancellationSMS', pushKey: 'ProviderAppointmentCancelledPush' }
      ]
    }
  ];
  const [allNotificationPrefs, setAllNotificationPrefData] = React.useState(allNotificationPrefData);
  const [formModal, setFormModal] = React.useState({
    ShareDocumentMail: false,ShareDocumentSMS: false,ShareDocumentPush: false, ShareMedicinePush: false,
    ShareFormPush: false, ShareVitalPush: false,ShareScanMail:false,ShareScanSMS:false, ShareScanPush: false, 
    AppointmentConfirmationMail: false, AppointmentConfirmationSMS: false, AppointmentConfirmationPush: false,
    AppointmentCancellationMail: false, AppointmentCancellationSMS: false, AppointmentCancelledPush: false,
    AppointmentNoShowMail: false, AppointmentNoShowSMS: false, AppointmentNoShowPush: false,
    AppointmentCheckInMail: false, AppointmentCheckInSMS: false, AppointmentCheckInPush: false,
    AppointmentPatientArrivedMail: false, AppointmentPatientArrivedSMS: false, AppointmentPatientArrivedPush: false,
    AppointmentCompleteMail: false, AppointmentCompleteSMS: false, AppointmentCompletePush: false,
    AppointmentReminderMail: false, AppointmentReminderSMS: false, AppointmentReminderPush: false,
    ProviderAppointmentCancellationMail: false, ProviderAppointmentCancellationSMS: false, ProviderAppointmentCancelledPush: false
  });

  const handleCheckbox = (event, actionCode) => {
    let itemObj = notificationPreferences.find((i: any) => i.actionCode == actionCode);
    formModal[itemObj.actionCode] = event.target.checked;
    setFormModal(prevState => {
      return { ...prevState, ...formModal }
    });
  }

  const getNotificationPreferences = () => {
    let inputData = {
      roleCode: roleCode,
      userId: userId
    }
    let apiOptions: any = {
      url: `usernotificationpreference/byrolecode`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      for (let itemObj of result) {
        formModal[itemObj.actionCode] = itemObj.isEnabled;
      }
      setFormModal(prevState => {
        return { ...prevState, ...formModal }
      });
      console.log("this.formModel=", formModal);
      setNotificationPreferences(result);
    });
  }

  const saveNotificationPreference = () => {
    for (let item of notificationPreferences) {
      item.userId = userId;
      item.isEnabled = formModal[item.actionCode];
    }
    let inputData = {
      NotificationPreferences: notificationPreferences
    }
    let apiOptions: any = {
      url: `usernotificationpreference/save`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      AlertService.showSuccessMsg();
      history.goBack();
    });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getNotificationPreferences();
  }, []);


  const body = (
    <div className="content-container">
      <div className="title-font">
        <span>Notification Preferences</span>
      </div>

      <div className='px-3 py-3'>
        {
          allNotificationPrefs.map((item, i) => (
            <div className="row m-0 col-12 border-bottom align-items-center" key={i}>
              <div className="col-12 pl-2 pb-2"><b> {item.category}</b></div>
              {
                item.actions.map((itemObj) => (
                  <div className="col-12">
                    <span className="col-8">
                      {itemObj.action}
                    </span>
                    <span className="col-4 float-right">
                      <span>
                        {itemObj.emailKey &&
                          <span>
                            Email
                            <Checkbox name={itemObj.emailKey} color="primary" checked={formModal[itemObj.emailKey]}
                              value={formModal[itemObj.emailKey]} onChange={(e) => handleCheckbox(e, itemObj.emailKey)} />
                          </span>
                        }
                        {itemObj.smsKey &&
                          <span>
                            SMS
                            <Checkbox name={itemObj.smsKey} color="primary" checked={formModal[itemObj.smsKey]}
                              value={formModal[itemObj.smsKey]} onChange={(e) => handleCheckbox(e, itemObj.smsKey)} />
                          </span>
                        }
                        {itemObj.pushKey &&
                          <span>
                            Push
                            <Checkbox name={itemObj.pushKey} color="primary" checked={formModal[itemObj.pushKey]}
                              value={formModal[itemObj.pushKey]} onChange={(e) => handleCheckbox(e, itemObj.pushKey)} />
                          </span>
                        }
                      </span>
                    </span>
                  </div>
                ))
              }
            </div>
          ))

        }

        <div className="row justify-content-end m-0 pt-3">
          <Button variant="contained" size="small" onClick={back}>Cancel</Button>
          <span className="pl-3">
            <Button variant="contained" size="small" color="primary" onClick={saveNotificationPreference}>Save</Button>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default NotificationPreferences;
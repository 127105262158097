import React, { useEffect, useState } from 'react';
import './DashboardCardList.scss';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Dialog, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { http, FxInput, AlertService, general, useNavigationService } from '../../fx-core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import DashboardCardForm from '../DashboardCardForm/DashboardCardForm';
import dummyImage from "./../../../assets/images/dummy.png";
import * as _ from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const DashboardCardList = () => {
    const { pageTitle } = useNavigationService();
    let defaultFilter: any = {
        title: ''
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [dashboardCardList, setDashboardCardList] = useState([]);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);
    const [cardId, setCardId] = useState(null);
    const [selectedItem, setSelectedItem] = useState({});
    const [message, setMessage] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getDashboardCardList({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getDashboardCardList(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getDashboardCardList = (options: any = {}) => {
        let startIndex = 0;
        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            filter.title = options.filter.title;
        }
        let filters = {
            title: filter.title
        };
        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `userdashboardcard/query`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getDashboardCardListCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getDashboardCardListCallBack = (res, pagerOptions) => {
        const result = res.data;
        result.items = _.orderBy(result.items, ['displayOrder'], ['asc']);
        setDashboardCardList(result.items);
        for (let item of result.items) {
            item.imageUrl = general.getImageUrl(item.imagePath);
        }
        computePagerData(result, pagerOptions);
    };

    const openDashboardCardForm = (id) => {
        setCardId(id);
        setOpen(true);
    };

    const handleFormClose = () => {
        setOpen(false);
        let options = { pager: { pageNo: 1 }, filter: filter };
        getDashboardCardList(options);
    };

    const confirmDelete = (item) => {
        setMessage("Do you want to delete " + item.title + "?");
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    };

    const closeDeleteCard = () => {
        setSelectedItem({});
        setDeleteDialogOpen(false);
    };

    const confirmMessage = () => {
        setDeleteDialogOpen(false);
        deleteDashboardCard(selectedItem);
    };

    const deleteDashboardCard = (item: any) => {
        let inputData = {
            id: item.id,
        };
        let apiOptions: any = {
            url: `userdashboardcard/${inputData.id}`
        };
        http.delete(apiOptions).then(res => {
            deleteDashboardCardCallBack(res);
        })
    };

    const deleteDashboardCardCallBack = (res) => {
        AlertService.showSuccessMsg();
        setSelectedItem({});
        let options = { pager, filter };
        getDashboardCardList(options);
    };


    useEffect(() => {
        let options = { pager: { pageNo: 1 }, filter: filter };
        getDashboardCardList(options);
    }, []);

    return (
        <div className="content-container cardlist-ui">
            <div className="title-font">
                <span>{pageTitle}</span>
                <span onClick={() => openDashboardCardForm(0)} className="pointer"><AddCircleOutlineIcon /></span>
            </div>
            <div className="px-3">
                <div className="col-4 py-3">
                    <FxInput name="title" variant="outlined" label="Search Title"
                        size="small"
                        value={filter.title}
                        onValueChange={handleInputChange}
                    />
                </div>
                <TableContainer component={Paper}>
                    <Table stickyHeader className="table table-striped">
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-head table-font"></TableCell>
                                <TableCell className="table-head table-font">Title</TableCell>
                                <TableCell className="table-head table-font">Description</TableCell>
                                <TableCell className="table-head table-font">Screen</TableCell>
                                <TableCell className="table-head table-font">Target Title</TableCell>
                                <TableCell className="table-head table-font">Order</TableCell>
                                <TableCell className="table-head table-font">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dashboardCardList && dashboardCardList.length > 0 &&
                                dashboardCardList.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <a className="pointer">
                                                {!row.imageUrl && <img src={dummyImage} alt="dummy" className="imgStyle"></img>}
                                                {row.imageUrl && <img src={row.imageUrl} alt="img" className="imgStyle"></img>}
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                <span>{row.title}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                <span>{row.description}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                <span>{row.controlDisplay}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                <span>{row.targetTitle}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                <span>{row.displayOrder}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <span className="table-font">
                                                <EditIcon
                                                    color="primary"
                                                    className='pointer'
                                                    onClick={() => openDashboardCardForm(row.id)} />
                                            </span>
                                            <span className="table-font ml-3">
                                                <DeleteOutlineIcon
                                                    color="primary"
                                                    className='pointer'
                                                    onClick={() => confirmDelete(row)} />
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="pt-2 row m-0 col-12 justify-content-between">
                    <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                        padding={1}
                        paddingRight={1}
                    >
                        <Pagination
                            page={pager.pageNo}
                            count={pager.pageCount}
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                            onChange={pageChanged}
                        />
                    </Box>
                </div>
            </div>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={handleFormClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DashboardCardForm cardId={cardId} handleFormClose={handleFormClose} />
            </Dialog>
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteCard}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleClose={closeDeleteCard} onConfirmCallback={confirmMessage} />
            </Dialog>
        </div>
    )
};

export default DashboardCardList;
import React, { useState, useEffect } from 'react';
import './AppReleaseForm.scss';
import { FxInput, FxDate, AlertService, http, FormValidator } from '../../fx-core';
import { Button } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from 'react-router-dom';

const AppReleaseForm = (props) => {
    const history = useHistory();

    const defaultItem = {
        androidVersion: "",
        iosVersion: "",
        isForceUpdate: false,
        isLatest: true,
        releaseDate: "",
        id: props.location.state ? props.location.state.id : 0
    };
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});

    const validationMap = {
        releaseDate: ["required"]
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            let itemVal = { name: key, value: item[key], validations: validationMap[key] }
            items.push(itemVal);
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleCheckbox = (event) => {
        handleItemChange({ [event.target.name]: event.target.checked });
    };

    const saveAppRelease = () => {
        if (item.id > 0) {
            updateAppRelease();
        }
        else {
            addAppRelease();
        }
    }

    const addAppRelease = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `apprelease/add`,
                data: item
            };
            http.post(apiOptions).then(res => {
                saveAppReleaseCallBack(res);
            });
        }
    };

    const updateAppRelease = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `apprelease/update`,
                data: item
            };
            http.put(apiOptions).then(res => {
                saveAppReleaseCallBack(res);
            });
        }
    };

    const saveAppReleaseCallBack = (res) => {
        AlertService.showSuccessMsg();
        back();
    };

    const getAppReleaseById = () => {
        let apiOptions: any = {
            url: `apprelease/${item.id}`
        };
        http.get(apiOptions).then(res => {
            getAppReleaseByIdCallBack(res);
        });
    };

    const getAppReleaseByIdCallBack = (res) => {
        setItem(res.data);
    };

    const back = () => {
        history.goBack();
    };

    useEffect(() => {
        if (item.id > 0) {
            getAppReleaseById();
        }
    }, []);

    return (
        <div className="content-container2 card margintop">
            <div className="title-font pl-4">
                <span>App Release Form</span>
            </div>

            <div className="px-3">
                <div className="col-4 mx-auto pt-4">
                    <div className="col-12">
                        <FxInput name="androidVersion" variant="outlined" label="Android Version" size="small" fullWidth
                            value={item.androidVersion}
                            onValueChange={handleInputChange}
                        />
                    </div>

                    <div className="col-12 pt-3">
                        <FxInput name="iosVersion" variant="outlined" label="IOS Version" size="small" fullWidth
                            value={item.iosVersion}
                            onValueChange={handleInputChange}
                        />
                    </div>

                    <div className="col-12 pt-3">
                        <FxDate name="releaseDate" label="Release Date" variant="outlined" fullWidth
                            value={item.releaseDate} onValueChange={handleInputChange} />

                        {
                            hasError("releaseDate", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                    </div>

                    <div className="col-12">
                        <Checkbox name="isLatest" color="primary" checked={item.isLatest}
                            value={item.isLatest} onChange={handleCheckbox} />Is Latest
                    </div>

                    <div className="col-12">
                        <Checkbox name="isForceUpdate" color="primary" checked={item.isForceUpdate}
                            value={item.isForceUpdate} onChange={handleCheckbox} />Is Force Update
                    </div>

                </div>
                <div className="col-4 mx-auto text-right">
                    <span className="pl-2">
                        <Button variant="contained" size="small" color="primary" onClick={back}>Cancel</Button>
                    </span>
                    <span className="pl-2">
                        <Button variant="contained" size="small" color="primary" onClick={saveAppRelease}>Save</Button>
                    </span>
                </div>
            </div>
        </div>
    )
};

export default AppReleaseForm;
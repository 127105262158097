import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import './ScanForm.css';
import DicomUploader from '../../dicomuploader/DicomUploader';
import { Button } from '@material-ui/core';
import * as _ from 'lodash';
import { FxInput, FxTextarea, session, AlertService, http, general } from '../../fx-core';
import { useHistory } from "react-router-dom";
import uploadImage from "./../../../assets/images/upload_info.png";

const ScanForm = (props) => {
  const history = useHistory();

  let defaultItem: any = {
    customerId: props.match && props.match.params.custId ? parseInt(props.match.params.custId) : 0,
    scanName: '',
    notes: '',
    id: props.match && props.match.params.id ? parseInt(props.match.params.id) : 0,
    appointmentId: props.match && props.match.params.apptId ? parseInt(props.match.params.apptId) : 0
  };
  const strTitle = props.match && props.match.params ? props.match.params.title : "Scan Form";
  const [title, setTitle] = useState(strTitle);
  const [type, setType] = useState(props.match && props.match.params ? parseInt(props.match.params.type) : "");
  const initialList = [];
  const [item, setItem] = useState(defaultItem);
  const [openView, setOpenView] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [studyIdList, setStudyIdList] = useState(defaultItem);
  const [uploadedList, setUploadedList] = useState([]);


  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getScanById = () => {
    let apiOptions: any = {
      url: `scan/${item.id}`
    };
    http.get(apiOptions).then(res => {
      setItem(res.data);
    });
  };


  function collectScanDetails() {
    let studyIdList = [];
    if (uploadedList && uploadedList.length > 0) {
      studyIdList = _.uniq(_.map(uploadedList, 'studyId'));
      console.log('uniqueStudyIdList=', studyIdList);
    }

    let studyIds = [];
    for (let item of studyIdList) {
      let newItem = {
        StudyId: item
      };
      studyIds.push(newItem);
    }
    return studyIds;
  }

  const saveScanInfo = () => {
    setError(null);
    if (!item.scanName || (item.scanName && general.emptyStringValidator(item.scanName))) {
      setError("Scan Name is required");
      return;
    }
    let scanDetails = collectScanDetails();
    if (item.id == 0 && scanDetails.length == 0) {
      AlertService.showErrorMsg("Please upload files");
      return;
    }
    let inputData: any;
    if (item.id > 0) {
      inputData = {
        scan: item
      };
    }
    else {
      inputData = {
        scan: item,
        scanDetails: scanDetails
      };
    }
    let apiOptions: any = {
      url: `scan/save`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      saveScanCallBack(res);
    });
  };

  const saveScanCallBack = (res) => {
    AlertService.showSuccessMsg();
    navigateBack();
  };

  const openViewer = () => {
    setOpenView(true);
  }
  const closeViewer = (result: any) => {
    setOpenView(false);
  }

  const navigateBack = () => {
    if (type == 1) {
      history.push({
        pathname: '/scans', state: {}
      });
    }
    else if (type == 2) {
      history.push({
        pathname: '/appointments', state: {}
      });
    }
  }

  useEffect(() => {
    if (item.id > 0) {
      getScanById();
    }
  }, []);

  const onUploadSuccess = (result: any) => {
    setUploadedList(result.uploadedList)
  }

  return (
    <>
      <div className="content-container">
        <div className="title-font">
          <span>{title}</span>
        </div>

        <div className="row m-0">
          <div className="col-6">
            <div className="col-6">
              <FxInput name="scanName" variant="outlined" label="Scan Name*" size="small" fullWidth
                value={item.scanName}
                onValueChange={handleInputChange}
              />
              {
                error &&
                <div className="validation-msg">{error}</div>
              }
            </div>
            <div className="col-12">
              <FxTextarea name="notes" variant="outlined" label="Notes" size="small"
                value={item.notes} maxRows={5}
                onValueChange={handleInputChange}
              />
            </div>
            <div className="col-12">
              <span className="col-3">
                <Button variant="contained" size="small" onClick={navigateBack}>Back</Button>
              </span>
              <span className="col-3">
                <Button variant="contained" size="small" color="primary"
                  onClick={saveScanInfo}>Save</Button>
              </span>

            </div>
          </div>
          {!item.id &&
            <div className="col-6">
              <div className="col-6">
                <DicomUploader onUploadSuccess={onUploadSuccess} />
              </div>
              <div className="col-12">
                <div className="col-12">
                  <span className="bold-text pl-2">Provider shall upload folder at Patient or Study level</span>
                </div>
                <div className="col-12 pt-4">
                  <img src={uploadImage} alt="upload_info" className="uploadimgstyle pl-2"></img>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default ScanForm;
import React, { useEffect } from 'react';
import './ImageViewer.scss';
import CloseIcon from '@material-ui/icons/Close';

export const ImageViewer = (props: any) => {

  return (
    <div className="imageviewer-ui">
      <div className="custom-head">
      <div className="row title-font mx-auto">      
        <div className="col-11 pl-0">
          <span>{props.docName}</span>
        </div>
        <div className="col-1 text-right pointer" onClick={props.handleClose}>
          <CloseIcon fontSize="small" color="secondary" />
        </div>
      </div>
      </div>
      <div className="pt-5 my-auto text-center">
        <img src={props.docData} className='img-style' />
      </div>
    </div>
  );
}
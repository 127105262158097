import moment from 'moment';
import { session }  from './sessionService';

export const general = {
    getFormattedDate: getFormattedDate,
    getFormattedTime: getFormattedTime,
    getImageUrl: getImageUrl,
    emptyStringValidator: emptyStringValidator
}

function getFormattedDate(date: any) {
    let formattedDate = moment(date).format("DD/MM/yyyy");
    return formattedDate;
}
function getFormattedTime(time: any) {
    let formattedTime = moment(time, 'hhmm').format('hh:mm A');
    return formattedTime;
}

function getImageUrl(imagePath:any) {
    if (imagePath) {
        let imagStorageUrl = session.getItem("imagStorageUrl");
        let result = imagStorageUrl + imagePath;
        return result;
    }
    return "";
}

//validator for empty space entered
function emptyStringValidator(controlValue:any) {
    controlValue = controlValue ? controlValue.trim() : null;
    if (controlValue.length == 0) {
        return true;
    }
}
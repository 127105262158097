import React, { useEffect, useState } from 'react';
import './ProviderService.scss';
import { FxInput, FxSelect, http, AlertService } from '../../fx-core';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import * as _ from 'lodash';

const ProviderService = (props: any) => {
  let defaultItem: any = {
    serviceCode: "-1",
    serviceName: null,
    providerId: props.providerId
  };
  const initialList = [];
  const [services, setServices] = useState(initialList);
  const [item, setItem] = useState(defaultItem);
  const [otherFlag, setOtherFlag] = useState(false);
  const [providerServices, setProviderServices] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [error, setError] = React.useState(null);
  const [otherError, setOtherError] = React.useState(null);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleStatusChange = (item) => {
    if (item.value == "other") {
      setOtherFlag(true);
      handleItemChange({ ["serviceName"]: "" });
    }
    else {
      setOtherFlag(false);
    }
    handleItemChange({ [item.name]: item.value });
  }

  const initLookup = () => {
    let inputData = {
      lookups: {
        ServiceType: { default: true }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'Options/GetLookupOptions',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let lastIdx = _.lastIndexOf(response.data.ServiceType);
      response.data.ServiceType.splice(lastIdx + 1, 0, { 'code': 'other', "text": "Other" });
      setServices(response.data.ServiceType);
    });
  }


  const saveProviderService = () => {
    setOtherError(false);
    setError(null);

    if (item.serviceCode == "-1") {
      setError("This field is required");
      return;
    }
    if (item.serviceCode == 'other' && !item.serviceName) {
      setOtherError("This field is required");
      return;
    }
    if (item.serviceCode && item.serviceCode != 'other') {
      let itemObj = _.find(services, { code: item.serviceCode });
      item.serviceName = itemObj.text;
    }

    let apiOptions: any = {
      url: 'providerservice/add',
      data: item
    };
    http.post(apiOptions).then(response => {
      saveServiceCallBack(response);
    });
  }

  const saveServiceCallBack = (res) => {
    setOtherFlag(false);
    setItem(defaultItem);
    AlertService.showSuccessMsg();
    initLookup();
    getProviderServices();
  };

  const getProviderServices = () => {
    let inputData = {
      providerId: props.providerId
    }
    let apiOptions: any = {
      url: 'providerservice/query', data: inputData
    };
    http.post(apiOptions).then(response => {
      setProviderServices(response.data);
    });
  }

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setMessage("Do you want to delete " + item.serviceName + "?");
    setOpen(true);
  };

  const confirmMessage = () => {
    setOpen(false);
    deleteService(selectedItem);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const deleteService = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `providerservice/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderServices();
    })
  }


  useEffect(() => {
    initLookup();
    getProviderServices();
  }, []);


  const body = (
    <div className="content-container1 card provider-ser">
      <div className="title-font">
        <span>Services</span>
      </div>
      <div className="row m-0 px-3">
        <div className="col-6 ">
          <FxSelect name="serviceCode" variant="outlined" label="Service*"
            options={services}
            selectedValue={item.serviceCode}
            valueField="code"
            displayField="text"
            onValueChange={handleStatusChange} />
          {
            error && item.serviceCode == "-1" &&
            <div className="error"> {error} </div>
          }
        </div>
        {
          otherFlag &&
          <div className="pl-3 ">
            <FxInput name="serviceName" variant="outlined" label="Other Service" size="small"
              value={item.serviceName}
              onValueChange={handleInputChange}
            />
            {
              otherError && !item.serviceName &&
              <div className="error"> {otherError} </div>
            }
          </div>
        }


        <div className="mt-3">
          <Button variant="contained" size="small" color="primary"
            onClick={() => saveProviderService()}>Add</Button>
        </div>
      </div>

      <div>
        <div className="row m-0 col-12 p-0 px-3 justify-content-between">
          {
            providerServices.map((item) => (
              <div className="row m-0 col-prov-edu-5 border-bottom mt-2">
                <div className="col-10 text-truncate" title={item.serviceName}>{item.serviceName}</div>
                <div className="col-2">
                  <span onClick={() => confirmDelete(item)}>
                    <DeleteIcon color="primary" />
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderService;